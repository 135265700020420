import React, { Fragment, useEffect, useState } from "react";
import "../../../../../components/CheckboxComponent/FeedCheckboxComponent.css";
import { useLoadProductBankPages } from "../../../../../Utils/customHooks/UpToDateContent/useLoadProductBankCount";
import { useLoadProductBankContent } from "../../../../../Utils/customHooks/UpToDateContent/useLoadProductBankConent";
import SearchInput from "../../../../../components/SearchInput/SearchInput";
import AllContentTable from "../../../../../components/EditTemplates/AllContentTable";
import { Button, Carousel, Input, Pagination, Skeleton } from "antd";
import { supabase } from "../../../../../config/supabaseClient";
import { HiCheck, HiPencil } from "react-icons/hi";
import { UpdateCombinedText } from "../../../../../Utils/SupabaseServices/ContentDatabase";

interface Post {
  table_id: any;
  table_image?: any;
  thumbnail?: any;
  table_name?: any;
  shop_title?: any;
}
const POSTS_PER_PAGE = 10;

const EditProductBankCheckboxComponent = (props: any) => {
  const {
    username,
    user_id,
    isShopLinks,
    componentName,
    post_ids,
    defaultQuery,
    setDefaultQuery,
    defaultAllPosts,
    // refetchCustomProduct,
    setRemovePosts,
    removePosts,
    isChange,
    setIsChange,
    initial, setInitial, edit, setEdit, editIndex, setEditIndex
  } = props;
  const [allPosts, setAllPosts] = useState([]);
  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postIds, setPostIds] = useState<any>();
  const [isActionLoading, setIsActionLoading] = useState(false);

  useEffect(() => {
    // setPostIds(post_ids);
    setAllPosts(defaultAllPosts);
  }, [defaultAllPosts, initial]);




  const { data: pages, refetch: refetchCount } = useLoadProductBankPages(
    user_id || "",
    POSTS_PER_PAGE,
    query,
    username || "",
    "All",
    "AllLinks"
  );
  const { data, isLoading, isError, error, refetch } =
    useLoadProductBankContent(
      user_id || "",
      username || "",
      currentPage,
      POSTS_PER_PAGE,
      pages?.totalPages,
      query,
      "All",
      "AllLinks"
    );

  function handleFeed(postIds: any) {
    setPostIds(postIds);
    props.handlePostIds(postIds);
  }

  useEffect(() => {
    setQuery(defaultQuery);
    if (defaultQuery === "") {
      refetch();
    }
  }, [defaultQuery]);
  return (
    <>
      <div className="margin-added-content">
        <AddedContentTable
          // defaultPosts={defaultAllPosts}
          posts={allPosts}
          username={username}
          user_id={user_id}
          setAllPosts={setAllPosts}
          componentName={componentName}
          handleFeed={handleFeed}
          refetch={refetch}
          setPostIds={setPostIds}
          setRemovePosts={setRemovePosts}
          setIsChange={setIsChange}
          initial={initial}
          setInitial={setInitial}
          edit={edit}
          setEdit={setEdit}
          editIndex={editIndex}
          setEditIndex={setEditIndex}
          isActionLoading={isActionLoading}
          setIsActionLoading={setIsActionLoading}
        />
        <SearchInput
          placeholder="Search your product bank"
          id="search-feed"
          value={query}
          onChange={(e: any) => {
            e.preventDefault();
            const elem: any = document.getElementById("search-feed");
            const searchVal = e.target.value;
            setQuery(e.target.value);
            setDefaultQuery(e.target.value);
            setCurrentPage(1);
          }}
          handleKeyUp={(e: any) => {
            e.preventDefault();
            if (e.keyCode === 13) {
              const elem: any = document.getElementById("search-feed");
              const searchVal = e.target.value;
              if (searchVal.length === 0) {
                elem.blur();
                setQuery("");
                return;
              }
              elem.blur();
              setQuery(searchVal);
              setDefaultQuery(searchVal);
              setCurrentPage(1);
            }
          }}
          isActiveFilter={Boolean(query)}
          onSearchClick={() => {
            const elem: any = document.getElementById("search-feed");
            const searchVal = elem.value;
            if (searchVal.length === 0) {
              return;
            }
            elem.blur();
            setQuery(searchVal);
            setDefaultQuery(searchVal);
            setCurrentPage(1);
          }}
          onClearClick={() => {
            const elem: any = document.getElementById("search-feed");
            elem.value = "";
            setCurrentPage(1);
            setQuery("");
            setDefaultQuery("");
            refetch();
            setCurrentPage(1);
          }}
        />
      </div>

      {/* Wrap the ContentTable component with InfiniteScroll */}

      <AllContentTable
        username={username}
        user_id={user_id}
        data={data}
        handleFeed={handleFeed}
        posts={allPosts}
        query={query}
        isLoading={isLoading}
        setAllPosts={setAllPosts}
        componentName={componentName}
        setRemovePosts={setRemovePosts}
        refetch={refetch}
        isActionLoading={isActionLoading}
        setIsActionLoading={setIsActionLoading}
      />

      {/* Render the Pagination component */}
      {pages?.feedCount > POSTS_PER_PAGE && (
        <Pagination
          className="pagination"
          current={currentPage}
          total={pages?.feedCount}
          size="small"
          showSizeChanger={false}
          showQuickJumper
          pageSize={POSTS_PER_PAGE}
          onChange={(page: number) => setCurrentPage(page)}
        />
      )}
    </>
  );
};

const AddedContentTable = ({
  // defaultPosts,
  username,
  user_id,
  posts,
  setAllPosts,
  componentName,
  handleFeed,
  refetch,
  setRemovePosts,
  setPostIds,
  setIsChange,
  initial,
  edit,
  setEdit,
  editIndex,
  setEditIndex,
  isActionLoading,
  setIsActionLoading
}: any) => {
  const [checkedValues, setCheckedValues] = useState<Post[]>([]);
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    setCheckedValues(posts);
  }, [posts, initial]);

  const onMinus = async (post_id: string) => {
    setIsActionLoading(true);
    try {
      const updatedPostArray = checkedValues.filter(
        (post) => post.table_id !== post_id
      );
      setRemovePosts((prev: any) => [
        ...(prev || []),
        checkedValues.find((post) => post.table_id === post_id)!,
      ]);
      setPostIds(updatedPostArray);
      setCheckedValues(updatedPostArray);
      await Promise.all([
        handleFeed(updatedPostArray),
      ]);
      setAllPosts(updatedPostArray);
      await new Promise(resolve => setTimeout(resolve, 1000))
    } finally {
      setIsActionLoading(false);
    }
  };

  const handleDone = async (post_id: string) => {
    const { data: user, error } = await supabase
      .from("product_bank")
      .select("*")
      .eq("product_id", post_id)
      .eq("user_id", user_id);

    await supabase
      .from("product_bank")
      .update({ shop_title: value })
      .eq("product_id", post_id)
      .eq("user_id", user_id);
    await supabase
      .from(`${username}`)
      .update({ shop_title: value, caption: value, product_name: value })
      .eq("post_id", post_id)
      .eq("user_id", user_id);
    UpdateCombinedText(username, user_id, post_id);
    // setIsChange(true)
    const postData = checkedValues.find((post) => post.table_id === post_id);
    if (postData) {
      postData.shop_title = value;
      setEdit(false);
      setEditIndex(null);
    }
    refetch();
  };
  const SkeletonStatBox = () => (
    <div className="skeletonDiv">
      <Skeleton className="skeletonStyle" />
    </div>
  );
  return (
    <div className="addedContent">
      <h5 className="addedContentTitle">{componentName || "Carousel Title"}</h5>
      <div className="addedSvg">
        {checkedValues && checkedValues.length > 0 ? (
          checkedValues.map((post, index) => (
            <Fragment key={index}>
              <div className="feed-tab-post-container added-post">
                <div className="feed-tab-media-wrapper">
                  <Button
                    onClick={() => onMinus(post.table_id)}
                    className="carousel-content-remove-btn"
                  >
                    Remove
                  </Button>
                  {post.table_image &&
                    <img
                      className="feed-tab-media-check"
                      src={post.table_image}
                      alt="post"
                    />
                  }
                </div>
                {edit && editIndex === index ? (
                  <>
                    <Input
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                    />
                    <HiCheck onClick={() => handleDone(post.table_id)} />
                  </>
                ) : (
                  <p style={{ textAlign: post.table_name ? "left" : "center" }}>
                    {post?.shop_title ? post?.shop_title : post?.table_name}{" "}
                    <HiPencil
                      onClick={() => {
                        setValue(
                          post?.shop_title ? post?.shop_title : post?.table_name
                        );
                        setEdit(true);
                        setEditIndex(index);
                      }}
                    />
                  </p>
                )}
              </div>
            </Fragment>
          ))
        ) : (
          <div className="feed-tab-post">
            {[...Array(4)].map((_, idx) => (
              <SkeletonStatBox key={idx} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default EditProductBankCheckboxComponent;
