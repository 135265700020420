import React, { Fragment, useEffect, useState } from "react";
import { Button, Carousel, Input, Pagination, Space } from "antd";
import { useLoadSearchFeedCount } from "../../../../Utils/customHooks/UpToDateContent/useLoadSearchFeedCount";
import { StaticVariables } from "../../../../Utils/StaticVariables";
import { useLoadSearchFeed } from "../../../../Utils/customHooks/UpToDateContent/useLoadSearchFeed";
import "../../../../styles/StayUptoDateCSS/FeedCheckboxComponent.css";
import { useLoadPostIdsData } from "../../../../Utils/customHooks/UpToDateContent/useLoadPostIdsData";
import Skeleton from "react-loading-skeleton";
import { supabase } from "../../../../config/supabaseClient";
import { HiCheck, HiPencil } from "react-icons/hi";
import VideoComponent from "../../../../components/VideoComponent/VideoComponent";
import { UpdateCombinedText } from "../../../../Utils/SupabaseServices/ContentDatabase";
import CarouselFilterControl from "../../../../components/CarouselControls/CarouselFilterControl";
import SearchbarControls from "../../Controls/LiveViewControls/Components/SearchbarControls";
import CarouselSearchbar from "../../../../components/CarouselControls/CarouselSearchbar";
import CarouselOrderControl from "../../../../components/CarouselControls/CarouselOrderControl";
import CarouselShapeControl from "../../../../components/CarouselControls/CarouselShapeControl";
import CarouselSoundControl from "../../../../components/CarouselControls/CarouselSoundControl";
import Title from "antd/es/typography/Title";
import { getPostTitle } from "../../../../Utils/HelperFunction/HelperFunction";
import LoadingPost from "../../../../components/skeleton/Views/LoadingPost";

const POSTS_PER_PAGE = 10;

const contentOrder = "Default";
const FeedCheckboxComponent = (props: any) => {
  const {
    username,
    user_id,
    isShopLinks,
    componentName,
    post_ids,
    defaultQuery,
    setDefaultQuery,
    type,
    activeFilter,
    setActiveFilter,
    isSoundOn,
    order,
    setOrder,
    shape,
    setShape,
    setIsSoundOn,
  } = props;

  const showSocials =
    type === "content"
      ? StaticVariables().CONTENT_PLATFORMS
      : activeFilter === "both"
        ? StaticVariables().PLATFORMS
        : activeFilter === "content"
          ? StaticVariables().CONTENT_PLATFORMS
          : activeFilter === "products"
            ? StaticVariables().PRODUCT_PLATFORMS
            : StaticVariables().PLATFORMS;

  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [postIds, setPostIds] = useState<any>();
  useEffect(() => {
    setPostIds(post_ids);
    setQuery(defaultQuery);
    if (defaultQuery === "") {
      refetch();
    }
  }, [post_ids, defaultQuery]);
  const { data: pages }: any = useLoadSearchFeedCount(
    username,
    user_id,
    query,
    POSTS_PER_PAGE,
    showSocials,
    isShopLinks,
    activeFilter
  );
  const {
    isLoading,
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  }: any = useLoadSearchFeed(
    currentPage,
    username,
    user_id,
    query,
    POSTS_PER_PAGE,
    pages?.totalPages || 0,
    pages?.feedCount,
    pages?.postIds,
    showSocials,
    contentOrder,
    isShopLinks,
    activeFilter
  );
  function handleFeed(postIds: any) {
    setPostIds(postIds);
    props.handlePostIds(postIds);
  }

  return (
    <>
      <div className="margin-table">
        <AddedContentTable
          post_ids={postIds}
          username={username}
          user_id={user_id}
          setPostIds={setPostIds}
          componentName={componentName}
          handlePostIds={props.handlePostIds}
          handleFeed={handleFeed}
          type={type}
          shape={shape}
          isSoundOn={isSoundOn}
          isActionLoading={isActionLoading}
          setIsActionLoading={setIsActionLoading}
        />
        <Title level={3}>Carosuel Control</Title>
        <div className="addedContent">
          <CarouselShapeControl shape={shape} setShape={setShape} />
          <CarouselSoundControl
            isSoundOn={isSoundOn}
            setIsSoundOn={setIsSoundOn}
          />
        </div>
        <CarouselFilterControl
          type={type}
          setActiveFilter={setActiveFilter}
          activeFilter={activeFilter}
          setCurrentPage={setCurrentPage}
        />

        <CarouselSearchbar
          isShopLinks={isShopLinks}
          query={query}
          setQuery={setQuery}
          setDefaultQuery={setDefaultQuery}
          setCurrentPage={setCurrentPage}
          setActiveFilter={setActiveFilter}
          refetch={refetch}
          postIds={postIds}
          setPostIds={setPostIds}
        />
      </div>

      <ContentTable
        username={username}
        user_id={user_id}
        data={data}
        handleFeed={handleFeed}
        postIds={postIds}
        query={query}
        isLoading={isLoading}
        isShopLinks={isShopLinks}
        type={type}
        isActionLoading={isActionLoading}
        setIsActionLoading={setIsActionLoading}
      />

      {pages?.feedCount > POSTS_PER_PAGE && (
        <Pagination
          className="pagination"
          current={currentPage}
          total={pages?.feedCount}
          size="small"
          showSizeChanger={false}
          showQuickJumper
          pageSize={POSTS_PER_PAGE}
          onChange={(page: number) => setCurrentPage(page)}
        />
      )}
    </>
  );
};

const AddedContentTable = (props: any) => {
  const {
    username,
    user_id,
    post_ids,
    componentName,
    handleFeed,
    type,
    shape,
    isSoundOn,
    isActionLoading,
    setIsActionLoading
  } = props;
  const [postIds, setPostIds] = useState(post_ids);
  useEffect(() => {
    setPostIds(post_ids);
  }, [post_ids]);
  const {
    isLoading: PostIdFeedLoading,
    data: PostIdData,

    refetch: PostIdDataRefetch,
  }: any = useLoadPostIdsData(
    username,
    user_id,

    postIds
  );

  const onMinus = async (post_id: string) => {
    setIsActionLoading(true);
    try {
      const isChecked = false;
      let updatedPostArray = postIds.filter((postId: any) => postId !== post_id);

      props.setPostIds(updatedPostArray);
      setPostIds(updatedPostArray);
      await Promise.all([
        handleFeed(updatedPostArray)
      ]);

      await new Promise(resolve => setTimeout(resolve, 1000));
    } finally {
      setIsActionLoading(false);
    }
  };
  const [data, setData] = useState<any>([]);
  const [value, setValue] = useState<string>();
  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  useEffect(() => {
    setEdit(false);
    setEditIndex("");
  }, [post_ids]);

  const handleDone = async (post_id: string) => {
    if (type === "content" || type === "query-content") {
      const { data: user, error } = await supabase
        .from(`${username}`)
        .update({ content_title: value })
        .eq("post_id", post_id)
        .eq("user_id", user_id);
      const { data: newProduct, error: newProductErroor } = await supabase
        .from("product_bank")
        .update({ content_title: value })
        .eq("product_id", post_id)
        .eq("user_id", user_id);
    } else {
      const { data: user, error } = await supabase
        .from(`${username}`)
        .update({ shop_title: value, caption: value, product_name: value })
        .eq("post_id", post_id)
        .eq("user_id", user_id);
      const { data: newProduct, error: newProductErroor } = await supabase
        .from("product_bank")
        .update({ shop_title: value })
        .eq("product_id", post_id)
        .eq("user_id", user_id);
    }

    const { data: newContent, error: newErroor } = await supabase
      .from("content_new")
      .update({ product_name: value })
      .eq("post_id", post_id)
      .eq("user_id", user_id);
    UpdateCombinedText(username, user_id, post_id);
    setEdit(false);
    setEditIndex("");
    PostIdDataRefetch();
  };

  const SkeletonStatBox = () => {
    return (
      <div className="skeletonDiv">
        <Skeleton className="skeletonStyle" />
      </div>
    );
  };
  return (
    <>
      <div className="addedContent">
        <h5 className="addedContentTitle">
          {componentName ? componentName : "Carousel Title"}
        </h5>
        <div className="addedSvg">
          {PostIdData && PostIdData.length ? (
            PostIdData.map((post: any, index: any) => (
              <React.Fragment key={index}>
                <div key={index} className="feed-tab-post-container added-post">
                  <div
                    className="feed-tab-media-wrapper"
                    style={
                      post?.platform === "newsletter" ||
                      post?.platform === "linkedin" ||
                      post?.platform === "twitter"
                        ? {
                            textAlign: "center",
                            height: "100%",
                            borderRadius: "1rem",
                          }
                        : {}
                    }
                  >
                    <Button
                      onClick={() => onMinus(post.post_id)}
                      className="carousel-content-remove-btn"
                    >
                      Remove
                    </Button>

                    {post?.platform === "newsletter" ? (
                      <div
                        className={`${
                          post?.type === "FEED" || shape === "sq"
                            ? "square-wrapper"
                            : ""
                        } no-media-url`}
                        // style={{ backgroundColor: "black", color: "white" }}
                      >
                        <div
                          className={`${
                            post?.type === "FEED" || shape === "sq"
                              ? "newsletter-media-carousel square-image"
                              : "newsletter-media-carousel h-250"
                          }`}
                        >
                          <h4 className="newsletter-label">Newsletter</h4>
                          <h4 className="mt-7 date">
                            {new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              day: "numeric",
                              month: "long",
                            }).format(new Date(post.post_date))}
                          </h4>
                        </div>
                      </div>
                    ) : post?.platform === "linkedin" && !post?.media_url ? (
                      <div
                        className={`${
                          post?.type === "FEED" || shape === "sq"
                            ? "square-wrapper"
                            : ""
                        } no-media-url`}
                        // style={{ backgroundColor: "black", color: "white" }}
                      >
                        <div
                          className={`${
                            post?.type === "FEED" || shape === "sq"
                              ? "newsletter-media-carousel square-image"
                              : "newsletter-media-carousel h-250"
                          }`}
                        >
                          <h4 className="newsletter-label">LinkedIn Post</h4>
                          <h4 className="mt-7 date">
                            {new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              day: "numeric",
                              month: "long",
                            }).format(new Date(post.post_date))}
                          </h4>
                        </div>
                      </div>
                    ) : post?.platform === "twitter" && !post?.media_url ? (
                      <div
                        className={`${
                          post?.type === "FEED" || shape === "sq"
                            ? "square-wrapper"
                            : ""
                        } no-media-url`}
                        // style={{ backgroundColor: "black", color: "white" }}
                      >
                        <div
                          className={`${
                            post?.type === "FEED" || shape === "sq"
                              ? "newsletter-media-carousel square-image"
                              : "newsletter-media-carousel h-250"
                          }`}
                        >
                          <h4 className="newsletter-label">Twitter Post</h4>
                          <h4 className="mt-7 date">
                            {new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              day: "numeric",
                              month: "long",
                            }).format(new Date(post.post_date))}
                          </h4>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`${
                          post?.type === "FEED" || shape === "sq"
                            ? "square-grid"
                            : ""
                        }`}
                      >
                        {post?.media_url ? (
                          <div
                            className={`${
                              post?.type === "FEED" || shape === "sq"
                                ? "square-wrapper"
                                : ""
                            }`}
                          >
                            {post?.media_url.split(",").length > 1 &&
                            post?.media_url
                              .split(",")
                              .every((url: any) => url.trim() !== "") ? (
                              <Carousel
                                dotPosition="bottom"
                                className="feed-tab-media-carousel"
                              >
                                {post?.media_url
                                  .split(",")
                                  .map((media_url: any, index: any) => (
                                    <React.Fragment key={index}>
                                      {media_url.includes("video") ? (
                                        <VideoComponent
                                          media_url={media_url}
                                          thumbnail={
                                            post.thumbnail &&
                                            post.thumbnail.split(",").length &&
                                            post.thumbnail.split(",")[index]
                                              ? post.thumbnail.split(",")[index]
                                              : "/assets/dummy_video.png"
                                          }
                                          content_shape={shape}
                                          isSoundOn={isSoundOn}
                                        />
                                      ) : (
                                        <img
                                          className={`${
                                            post?.type === "FEED" ||
                                            shape === "sq"
                                              ? "feed-tab-media-check square-image mb-2"
                                              : "feed-tab-media-check mb-2"
                                          }`}
                                          src={media_url}
                                          alt="post"
                                        />
                                      )}
                                    </React.Fragment>
                                  ))}
                              </Carousel>
                            ) : (
                              <>
                                {post.media_url.includes("video") ? (
                                  <div
                                    className={`${
                                      post?.type === "FEED" || shape === "sq"
                                        ? "square-wrapper"
                                        : "h-255"
                                    }`}
                                  >
                                    <VideoComponent
                                      media_url={post.media_url}
                                      thumbnail={post.thumbnail}
                                      content_shape={shape}
                                      isSoundOn={isSoundOn}
                                    />
                                  </div>
                                ) : (
                                  <img
                                    className={`${
                                      post?.type === "FEED" || shape === "sq"
                                        ? "feed-tab-media-check square-image"
                                        : "feed-tab-media-check"
                                    }`}
                                    src={
                                      post.media_url
                                        ? post.media_url
                                        : "/assets/dummy_video.png"
                                    }
                                    alt="post"
                                  />
                                )}
                              </>
                            )}
                          </div>
                        ) : (
                          <div
                            className={`${
                              post?.type === "FEED" || shape === "sq"
                                ? "square-wrapper"
                                : ""
                            }`}
                          >
                            <img
                              className={`${
                                post?.type === "FEED" || shape === "sq"
                                  ? "feed-tab-media-check square-image"
                                  : "feed-tab-media-check"
                              }`}
                              src={
                                post?.thumbnail
                                  ? post?.thumbnail
                                  : "/assets/dummy_video.png"
                              }
                              alt="post"
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <>
                    {" "}
                    {edit === true && editIndex === index ? (
                      <>
                        <Input
                          value={value}
                          onChange={(e: any) => setValue(e.target.value)}
                          className="w-80"
                        />{" "}
                        <HiCheck
                          onClick={() => {
                            handleDone(post.post_id);
                          }}
                        />
                      </>
                    ) : (
                      <p
                        style={{
                          textAlign:
                            post?.shop_title !== "" ||
                            post?.product_name !== "" ||
                            post?.content_title !== ""
                              ? "left"
                              : "center",
                        }}
                      >
                        {getPostTitle(post, type)}
                        <HiPencil
                          onClick={() => {
                            const nameValue = getPostTitle(post, type);
                            setValue(nameValue);
                            setEdit(true);
                            setEditIndex(index);
                          }}
                        />
                      </p>
                    )}
                  </>
                </div>
              </React.Fragment>
            ))
          ) : (
            <React.Fragment>
              {/* <p>No Added Data</p> */}
              <>
                <div
                  // key={index}
                  // className="feed-tab-post-container"
                  className="feed-tab-post"
                >
                  {[...Array(4)].map((_, index) => (
                    <SkeletonStatBox key={index} />
                  ))}
                </div>
              </>
            </React.Fragment>
          )}
        </div>
      </div>
    </>
  );
};

const ContentTable = (props: any) => {
  const { data, postIds, isLoading, query, isShopLinks, type, isActionLoading, setIsActionLoading } = props;
  const [checkedValues, setCheckedValues] = useState<any>([]);

  const onAdd = async (post_id: any) => {
    setIsActionLoading(true);
    try {
      if (!checkedValues.includes(post_id)) {
        const updatedCheckedValues = [post_id, ...checkedValues];
        setCheckedValues(updatedCheckedValues);
        await Promise.all([
          props.handleFeed(updatedCheckedValues),
        ]);
        await new Promise(resolve => setTimeout(resolve, 1000))
      }
    } finally {
      setIsActionLoading(false);
    }
  };

  const onMinus = async (post_id: any) => {
    setIsActionLoading(true);
    try {
      const updatedCheckedValues = checkedValues.filter(
        (postId: any) => postId !== post_id
      );
      setCheckedValues(updatedCheckedValues);
      await Promise.all([
        props.handleFeed(updatedCheckedValues),
      ]);
      await new Promise(resolve => setTimeout(resolve, 1000))
    } finally {
      setIsActionLoading(false);
    }
  };

  useEffect(() => {
    setCheckedValues(postIds);
  }, [postIds]);

  return (
    <>
      {!isLoading && !isActionLoading ? (
        <>
          {data?.pages ? (
            <div className="feed-tab-posts-container">
              {data.pages.map((group: any, pageIndex: number) => (
                <Fragment key={pageIndex}>
                  {group.map((image: any, index: number) => (
                    <div
                      key={index}
                      className="feed-tab-post-container feed-post-wrapper"
                    >
                      <div
                        className="feed-tab-media-wrapper"
                        style={
                          image?.platform === "newsletter" ||
                            image?.platform === "linkedin" ||
                            image?.platform === "twitter"
                            ? {
                              textAlign: "center",
                              height: "100%",
                              borderRadius: "1rem",
                            }
                            : {}
                        }
                      >
                        {Array.isArray(checkedValues) &&
                          checkedValues?.includes(image.post_id) &&
                          postIds.includes(image.post_id) ? (
                          <Button
                            onClick={() => onMinus(image.post_id)}
                            className="carousel-content-remove-btn"
                          >
                            Remove
                          </Button>
                        ) : (
                          <Button
                            onClick={() => onAdd(image.post_id)}
                            className="carousel-content-add-btn"
                          >
                            Add
                          </Button>
                        )}

                        {image?.platform === "newsletter" ? (
                          <div
                            className="newsletter-media-carousel h-250 no-media-url"
                            // style={{ backgroundColor: "black", color: "white" ,borderRadius: "1rem", border: "1px solid #e6e7eb"}}
                          >
                            <h4 className="newsletter-label"> Newsletter</h4>
                            <h4 className="mt-7 date">
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                day: "numeric",
                                month: "long",
                              }).format(new Date(image.post_date))}
                            </h4>
                          </div>
                        ) : image?.platform === "linkedin" &&
                          !image?.media_url ? (
                          <div
                            className="newsletter-media-carousel h-250 no-media-url"
                            // style={{ backgroundColor: "black", color: "white" ,borderRadius: "1rem", border: "1px solid #e6e7eb"}}
                          >
                            <h4 className="newsletter-label">LinkedIn Post</h4>
                            <h4 className="mt-7 date">
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                day: "numeric",
                                month: "long",
                              }).format(new Date(image.post_date))}
                            </h4>
                          </div>
                        ) : image?.platform === "twitter" &&
                          !image?.media_url ? (
                          <div
                            className="newsletter-media-carousel h-250 no-media-url"
                            // style={{ backgroundColor: "black", color: "white" ,borderRadius: "1rem", border: "1px solid #e6e7eb"}}
                          >
                            <h4 className="newsletter-label">Twitter Post</h4>
                            <h4 className="mt-7 date">
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                day: "numeric",
                                month: "long",
                              }).format(new Date(image.post_date))}
                            </h4>
                          </div>
                        ) : (
                          <>
                            {image.media_url ? (
                              <>
                                {image.media_url.split(",").length > 1 &&
                                  image.media_url
                                    .split(",")
                                    .every((url: any) => url.trim() !== "") ? (
                                  <Carousel
                                    dotPosition="bottom"
                                    className="feed-tab-media-carousel newsletter-media-carousel"
                                  >
                                    {image.media_url
                                      .split(",")
                                      .map((media_url: any, index: any) => (
                                        <React.Fragment key={index}>
                                          {media_url.includes("video") ? (
                                            <div className="h-255">
                                              <VideoComponent
                                                media_url={media_url}
                                                thumbnail={
                                                  image.thumbnail &&
                                                    image.thumbnail.split(",")
                                                      .length &&
                                                    image.thumbnail.split(",")[
                                                    index
                                                    ]
                                                    ? image.thumbnail.split(
                                                      ","
                                                    )[index]
                                                    : "/assets/dummy_video.png"
                                                }
                                              />
                                            </div>
                                          ) : (
                                            <img
                                              className="feed-tab-media-check mb-2"
                                              src={media_url}
                                              alt="post"
                                            />
                                          )}
                                        </React.Fragment>
                                      ))}
                                  </Carousel>
                                ) : (
                                  <>
                                    {image.media_url.includes("video") ? (
                                      <div className="h-255">
                                        <VideoComponent
                                          media_url={image.media_url}
                                          thumbnail={image.thumbnail}
                                        />
                                      </div>
                                    ) : (
                                      // </div>
                                      <img
                                        className="feed-tab-media-check"
                                        src={
                                          image.media_url
                                            ? image.media_url
                                            : "/assets/dummy_video.png"
                                        }
                                        alt="post"
                                      />
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <img
                                className="feed-tab-media-check"
                                src={
                                  image.thumbnail
                                    ? image.thumbnail
                                    : "/assets/dummy_video.png"
                                }
                                alt="post"
                              />
                            )}
                          </>
                        )}
                      </div>
                      {/* <p
                        style={{
                          textAlign:
                            image.product_name !== "" ? "left" : "center",
                        }}
                      >
                        {type === "content" || type === "query-content"
                          ? image?.content_title
                            ? image?.content_title
                            : image?.product_name
                          : image?.shop_title
                          ? image?.shop_title
                          : image?.product_name}{" "}
                      </p> */}

                      <p
                        style={{
                          textAlign:
                            image.product_name !== "" ? "left" : "center",
                        }}
                      >
                        {type === "content" || type === "query-content"
                          ? image?.content_title
                            ? image?.content_title
                            : image?.product_name
                          : image?.shop_title
                            ? image?.shop_title
                            : image?.product_name}{" "}
                        <HiPencil
                        // onClick={() => {
                        //   setValue(
                        //     type === "content" || type === "query-content"
                        //       ? post?.content_title
                        //         ? post?.content_title
                        //         : post?.product_name
                        //       : post?.shop_title
                        //       ? post?.shop_title
                        //       : post?.product_name
                        //   );
                        //   setEdit(true);
                        //   setEditIndex(index);
                        // }}
                        />
                      </p>
                    </div>
                  ))}
                </Fragment>
              ))}
            </div>
          ) : (
            <>
              {isShopLinks ? (
                <p>No shoppable content present for '{query}'</p>
              ) : (
                <p>No content present for '{query}'</p>
              )}
            </>
          )}
        </>
      ) : (
        <div className="feed-tab-posts-container">
          {[...Array(4)].map((_, index) => (
            <LoadingPost key={index} />
          ))}
        </div>
      )}
    </>
  );
};

export default FeedCheckboxComponent;
