import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import useGraphicalAnalytics from "../Overview/useGraphicalAnalytics";
import { Spin } from "antd";
import { StaticVariables } from "../../../../Utils/StaticVariables";

const Charts = ({
  user_id,
  startDate,
  endDate,
  isYesterday,
  daysNumber,
}: any) => {
  const { loadingPageAnalytics, pageAnalytics } = useGraphicalAnalytics(
    user_id,
    startDate,
    endDate,
    isYesterday,
    daysNumber
  );
  const renderCustomTooltip = (props: any) => {
    const { active, payload } = props;

    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="custom-tooltip">
          <p className="m-2 p-3px">{data.formatted_date}</p>
          <p className="user-clr p-3px">Users: {data.users}</p>
          <p className="search-clr p-3px">Searches: {data.searches}</p>
          <p className="clicks-clr p-3px">
            Shoppable link clicks: {data["links"]}
          </p>
        </div>
      );
    }

    return null;
  };

  const CustomLegend = () => (
    <div className="clicks">
      <div className="search-clr">
        <ul>
          <li> Searches</li>
        </ul>
      </div>
      <div className="user-clr">
        <ul>
          <li> Users</li>
        </ul>
      </div>
      <div>
        <div className="clicks-clr">
          <ul>
            <li>Shoppable links clicks</li>
          </ul>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <h5>Page analytics</h5>
      <div className="loadingPageAnalytics">
        <div className="fulliv">
          {loadingPageAnalytics ? (
            <div className="spin">
              <Spin />
            </div>
          ) : (
            <ResponsiveContainer width="100%" height={550}>
              <LineChart
                data={pageAnalytics}
                margin={{
                  top: 15,
                  right: window.innerWidth <= 768 ? 0 : 30,
                  left: window.innerWidth <= 768 ? 0 : 20,
                  bottom: 5,
                }}
              >
                <XAxis
                  dataKey="formatted_date"
                  stroke="grey"
                  width={75}
                  strokeWidth={StaticVariables().X_AXIS_STROKE_WIDTH}
                />
                <YAxis
                  stroke="grey"
                  width={60}
                  tick={{ dx: 0 }}
                  strokeWidth={StaticVariables().Y_AXIS_STROKE_WIDTH}
                />
                <Tooltip content={renderCustomTooltip} />
                <Legend content={CustomLegend} />
                <Line
                  type="monotone"
                  dataKey="users"
                  stroke="#8685EF"
                  activeDot={{ r: 8 }}
                  strokeWidth={StaticVariables().LINE_STROKE_WIDTH}
                />
                <Line
                  type="monotone"
                  dataKey="searches"
                  stroke="#404040"
                  strokeWidth={StaticVariables().LINE_STROKE_WIDTH}
                />
                <Line
                  type="monotone"
                  dataKey="links"
                  stroke="#00C6CF"
                  strokeWidth={StaticVariables().LINE_STROKE_WIDTH}
                />
              </LineChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
    </>
  );
};

export default Charts;
