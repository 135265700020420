import { useQuery } from "react-query";
import { supabase } from "../../../config/supabaseClient";

export function useLoadStayUpToDateComponents(
  username: any,
  user_id: any,
  isFilloutOn?: any
) {
  async function getStayUpToDateComponents() {
    let componentsOrder: any = [];
    let showingComponents: any = [];
    let archiveComponents: any = [];

    const { data, error }: any = await supabase
      .from("stayuptodate_components")
      .select("*")
      .eq("user_id", user_id)
      .order("index");
    if (data && data.length) {
      for (let i of data) {
        componentsOrder.push(i.name);
        if (i.status === "archive") {
          if (i.display_name === "Newsletter") {
            if (isFilloutOn) {
              archiveComponents.push(i);
            }
          } else {
            archiveComponents.push(i);
          }
        } else {
          if (i.display_name === "Newsletter") {
            if (isFilloutOn) {
              archiveComponents.push(i);
            }
          } else {
            showingComponents.push(i);
          }
        }
      }
    }
    if (data.length === 0) {
      await supabase
        .from("controls_live")
        .update({ initial_content2: "Feed" })
        .eq("user_id", user_id);
    }
    return {
      order: componentsOrder,
      data: data.filter((item: any) =>
        item.display_name === "Newsletter" ? isFilloutOn : true
      ),
      showingComponents: showingComponents,
      archiveComponents: archiveComponents,
    };
  }

  return useQuery(
    ["StayUpTpDateComponents", user_id],
    getStayUpToDateComponents,
    {}
  );
}
