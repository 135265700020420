import React, { useState } from "react";
import { supabase } from "../../../../config/supabaseClient";
import { signupUserFolder } from "../../../../Utils/SupabaseServices/OnboardingFlow";
import UsernameSection from "./UsernameSection";




const UserInfoStep = (props: any) => {
  const { handleNext, handleBack, formData, setFormData, currentStep,username } = props; 
  const [showPassword, setShowPassword] = useState(false);
  const [usernameError, setUsernameError] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUserInfo = async () => {
    console.log("formData", formData);
    // const { data: existingUser } = await supabase
    //   .from("user")
    //   .select("*")
    //   .eq("username", formData.username);

    // if (existingUser && existingUser.length > 0) {
    //   setUsernameError("Username already exists!");
    //   return;
    // } else {
    console.log("username", username);
      setUsernameError("");
      const res: any = await signupUserFolder(
        formData.name,
        username,
        formData.email,
        formData.phone,
        formData.password
      );
      // const res = { error: false, message: "done" };
      if (res.error) {
        setErrorMsg(res.message || "Error Adding Creator!");
        return;
      } else {
        setUsernameError("");
        setErrorMsg("");
        const { data } = await supabase
          .from("user")
          .update({ is_username_signup: true, onboarding_step: currentStep })
          .eq("username", username);
        handleNext();
      }
    // }
  };

  // Add this function to check if all fields are filled
  const isFormValid = () => {
    return (
      formData?.name?.trim() !== '' &&
      formData?.username?.trim() !== '' &&
      formData?.email?.trim() !== '' &&
      formData?.phone?.trim() !== '' &&
      formData?.password?.trim() !== ''
    );
  };

  return (
    <>
     

      <div style={{ textAlign: "center" }} >
        <h2 style={{ fontSize: 20, marginBottom: 16, color: "#333" }}>
          Personal Information
        </h2>
        <p style={{ color: "#666", marginBottom: 16 }}>
          Please fill in your details
        </p>

        {/* Display error messages */}
        {errorMsg && (
          <p style={{ color: "red", marginBottom: 16 }}>{errorMsg}</p>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 12,
            marginBottom: 16,
          }}
        >
          {/* Name Field */}
          <div style={{ marginBottom: 16 }}>
            <label
              htmlFor="name"
              style={{
                display: "block",
                textAlign: "left",
                marginBottom: 8,
                color: "#666",
              }}
            >
              Full Name
            </label>
            <div style={{ position: "relative" }}>
              <input
                id="name"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                style={{
                  width: "100%",
                  padding: "8px 12px",
                  paddingLeft: "40px",
                  border: "1px solid #ccc",
                  borderRadius: 4,
                  fontSize: "16px",
                }}
                placeholder="Enter your full name"
              />
              <svg
                viewBox="0 0 24 24"
                width="20"
                height="20"
                fill="#666"
                style={{
                  position: "absolute",
                  left: 12,
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
              </svg>
            </div>
          </div>

          {/* Email Field */}
          <div style={{ marginBottom: 16 }}>
            <label
              htmlFor="email"
              style={{
                display: "block",
                textAlign: "left",
                marginBottom: 8,
                color: "#666",
              }}
            >
              Email
            </label>
            <div style={{ position: "relative" }}>
              <input
                id="email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                style={{
                  width: "100%",
                  padding: "8px 12px",
                  paddingLeft: "40px",
                  border: "1px solid #ccc",
                  borderRadius: 4,
                  fontSize: "16px",
                }}
                placeholder="Enter your email"
              />
              <svg
                viewBox="0 0 24 24"
                width="20"
                height="20"
                fill="#666"
                style={{
                  position: "absolute",
                  left: 12,
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z" />
              </svg>
            </div>
          </div>

          {/* Phone Field */}
          <div style={{ marginBottom: 16 }}>
            <label
              htmlFor="phone"
              style={{
                display: "block",
                textAlign: "left",
                marginBottom: 8,
                color: "#666",
              }}
            >
              Phone Number
            </label>
            <div style={{ position: "relative" }}>
              <input
                id="phone"
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                style={{
                  width: "100%",
                  padding: "8px 12px",
                  paddingLeft: "40px",
                  border: "1px solid #ccc",
                  borderRadius: 4,
                  fontSize: "16px",
                }}
                placeholder="Enter your phone number"
              />
              <svg
                viewBox="0 0 24 24"
                width="20"
                height="20"
                fill="#666"
                style={{
                  position: "absolute",
                  left: 12,
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <path d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z" />
              </svg>
            </div>
          </div>

          {/* Password Field */}
          <div style={{ marginBottom: 16 }}>
            <label
              htmlFor="password"
              style={{
                display: "block",
                textAlign: "left",
                marginBottom: 8,
                color: "#666",
              }}
            >
              Password
            </label>
            <div style={{ position: "relative" }}>
              <input
                id="password"
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleChange}
                style={{
                  width: "100%",
                  padding: "8px 12px",
                  paddingLeft: "40px",
                  border: "1px solid #ccc",
                  borderRadius: 4,
                  fontSize: "16px",
                }}
                placeholder="Enter your password"
              />
              <svg
                viewBox="0 0 24 24"
                width="20"
                height="20"
                fill="#666"
                style={{
                  position: "absolute",
                  left: 12,
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" />
              </svg>
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  position: "absolute",
                  right: 12,
                  top: "50%",
                  transform: "translateY(-50%)",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  padding: 0,
                }}
              >
                <svg viewBox="0 0 24 24" width="20" height="20" fill="#666">
                  {showPassword ? (
                    <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
                  ) : (
                    <path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z" />
                  )}
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button
            onClick={handleUserInfo}
            disabled={!isFormValid()}
            style={{
              backgroundColor: isFormValid() ? '#000' : '#ccc',
              color: '#fff',
              border: 'none',
              padding: '8px 16px',
              borderRadius: 4,
              cursor: isFormValid() ? 'pointer' : 'not-allowed',
              width: '100%',
            }}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default UserInfoStep;
