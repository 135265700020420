import { supabase } from "../../config/supabaseClient";

export async function addComponent(
  name: any,
  details: any,
  username: any,
  user_id: any,
  isDays: any,
  cta: any,
  query: any,
  displayName?: any,
  type?: any,
  postIds?: any,
  content_order?: any,
  content_shape?: any,
  content_sound?: any
) {
  type = type || "";
  const { data } = await supabase
    .from("stayuptodate_components")
    .select("*")
    .eq("name", name)
    // .eq("type", type)
    .eq("user_id", user_id);
  if (data?.length === 0) {
    const { data: count } = await supabase
      .from("stayuptodate_components")
      .select("*")
      // .eq("name", name)
      .eq("user_id", user_id);
    const { error } = await supabase.from("stayuptodate_components").insert([
      {
        name,
        details,
        user_id,
        username,
        index: count ? count?.length : 0,
        type,
        post_ids: postIds,
        display_name: displayName,
        cta_placeholder: cta,
        query: query,
        content_order,
        content_shape,
        content_sound,
      },
    ]);

    return "true";
  } else {
    return "Duplicate Name";
  }
}

export async function updateComponent(
  name: any,
  component: any,
  username: any,
  user_id: any,
  query: any,
  type: any,
  postIds: any,
  details: any,

  removePostIds?: any,
  content_order?: any,
  content_shape?: any,
  content_sound?: any
) {
  type = type || "";

  const { data: already } = await supabase
    .from("stayuptodate_components")
    .select("*")
    .eq("user_id", user_id)
    // .eq("type", component.type)
    .eq("name", name)
    .neq("id", component.id);
  if (already && already.length > 0) {
    return "Duplicate Name";
  } else {
    const { data: upd } = await supabase
      .from("stayuptodate_components")
      .update({
        name,
        post_ids: postIds,
        query,
        removed_postids: removePostIds,
        details,
        content_order,
        content_shape,
        content_sound,
      })
      .eq("display_name", component.display_name)
      .eq("type", component.type)
      .eq("user_id", user_id);
    if (name !== component.name) {
      const { data: analytics } = await supabase
        .from("analytics")
        .select("id, details")
        .eq("user_id", user_id)
        .ilike("details", `%"title":"${component.name}"%`)
        .eq("name", "Post Click").order("created_at",{ascending:false});

      if (analytics?.length) {
        const updatePromises = analytics.map(({ id, details }) =>
          supabase
            .from("analytics")
            .update({
              details: JSON.stringify({
                ...JSON.parse(details),
                title: name,
              }),
            })
            .eq("user_id", user_id)
            .eq("id", id)
            .then(() => {
              // Optional: Handle successful update
            })
            
        );
        
        // Fire and forget - don't await
        Promise.all(updatePromises);
      }
    }

    if (type === "product-bank") {
      const { data, error } = await supabase.rpc(
        "querybankpostss",
        // "get_queryproductbankpostttsssss",
        {
          username,
          query_string: "",
          userid: user_id,
          title: component?.name,
          query_tagg: "",
        }
      );
      if (data?.length) {
        const updatePromises = data.flatMap((i: any) => {
          const regex = new RegExp(`\\|${component?.name}\\|`, "g");
          const table_query = i.table_query.replace(regex, `|${name}|`);

          // Create promises for both updates
          const productBankUpdate = supabase
            .from("product_bank")
            .update({ query: table_query })
            .eq("user_id", user_id)
            .eq("product_id", i.table_id);

          const usernameUpdate = supabase
            .from(username)
            .update({ metadata: table_query })
            .eq("user_id", user_id)
            .eq("post_id", i.table_id);

          return [productBankUpdate, usernameUpdate]; // Return both promises
        });

        // Execute all update promises
        await Promise.all(updatePromises);
      }

      // if (data?.length) {
      //   const updatePromises = data.map((i: any) => {
      //     const regex = new RegExp(`\\|${component?.name}\\|`, "g");
      //     const table_query = i.table_query.replace(regex, `|${name}|`);
      //     return supabase
      //       .from("product_bank")
      //       .update({ query: table_query })
      //       .eq("user_id", user_id)
      //       .eq("product_id", i.table_id);
      //       return supabase
      //       .from(username)
      //       .update({ metadata: table_query })
      //       .eq("user_id", user_id)
      //       .eq("post_id", i.table_id);
      //   });
      //   await Promise.all(updatePromises);
      // }
    }

    return "true";
  }
}
export async function updateComponentSingle(
  name: any,
  component: any,
  details: any,
  username: any,
  user_id: any,
  cta?: any,
  type?: any,
  postIds?: any
) {
  type = type || "";

  const { data: already } = await supabase
    .from("stayuptodate_components")
    .select("*")
    .eq("user_id", user_id)
    // .eq("type", component.type)
    .eq("name", name)
    .neq("id", component.id);
  if (already && already.length > 0) {
    return "Duplicate Name";
  } else {
    const { data, error } = await supabase
      .from("stayuptodate_components")
      .update({ details: details, name: name, cta_placeholder: cta })
      .eq("display_name", component.display_name)
      .eq("type", component.type)
      .eq("user_id", user_id);
    return "true";
  }
}

export async function updateIndexValue(user_id: any, newData: any) {
  let errorArray: any[] = [];
  for (const [idx, i] of newData.entries()) {
    const { data, error } = await supabase
      .from("stayuptodate_components")
      .update({ index: idx })
      .eq("name", i.name)
      .eq("user_id", user_id);
    if (error !== null) {
      errorArray.push(error.message);
    }
  }
}

export async function deleteComponent(
  name: any,
  user_id: any,
  data: any,
  dataToDelete: any,
  refetch: any,
  username: any
) {
  let query = dataToDelete?.[0]?.name;
  let type = dataToDelete?.[0]?.type;

  if (type === "product-bank") {
    const { data: products, error }: any = await supabase
      .from("product_bank")
      .select("*")
      .eq("user_id", user_id)
      .ilike("query", `%|${query}|%`);
    for (let p of products) {
      let updatedData = p.query
        ?.replace(/\|/g, "") // Remove all pipes
        .split(",") // Split by comma
        .filter((item: any) => {
          return item !== query;
        }) // Filter out the `query` item
        .map((item: any) => `|${item}|`) // Add pipes around each item
        .join(","); // Join items back into a string
      const { data, error } = await supabase
        .from("product_bank")
        .update({ query: updatedData })
        .eq("user_id", user_id)
        .eq("product_id", p.product_id);
      await supabase
        .from(username)
        .update({ metadata: updatedData })
        .eq("user_id", user_id)
        .eq("post_id", p.product_id);
    }
  }
  if (type === "links-section") {
    const { data: links, error: linksError } = await supabase
      .from("links_section")
      .delete()
      .eq("collection_name", name)
      .eq("user_id", user_id);
  }
  await supabase
    .from("stayuptodate_components")
    .delete()
    .eq("name", name)
    .eq("user_id", user_id);
  let errorArray: any[] = [];
  for (const [idx, i] of data.entries()) {
    const { data, error } = await supabase
      .from("stayuptodate_components")
      .update({ index: idx })
      .eq("name", i.name)
      .eq("user_id", user_id);
    if (error !== null) {
      errorArray.push(error.message);
    }
  }
  refetch();
}

export async function changeComponentStatus(
  value: any,
  user_id: any,
  component: any
) {
  const { data, error } = await supabase
    .from("stayuptodate_components")
    .update({ status: value })
    .eq("user_id", user_id)
    .eq("id", component.id);

  return data;
}
