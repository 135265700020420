import React, { useState } from "react";
import { supabase } from "../../../../config/supabaseClient";

const UsernameSection = (props: any) => {
  const { username, setUsername, handleChange, handleNext, currentStep } =
    props;
  const [localUsername, setLocalUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const handleUsername = async () => {
    const { data: existingUser } = await supabase
      .from("user")
      .select("*")
      .eq("username", localUsername);
    console.log("existingUser", existingUser, localUsername);
    if (existingUser && existingUser.length > 0) {
      setUsernameError("Username already exists!");
      return;
    } else {
      setUsernameError("");
      setUsername(localUsername);
      handleNext();
      const { data } = await supabase
        .from("user")
        .update({ is_username_signup: true, onboarding_step: currentStep })
        .eq("username", localUsername);
    }
  };
  return (
    <>
      {/* Username Section */}
      <div style={{ marginBottom: 8 }}>
        <div
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            color: "#333",
            textAlign: "left",
            marginBottom: 4,
          }}
        >
          Username
        </div>
      </div>

      {/* Username Field */}
      <div style={{ marginBottom: 16 }}>
        <label
          htmlFor="username"
          style={{
            display: "block",
            textAlign: "left",
            marginBottom: 8,
            color: "#666",
          }}
        >
          Pick a unique username
        </label>
        <div style={{ position: "relative" }}>
          <input
            id="username"
            type="text"
            name="username"
            value={localUsername}
            onChange={(e) => {
              // setUsername(e.target.value);
              // handleChange(e);
              setLocalUsername(e.target.value);
              setUsernameError("");
            }}
            style={{
              width: "100%",
              padding: "8px 12px",
              paddingLeft: "40px",
              border: usernameError ? "1px solid #ff4444" : "1px solid #ccc",
              borderRadius: 4,
              fontSize: "16px",
            }}
            placeholder="Choose your username"
          />
          <svg
            viewBox="0 0 24 24"
            width="20"
            height="20"
            fill="#666"
            style={{
              position: "absolute",
              left: 12,
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
          </svg>
        </div>
        {usernameError && (
          <div
            style={{
              color: "#ff4444",
              fontSize: "14px",
              textAlign: "left",
              marginTop: "4px",
            }}
          >
            {usernameError}
          </div>
        )}
      </div>

      {/* Next Button */}
      <div style={{ marginTop: 24 }}>
        <button
          onClick={() => handleUsername()}
          disabled={!localUsername.trim()}
          style={{
            width: "100%",
            padding: "12px",
            backgroundColor: localUsername.trim() ? "black" : "#ccc",
            color: "white",
            border: "none",
            borderRadius: "4px",
            fontSize: "16px",
            fontWeight: "bold",
            cursor: localUsername.trim() ? "pointer" : "not-allowed",
          }}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default UsernameSection;
