import { Button, Carousel, Input, List, Modal } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import '../../../../../styles/StayUptoDateCSS/StayUpToDateControls.css'
import { updateComponent } from "../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import Skeleton from "react-loading-skeleton";
import { supabase } from "../../../../../config/supabaseClient";
import { HiCheck, HiPencil } from "react-icons/hi";
import { StaticVariables } from "../../../../../Utils/StaticVariables";
import VideoComponent from "../../../../../components/VideoComponent/VideoComponent";
import RemovedContentTable from "../../../../../components/EditModalComponents.tsx/RemovedContentTable";
import { daysSinceMultipleDays } from "../../../../../Utils/StayUpToDate/RenderComponent";
import { useLoadYesterdayContent } from "../../../../../Utils/customHooks/UpToDateContent/useLoadYesterdayContent";
import { getPostTitle } from "../../../../../Utils/HelperFunction/HelperFunction";
import { UpdateCombinedText } from "../../../../../Utils/SupabaseServices/ContentDatabase";

const EditYesterdayContent = (props: any) => {
  const {
    username,
    user_id,
    isYesterdayLinksEditModalOpen,
    setIsYesterdayLinksEditModalOpen,
    type,
    component,
  } = props;
  const [name, setName] = useState(component.name || ""); // const [details, setDetails] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // State to manage button disable state
  const [postIds, setPostIds] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [defaultQuery, setDefaultQuery] = useState("");
  const [removePostIds, setRemovePostIds] = useState<any>("");
  const [isChange, setIsChange] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  const [isActionLoading, setIsActionLoading] = useState(false);
  useEffect(() => {
    setPostIds(component?.post_ids);
    setName(component?.name);
    setDefaultQuery(component?.query);
    setRemovePostIds(component?.removed_postids);
  }, [component]);
  const handleInputChange = (value: string, setter: Function) => {
    setter(value); // Set the input value
    setIsButtonDisabled(!value); // Disable button if either name or details is empty
  };
  const handleOk = async () => {
    setConfirmLoading(true);
    const res = await updateComponent(
      name,
      component,
      username,
      user_id,
      defaultQuery,
      component?.type,
      "",
      "",
      removePostIds
    );
    if (res === "true") {
      props.refetch();

      props.setIsYesterdayLinksEditModalOpen(false);
      props.setSelectedComponent("");
      setConfirmLoading(false);
      setIsChange(false);
      setName("");
      setPostIds("");
      setErrorMsg("");
      setDefaultQuery("");
      setRemovePostIds("");
      setEdit(false)
      setEditIndex("")
    } else {
      setErrorMsg("Category with this name already exists");
      setConfirmLoading(false);
      setEdit(false)
      setEditIndex("")
    }

    // props.setIsCustomLinksComponentOpen(false);
  };

  const handleCancel = () => {
    props.setIsYesterdayLinksEditModalOpen(false);
    props.setSelectedComponent("");

    // props.setIsCustomLinksComponentOpen(false);
    setName("");
    setPostIds("");
    setErrorMsg("");
    setDefaultQuery("");
    setRemovePostIds("");
    setEdit(false)
    setEditIndex("")
  };

  // function handleRemovedPostIds(post_ids: any) {
  //   setRemovePostIds((prev: any) => {
  //     const idsArray = prev.split(",").filter(Boolean);
  //     idsArray.push(post_ids);
  //     return idsArray.join(","); // Convert array back to string
  //   });
  //   // setRemovePostIds((prev: any) => (prev ? [...prev, post_ids] : [post_ids]));
  //   // setPostIds(post_ids);
  // }
  function handleRemovedPostIds(post_ids: any, type: any) {
    if (type === "minus") {
      setRemovePostIds((prev: any) => {
        const idsArray = prev?.split(",").filter(Boolean);
        idsArray.push(post_ids);
        return idsArray.join(","); // Convert array back to string
      });
    } else if (type === "add") {
      setRemovePostIds((prev: any) => {
        const idsArray = prev.split(",").filter(Boolean);
        const filteredArray = idsArray.filter((elem: any) => elem !== post_ids);

        return filteredArray.join(","); // Convert array back to string
      });
    }
  }

  return (
    <>
      <Modal
        open={isYesterdayLinksEditModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Update"
        confirmLoading={confirmLoading}
        okButtonProps={{ disabled: !name }}
      >
        <div className="mt-1">
          <h5 className="editTitle">Editing {name}</h5>
          <label className="bold-label">Carousel Title</label>
          <Input
            placeholder="Enter Title"
            value={name}
            onChange={(e: any) => {
              handleInputChange(e.target.value, setName);
              setErrorMsg("");
            }}
            className="mb-15"
          />
          {errorMsg && <p className="errorMsg">{errorMsg}</p>}
          {component?.display_name && component?.display_name !== "" && (
            <p>
              {component?.name && component.name.split(",").join(" & ")}
              {/* Content */}
            </p>
          )}
          <AddedContentTable
            post_ids={component?.post_ids?.split(",")}
            username={username}
            user_id={user_id}
            setPostIds={setPostIds}
            handleRemovedPostIds={handleRemovedPostIds}
            componentName={name}
            refetchDashboard={props.refetch}
            removePostIds={removePostIds}
            setRemovePostIds={setRemovePostIds}
            type={component?.type}
            displayName={component?.display_name}
            title={component?.name}
            setIsChange={setIsChange}
            isChange={isChange}
            edit={edit}
            setEdit={setEdit}
            editIndex={editIndex}
            setEditIndex={setEditIndex} 
            isActionLoading={isActionLoading}
            setIsActionLoading={setIsActionLoading}
          // yesterdayLinksData={yesterdayLinksData}
          // refetchLinks={refetchLinks}
          // yesterdayLinksLoading={yesterdayLinksLoading}
          />
          <RemovedContentTable
            post_ids={component?.post_ids?.split(",")}
            username={username}
            user_id={user_id}
            setPostIds={setPostIds}
            handleRemovedPostIds={handleRemovedPostIds}
            componentName={name}
            refetchDashboard={props.refetch}
            removePostIds={removePostIds}
            setRemovePostIds={setRemovePostIds}
            type={component?.type}
            displayName={component?.display_name}
            title={component?.name}
            edit={edit}
            setEdit={setEdit}
            editIndex={editIndex}
            setEditIndex={setEditIndex}
            isActionLoading={isActionLoading}
            setIsActionLoading={setIsActionLoading}
          // yesterdayLinksData={yesterdayLinksData}
          // refetchLinks={refetchLinks}
          // yesterdayLinksLoading={yesterdayLinksLoading}
          />
        </div>
      </Modal>
    </>
  );
};
const AddedContentTable = (props: any) => {
  const {
    username,
    user_id,
    post_ids,
    componentName,
    refetchDashboard,
    setRemovePostIds,
    type,
    displayName,
    removePostIds,
    title,
    setIsChange,
    isChange,
    edit,
    setEdit,
    editIndex,
    setEditIndex,
    isActionLoading,
    setIsActionLoading
    // yesterdayLinksData,
    // refetchLinks,
    // yesterdayLinksLoading
  } = props;
  const [postIds, setPostIds] = useState(post_ids);
  const [deletePostIds, setDeletePostIds] = useState(removePostIds);
  useEffect(() => {
    setDeletePostIds(removePostIds);
  }, [removePostIds]);

  let numberOfDays;
  if (displayName === "This Week Content") {
    numberOfDays = [7];
  } else if (displayName === "Yesterday") {
    numberOfDays = [1];
  } else if (displayName === "This Month") {
    numberOfDays = [30];
  } else {
    const dayMappings = StaticVariables().dayMappings;
    numberOfDays = daysSinceMultipleDays(dayMappings[displayName]);
  }
  let {
    isLoading: yesterdayLinksLoading,
    data: yesterdayLinksData,
    refetch,
  }: any = useLoadYesterdayContent(
    username,
    user_id,
    numberOfDays,
    postIds,
    deletePostIds,
    title,
    isChange
  );
  const onMinus = async (post_id: string) => {
    setIsActionLoading(true);
    try {
      setDeletePostIds((prev: any) => {
        const idsArray = prev.split(",").filter(Boolean);
        idsArray.push(post_id);
        return idsArray.join(","); // Convert array back to string
    });
    await Promise.all([
      props.handleRemovedPostIds(post_id, "minus"),
      refetch()
    ]);
    await new Promise(resolve => setTimeout(resolve, 1000));
    } finally {
      setIsActionLoading(false);
    }
  };
  const [value, setValue] = useState<string>();
 

  const handleDone = async (post_id: string) => {
    const { data: user, error } = await supabase
      .from(`${username}`)
      .select("*")
      .eq("post_id", post_id)
      .eq("user_id", user_id);

    const { data } = await supabase
      .from(`${username}`)
      .update({
        content_title: value,
      })
      .eq("post_id", post_id)
      .eq("user_id", user_id);
    const { data: newContent, error: newErroor } = await supabase
      .from("content_new")
      .update({
        product_name: value,
      })
      .eq("post_id", post_id)
      .eq("user_id", user_id);
    const { data: bankContent, error: bankErroor } = await supabase
      .from("product_bank")
      .update({
        content_title: value,
      })
      .eq("product_id", post_id)
      .eq("user_id", user_id);
    UpdateCombinedText(username, user_id, post_id);
    refetch();
    setEdit(false);
    setEditIndex("");
    setIsChange(true);
    
    // PostIdDataRefetch();
    refetchDashboard();
  };

  const filteredData = useMemo(() => {
    return (yesterdayLinksData || [])
      .filter((element: any) => element !== undefined)
      .filter((obj: any) => {
        const deleteIdsArray = deletePostIds?.split(",") || [];
        return !deleteIdsArray.includes(obj.post_id);
      });
  }, [yesterdayLinksData, deletePostIds]);

  const hasFilteredData = filteredData.length > 0;
  const SkeletonStatBox = () => {
    return (
      <div className="skeletonDiv">
        <Skeleton className="skeletonStyle" />
      </div>
    );
  };

  // useEffect(()=>{
  //   refetchLinks();
  // },[deletePostIds])
  return (
    <>
      <div className="addedContent">
        <h5 className="addedContentTitle">{componentName}</h5>
        {!yesterdayLinksLoading ? (
          <div className="addedSvg">
            {hasFilteredData ? (
              // yesterdayLinksData && yesterdayLinksData
              //   .filter((element: any) => element !== undefined)
              //   .filter((obj: any) => !deletePostIds?.includes(obj.post_id))
              //   .length
              filteredData
                // yesterdayLinksData
                //   .filter((element: any) => element !== undefined)
                //   .filter((obj: any) => !deletePostIds?.includes(obj.post_id))
                .map((post: any, index: any) => (
                  <React.Fragment key={post.post_id}>
                    {/* <div
                key={index}
                className="feed-tab-post-container"
                style={{
                  flex: "0 0 auto",
                  marginRight: "0px",
                  width: "180px", // Set a fixed width for the posts
                }}
              > */}
                    <div
                      key={index}
                      className="feed-tab-post-container added-post"
                    >
                      <div
                        className="feed-tab-media-wrapper"
                      >
                        {/* <FaMinusCircle onClick={() => onMinus(post?.post_id)} /> */}

                        <Button
                          onClick={() => onMinus(post.post_id)}
                          className="carousel-content-remove-btn"
                        >
                          Remove
                        </Button>

                        {post?.platform === "newsletter" ? (
                          <div className="newsletter-media-carousel h-250" style={
                            post?.platform === "newsletter"
                              ? {
                                textAlign: "center",
                              }
                              : {}
                          }>
                            <h4 className="newsletter-label"> Newsletter</h4>
                            <h4 className="mt-7 date">
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                day: "numeric",
                                month: "long",
                              }).format(new Date(post?.post_date))}
                            </h4>
                          </div>
                        ) : (post?.platform === "linkedin" && !post.media_url) ? (
                          <div className="newsletter-media-carousel h-250 no-media-url" 
                          // style={{backgroundColor: "black", color: "white"}}
                          >
                          <h4 className="newsletter-label">LinkedIn Post</h4>
                            <h4 className="mt-7 date">
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                day: "numeric",
                                month: "long",
                              }).format(new Date(post.post_date))}
                            </h4>
                          </div>
                        ): (post?.platform === "twitter" && !post.media_url) ? (
                          <div className="newsletter-media-carousel h-250 no-media-url" 
                          // style={{backgroundColor: "black", color: "white"}}
                          >
                            <h4 className="newsletter-label">Twitter Post</h4>
                            <h4 className="mt-7 date">
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                day: "numeric",
                                month: "long",
                              }).format(new Date(post.post_date))}
                            </h4>
                          </div>
                        ) : (

                          <>
                            {post?.media_url ? (
                              <>
                                {post?.media_url.split(",").length > 1 &&
                                  post?.media_url
                                    .split(",")
                                    .every((url: any) => url.trim() !== "") ? (
                                  <Carousel
                                    dotPosition="bottom"
                                    className="feed-tab-media-carousel"
                                  >
                                    {post?.media_url
                                      .split(",")
                                      .map((media_url: any, index: any) => (
                                        <React.Fragment key={index}>
                                          {media_url.includes("video") ? (
                                            
                                            <div className="h-255">
                                              <VideoComponent
                                                media_url={media_url}
                                                thumbnail={
                                                  post.thumbnail &&
                                                    post.thumbnail.split(",")
                                                      .length &&
                                                    post.thumbnail.split(",")[
                                                    index
                                                    ]
                                                    ? post.thumbnail.split(",")[
                                                    index
                                                    ]
                                                    : "/assets/dummy_video.png"
                                                }
                                              />
                                            </div>
                                          ) : (
                                            <img
                                              className="feed-tab-media-check mh-300 mb-2"
                                              src={media_url}
                                              alt="post"
                                            />
                                          )}
                                        </React.Fragment>
                                      ))}
                                  </Carousel>
                                ) : (
                                  <>
                                    {post.media_url.includes("video") ? (
                                     
                                      <div className="h-255">
                                        <VideoComponent
                                          media_url={post.media_url}
                                          thumbnail={post.thumbnail}
                                        />
                                      </div>
                                    ) : (
                                      <img
                                        className="feed-tab-media-check"
                                        src={
                                          post.media_url
                                            ? post.media_url
                                            : "/assets/dummy_video.png"
                                        }
                                        alt="post"
                                      />
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <img
                                className="feed-tab-media-check"
                                src={
                                  post?.thumbnail
                                    ? post?.thumbnail
                                    : "/assets/dummy_video.png"
                                }
                                alt="post"
                              />
                            )}
                          </>

                        )}
                      </div>
                      {edit === true && editIndex === index ? (
                        <>
                          <Input
                            value={value}
                            onChange={(e: any) => setValue(e.target.value)}
                          />{" "}
                          <HiCheck
                            onClick={() => {
                              handleDone(post.post_id);
                            }}
                          />
                        </>
                      ) : (
                        <p
                          style={{
                            textAlign:
                              post.product_name !== "" ? "left" : "center",
                          }}
                        >
                          {getPostTitle(post, "content")}
                          <HiPencil
                            onClick={() => {
                              const name = getPostTitle(post, type);
                              setValue(name);
                              setEdit(true);
                              setEditIndex(index);
                            }}
                          />
                        </p>
                      )}
                    </div>
                  </React.Fragment>
                ))
            ) : (
              <React.Fragment>
                {/* <p>No Added Data</p> */}
                <>
                  <div
                    // key={index}
                    // className="feed-tab-post-container"
                    className="feed-tab-post"
                  >
                    {[...Array(4)].map((_, index) => (
                      <SkeletonStatBox key={index} />
                    ))}
                  </div>
                </>
              </React.Fragment>
            )}
          </div>
        ) : (
          <>
            <React.Fragment>
              {/* <p>No Added Data</p> */}
              <>
                <div
                  // key={index}
                  // className="feed-tab-post-container"
                  className="feed-tab-post"
                >
                  {[...Array(4)].map((_, index) => (
                    <SkeletonStatBox key={index} />
                  ))}
                </div>
              </>
            </React.Fragment>
          </>
        )}
      </div>
    </>
  );
};
export default EditYesterdayContent;
