import { Button, Carousel } from "antd";
import React from "react";
import { Card } from "react-bootstrap";
import { BsHourglassSplit } from "react-icons/bs";
import ImagePostTag from "./ImagePostTag";
import VideoPostTag from "./VideoPostTag";
import { getPostTitle } from "../../Utils/HelperFunction/HelperFunction";
import { getThumbnail } from "../StayUpToDateComponent/StayUpToDate";
import ProductName from "../ProductName/ProductName";

const ImagePostCarouselTag = (props: any) => {
  const {
    post,
    redirectVariable,
    isLinks,
    handleRedirect,
    title,
    brand_color,
    isDashboard,
    height,
    isYoutube,
    isHasMediaUrl,
    productName,
    type,
    contentShape,
    isSoundOn,

    isCarousel,
  } = props;
  const mediaUrls = post.media_url
    .split(",")
    .filter((url: any) => url.trim() !== "");

  const getWrapperClassName = () => {
    const baseClass = "creator-feed-image-wrapper-links";
    const classes = [baseClass];

    if (isHasMediaUrl) classes.push("square-wrapper");
    else if (contentShape) classes.push("square-wrapper");
    else if (isYoutube) classes.push("youtube-feed-wrapper");

    return classes.join(" ");
  };

  const getImageClassName = () => {
    const baseClass = "creator-feed-card-links-image";
    const classes = [baseClass];

    if (isHasMediaUrl) classes.push("media-feed-height");
    else if (contentShape) classes.push("square-image");
    else if (isYoutube) classes.push("youtube-feed-height");

    return classes.join(" ");
  };

  const onChange = (currentSlide: number) => { };

  // const onChange = (currentSlide: number) => {};
  const ImagePostTagCarousel = React.memo((props: any) => {
    const { media_url, isVideo, post, handleRedirect, productName } = props;

    const redirectUrl = !isLinks
      ? post?.permalink || post?.shop_link || post?.table_url
      : post?.shop_link || post?.table_url || post?.permalink;

    return (
      <>
        <a
          href={
            redirectUrl
            // !isLinks ? (post?.permalink ? post?.permalink : post?.table_url ? post?.table_url : post?.shop_link) :
            //   (post?.shop_link && post?.shop_link !== ""
            //     ? post?.shop_link
            //     : post?.permalink ? post?.permalink : post?.table_url)
          }
          target="_blank"
          onClick={(e) => {
            e.preventDefault();
            handleRedirect(
              post?.post_id,
              post?.media_url.includes("video")
                ? post?.product_image
                  ? post?.product_image
                  : post?.thumbnail
                : post?.product_image
                  ? post?.product_image
                  : post?.media_url,
              title,
              post?.product_name
            );
            if (
              (post?.shop_link && post?.shop_link !== "") ||
              (post?.permalink && post?.permalink !== "") ||
              (post?.table_url && post?.table_url !== "")
            ) {
              window.open(
                redirectUrl,
                // post?.shop_link && post?.shop_link !== ""
                //   ? post?.shop_link
                //   : post.permalink ? post?.permalink : post?.table_url,
                "_blank"
              );
            }
          }}
        >
          {media_url === "" ? (
            <div
              className={
                isYoutube
                  ? "creator-feed-card-video not-available youtube-feed-height"
                  : isHasMediaUrl
                    ? "creator-feed-card-video not-available media-feed-height"
                    : "creator-feed-card-video not-available"
              }
            >
              <BsHourglassSplit className="hourglassSplit" />
              <p>
                Work in progress: This content will be available in a few
                minutes.
              </p>
            </div>
          ) : (
            <>
              <Card.Img
                variant="top"
                src={media_url}
                className={getImageClassName()}
                loading="eager"
                style={{
                  backgroundColor: brand_color,
                  marginBottom: "2%",
                  height: height,
                }}
              />
            </>
          )}
        </a>
      </>
    );
  });

  return (
    <>
      {" "}
      <span>
        <div className={getWrapperClassName()}>
          {mediaUrls.some((media_url: string) =>
            media_url.includes("video")
          ) ? (
            <>
              <Carousel afterChange={onChange} dotPosition="bottom">
                {mediaUrls.map((media_url: string, index: number) => (
                  <div key={index}>
                    {media_url.includes("video") ? (
                      <div className={getWrapperClassName()}>
                        <VideoPostTag
                          media_url={media_url}
                          productName={productName}
                          post={post}
                          thumbnail={getThumbnail(post, index)}
                          handleRedirect={handleRedirect}
                          isSoundOn={isSoundOn}
                          type={type}
                        />
                      </div>
                    ) : (
                      <div className={getWrapperClassName()}>
                        <ImagePostTag
                          data={post}
                          title={title}
                          media_url={media_url}
                          isVideo={true}
                          handleRedirect={handleRedirect}
                          productName={productName}
                          isCarousel={true}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </Carousel>

              <ProductName type={type} post={post} />
            </>
          ) : (
            <>
              <Carousel afterChange={onChange} dotPosition="bottom">
                {mediaUrls.map((media_url: any, index: any) => (
                  <div key={index} className={getWrapperClassName()}>
                    <ImagePostTagCarousel
                      post={post}
                      media_url={media_url}
                      handleRedirect={handleRedirect}
                      productName={productName}
                      isCarousel={true}
                    />
                  </div>
                ))}
              </Carousel>

              <ProductName type={type} post={post} />
            </>
          )}
        </div>
      </span>
    </>
  );
};

export default ImagePostCarouselTag;
