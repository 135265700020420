import { useEffect, useState, Fragment, useCallback } from "react";
import { Button, Carousel } from "antd";
import VideoComponent from "../VideoComponent/VideoComponent";
import { debounce } from "lodash";

const AllContentTable = ({
  username,
  user_id,
  data,
  posts,
  isLoading,
  query,
  setAllPosts,
  componentName,
  setRemovePosts,
  handleFeed,
  isActionLoading,
  setIsActionLoading
}: any) => {
  const [checkedValues, setCheckedValues] = useState(posts || []);

  useEffect(() => {
    setCheckedValues(posts);
  }, [posts]);

  // Debounce the state updates for checkedValues and setAllPosts
  const debouncedSetCheckedValues = useCallback(
    debounce((updatedCheckedValues: any) => {
      setCheckedValues(updatedCheckedValues);
      handleFeed(updatedCheckedValues);
    }, 300),
    []
  );

  const debouncedSetAllPosts = useCallback(
    debounce((updatedAllPosts: any) => {
      setAllPosts(updatedAllPosts);
    }, 300),
    []
  );

  const onAdd = useCallback(
    async (post_id: any) => {
      setIsActionLoading(true);
      try {
        if (!checkedValues.some((post: any) => post.table_id === post_id)) {
          const postData = data?.pages?.[0].find(
            (post: any) => post.table_id === post_id
          );
          if (postData) {
            const updatedCheckedValues = [postData, ...checkedValues];
            await Promise.all([
              debouncedSetCheckedValues(updatedCheckedValues),
              debouncedSetAllPosts((prev: any) => [...prev, postData])
            ]);
            await new Promise(resolve => setTimeout(resolve, 1000));
          }
        }
      } finally {
        setIsActionLoading(false);
      }
    },
    [checkedValues, data?.pages]
  );

  const onMinus = useCallback(
    async (post_id: any) => {
      setIsActionLoading(true);
      try {
        const postData = checkedValues.find(
          (post: any) => post?.table_id === post_id
        );
        if (postData) {
          setRemovePosts((prev: any) => [...prev, postData]);
          const updatedCheckedValues = checkedValues.filter(
            (post: any) => post.table_id !== post_id
          );

          await Promise.all([
            debouncedSetCheckedValues(updatedCheckedValues),
            debouncedSetAllPosts(updatedCheckedValues)
          ]);
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      } finally {
        setIsActionLoading(false);
      }
    },
    [checkedValues, setRemovePosts]
  );

  return (
    <>
      {!isLoading && data?.pages ? (
        <div className="feed-tab-posts-container">
          {data.pages.map((group: any, pageIndex: any) => (
            <Fragment key={pageIndex}>
              {group.map((image: any, index: any) => (
                <div key={index} className="feed-tab-post-container post-width">
                  <div
                    className="feed-tab-media-wrapper"
                    style={
                      image.platform === "newsletter"
                        ? { backgroundColor: "#efeded", textAlign: "center" }
                        : {}
                    }
                  >
                    {checkedValues &&
                      checkedValues.length > 0 &&
                      checkedValues?.some(
                        (elem: any) => elem.table_id === image.table_id
                      ) &&
                      posts?.some(
                        (elem: any) => elem.table_id === image.table_id
                      ) ? (
                      <Button
                        onClick={() => onMinus(image.table_id)}
                        className="carousel-content-remove-btn"
                      >
                        Remove
                      </Button>
                    ) : (
                      <Button
                        onClick={() => onAdd(image.table_id)}
                        className="carousel-content-add-btn"
                      >
                        Add
                      </Button>
                    )}

                    {image.platform === "newsletter" ? (
                      <div className="newsletter-media-carousel h-250">
                        <h4 className="newsletter-label">Newsletter</h4>
                        <h4 className="mt-7 date">
                          {new Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            day: "numeric",
                            month: "long",
                          }).format(new Date(image.post_date))}
                        </h4>
                      </div>
                    ) : image.platform === "linkedin" && !image.media_url ? (
                      <div className="newsletter-media-carousel h-250">
                        <h4 className="newsletter-label">LinkedIn Post</h4>
                        <h4 className="mt-7 date">
                          {new Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            day: "numeric",
                            month: "long",
                          }).format(new Date(image.post_date))}
                        </h4>
                      </div>
                    ) : image.platform === "twitter" && !image.media_url ? (
                      <div className="newsletter-media-carousel h-250">
                        <h4 className="newsletter-label">Twitter Post</h4>
                        <h4 className="mt-7 date">
                          {new Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            day: "numeric",
                            month: "long",
                          }).format(new Date(image.post_date))}
                        </h4>
                      </div>
                    ) : image.table_image ? (
                      image.table_image.includes(",") ? (
                        <Carousel
                          dotPosition="bottom"
                          className="feed-tab-media-carousel"
                        >
                          {image.table_image
                            .split(",")
                            .map((media_url: any, idx: any) => (
                              <Fragment key={idx}>
                                {media_url.includes("video") ? (
                                  <div className="h-255">
                                    <VideoComponent
                                      media_url={media_url}
                                      thumbnail={
                                        image.thumbnail?.split(",")[idx] ||
                                        "/assets/dummy_video.png"
                                      }
                                    />
                                  </div>
                                ) : (
                                  <img
                                    className="feed-tab-media-check mb-2"
                                    src={media_url}
                                    alt="post"
                                  />
                                )}
                              </Fragment>
                            ))}
                        </Carousel>
                      ) : image.table_image.includes("video") ? (
                        <div className="h-255">
                          <VideoComponent
                            media_url={image.table_image}
                            thumbnail={
                              image.thumbnail || "/assets/dummy_video.png"
                            }
                          />
                        </div>
                      ) : (
                        <img
                          className="feed-tab-media-check"
                          src={image.table_image || "/assets/dummy_video.png"}
                          alt="post"
                        />
                      )
                    ) : (
                      <img
                        className="feed-tab-media-check"
                        src={image.table_image}
                        alt="post"
                      />
                    )}
                  </div>
                </div>
              ))}
            </Fragment>
          ))}
        </div>
      ) : (
        <p>No shoppable content present for '{query}'</p>
      )}
    </>
  );
};

export default AllContentTable;
