import React, { useState } from "react";
import "../../../../styles/StayUptoDateCSS/SubComponents.css";
import { useLocation } from "react-router-dom";
import track, {
  TRACKING_TYPES,
} from "../../../../Utils/SupabaseServices/Track";
import ImagePostCarouselTag from "../../../../components/GeneralPostTag/ImagePostCarouselTag";
import VideoPostTag from "../../../../components/GeneralPostTag/VideoPostTag";
import ImagePostTag from "../../../../components/GeneralPostTag/ImagePostTag";
import { useLoadQueryFeed } from "./useLoadQueryFeed";
import { StaticVariables } from "../../../../Utils/StaticVariables";
import DataSkeleton from "../../../../components/StayUpToDateComponent/UpToDateComponents/DataSkeleton";
import { useDomLoaded } from "../../../../hooks/DomLoadedContext";
import DashboardCarouselStructure from "../../../../components/CarouselStructure/DashboardCarouselStructure";
export const QueryLinksDashboard = (props: any) => {
  const { user_id, username, numberOfDays, isLinks, brand_color, component } =
    props;
  const {
    key,
    name: title,
    postIds,
    cta,
    query,
    removed_postids: removedPostIds,
    content_order,
    content_shape,
    content_sound,
    type
  } = component;
  const location = useLocation();
  const domLoaded = useDomLoaded();
  const POSTS_PER_PAGE = 10;
  const isShopLinks = true;
  const showSocials = StaticVariables().PLATFORMS;
  const contentOrder = "Default";

  const {
    isLoading,
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  }: any = useLoadQueryFeed(
    username,
    user_id,
    query,

    showSocials,
    contentOrder,
    isShopLinks,
    removedPostIds,
    title,
    content_order
  );
  function handleShop(postId: string, media_url: string) {
    track("Redirect to shop link", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: { post_id: postId, media_url: media_url },
      user_id: user_id,
      title: "Stay Up To Date",
    });
  }

  return (
    <>
      <div>
        {isLoading || !domLoaded ? (
          <div className="outer-container-subcomponents">
            <DataSkeleton dashboard={true} />{" "}
          </div>
        ) : (
          <div className="outer-container-subcomponents">
            {data && data.length ? (
              <div className={`links-grid-container-subcomponents ${content_shape === "sq" ? 'square-grid' : ''}`}>
                {data &&
                  data.map((link: any, index: any) => (
                    <React.Fragment key={index}>
                    <DashboardCarouselStructure
                      // key={index}
                      link={link}
                      index={index}
                      handleShop={handleShop}
                      title={title}
                      brand_color={brand_color}
                      isLinks={isLinks}
                      contentShape={content_shape == "sq" ? true : false}
                      contentSound={content_sound}
                      type={type}
                    />
                    </React.Fragment>
                  ))}
              </div>
            ) : (
              <div className="outer-container-subcomponents">
                <p>No content at this time</p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
