import { User } from "@supabase/supabase-js";
import { supabase } from "../../config/supabaseClient";
import axios from "axios";

export async function signUpEmail(email: any, password: any) {
  var { data, error }: any = await supabase.auth.signUp({
    email: email,
    password: password,
  });
  if (error) {
    return false;
  } else if (data) {
    localStorage.setItem("emailCredentials", email);
    return email;
  }
}
export async function loginEmail(email: any, password: any) {
  const { data, error } = await supabase.auth.signInWithPassword({
    email: email,
    password: password,
  });
  if (data.user) {
    const { data } = await supabase
      .from("user")
      .select("username")
      .eq("email", email);

    if (data) {
      if (data.length === 0) {
        return false;
      } else {
        const username = data[0].username;
        localStorage.setItem("loggedIn", username);
        return username;
      }
    }
  } else if (error) {
    return "error";
  }
}

export async function signupUser(
  username: string,
  fullName: any,
  instagramHandle: string,
  youtubeChannelId: any,
  tiktokUsername: any,
  podcastShowLink: any,
  firstPlatform: any,
  // email: string,
  phoneNumber: any,
  user: User
) {
  const res = {
    error: false,
    message: "Error, please try again later or contact our support team.",
  };
  let createNewUserError;
  const slackToken =
    "xoxb-4117828880165-5021374947286-Srzu3WPZg8n26bq5gHCrjaGX";
  const channel = "#newcreators";
  try {
    if (!username || !fullName || !phoneNumber) {
      res.error = true;
      res.message = "Please fill in all fields";
      return res;
    } else {
    }
    if (!user) {
      const {
        data: { user: sessionUser },
      } = await supabase.auth.getUser();
      if (!sessionUser) {
        res.error = true;
        return res;
      }
      user = sessionUser;
    }
    // const name = firstName + " " + lastName;
    if (firstPlatform === "Instagram") {
      const { error } = await supabase.from("user").insert([
        {
          name: fullName,
          username,
          user_id: user.id,
          instagram_username: instagramHandle,
          first_connected_platform: firstPlatform,
          phoneNo: phoneNumber,
          email: user.email,
        },
      ]);
      await supabase
        .from("user")
        .update({ show_instagram: false, is_instagram_on: true })
        .eq("user_id", user.id);
      storeControlsAndTable(user.id, username, firstPlatform);
      try {
        const url = `https://dev.thesearchbubble.com/slack/update`;

        const resServer = await axios.post(
          url,
          {
            userId: user.id,
            user: username,
            channel: channel,
            msg: `New user signed up
        userrname :- ${username},
        platform :- ${firstPlatform},
        phoneNumber :-${phoneNumber},
        name :- ${fullName},
        instagram handle :- ${instagramHandle}
        USer id :- ${user.id}`,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const { success, message } = resServer.data;
      } catch (error) { }
      createNewUserError = error;
    } else if (firstPlatform === "Youtube") {
      const { error } = await supabase.from("user").insert([
        {
          name: fullName,
          username,
          user_id: user.id,
          youtube_channel_id: youtubeChannelId,
          first_connected_platform: firstPlatform,
          phoneNo: phoneNumber,
          email: user.email,
        },
      ]);
      await supabase
        .from("user")
        .update({ show_youtube: false, is_youtube_on: true })
        .eq("user_id", user.id);
      storeControlsAndTable(user.id, username, firstPlatform);

      createNewUserError = error;
      try {
        const url = `https://dev.thesearchbubble.com/slack/update`;

        const resServer = await axios.post(
          url,
          {
            userId: user.id,
            user: username,
            channel: channel,
            msg: `New user signed up
            userrname :- ${username},
            platform :- ${firstPlatform},
            phoneNumber :-${phoneNumber},
            name :- ${fullName},
            youtube channel id :- ${youtubeChannelId}
            USer id :- ${user.id}`,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const { success, message } = resServer.data;
      } catch (error) { }
    } else if (firstPlatform === "Podcast") {
      const regex = /\/show\/(\w+)\?/;

      // Use the regex to extract the show ID
      const match = podcastShowLink?.match(regex);

      // Check if there's a match and get the show ID
      const showId = match ? match[1] : "";
      const { error } = await supabase.from("user").insert([
        {
          name: fullName,
          username,
          user_id: user.id,
          podcast_link: podcastShowLink,
          podcast_id: showId,
          first_connected_platform: firstPlatform,
          phoneNo: phoneNumber,
          email: user.email,
        },
      ]);
      await supabase
        .from("user")
        .update({ show_podcast: false, is_podcast_on: true })
        .eq("user_id", user.id);
      storeControlsAndTable(user.id, username, firstPlatform);

      createNewUserError = error;

      try {
        const url = `https://dev.thesearchbubble.com/slack/update`;

        const resServer = await axios.post(
          url,
          {
            userId: user.id,
            user: username,
            channel: channel,
            msg: `New user signed up
            userrname :- ${username},
            platform :- ${firstPlatform},
            phoneNumber :-${phoneNumber},
            name :- ${fullName},
            podcast show link :- ${podcastShowLink},
            podcast id :- ${showId}

            USer id :- ${user.id}`,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const { success, message } = resServer.data;
      } catch (error) { }
    } else if (firstPlatform === "Tiktok") {
      const { error } = await supabase.from("user").insert([
        {
          name: fullName,
          username,
          user_id: user.id,
          tiktok_user: tiktokUsername,
          first_connected_platform: firstPlatform,
          phoneNo: phoneNumber,
          email: user.email,
        },
      ]);
      await supabase
        .from("user")
        .update({ show_tiktok: false, is_tiktok_on: true })
        .eq("user_id", user.id);
      storeControlsAndTable(user.id, username, firstPlatform);

      createNewUserError = error;
      try {
        const url = `https://dev.thesearchbubble.com/slack/update`;

        const resServer = await axios.post(
          url,
          {
            userId: user.id,
            user: username,
            channel: channel,
            msg: `New user signed up
            userrname :- ${username},
            platform :- ${firstPlatform},
            phoneNumber :-${phoneNumber},
            email :- ${user.email}
            name :- ${fullName},
            tiktok username :- ${tiktokUsername}
            USer id :- ${user.id}`,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const { success, message } = resServer.data;
      } catch (error) { }
    }

    if (createNewUserError) {
      res.error = true;
      if (
        createNewUserError.message ===
        'duplicate key value violates unique constraint "user_email_key"'
      ) {
        res.message = "This email is already in use.";
      }
      if (
        createNewUserError.message ===
        'duplicate key value violates unique constraint "user_username_key"'
      ) {
        res.message = "This username is already taken. Please try another one.";
      }
      return res;
    }

    // TODO - for now we just insert email to our table, future work: add the following lines of code to register the email in the auth.user supabase table. This will be done after a user clicks on "verify email" button
    // const { data, error } = await supabase.auth.updateUser({
    // 	email,
    // });
    await supabase.auth.updateUser({
      data: { username, currentOnboardingStep: 3 },
    });
    localStorage.setItem("loggedIn", username);

    return res;
  } catch (error: any) {
    res.error = true;
    res.message =
      error?.message ||
      "Error while creating user, please report this error to our support team.";
    return res;
  }
}
async function storeControlsAndTable(
  user_id: any,
  username: any,
  platform: any
) {
  await supabase
    .from("controls")
    .insert([
      {
        user_id: user_id,
        show_caption: "true",
        showCreatorCaption: "true",
        maxCreatorHashtag: 20,
        totalHashtagNum: 20,
        maxHashtag: 20,
        totalRecentSearches: "20",
        totalPopularSearches: "20",
        popularSearchesNumFollower: "20",
        popularSearchesNumCreator: "20",
        recentSearchesNumFollower: "20",
        recentSearchesNumCreator: "20",
        recent_searches: false,
        popular_searches: false,
        popularSearchesCreator: false,

        showCreatorFilters: false,
        showFollowerFilters: false,
        showAdsCreator: false,
        showAdsFollower: false,
        show_creator_comments: false,
        show_follower_comments: false,
      },
    ])
    .single();
  await supabase
    .from("controls_live")
    .insert([
      {
        user_id: user_id,
        show_caption: "true",
        totalHashtagNum: 20,
        maxHashtag: 20,
        totalRecentSearches: "20",
        totalPopularSearches: "20",
        popularSearchesNumFollower: "20",
        recentSearchesNumFollower: "20",
        recent_searches: false,
        popular_searches: false,

        showFollowerFilters: false,
        showAdsFollower: false,
        show_follower_comments: false,
        initial_content2: platform === "Instagram" ? "Feed" : "All",
      },
    ])
    .single();
  await supabase
    .from("controls_staging")
    .insert([
      {
        user_id: user_id,
        showCreatorCaption: "true",
        maxCreatorHashtag: 20,
        totalHashtagNum: 20,
        totalRecentSearches: "20",
        totalPopularSearches: "20",
        popularSearchesNumCreator: "20",
        recentSearchesNumCreator: "20",

        popularSearchesCreator: false,

        showCreatorFilters: false,
        showAdsCreator: false,
        show_creator_comments: false,
        initial_content2: platform === "Instagram" ? "Feed" : "All",
      },
    ])
    .single();
  const url = `https://dev.thesearchbubble.com/createTable`;
  await axios.post(url, {
    id: user_id,
    user: username,
  });
}
export async function storeUserDetails(
  provider_token: any,
  picture: any,
  provider_id: any,
  username: any,
  instagramId: any,
  auth_user_id: any
) {
  const response = await fetch(picture);

  const blob = await response.blob();

  if (
    blob.type === "image/jpeg" ||
    blob.type === "image/png" ||
    blob.type === "image/webp"
  ) {
    const mediaExt = blob.type.split("/")[1];

    await supabase.storage
      .from("profile-picture")
      .upload(`${username}.${mediaExt}`, blob, {
        contentType: blob.type,
      });

    const { data: image } = await supabase.storage
      .from("profile-picture")
      .getPublicUrl(`${username}.${mediaExt}`);

    await supabase
      .from("user")
      .update({
        provider_token,
        picture,
        provider_id,
        instagramId,
        storage_url: image?.publicUrl ? image.publicUrl : picture,
      })
      .eq("user_id", auth_user_id);
  }
}

export async function signUpPhone(phoneNumber: any) {
  const { data, error } = await supabase.auth.signInWithOtp({
    phone: phoneNumber,
  });

  if (error === null) {
    return true;
  } else {
    return false;
  }
}
export async function signInEmailOTP(emailId: any) {
  // const { data, error } = await supabase.auth.signInWithOtp({
  //   phone: phoneNumber,
  // });
  let loginError: any;
  const { data: login, error: errorLogin } = await supabase
    .from("user")
    .select("*")
    .eq("email", emailId);
  if (login) {
    if (login.length !== 0) {
      const { data, error } = await supabase.auth.signInWithOtp({
        email: emailId,
      });
      loginError = error;
    }
  }
  if (loginError === null) {
    return true;
  } else {
    if (loginError.includes("60 seconds")) {
      return "time error";
    } else {
      return false;
    }
  }
}
export async function signUpEmailOTP(emailId: any) {
  // const { data, error } = await supabase.auth.signInWithOtp({
  //   phone: phoneNumber,
  // });
  let signupError;
  const { data: signup, error: errorsignup } = await supabase
    .from("user")
    .select("*")
    .eq("email", emailId);
  if (signup) {
    if (signup.length === 0) {
      const { data, error } = await supabase.auth.signInWithOtp({
        email: emailId,
      });
      signupError = error;
    } else {
    }
  }
  if (signupError === null) {
    return true;
  } else {
    return false;
  }
}
export async function loginPhone(phoneNumber: any) {
  const { data, error } = await supabase.auth.signInWithOtp({
    phone: phoneNumber,
  });

  if (error === null) {
    return true;
  } else {
    return false;
  }
}
export async function verifyPhoneNumberSignUp(
  phnWithCode: any,
  token: any,
  moveToNextStep: (nextStep: number) => void
) {
  const res = {
    error: false,
    message: "",
  };

  const { data: sessionData, error } = await supabase.auth.verifyOtp({
    phone: phnWithCode,
    token,
    type: "sms",
  });
  if (error || !sessionData || !sessionData.user || !sessionData.user.id) {
    res.error = true;
    res.message = "Invalid token";
    return res;
  } else {
    localStorage.setItem("phoneCredentials", phnWithCode);
    // check user is already stored
    const { data }: any = await supabase
      .from("user")
      .select("*")
      .eq("user_id", sessionData.user.id)
      .single();
    if (data) {
      if (data.is_active) {
        await supabase.auth.updateUser({
          data: {
            username: data.username,
            currentOnboardingStep: 3,
            isOnboardingFinished: true,
          },
        });
        moveToNextStep(4);
        return res;
      }

      if (data.username && data.phoneNo) {
        await supabase.auth.updateUser({
          data: {
            username: data.username,
            currentOnboardingStep: 3,
            isOnboardingFinished: false,
          },
        });
        moveToNextStep(3);
        return res;
      }
    }

    // if the phone number is not registered, we continue with the onboarding flow
    await supabase.auth.updateUser({
      data: { currentOnboardingStep: 2, isOnboardingFinished: false },
    });
    moveToNextStep(2);
    return res;
  }
}

export async function verifyEmailSignUp(
  email: any,
  token: any,
  moveToNextStep: (nextStep: number) => void
) {
  const res = {
    error: false,
    message: "",
  };

  // const { data: sessionData, error } = await supabase.auth.verifyOtp({
  //   phone: phnWithCode,
  //   token,
  //   type: "sms",
  // });

  const { data: sessionData, error } = await supabase.auth.verifyOtp({
    email,
    token,
    type: "email",
  });
  if (error || !sessionData || !sessionData.user || !sessionData.user.id) {
    res.error = true;
    res.message = "Invalid token";
    return res;
  } else {
    localStorage.setItem("phoneCredentials", email);
    // check user is already stored
    const { data }: any = await supabase
      .from("user")
      .select("*")
      .eq("user_id", sessionData.user.id)
      .single();

    if (data) {
      if (data.is_active) {
        await supabase.auth.updateUser({
          data: {
            username: data.username,
            currentOnboardingStep: 3,
            isOnboardingFinished: true,
          },
        });
        moveToNextStep(4);
        return res;
      }

      if (data.username && data.email) {
        await supabase.auth.updateUser({
          data: {
            username: data.username,
            currentOnboardingStep: 3,
            isOnboardingFinished: false,
          },
        });
        moveToNextStep(3);
        return res;
      }
    }

    // if the phone number is not registered, we continue with the onboarding flow
    await supabase.auth.updateUser({
      data: { currentOnboardingStep: 2, isOnboardingFinished: false },
    });
    moveToNextStep(2);
    return res;
  }
}

export async function verifyPhoneNumberSignIn(
  phnWithCode: any,
  token: any,
  loginSuccess: (username: string) => void
) {
  const res = {
    error: false,
    message: "",
  };

  const { data: sessionData, error } = await supabase.auth.verifyOtp({
    phone: phnWithCode,
    token,
    type: "sms",
  });
  // miland@alumni.stanford.edu
  if (error || !sessionData || !sessionData.user || !sessionData.user.id) {
    res.error = true;
    res.message = "Invalid token";
    return res;
  } else {
    localStorage.setItem("phoneCredentials", phnWithCode);
    // Check user is already stored
    // const { data }: any = await supabase
    //   .from("user")
    //   .select("*")
    //   .eq("user_id", sessionData.user.id)
    //   .single();

    // if (data && data.username) {
    if (sessionData && sessionData.user && sessionData.user.user_metadata) {
      localStorage.setItem("loggedIn", sessionData.user.user_metadata.username);

      loginSuccess(sessionData.user.user_metadata.username);
    } else {
      res.error = true;
      res.message = "There is no account linked to this phone number";
      return res;
    }
    return res;
  }
}

export async function verifyEmailSignIn(
  email: any,
  token: any,
  loginSuccess: (username: string) => void
) {
  const res = {
    error: false,
    message: "",
  };

  // const { data: sessionData, error } = await supabase.auth.verifyOtp({
  //   phone: phnWithCode,
  //   token,
  //   type: "sms",
  // });

  const { data: sessionData, error } = await supabase.auth.verifyOtp({
    email,
    token,
    type: "email",
  });
  if (error || !sessionData || !sessionData.user || !sessionData.user.id) {
    res.error = true;
    res.message = "Invalid token";
    return res;
  } else {
    localStorage.setItem("phoneCredentials", email);
    // Check user is already stored
    const { data }: any = await supabase
      .from("user")
      .select("*")
      .eq("user_id", sessionData?.user?.id)
      .single();

    if (data && data.username) {
      // if (sessionData && sessionData.user && sessionData.user.user_metadata) {
      localStorage.setItem("loggedIn", data.username);

      loginSuccess(data.username);
    } else {
      res.error = true;
      res.message = "There is no account linked to this email";
      return res;
    }
    return res;
  }
}

export async function verifyPhoneLogin(phnWithCode: any, token: any) {
  let { data, error } = await supabase.auth.verifyOtp({
    phone: phnWithCode,
    token: token,
    type: "sms",
  });
  if (data.user) {
    const { data } = await supabase
      .from("user")
      .select("*")
      .eq("phoneNo", phnWithCode);

    if (data) {
      if (data.length === 0) {
        return false;
      } else {
        const username = data[0].username;
        localStorage.setItem("loggedIn", username);
        return username;
      }
    }
  } else if (error) {
    return "error";
  }
}

export async function activateUser(user_id: string) {
  await supabase
    .from("user")
    .update({ is_active: true })
    .eq("user_id", user_id);
}

export async function fetchUser(username: any) {
  let activeSocials: any = [];
  let { data }: any = await supabase
    .from("user")
    .select("*")
    .eq("username", username);
  if (data && data?.length === 0) {
    return false;
  } else {
    const user_id = data[0].user_id;
    const name = data[0].name;
    var picture;
    if (data[0].storage_url) {
      picture = data[0].storage_url;
    } else {
      picture = data[0].picture;
    }

    const email = data[0].email;
    const phoneNo = data[0].phoneNo;
    const instaLink = data[0].instagram_link;
    const youtubeLink = data[0].youtube_link;
    const amazonLink = data[0].amazon_link;
    const podcastLink = data[0].podcast_link;
    const pinterestLink = data[0].pinterest_link;
    const twitterLink = data[0].twitter_link;
    const instagram_links = data[0].instagram_links;
    const isOnboardingFinished = data[0].is_onboarding_finished;
    if (instaLink) {
      activeSocials.push({ term: "instagram", link: instaLink });
    }
    if (youtubeLink) {
      activeSocials.push({ term: "youtube", link: youtubeLink });
    }
    if (amazonLink) {
      activeSocials.push({ term: "amazon", link: amazonLink });
    }
    if (podcastLink) {
      activeSocials.push({ term: "podcast", link: podcastLink });
    }
    if (pinterestLink) {
      activeSocials.push({ term: "pinterest", link: pinterestLink });
    }
    if (twitterLink) {
      activeSocials.push({ term: "twitter", link: twitterLink });
    }
    return {
      name,
      picture,
      email,
      phoneNo,
      instaLink,
      youtubeLink,
      amazonLink,
      podcastLink,
      pinterestLink,
      twitterLink,
      activeSocials,
      user_id,
      instagram_links,
      isOnboardingFinished,
    };
  }
}

export async function updatePhoneNo(
  user_id: any,
  username: any,
  phoneNumber: any
) {
  // const { data, error } = await supabase.auth.updateUser({
  //   phone: phoneNumber,
  // });
  const { data, error } = await supabase.auth.admin.updateUserById(user_id, {
    phone_confirm: true,
  });
  let { data: user }: any = await supabase
    .from("user")
    .select("*")
    .eq("username", `${username}`);
  if (user.length > 0) {
    await supabase
      .from("user")
      .update({ phoneNo: `${phoneNumber}` })
      .eq("username", `${username}`);
  }
}

export async function updateName(username: any, name: any) {
  let { data }: any = await supabase
    .from("user")
    .select("*")
    .eq("username", `${username}`);
  if (data) {
    await supabase
      .from("user")
      .update({ name: `${name}` })
      .eq("username", `${username}`);
  }
}

export async function updateProfileNameDescription(username: any, newVaule: any) {
  let { data }: any = await supabase
    .from("user")
    .select("*")
    .eq("username", `${username}`);
  if (data) {
    const { error } = await supabase
      .from("user")
      .update(newVaule)
      .eq("username", `${username}`);
  }
}

export async function updateUsername(username: string, user_id: any) {
  const { error } = await supabase
    .from("user")
    .update({ username })
    .eq("user_id", user_id);
  if (error) return false;
  await supabase.auth.updateUser({
    data: { username },
  });
  localStorage.setItem("loggedIn", username);
  return true;
}
export async function updateEmail(user_id: any, username: any, email: any) {
  const { data: user, error } = await supabase.auth.admin.updateUserById(
    user_id,
    { email: email }
  );
  const { data } = await supabase
    .from("user")
    .update({
      email: `${email}`,
      emailSignup: `${email}`,
      email_verify: true,
    })
    .eq("username", `${username}`);
}

export async function fetchUsername(email: any) {
  const { data } = await supabase.from("user").select("*").eq("email", email);

  if (data) {
    if (data?.length === 0) {
      return false;
    } else {
      const username = data[0].username;
      localStorage.setItem("loggedIn", username);
      return username;
    }
  }
}

export async function fetchIncludedUsername() {
  const { data, error } = await supabase
    .from("user")
    .select("username,user_id")
    .eq("global_view", true);

  if (data) {
    const users: any = [];
    const userIds: any = [];
    data.map((data: any) => {
      users.push(data.username);
      userIds.push(data.user_id);
    });
    return { users, userIds };
  }
}

export async function resetUserPassword(email: any) {
  const { error } = await supabase.auth.resetPasswordForEmail(email, {
    redirectTo: "https://thesearchbubble.com//auth/update-password", //TODO -> env variable
  });

  if (error === null) {
    return true;
  } else {
    return false;
  }
}
export async function updateUserPassword(password: any) {
  const { error } = await supabase.auth.updateUser({
    password: password,
  });

  if (error === null) {
    return true;
  } else {
    return false;
  }
}

export async function storeDirectInstagramUser(
  user_id: string,
  username: string
) {
  const { error } = await supabase
    .from("user")
    .update({ instagram_username: username })
    .eq("user_id", user_id);
  if (error === null) {
    return true;
  }
  return false;
}

export async function storeLinks(
  user_id: any,
  username: any,
  linkName: any,
  link: any,
  imageLinkUrl: any
) {
  const { data, error }: any = await supabase
    .from("user")
    .select("*")
    .eq("user_id", user_id);
  const completeLink = linkName + ":-" + link + ":-" + imageLinkUrl;
  if (data[0].instagram_links) {
    const addedLink = data[0].instagram_links + "," + completeLink;
    const { error } = await supabase
      .from("user")
      .update({ instagram_links: addedLink })
      .eq("user_id", user_id);
    if (error === null) {
      return true;
    }
    return false;
  } else {
    const { error } = await supabase
      .from("user")
      .update({ instagram_links: completeLink })
      .eq("user_id", user_id);
    if (error === null) {
      return true;
    }
    return false;
  }

  // if(error===null){
  // 	return true
  // }
  // return false
}

export async function updateTiktokToggle(user_id: any, toggle: any) {
  await supabase
    .from("user")
    .update({ is_tiktok_on: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateTwitterToggle(user_id: any, toggle: any) {
  await supabase
    .from("user")
    .update({ is_twitter_on: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateYoutubeToggle(user_id: any, toggle: any) {
  await supabase
    .from("user")
    .update({ is_youtube_on: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateNewsletterToggle(user_id: any, toggle: any) {
  await supabase
    .from("user")
    .update({ is_newsletter_on: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateInstagramToggle(user_id: any, toggle: any) {
  await supabase
    .from("user")
    .update({ is_instagram_on: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updatePodcastToggle(user_id: any, toggle: any) {
  await supabase
    .from("user")
    .update({ is_podcast_on: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updatePinterestToggle(user_id: any, toggle: any) {
  await supabase
    .from("user")
    .update({ is_pinterest_on: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateFlowdeskToggle(user_id: any, toggle: any) {
  await supabase
    .from("user")
    .update({ is_flowdesk_on: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateFlowdeskPopupToggle(user_id: any, toggle: any) {
  await supabase
    .from("user")
    .update({ is_flowdeskpopup_on: toggle })
    .eq("user_id", `${user_id}`);
}
export async function updateFilloutToggle(user_id: any, toggle: any) {
  await supabase
    .from("user")
    .update({ is_fillout_on: toggle })
    .eq("user_id", `${user_id}`);
}

export async function updatePlatformToggle(user_id: any, key: string, toggle: any) {
  await supabase
    .from("user")
    .update({ [`is_${key}_on`]: toggle })
    .eq("user_id", `${user_id}`);
}


export async function deleteInstagram(username: any, user_id: any) { }

export async function deleteYoutube(username: any, user_id: any) {
  const { data, error } = await supabase
    .from("user")
    .update({
      youtube_link: null,
      youtube_channel_id: null,
      youtube_token: null,
      youtube_playlist_id: null,
      show_youtube: false,
      yt_storage_url: "",
      is_youtube_on: false,
    })
    .eq("user_id", user_id);
  const { data: del, error: delError } = await supabase
    .from("content_yt")
    .delete()
    .eq("user_id", user_id);
  const { data: del1, error: del1error } = await supabase
    .from("content_new")
    .delete()
    .eq("user_id", user_id)
    .eq("platform", "youtube");
  const { data: del2, error: del2error } = await supabase
    .from(`${username}`)
    .delete()
    .eq("user_id", user_id)
    .eq("platform", "youtube");
}

export async function deletePodcast(username: any, user_id: any) {
  const { data, error } = await supabase
    .from("user")
    .update({
      podcast_link: null,
      podcast_id: null,
      show_podcast: false,
      is_podcast_on: false,
    })
    .eq("user_id", user_id);
  const { data: del, error: delError } = await supabase
    .from("content_podcast")
    .delete()
    .eq("user_id", user_id);
  const { data: del1, error: del1error } = await supabase
    .from("content_new")
    .delete()
    .eq("user_id", user_id)
    .eq("platform", "podcast");
  const { data: del2, error: del2error } = await supabase
    .from(`${username}`)
    .delete()
    .eq("user_id", user_id)
    .eq("platform", "podcast");
  const { data: list }: any = await supabase.storage
    .from("podcasts")
    .list(`${username}`, { limit: 5000 });
  for (let x of list) {
    await supabase.storage.from("podcasts").remove([`${username}/${x.name}`]);
  }
}

export async function deleteNewsletter(username: any, user_id: any) {
  const { data, error } = await supabase
    .from("user")
    .update({
      newsletter_email: null,

      show_newsletter: false,
      is_newsletter_on: false,
    })
    .eq("user_id", user_id);
  const { data: del, error: delError } = await supabase
    .from("content_newsletter")
    .delete()
    .eq("user_id", user_id);
  const { data: del1, error: del1error } = await supabase
    .from("content_new")
    .delete()
    .eq("user_id", user_id)
    .eq("platform", "newsletter");
  const { data: del2, error: del2error } = await supabase
    .from(`${username}`)
    .delete()
    .eq("user_id", user_id)
    .eq("platform", "newsletter");
}

export async function deleteTiktok(username: any, user_id: any) {
  const { data, error } = await supabase
    .from("user")
    .update({
      tiktok_user: null,

      show_tiktok: false,
      is_tiktok_on: false,
    })
    .eq("user_id", user_id);
  const { data: del, error: delError } = await supabase
    .from("content_tiktok")
    .delete()
    .eq("user_id", user_id);
  const { data: del1, error: del1error } = await supabase
    .from("content_new")
    .delete()
    .eq("user_id", user_id)
    .eq("platform", "tiktok");
  const { data: del2, error: del2error } = await supabase
    .from(`${username}`)
    .delete()
    .eq("user_id", user_id)
    .eq("platform", "tiktok");
  const { data: list }: any = await supabase.storage
    .from("tiktok")
    .list(`${username}`, { limit: 5000 });
  for (let x of list) {
    await supabase.storage.from("tiktok").remove([`${username}/${x.name}`]);
  }
}
export async function deletePinterest(username: any, user_id: any) {
  const { data, error } = await supabase
    .from("user")
    .update({
      pinterest_user: null,

      show_pinterest: false,
      is_pinterest_on: false,
    })
    .eq("user_id", user_id);
  const { data: del, error: delError } = await supabase
    .from("content_pinterest")
    .delete()
    .eq("user_id", user_id);
  const { data: del1, error: del1error } = await supabase
    .from("content_new")
    .delete()
    .eq("user_id", user_id)
    .eq("platform", "pinterest");
  const { data: del2, error: del2error } = await supabase
    .from(`${username}`)
    .delete()
    .eq("user_id", user_id)
    .eq("platform", "pinterest");
  const { data: list }: any = await supabase.storage
    .from("pinterest_pins")
    .list(`${username}`, { limit: 5000 });
  for (let x of list) {
    await supabase.storage
      .from("pinterest_pins")
      .remove([`${username}/${x.name}`]);
  }
}
export async function deleteFlowdesk(username: any, user_id: any) {
  const { data, error } = await supabase
    .from("user")
    .update({
      flowdesk_user: null,

      show_flowdesk: false,
      is_flowdesk_on: false,
    })
    .eq("user_id", user_id);

}
export async function deleteFlowdeskPopup(username: any, user_id: any) {
  const { data, error } = await supabase
    .from("user")
    .update({
      flowdeskpopup_user: null,

      show_flowdeskpopup: false,
      is_flowdeskpopup_on: false,
    })
    .eq("user_id", user_id);

}
export async function deleteFillout(username: any, user_id: any) {
  const { data, error } = await supabase
    .from("user")
    .update({
      fillout_user: null,

      show_fillout: false,
      is_fillout_on: false,
    })
    .eq("user_id", user_id);
  // const { data: del, error: delError } = await supabase
  //   .from("content_pinterest")
  //   .delete()
  //   .eq("user_id", user_id);
  // const { data: del1, error: del1error } = await supabase
  //   .from("content_new")
  //   .delete()
  //   .eq("user_id", user_id)
  //   .eq("platform", "pinterest");
  // const { data: del2, error: del2error } = await supabase
  //   .from(`${username}`)
  //   .delete()
  //   .eq("user_id", user_id)
  //   .eq("platform", "pinterest");
  // const { data: list }: any = await supabase.storage
  //   .from("pinterest_pins")
  //   .list(`${username}`, { limit: 5000 });
  // for (let x of list) {
  //   await supabase.storage
  //     .from("pinterest_pins")
  //     .remove([`${username}/${x.name}`]);
  // }
}

export async function changeSearchBarText(user_id: any, text: any) {
  const { data, error } = await supabase
    .from("user")
    .update({ search_bar_text: text })
    .eq("user_id", user_id);
}
export async function reorderSocialLinks(user_id: any, actual: any, newArray: any, length: any) {
  const actualOrder = actual?.split(',')
  const reorderedArray = [
    ...newArray.filter((item: any) => actualOrder.includes(item)), // Items in the initial order that are also in the actual order
    ...actualOrder.filter((item: any) => !newArray.includes(item)) // Items in the actual order that are not in the initial order
  ];
  const { data, error } = await supabase.from('user').update({ social_links_order: reorderedArray.toString() }).eq("user_id", user_id)
}





