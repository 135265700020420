import "react-tooltip/dist/react-tooltip.css";
import SearchBarTags from "../../../../../components/SearchBarText/SearchBarTags";
import { useLoadUser } from "../../../../../Utils/customHooks/useLoadUser";
import SearchBarText from "../../../../../components/SearchBarText/SearchBarText";
import { useNavigate } from "react-router-dom";

const SearchbarControls = (props: any) => {
  const {
    username,
    controlsLoading,
    controls,
    refetchControls,
    userLoading,
    userData,
    refetch,
    user_id,
  } = props;

  return (
    <div className="controls-container">
      <div className="row">
        <div className="controls p-4">
          <div className="account-values-profiles">
            <SearchBarText
              isLoading={userLoading}
              userData={userData}
              refetch={refetch}
              searchBarText={
                userData?.search_bar_text
                  ? userData?.search_bar_text
                  : `Search ${userData?.name} Content`
              }
              username={username}
              user_id={user_id}
              controls={controls}
              refetchControls={refetchControls}
            />
          </div>
          {/* <div className="account-values-profiles">
            <SearchBarTags
              searchBarText={
                userData?.search_bar_text
                  ? userData?.search_bar_text
                  : `Search ${userData?.name} Content`
              }
              username={userData?.username}
              isLoading={userLoading}
              userData={userData}
              refetch={refetch}
            />
          </div> */}
          {/* <div className="push-btn-div">
              <PreviewBtn />
            </div> */}
        </div>
      </div>
    </div>
  );
};

export default SearchbarControls;
