import React, { useEffect, useState, useRef } from "react";
import HoverVideoPlayer from "react-hover-video-player";
import { BsFillPlayFill, BsHourglassSplit, BsVolumeMute, BsVolumeUp } from "react-icons/bs";
const VideoComponent = (props: any) => {
  const { media_url, thumbnail, content_shape, isSoundOn } = props;


  const VideoTag = (props: any) => {
    const { media_url, thumbnail, isSoundOn } = props;
    const videoRef: any = useRef();
    const [hover, setHover] = useState(false);
    const [isMuted, setIsMuted] = useState(true);
    const [customPause, setCustomPause] = useState(false);
    const [showClickablePlayBtn, setShowClickablePlayBtn] = useState(false);

    useEffect(() => {
      if (
        navigator.userAgent.search("Safari") >= 0 &&
        navigator.userAgent.search("Chrome") < 0
      ) {
        setShowClickablePlayBtn(true);
      }
    }, []);
    const OverlayStatus = ({
      hover,
      showClickablePlayBtn,
    }: {
      hover: boolean;
      showClickablePlayBtn: boolean;
    }) => {
      return (
        <>
          {!hover && <BsFillPlayFill className={"creator-feed-card-play-btn"} />}
        </>
      );
    };

    useEffect(() => {
      videoRef.current.playsInline = true;
    }, []);

    const toggleMute = (e: React.MouseEvent) => {
      e.stopPropagation(); // Prevent click from triggering parent events
      e.preventDefault();
      setIsMuted((prev) => !prev);
    };


    return (
      <>
        {!Boolean(media_url) ? (
          <div>
            <BsHourglassSplit
            // className="hourglassSplit"
            />
            <p>
              Work in progress: This content will be available in a few minutes.
            </p>
          </div>
        ) : (
          <HoverVideoPlayer
            videoRef={videoRef}
            videoSrc={media_url}
            muted={isSoundOn ? isMuted : true}
            className={`${content_shape === "sq" ? 'square-video mb-2' : 'mb-2'}`}
            // className="feed-tab-media-check"
            //   className={"feed-tab-media-check hover-play"}

            // style={{
            //   maxHeight: "300px",
            //   whiteSpace: "nowrap",
            //   overflowY: "scroll",
            //   borderRadius: "1rem",
            // }}
            preload={"metapost"}
            contextMenu="return false;"
            hoverOverlay={
              <div className="video-hover">
                {isSoundOn && <div
                  className="sound-icon"
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                    zIndex: 10,
                  }}
                  onClick={toggleMute}
                >
                  {isMuted ? (
                    <BsVolumeMute size={24} color="white" style={{ width: "100%", height: "100%" }} />
                  ) : (
                    <BsVolumeUp size={24} color="white" style={{ width: "100%", height: "100%" }} />
                  )}
                </div>}
              </div>
            }
            pausedOverlay={
              <div
                className={`${content_shape === "sq" ? 'square-video mb-2' : 'mb-2'}`}
              >
                <img
                  src={thumbnail}
                  alt=""
                  className={`${content_shape === "sq" ? 'square-video mb-2' : 'h-100 mb-2'}`}
                  loading="eager"
                />

                <OverlayStatus
                  hover={hover}
                  showClickablePlayBtn={showClickablePlayBtn}
                />
              </div>
            }
            loadingOverlay={
              <img
                src={thumbnail}
                alt=""

                className={`${content_shape === "sq" ? 'video-component-feed-card-links-image square-video mb-2' : 'video-component-feed-card-links-image mb-2'}`}
                // className={"creator-feed-card-links-image"}
                // className="feed-tab-media-check"
                loading="eager"
              />
            }
            unloadVideoOnPaused
            onHoverStart={() => {
              setHover(true);
            }}
            onHoverEnd={() => {
              setHover(false);
              setCustomPause(false);
            }}
            disableDefaultEventHandling={customPause}
          />
        )}
      </>
    );
  };
  return (
    <>
      <VideoTag
        media_url={media_url}
        thumbnail={thumbnail ? thumbnail : "/assets/dummy_video.png"}
        isSoundOn={isSoundOn}
      />
    </>
  );
};

export default VideoComponent;
