import { Switch, Typography } from 'antd';
import React, { useState } from 'react'
import { SoundOutlined, AudioMutedOutlined } from "@ant-design/icons";

const { Title } = Typography;

const CarouselSoundControl = (props: any) => {
  const { isSoundOn, setIsSoundOn } = props;
  const handleToggle = (checked: any) => {
    setIsSoundOn(checked);
  };

  return (
    <div>
      <Title level={4}>Content Sound &nbsp;
      <Switch
        size= 'small'
        checked={isSoundOn}
        onChange={handleToggle}
        checkedChildren={<SoundOutlined />}
        unCheckedChildren={<AudioMutedOutlined />}
      />
      </Title>
      {/* <div style={{ marginTop: "10px" }}>
        {isMuted ? "Muted" : "Sound On"}
      </div> */}
    </div>
  );
};

export default CarouselSoundControl