import { useState, useRef, useEffect } from "react";
import HoverVideoPlayer from "react-hover-video-player";
import { BsFillPlayFill, BsHourglassSplit } from "react-icons/bs";

const OverlayStatus = ({ hover }: { hover: boolean }) => {
  return (
    <>{!hover && <BsFillPlayFill className="creator-feed-card-play-btn" />}</>
  );
};

const VideoPlayer = (props: any) => {
  const {
    media_url,
    thumbnail,
    showAsAd,
    post_date,
    permalink,
    post_id,
    username,
    shop_link,
    tagged_link,
  } = props;
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [hover, setHover] = useState(false);
  const [customPause, setCustomPause] = useState(false);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playsInline = true;
    }
  }, []);

  return (
    <>
      {!media_url ? (
        <div className="creator-feed-card-video not-available">
          <BsHourglassSplit className="hourglassSplit" />
          <p>
            Work in progress: This content will be available in a few minutes.
          </p>
        </div>
      ) : (
        <div>
          <HoverVideoPlayer
            videoRef={videoRef}
            videoSrc={media_url}
            className="creator-feed-card-video hover-play"
            muted
            preload="metadata"
            contextMenu="return false;"
            hoverOverlay={<div className="video-hover"></div>}
            pausedOverlay={
              <div className="pause-overlay">
                <img
                  src={thumbnail || "/assets/dummy_video.png"}
                  alt=""
                  className="creator-feed-card-video"
                  loading="eager"
                />
                <OverlayStatus hover={hover} />
              </div>
            }
            loadingOverlay={
              <img
                src={thumbnail || "/assets/dummy_video.png"}
                alt=""
                className="creator-feed-card-video"
                loading="eager"
              />
            }
            unloadVideoOnPaused
            onHoverStart={() => setHover(true)}
            onHoverEnd={() => {
              setHover(false);
              setCustomPause(false);
            }}
            disableDefaultEventHandling={customPause}
          />

          {/* Example RefTag Component */}
          {/* <RefTag
            media_url={media_url}
            showAsAd={showAsAd}
            post_date={post_date}
            permalink={permalink}
            shop_link={shop_link}
            post_id={post_id}
            username={username}
            tagged_link={tagged_link}
            isVideo
          /> */}
        </div>
      )}
    </>
  );
};

export default VideoPlayer;
