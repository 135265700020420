import axios from "axios";
import { supabase } from "../../config/supabaseClient";
import { extractLinkedInUsername } from "./ContentDatabase";

export async function signupUserFolder(
  name: any,
  username: any,
  email: any,
  phone: any,
  password: any
) {
  try {
    if (!username) {
      return {
        error: true,
        message: "Please fill in all fields",
      };
    }
    // const pwd = "12345";

    // const fullName = `${username} ${username}`;
    // const email = `${username}@gmail.com`;
    //   const phoneNo = Math.floor(100000000000 + Math.random() * 900000000000);
    // const instaUsername = username;
    // const url = `http://localhost:3001/createuser`;
    console.log("fgd", username, email, password, name, phone);
    const url = `https://dev.thesearchbubble.com/createuser`;
    const createNewUserError = await axios.post(
      url,
      {
        //   id: userId,
        email: email,
        password: password,
        name: name,
        username: username,
        // instaUsername: instaUsername,
        phoneNo: phone,
      }
      // {
      //   headers: {
      //     "content-type": "application/json",
      //   },
      // }
    );

    if (createNewUserError.data !== "done") {
      const errorMessage = createNewUserError.data.message || "";
      const duplicateEmailMessage =
        'duplicate key value violates unique constraint "user_email_key"';
      const duplicateUsernameMessage =
        'duplicate key value violates unique constraint "user_username_key"';
      const duplicateEmailError = errorMessage.includes(duplicateEmailMessage);
      const duplicateUsernameError = errorMessage.includes(
        duplicateUsernameMessage
      );

      return {
        error: true,
        message: duplicateEmailError
          ? "This email is already in use."
          : duplicateUsernameError
          ? "This username is already taken. Please try another one."
          : "Error, please try again later or contact our support team.",
      };
    } else {
      return {
        error: false,
        message: "User created successfully",
      };
    }
  } catch (error: any) {
    return {
      error: true,
      message:
        error?.message ||
        "Error while creating user, please report this error to our support team.",
    };
  }
}

export async function updateSelectedPlatforms(
  selectedPlatforms: any,
  username: any,
  newPlatforms: any
) {
  console.log("selectedPlatforms", selectedPlatforms, username, newPlatforms);

  for (let [index, platform] of selectedPlatforms.entries()) {
    if (platform.platform === "instagram") {
      const instagramPlatform = await supabase
        .from("user")
        .update({ instagram_username: platform.username })
        .eq("username", username);
      // .single();
    } else if (platform.platform === "linkedin") {
      const linkedInUsername = await extractLinkedInUsername(platform.username);
      await supabase
        .from("user")
        .update({ linkedin_user: linkedInUsername })
        .eq("username", username);
      // .single();
    } else if (platform.platform === "tiktok") {
      const result = platform.username.startsWith("@")
        ? platform.username.slice(1)
        : platform.username;
      await supabase
        .from("user")
        .update({ tiktok_user: result })
        .eq("username", username);
    } else if (platform.platform === "youtube") {
      // const youtubeChannelId = await extractYoutubeChannelId(platform.username);
      await supabase
        .from("user")
        .update({ youtube_channel_id: platform.username })
        .eq("username", username);
    } else if (platform.platform === "newsletter") {
      await supabase
        .from("user")
        .update({ newsletter_email: platform.username })
        .eq("username", username);
    } else if (platform.platform === "podcast") {
      const [showName, showLink] = platform.username.split(",");
      const regex = /\/show\/(\w+)\?/;

      // Use the regex to extract the show ID
      const match = showLink?.match(regex);

      // Check if there's a match and get the show ID
      const showId = match ? match[1] : "";
      const { data, error } = await supabase
        .from("user")
        .update({
          podcast_link: showLink,
          podcast_artist: showName,
          podcast_id: showId,
        })
        .eq("username", username);
    } else if (platform.platform === "twitter") {
      await supabase
        .from("user")
        .update({ twitter_user: platform.username })
        .eq("username", username);
    }
  }
}

export async function IntegratePlatforms(
  selectedPlatforms: any,
  username: any
) {
  console.log("selectedPlatforms", selectedPlatforms, username);
  const { data } = await supabase
    .from("user")
    .select("*")
    .eq("username", username);
  const user_id = data?.[0].user_id;
  const channel = "#newintegrations";

  const slackUrl = `https://dev.thesearchbubble.com/slack/update`;
  for (let [index, platform] of selectedPlatforms.entries()) {
    if (platform.platform === "instagram") {
      const highlightSetupUrl = `https://dev.thesearchbubble.com/highlightsetup-onboarding`;
      // const highlightSetupUrl = `http://localhost:3001/highlightsetup`;

      axios.post(
        highlightSetupUrl,
        { id: user_id, name: username, from: "content",instaUsername:platform.username   },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );
      const demoSetupUrl = `https://dev.thesearchbubble.com/demosetup-onboarding`;
      // const demoSetupUrl = `http://localhost:3001/demosetup`;

      const resThumbnail = axios.post(
        demoSetupUrl,
        { id: user_id, name: username, from: "content",instaUsername:platform.username   },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );
      await axios.post(
        slackUrl,
        {
          userId: user_id,
          user: username,
          channel: channel,
          msg: `Instagram Integration
          Username: ${username},
        
         
          Instagram Handle: ${platform.username}
          User ID: ${user_id}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // }, 2000);
    } else if (platform.platform === "linkedin") {
      const url = `https://dev.thesearchbubble.com/fetch/linkedin`;

      // const url = `http://localhost:3001/fetch/linkedin`;

      const resServer = axios.post(
        url,
        {
          userId: user_id,
          user: username,
          linkedInUsername: platform.username,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      await axios.post(
        slackUrl,
        {
          userId: user_id,
          user: username,
          channel: channel,
          msg: `LinkedIn Integration
          Username: ${username},
        
         
          Email: ${platform.username}
          User ID: ${user_id}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } else if (platform.platform === "tiktok") {
      const url = `https://dev.thesearchbubble.com/integrate/tiktok`;

      axios.post(
        url,
        { id: user_id, name: username, username: username,tiktokUsername:platform.username },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      await axios.post(
        slackUrl,
        {
          userId: user_id,
          user: username,
          channel: channel,
          msg: `TikTok Integration
          Username: ${username},
        
         
          TikTok Handle: ${platform.username}
          User ID: ${user_id}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } else if (platform.platform === "youtube") {
      // const youtubeChannelId = await extractYoutubeChannelId(platform.username);
      const url = `https://dev.thesearchbubble.com/api/youtube/storeVideosByChannelId`;
      // const url = `https://dev.thesearchbubble.com/api/youtube/storeVideosByChannelId`;
      const resServer =axios.post(
        url,
        { userId: user_id, channelId: platform.username, username: username },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      try {
        const resServer = await axios.post(
          slackUrl,
          {
            userId: user_id,
            user: username,
            channel: channel,
            msg: `YouTube Integration
          Username: ${username},
        
         
          YouTube Channel ID: ${platform.username}
          User ID: ${user_id}`,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const { success, message } = resServer.data;
      } catch (error) {}
    } else if (platform.platform === "newsletter") {
      const url = `https://dev.thesearchbubble.com/api/user/store/newsletter`;
      // const url = `https://dev.thesearchbubble.com/api/user/store/newsletter`;

      const resServer =  axios.post(
        url,
        { userId: user_id, user: username, email: platform.username },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
       await axios.post(
        slackUrl,
        {
          userId: user_id,
          user: username,
          channel: channel,
          msg: `Newsletter Integration
          Username: ${username},
        
         
          Email: ${platform.username}
          User ID: ${user_id}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } else if (platform.platform === "podcast") {
      const [showName, showLink] = platform.username.split(",");
      const regex = /\/show\/(\w+)\?/;

      // Use the regex to extract the show ID
      const match = showLink?.match(regex);

      // Check if there's a match and get the show ID
      const showId = match ? match[1] : "";
      const url = `https://dev.thesearchbubble.com/fetch/podcast`;

      let res: any =  axios.post(
        url,
        { user: username, user_id: user_id, id: showId, showName: showName },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      await axios.post(
        slackUrl,
        {
          userId: user_id,
          user: username,
          channel: channel,
          msg: `Podcast Integration
          Username: ${username},
        
          Podcast Show Name: ${showName},
          Podcast Show Link:  ${showLink} ,
          Podcast Show ID:- ${showId}
          User ID: ${user_id}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } else if (platform.platform === "twitter") {
      const url = `https://dev.thesearchbubble.com/fetch/twitter`;

      // const url = `http://localhost:3001/fetch/twitter`;
    
      const response = axios.post(
        url,
        { userId: user_id, user: username, twitterUser: platform.username},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      await axios.post(
        slackUrl,
        {
          userId: user_id,
          user: username,
          channel: channel,
          msg: `Twitter Integration
          Username: ${username},
        
         
          Twitter Handle: ${platform.username}
          User ID: ${user_id}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    }
  }
}
