import React from "react";
import LinksWrapper from "./TemplateWrapper/LinksWrapper/LinksWrapper";
import ContentWrapper from "./TemplateWrapper/ContentWrapper";
import BannerWrapper from "./TemplateWrapper/BannerWrapper";
import HighlightWrapper from "./TemplateWrapper/HighlightWrapper";
import MyLinksWrapper from "./TemplateWrapper/MyLinksWrapper";
import ProductBankWrapper from "./TemplateWrapper/ProductBankWrapper";
import { QueryLinksDashboard } from "../../LandingPageCarousels/QueryBasedCarousel/QueryLinksDashboard";
import { QueryContentDashboard } from "../../LandingPageCarousels/QueryBasedCarousel/QueryContentDashboard";
import { StaticVariables } from "../../../../Utils/StaticVariables";
import TrendingProductsWrapper from "./TemplateWrapper/TrendingProductsWrapper";
import NewsletterWrapper from "./TemplateWrapper/NewsletterWrapper";
import FormWrapper from "./TemplateWrapper/FormWrapper";
import NewsletterPopupWrapper from "./TemplateWrapper/NewsletterPopupWrapper";
import { daysSinceMultipleDays } from "../../../../Utils/StayUpToDate/RenderComponent";
import YesterdayContentWrapper from "./TemplateWrapper/YesterdayContentWrapper/YesterdayContentWrapper";
import LinksComponentDashboard from "../LandingPageComponents/LinksComponent/LinksComponentDashboard";
import CustomContentDashboard from "../../LandingPageCarousels/CustomCarousel/CustomContentDashboard";
import CustomLinksDashboard from "../../LandingPageCarousels/CustomCarousel/CustomLinksDashboard";
import CustomLinksWrapper from "./TemplateWrapper/CustomLinksWrapper";

const SubComponents = (props: any) => {
  const {
    username,
    user_id,
    component,
    isEdit,
    selectedComponent,
    setIsEdit,
    isEditModalOpen,
    setIsEditModalOpen,
    refetch,
    setSelectedComponent,
    brand_color,
  } = props;

  const dayMappings: { [key: string]: number[] } =
    StaticVariables().dayMappings;
  const renderComponent = () => {
    const {
      display_name: displayName,
      name,
      type,
      post_ids: postIds,
    } = component;
    if (component.type === "links-section") {
      return (
        <LinksComponentDashboard
          key={component.id}
          user_id={user_id}
          username={username}
          numberOfDays={7}
          component={component}
          title={component.name}
          details={component.details}
          brand_color={brand_color}
          cta={component?.cta_placeholder}
        />
      );
    }
    if (dayMappings[displayName]) {
      const numberOfDays = daysSinceMultipleDays(dayMappings[displayName]);
      return (
        <LinksWrapper
          key={component.id}
          user_id={user_id}
          username={username}
          numberOfDays={numberOfDays}
          title={component.name}
          isLinks={true}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          isEditModalOpen={isEditModalOpen}
          setIsEditModalOpen={setIsEditModalOpen}
          refetch={refetch}
          component={component}
          setSelectedComponent={setSelectedComponent}
          selectedComponent={selectedComponent}
          brand_color={brand_color}
          cta={component?.cta_placeholder}
          type={component?.type}
        />
      );
    }
    switch (component.display_name) {
      case "My Links":
        return (
          <MyLinksWrapper
            key={component.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={component.name}
            details={component.details}
            brand_color={brand_color}
            cta={component?.cta_placeholder}
            type={component?.type}
          />
        );
      case "Inline Newsletter":
        return (
          <NewsletterWrapper
            key={component.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={component.name}
            brand_color={brand_color}
            cta={component?.cta_placeholder}
            flowdeskId={component?.details?.split(",")[0]}
            type={component?.type}
          />
        );
      case "Popup Newsletter":
        return (
          <NewsletterPopupWrapper
            key={component.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={component.name}
            brand_color={brand_color}
            cta={component?.cta_placeholder}
            flowdeskId={component?.details?.split(",")[0]}
            type={component?.type}
          />
        );
      case "Form":
        return (
          <FormWrapper
            key={component.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={component.name}
            brand_color={brand_color}
            cta={component?.cta_placeholder}
            filloutId={component?.details}
            type={component?.type}
          />
        );

      case "reels":
        return (
          <ContentWrapper
            key={component.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={component.name}
            platform="instagram"
            type="REELS"
            isPodcast={component.display_name === "podcast"}
            isYoutube={component.display_name === "youtube"}
            brand_color={brand_color}
            cta={component?.cta_placeholder}
          />
        );
      case "tiktok":
        return (
          <ContentWrapper
            key={component.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={component.name}
            platform="tiktok"
            type="TIKTOK"
            isPodcast={component.display_name === "podcast"}
            isYoutube={component.display_name === "youtube"}
            brand_color={brand_color}
            cta={component?.cta_placeholder}
          />
        );
      case "instagram story":
        return (
          <ContentWrapper
            key={component.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={component.name}
            platform="instagram"
            type="STORY"
            isPodcast={component.display_name === "podcast"}
            isYoutube={component.display_name === "youtube"}
            brand_color={brand_color}
            cta={component?.cta_placeholder}
          />
        );
      case "podcast":
        return (
          <ContentWrapper
            key={component.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={component.name}
            platform="podcast"
            type="PODCAST"
            isPodcast={component.display_name === "podcast"}
            isYoutube={component.display_name === "youtube"}
            brand_color={brand_color}
            cta={component?.cta_placeholder}
          />
        );
      case "youtube":
        return (
          <ContentWrapper
            key={component.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={component.name}
            platform="youtube"
            type="VIDEO"
            isPodcast={component.display_name === "podcast"}
            isYoutube={component.display_name === "youtube"}
            brand_color={brand_color}
            cta={component?.cta_placeholder}
          />
        );
      case "featured":
        return (
          <ContentWrapper
            key={component.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={component.name}
            platform="featured"
            type="FEATURE"
            isPodcast={component.display_name === "podcast"}
            isYoutube={component.display_name === "youtube"}
            brand_color={brand_color}
            cta={component?.cta_placeholder}
          />
        );
      case "This Week":
        return (
          <LinksWrapper
            key={component.id}
            user_id={user_id}
            username={username}
            numberOfDays={[7]}
            title={component.name}
            isLinks={true}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            isEditModalOpen={isEditModalOpen}
            setIsEditModalOpen={setIsEditModalOpen}
            refetch={refetch}
            component={component}
            setSelectedComponent={setSelectedComponent}
            selectedComponent={selectedComponent}
            brand_color={brand_color}
            cta={component?.cta_placeholder}
            type={component?.type}

            // handleShop={handleShop}
          />
        );
      case "This Week Content":
        return (
          <YesterdayContentWrapper
            key={component.id}
            user_id={user_id}
            username={username}
            numberOfDays={[7]}
            title={component.name}
            isLinks={true}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            isEditModalOpen={isEditModalOpen}
            setIsEditModalOpen={setIsEditModalOpen}
            refetch={refetch}
            component={component}
            setSelectedComponent={setSelectedComponent}
            selectedComponent={selectedComponent}
            brand_color={brand_color}
            cta={component?.cta_placeholder}
            type={component?.type}
            // handleShop={handleShop}
          />
        );
      case "This Month":
        return (
          <LinksWrapper
            user_id={user_id}
            username={username}
            numberOfDays={[30]}
            title={component.name}
            isLinks={true}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            isEditModalOpen={isEditModalOpen}
            setIsEditModalOpen={setIsEditModalOpen}
            refetch={refetch}
            component={component}
            setSelectedComponent={setSelectedComponent}
            selectedComponent={selectedComponent}
            brand_color={brand_color}
            cta={component?.cta_placeholder}
            type={component?.type}

            // handleShop={handleShop}
          />
        );
      case "Yesterday":
        return (
          <LinksWrapper
            key={component.id}
            user_id={user_id}
            username={username}
            numberOfDays={[1]}
            title={component.name}
            isLinks={true}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            isEditModalOpen={isEditModalOpen}
            setIsEditModalOpen={setIsEditModalOpen}
            refetch={refetch}
            component={component}
            setSelectedComponent={setSelectedComponent}
            selectedComponent={selectedComponent}
            brand_color={brand_color}
            cta={component?.cta_placeholder}
            type={component?.type}

            // handleShop={handleShop}
          />
        );
      default:
        if (component.type === "links-section") {
          return (
            <LinksComponentDashboard
              key={component.id}
              user_id={user_id}
              username={username}
              numberOfDays={7}
              component={component}
              title={component.name}
              details={component.details}
              brand_color={brand_color}
              cta={component?.cta_placeholder}
            />
          );
        } else if (component.type === "content") {
          return (
            <CustomContentDashboard
              key={component.id}
              user_id={user_id}
              username={username}
              numberOfDays={7}
              platform="instagram"
              brand_color={brand_color}
              component={component}
            />
          );
        } else if (component.type === "links") {
          return (
            <CustomLinksWrapper
              key={component.id}
              user_id={user_id}
              username={username}
              numberOfDays={8}
              isLinks={true}
              brand_color={brand_color}
              component={component}
            />
          );
        } else if (component.type === "query-content") {
          return (
            <QueryContentDashboard
              key={component.id}
              user_id={user_id}
              username={username}
              numberOfDays={7}
              platform="instagram"
              brand_color={brand_color}
              component={component}
              cta={component?.cta_placeholder}
              query={component?.query}
              removedPostIds={component?.removed_postids}
            />
          );
        } else if (component.type === "query-links") {
          return (
            <QueryLinksDashboard
              key={component.id}
              user_id={user_id}
              username={username}
              numberOfDays={8}
              isLinks={true}
              brand_color={brand_color}
              component={component}
            />
          );
        } else if (component.type === "links") {
          return (
            <CustomLinksDashboard
              key={component.id}
              user_id={user_id}
              username={username}
              numberOfDays={8}
              isLinks={true}
              brand_color={brand_color}
              component={component}
              cta={component?.cta_placeholder}
              query={component?.query}
              removedPostIds={component?.removed_postids}
              type={component?.type}
            />
          );
        } else if (component.type === "product-bank") {
          return (
            <ProductBankWrapper
              key={component.id}
              user_id={user_id}
              username={username}
              numberOfDays={8}
              title={component.name}
              isLinks={true}
              postIds={component.post_ids}
              brand_color={brand_color}
              cta={component?.cta_placeholder}
              type={component?.type}
            />
          );
        } else if (component.type === "trending products") {
          return (
            <TrendingProductsWrapper
              key={component.id}
              user_id={user_id}
              username={username}
              details={component?.details}
              title={component.name}
              isLinks={true}
              postIds={component.post_ids}
              brand_color={brand_color}
              cta={component?.cta_placeholder}
              removedPostIds={component?.removed_postids}
              type={component?.type}
            />
          );
        } else if (component.type === "banner") {
          return (
            <BannerWrapper
              key={component.id}
              user_id={user_id}
              username={username}
              title={component.name}
              component={component}
              brand_color={brand_color}
              cta={component?.cta_placeholder}
              type={component?.type}
            />
          );
        } else if (component.type === "highlight") {
          return (
            <HighlightWrapper
              key={component.id}
              user_id={user_id}
              username={username}
              numberOfDays={7}
              title={component.name}
              postIds={component.post_ids}
              data={component}
              brand_color={brand_color}
              cta={component?.cta_placeholder}
              type={component?.type}
            />
          );
        }
        return null;
    }
  };

  return <React.Fragment>{renderComponent()}</React.Fragment>;
};

export default SubComponents;
