import { Button, Space } from 'antd';
import React from 'react'

const CarouselFilterControl = (props:any) => {
    const {type,setCurrentPage,activeFilter,setActiveFilter}=props;
    const FILTER_OPTIONS = [
        { key: "both", label: "Both" },
        { key: "content", label: "Content" },
        { key: "products", label: "Products" },
      ];
    
      const buttonStyle = (isActive: any) => ({
        backgroundColor: isActive ? "black" : "white",
        color: isActive ? "white" : "black",
      });
  return (
    <>
     {type === "links" && (
          <div className="my-2">
            <Space>
              {FILTER_OPTIONS.map(({ key, label }) => (
                <Button
                  key={key}
                  onClick={() => {
                    setActiveFilter(key);
                    setCurrentPage(1)
                  }}
                  style={buttonStyle(activeFilter === key)}
                >
                  {label}
                </Button>
              ))}
            </Space>
          </div>
        )}
    
    </>
  )
}

export default CarouselFilterControl