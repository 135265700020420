import React from 'react';

const CompletionStep = (props:any) => {
  const {username} = props;
  return (
    <div style={{ textAlign: 'center', padding: '40px 20px' }}>
      <h2 style={{ marginBottom: '20px' }}>Thank You!</h2>
      <p style={{ fontSize: '16px', lineHeight: '1.5' ,color:"#000"}}>
        Hey {username},
        Your page will be ready as per your preference. You will receive an email soon with further instructions.
      </p>
    </div>
  );
};

export default CompletionStep; 