import { List, Modal } from "antd";
import React, { useState } from "react";
import { FaArrowLeft, FaBars } from "react-icons/fa";
import '../../../../../styles/StayUptoDateCSS/StayUpToDateControls.css'
import { addComponent } from "../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import { BsGrid3X3GapFill } from "react-icons/bs";


const LinksComponents = (props: any) => {
  const { username, user_id, isLinksModalOpen, StayUpToDateComponents } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [view, setView] = useState("grid");

  const handleOk = async () => {
    setConfirmLoading(true);
    const res = await addComponent(
      "My Links",
      view,
      username,
      user_id,
      "false",
      "Check it out!",
      "",
      "My Links"
    );
    if (res === "true") {
      props.refetch();
      props.setIsLinksModalOpen(false);
      props.setIsModalOpen(false);
      setConfirmLoading(false);
      setView("grid");
    }
  };

  const handleCancel = () => {
    props.setIsLinksModalOpen(false);
    props.setIsModalOpen(false);
    setView("grid");
  };
  const handlePrevious = () => {
    props.setIsModalOpen(true);
    props.setIsLinksModalOpen(false);
    setView("grid");
  };

  return (
    <>
      <Modal
        title={
          <div>
            <button onClick={handlePrevious} className="newbutton">
              <FaArrowLeft />
            </button>
          </div>
        }
        open={isLinksModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        okText="Add"
        data-testid="mylink-add"
      >
        <div>
          <h5 className="title mb-15">My Links</h5>
          <p>
            These are all of the links added under the <i>My Links</i> section
            of the dashboard will show up in this section. Basically, all of
            your tranditional link-in-bio links!{" "}
          </p>
          <h5 className="bold-label text-center">View</h5>
          <div className="link-presentation">
            <span
              className={view == "grid" ? "active" : ""}
              onClick={() => setView("grid")}
            >
              {" "}
              <BsGrid3X3GapFill className="links-view-icon" />{" "}
            </span>
            {/* <HiBars4 /> */}
            <span
              className={view == "line" ? "active" : ""}
              onClick={() => setView("line")}
            >
              {" "}
              <FaBars className="links-view-icon" />{" "}
            </span>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LinksComponents;
