import { Radio, Typography } from 'antd';
import React, { useState } from 'react'
const { Title } = Typography;

const CarouselShapeControl = (props: any) => {
  const { shape, setShape } = props;
  const handleShapeChange = (e: any) => {
    setShape(e.target.value);
  };

  return (
    <div className='mb-3'>
      <Title level={4}>Content Shape</Title>
      <Radio.Group onChange={handleShapeChange} value={shape}>
        <Radio value="rect">Rectangle</Radio>
        <Radio value="sq">Square</Radio>
      </Radio.Group>
    </div>
  );
};
export default CarouselShapeControl