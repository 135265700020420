import React, { useState } from "react";
import { Alert, Col, Input, Modal, Row, Spin } from "antd";
import { useAuth } from "../../hooks/useAuth";
import { storeLinks } from "../../Utils/SupabaseServices/UserDatabase";
import { useLoadLinks } from "../../Utils/customHooks/useLoadLinks";
import "./AccountsIntegration.css";
import "../AccountDescription/AccountDescription.css";
import useUpdateLinkTag from "./useUpdateLinkTag";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { MdOutlineDragIndicator } from "react-icons/md";
import { UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { Button, message, Upload } from "antd";
import { supabase } from "../../config/supabaseClient";
import { BsTrash } from "react-icons/bs";
import { isMobile } from "react-device-detect";

const AccountsIntegration = (props: any) => {
  const { isLinksModalOpen, username, setIsLinksModalOpen } = props;
  const { user } = useAuth();
  const user_id = user?.id || "";
  const [linkURL, setLinkURL] = useState("");
  const [imageLinkURL, setImageLinkURL] = useState("");

  const [linkName, setLinkName] = useState("");
  const [isEditLink, setIsEditLink] = useState(false);
  const [isEditImageLink, setIsEditImageLink] = useState(false);

  const [isEditLinkName, setIsEditLinkName] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [showDeleteConfirmationIndex, setShowDeleteConfirmationIndex] =
    useState(-1);
  const [errorLink, setErrorLink] = useState("");

  const { mutate: updateLinkTags } = useUpdateLinkTag();
  let {
    isLoading,

    data,

    refetch,
  } = useLoadLinks(user_id);
  const [localLinks, setLocalLinks] = useState("");
  const [isDragging, setIsDragging] = useState(false);
  const handleOk = async () => {
    const urlRegex = /^(?:(https?|ftp):\/\/)?(www\.)?[^\s/$.?#].[^\s]*$/;

    if (data) {
      const existingLinks = data.split(",");
      const isDuplicateName = existingLinks.some((link: any) => {
        const existingName = link.split(":-")[0];
        return existingName.toLowerCase() === linkName.toLowerCase();
      });

      if (isDuplicateName) {
        setErrorLink("Link name already exists");
        return;
      }
    }

    if (linkURL && linkName) {
      if (urlRegex.test(linkURL)) {
        const res = await storeLinks(
          user_id,
          username,
          linkName,
          linkURL,
          imageLinkURL
        );
        if (res) {
          refetch();
          props.setIsLinksModalOpen(false);
          setErrorLink("");
          setLinkURL("");
          setLinkName("");
          setImageLinkURL("");
        }
      } else {
        setErrorLink("Enter valid url");
      }
    } else {
      if (linkName) {
        setErrorLink("Enter valid url");
      } else if (linkURL) {
        setErrorLink("Enter valid name");
      }
    }
  };
  const removeTag = (index: any) => {
    setShowDeleteConfirmationIndex(index);
  };

  const handleDeleteConfirmation = (index: any) => {
    removeTagLogic(index);
    setShowDeleteConfirmationIndex(-1);
    setIsEditLink(false);
    setIsEditLinkName(false);
    setIsEditImageLink(false);

    setEditIndex(-1);
    setLinkURL("");
    setLinkName("");
    setImageLinkURL("");
  };

  const removeTagLogic = (index: any) => {
    const updatedData = data.split(",").filter((_: any, i: any) => i !== index);
    updateLinkTags({
      tagString: updatedData.toString(),
      user_id,
      pageIndex: 1,
      index: 1,
    });
  };

  const handleCancel = () => {
    props.setIsLinksModalOpen(false);
    setErrorLink("");
    // setLinkURL("");
    // setLinkName("");
    setImageLinkURL("");
    setErrorLink("");
  };

  function editLink(link: any) {
    setLinkURL(link.split(":-")[1]);

    setImageLinkURL(link.split(":-")[2] ? link.split(":-")[2] : "");
  }

  const editLinkName = (link: any) => {
    setLinkName(link.split(":-")[0]);

    setEditIndex(-1);
  };
  const handleEdit = (data: any, changedLink: any) => {
    const idx = data.indexOf(changedLink);

    data.splice(idx, 1);

    const newLink = `${linkName}:-${linkURL}:-${imageLinkURL}`;
    data.splice(idx, 0, newLink);
    updateLinkTags({
      tagString: data.toString(),
      user_id,
      pageIndex: 1,
      index: 1,
    });

    setIsEditLinkName(false);
    setIsEditLink(false);
    setIsEditImageLink(false);

    setEditIndex(-1);
    setImageLinkURL("");
  };

  const handleKeyPress = async (
    data: any,
    link: any,
    element: any,
    index: any
  ) => {
    if (linkName && linkURL) {
      handleEdit(data, link);
      setErrorLink("");
      setIsEditLinkName(false);
      setIsEditLink(false);
      setIsEditImageLink(false);

      setEditIndex(index);
      setShowDeleteConfirmationIndex(-1);
      setLinkName("")
      setLinkURL("")
    } else {
      setErrorLink("Enter correct values");
    }
  };

  function onDragEnd(result: any) {
    setIsDragging(true);
    const { destination, source, draggableId } = result;
    if (!destination) {
      setLocalLinks(data);
      setIsDragging(false);
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      setLocalLinks(data);
      setIsDragging(false);
      return;
    }

    const newLinks = Array.from(data.split(","));
    const movedLink = newLinks.splice(source.index, 1)[0];
    newLinks.splice(destination.index, 0, movedLink);
    updateLinkTags({
      tagString: newLinks.toString(),
      user_id,
      pageIndex: 1,
      index: 1,
    });
    data = newLinks.toString();
    setLocalLinks(newLinks.toString());
    refetch();
    setTimeout(() => {
      setIsDragging(false);
    }, 4000);
  }

  const uploadProps: UploadProps<any> = {
    name: "file",
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    headers: {
      authorization: "authorization-text",
    },
    async onChange(info: any) {
      info.file.status = "uploading";
      setImageLinkURL("/assets/image-bg.jpeg");
      setTimeout(() => {
        info.file.status = "done";
      }, 1500);

      // const { data: highlightslist }: any = await supabase.storage
      //   .from("mylinks")
      //   .list(`${username}/${linkName}`, { limit: 5000 });

      // for (let item of highlightslist) {
      //   await supabase.storage
      //     .from("mylinks")
      //     .remove([`${username}/${linkName}/${item.name}`]);
      // }

      const fileName = info.file.name;

      const sanitizedFileName = fileName
        .replace(/\s+/g, "-")
        .replace(/[^a-zA-Z0-9\-]/g, "");
      const timestamp = Date.now();

      const post_id = `${sanitizedFileName}-${timestamp}`;



      const { data, error }: any = await supabase.storage
        .from("mylinks")
        .upload(
          `${username}/${linkName}/${info.file.type.split("/")[0]}${post_id}.${info.file.type.split("/")[1]
          }`,
          info.file.originFileObj
            ? info.file.originFileObj
            : info.fileList[info.fileList.length - 1].originFileObj,
          {
            contentType: `${info.file.type}`,
          }
        );

      var { data: url }: any = await supabase.storage
        .from("mylinks")
        .getPublicUrl(
          `${username}/${linkName}/${info.file.type.split("/")[0]}${post_id}.${info.file.type.split("/")[1]
          }`
        );
      setImageLinkURL(url.publicUrl);


    },
  };

  async function removeImage(link: any) {
    const localData = data?.split(",");
    setImageLinkURL("");
    const foundElement = localData.find((element: any) =>
      element.includes(link)
    );
    if (foundElement) {
      const parts = foundElement.split(":-");
      const modifiedElement = parts.slice(0, 2).join(":-");

      const index = localData.indexOf(foundElement);
      if (index !== -1) {
        localData[index] = modifiedElement;
      }
    }

    const updatedData = localData.join(",");

    const { data: upd } = await supabase
      .from("user")
      .update({ instagram_links: updatedData })
      .eq("user_id", user_id);
  }


  const beforeUpload = (file: any) => {
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      message.error(<div style={{ color: 'black' }}>You can only upload image files!</div>);
    }
    return isImage || Upload.LIST_IGNORE;
  };
  return (
    <>
      <>
        <div className="account-social-logins-heading">
          <h5>
            <Button
              onClick={() => {
                setImageLinkURL("");
                setLinkURL("");
                setLinkName("");
                setEditIndex(-1);
                setShowDeleteConfirmationIndex(
                  -1
                );
                setIsLinksModalOpen(true);
                setIsEditLinkName(false);
                setIsEditLink(false);
                setIsEditImageLink(false);
              }}
              className="btn-primary"
              id="add-link"
            >
              Add Link
            </Button>
          </h5>
        </div>
        <div className="account-social-content-connect">
          {isLoading ? (
            <div className="spin-style">
              <Spin />
            </div>
          ) : (
            <>
              {data ? (
                <>
                  <DragDropContext
                    onDragEnd={(result: any) => {
                      onDragEnd(result);
                    }}
                  >
                    <Droppable droppableId="links">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {(isDragging ? localLinks : data)
                            .split(",")
                            .map((link: any, index: any) => {
                              const draggableId = `link-${index}`;
                              const positionNames = [
                                "First",
                                "Second",
                                "Third",
                                "Fourth",
                                "Fifth",
                                "Sixth",
                                "Seventh",
                                "Eighth",
                                "Ninth",
                                "Tenth",
                                "Eleventh",
                                "Twefth",
                                "Thirteenth",
                                "Fourteenth",
                                "Fifteenth",
                                "Sixteenth",
                                "Seventeenth",
                                "Eighteenth",
                                "Nineteenth",
                                "Twentieth",
                              ];

                              const positionText =
                                positionNames[index] || `${index + 1}th`;

                              return (
                                <Draggable
                                  key={index}
                                  draggableId={draggableId}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <React.Fragment key={index}>
                                        <div
                                          key={index}
                                          className="account-social-link-connect-row ml-2"
                                        >
                                          <div className="account-social-link-connect-row-cont">
                                            <p>
                                              <MdOutlineDragIndicator className="drag-indicator" />
                                              {positionText} Link{" "}
                                              {isEditLink &&
                                                isEditLinkName &&
                                                editIndex === index ? (
                                                <span className="title-btn mb-2">
                                                  <Button
                                                    className="btn-primary vertical-top ml-1"
                                                    onClick={() => {
                                                      handleKeyPress(
                                                        data.split(","),
                                                        link,
                                                        "all",
                                                        index
                                                      );
                                                    }}
                                                  >
                                                    Save
                                                  </Button>
                                                  <Button
                                                    className="btn-primary vertical-top ml-1"
                                                    onClick={() => {
                                                      setIsEditLinkName(false);
                                                      setIsEditLink(false);
                                                      setIsEditImageLink(false);

                                                      setEditIndex(index);
                                                      setShowDeleteConfirmationIndex(
                                                        -1
                                                      );
                                                      setLinkName("")
                                                      setLinkURL("")
                                                    }}
                                                  >
                                                    Cancel
                                                  </Button>
                                                </span>
                                              ) : (
                                                <Button
                                                  className="btn-primary vertical-top ml-1 mr-2 float-right"
                                                  id="link-edit"
                                                  onClick={() => {
                                                    setIsEditLinkName(true);
                                                    setShowDeleteConfirmationIndex(
                                                      -1
                                                    );
                                                    setEditIndex(index);
                                                    editLinkName(link);
                                                    setIsEditLink(true);
                                                    setIsEditImageLink(true);
                                                    setErrorLink("");
                                                    setEditIndex(index);
                                                    editLink(link);
                                                  }}
                                                >
                                                  Edit
                                                </Button>
                                              )}
                                            </p>
                                            {/* {!isMobile ? ( */}
                                            <Row className="mt-2" wrap={true}>
                                              <Col xs={24} lg={8}>
                                                {isEditImageLink &&
                                                  editIndex === index ? (
                                                  <div className="account-image-wrapper">
                                                    {imageLinkURL &&
                                                      imageLinkURL != "" ? (
                                                      <img
                                                        src={imageLinkURL}
                                                        className="uploaded-image"
                                                      />
                                                    ) : (
                                                      <img
                                                        src="/assets/image-bg.jpeg"
                                                        className="uploaded-image"
                                                      />
                                                    )}
                                                    <Upload
                                                      {...uploadProps}
                                                      maxCount={1}
                                                      showUploadList={
                                                        imageLinkURL &&
                                                          imageLinkURL !== ""
                                                          ? { showRemoveIcon: false }
                                                          : false
                                                      }

                                                      beforeUpload={beforeUpload}
                                                    >
                                                      <Button
                                                        className="editUploadButton"
                                                        icon={
                                                          <UploadOutlined />
                                                        }
                                                      >
                                                        Click to Upload
                                                      </Button>
                                                    </Upload>
                                                    {imageLinkURL &&
                                                      imageLinkURL != "" && (
                                                        <Button
                                                          className="editUploadButton edit-upload-btn"
                                                          icon={<BsTrash />}
                                                          onClick={() =>
                                                            removeImage(link)
                                                          }
                                                        >
                                                          Remove Image
                                                        </Button>
                                                      )}
                                                  </div>
                                                ) : (
                                                  <>
                                                    {link.split(":-")[2] &&
                                                      link.split(":-")[2] !==
                                                      "" && (
                                                        <div className="account-image-wrapper">
                                                          <img
                                                            src={
                                                              link.split(
                                                                ":-"
                                                              )[2]
                                                            }
                                                            className="w-60"
                                                          />
                                                        </div>
                                                      )}
                                                  </>
                                                )}{" "}
                                              </Col>
                                              <Col xs={24} lg={16}>
                                                {isEditLinkName &&
                                                  editIndex === index ? (
                                                  <>
                                                    <label className="label">
                                                      Link Name
                                                    </label>
                                                    <Input
                                                      value={linkName}
                                                      onChange={(e: any) => {
                                                        setErrorLink("");
                                                        setLinkName(e.target.value);
                                                      }}
                                                      placeholder="Name"
                                                      className="input-style"
                                                      id="edit-link-name"
                                                    />
                                                  </>
                                                ) : (
                                                  <div className="account-image-wrapper">
                                                    <p className="label">
                                                      {link.split(":-")[0]}
                                                    </p>
                                                  </div>
                                                )}
                                                {isEditLink &&
                                                  editIndex === index ? (
                                                  <>
                                                    <label className="label">
                                                      URL
                                                    </label>
                                                    <br />
                                                    <Input
                                                      value={linkURL}
                                                      placeholder="www.yourlink.com"
                                                      onChange={(e: any) => {

                                                        setLinkURL(
                                                          e.target.value
                                                        );

                                                      }}
                                                      className="input-style"
                                                      id="edit-link-url"
                                                    />
                                                  </>
                                                ) : (
                                                  <div className="account-image-wrapper">
                                                    <p className="link">
                                                      {link.split(":-")[1]}
                                                    </p>
                                                  </div>
                                                )}{" "}
                                              </Col>
                                            </Row>
                                            {/* ) : (
                                              <Row className="mt-2rem display-block">
                                                <div className="text-center">
                                                  {isEditImageLink &&
                                                  editIndex === index ? (
                                                    <>
                                                      {imageLinkURL &&
                                                      imageLinkURL !== "" ? (
                                                        <img
                                                          src={imageLinkURL}
                                                          className="w-60 mb-2"
                                                        />
                                                      ) : (
                                                        <img
                                                          src="/assets/image-bg.jpeg"
                                                          className="edit-image-link"
                                                        />
                                                      )}
                                                      <div className="image-link-div mt-2">
                                                        {isEditImageLink &&
                                                        editIndex === index ? (
                                                          <>
                                                            <div className="mb-2 mt-2">
                                                              <Upload
                                                                {...uploadProps}
                                                                maxCount={1}
                                                                showUploadList={
                                                                  imageLinkURL &&
                                                                  imageLinkURL !==
                                                                    ""
                                                                    ? true
                                                                    : false
                                                                }
                                                              >
                                                                <Button
                                                                  className="editUploadButton font-6"
                                                                  id="edit-link-image"
                                                                  icon={
                                                                    <UploadOutlined />
                                                                  }
                                                                >
                                                                  Click to
                                                                  Upload
                                                                </Button>
                                                              </Upload>
                                                              {imageLinkURL &&
                                                                imageLinkURL !=
                                                                  "" && (
                                                                  <Button
                                                                    className="editUploadButton trash-icon"
                                                                    icon={
                                                                      <BsTrash />
                                                                    }
                                                                    onClick={() =>
                                                                      removeImage(
                                                                        link
                                                                      )
                                                                    }
                                                                  >
                                                                    Remove Image
                                                                  </Button>
                                                                )}
                                                            </div>
                                                          </>
                                                        ) : null}
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      {link.split(":-")[2] &&
                                                        link.split(":-")[2] !==
                                                          "" && (
                                                          <img
                                                            src={
                                                              link.split(
                                                                ":-"
                                                              )[2]
                                                            }
                                                            className="w-60"
                                                          />
                                                        )}
                                                    </>
                                                  )}
                                                </div>
                                                {isEditLinkName &&
                                                editIndex === index ? (
                                                  <>
                                                    <label className="label">
                                                      Display as:
                                                    </label>
                                                    <Input
                                                      value={linkName}
                                                      onChange={(e: any) => {
                                                        setLinkName(
                                                          e.target.value
                                                        );
                                                      }}
                                                      placeholder="Ex. Link Name"
                                                      className="input-style"
                                                    />
                                                  </>
                                                ) : (
                                                  <>
                                                    <p className="bold-link text-center mt-2">
                                                      {link.split(":-")[0]}
                                                    </p>
                                                  </>
                                                )}

                                                {isEditLink &&
                                                editIndex === index ? (
                                                  <>
                                                    <label className="label">
                                                      Url
                                                    </label>
                                                    <br />
                                                    <Input
                                                      value={linkURL}
                                                      placeholder="https://links.com"
                                                      onChange={(e: any) => {
                                                     
                                                        setLinkURL(
                                                          e.target.value
                                                        );
                                                      
                                                      }}
                                                      className="input-style"
                                                    />
                                                  </>
                                                ) : (
                                                  <>
                                                    <p className="link text-center mt-2">
                                                      {link.split(":-")[1]}
                                                    </p>
                                                  </>
                                                )}
                                              </Row>
                                             )} */}

                                            {isEditLink &&
                                              isEditLinkName &&
                                              editIndex === index &&
                                              errorLink ? (
                                              <p className="error-link">
                                                {errorLink}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                            <div className="account-integration">
                                              {isEditLink &&
                                                isEditLinkName &&
                                                editIndex === index && (
                                                  <Button
                                                    className="remove-btn ml-2 mb-2"
                                                    id="remove-link"
                                                    style={{
                                                      marginTop: `${!isMobile ? "-1%" : "2%"
                                                        }`,
                                                    }}
                                                    onClick={() =>
                                                      removeTag(index)
                                                    }
                                                  >
                                                    Remove
                                                  </Button>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                        {showDeleteConfirmationIndex ===
                                          index && (
                                            <div className="confirmation-dialog text-center">
                                              <p>
                                                Are you sure you want to delete
                                                this link?
                                              </p>
                                              <Button
                                                className="confirm-btn cancel"
                                                onClick={() => {
                                                  // setLinkName("")
                                                  // setLinkURL("")
                                                  setShowDeleteConfirmationIndex(
                                                    -1
                                                  )

                                                }
                                                }
                                              >
                                                Cancel
                                              </Button>
                                              <Button
                                                className="confirm-btn confirm confirm-color ml-1"
                                                onClick={() =>
                                                  handleDeleteConfirmation(index)
                                                }
                                              >
                                                Confirm
                                              </Button>
                                            </div>
                                          )}
                                        <hr className="height-style" />
                                      </React.Fragment>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </>
              ) : (
                <p className="no-links-msg">No Links</p>
              )}
            </>
          )}
        </div>
      </>

      {isLinksModalOpen ? (
        <Modal
          title="Add Link  fgdhjfs"
          open={isLinksModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          data-testid="link-add-btn"
          okText="Add"
          className="add-link-modal"
          okButtonProps={{ disabled: !linkName || !linkURL }}
        >
          {errorLink && (
            <Alert
              type="error"
              message={`Error: ${errorLink}`}
              showIcon
              className="alert-margin"
            />
          )}
          <label className="modal-label">
            Link Name
            <Input
              placeholder="Name"
              required
              onChange={(e) => {
                setErrorLink("");
                setLinkName(e.target.value);
              }}
              id="link-name"
            />
          </label>
          <br />
          <br />
          <label className="modal-label">
            URL
            <Input
              placeholder="www.yourlink.com"
              required
              onChange={(e) => {
                setLinkURL(e.target.value);
              }}
              className="w-100"
              id="link-url"
            />
          </label>
          <br />

          {imageLinkURL && imageLinkURL !== "" && (
            <div className="image-link">
              <img src={imageLinkURL} />
            </div>
          )}
          <Upload {...uploadProps} beforeUpload={beforeUpload} maxCount={1} className="upload-btn">
            <Button
              icon={<UploadOutlined />}
              className="mt-10"
              id="link-image"
              disabled={!linkName}

            >
              Click to Upload
            </Button>
          </Upload>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

export default AccountsIntegration;
