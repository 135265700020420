import { Button, List, Modal } from "antd";
import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import '../../../../../../styles/StayUptoDateCSS/StayUpToDateControls.css';
import { addComponent } from "../../../../../../Utils/SupabaseServices/StayUpToDateDatabase";

const InstagramStoryContentComponent = (props: any) => {
  const { username, user_id, isInstagramStoryContentComponentOpen } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = async () => {
    setConfirmLoading(true);
    const res = await addComponent(
      "Latest Instagram Stories",
      "",
      username,
      user_id,
      "false",
      "Check it out!",
      "",
      "instagram story"
    );
    if (res === "true") {
      props.refetch();
      props.setIsContentModalOpen(false);

      props.setIsInstagramStoryContentComponentOpen(false);
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    props.setIsContentModalOpen(false);
    props.setIsInstagramStoryContentComponentOpen(false);
  };
  const handlePrevious = () => {
    props.setIsInstagramStoryContentComponentOpen(false);
    props.setIsContentModalOpen(true);
  };

  return (
    <>
      <Modal
        title={
          <div className="modal-title">
            <button onClick={handlePrevious} className="newbutton">
              <FaArrowLeft />
            </button>
          </div>
        }
        open={isInstagramStoryContentComponentOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        okText="Add"
        data-testid="instagram-stories"
      >
        <h5 className="title mb-20">
          Your latest Instagram Stories will be shown.{" "}
        </h5>
      </Modal>
    </>
  );
};

export default InstagramStoryContentComponent;
