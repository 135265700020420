import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomPagination from "../../CustomPagination";
import "./CustomLinkData.css";
import Card from "react-bootstrap/esm/Card";
import { Button } from "antd";
import DataSkeleton from "../DataSkeleton";
import { updateRefClick } from "../../../../Utils/SupabaseServices/ContentDatabase";
import track, {
  TRACKING_TYPES,
} from "../../../../Utils/SupabaseServices/Track";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { debounce } from "lodash";
import { BsLink45Deg } from "react-icons/bs";
import { isNavigable } from "../../../../Utils/StayUpToDate/RenderComponent";
import { isMobile } from "react-device-detect";
import { useDomLoaded } from "../../../../hooks/DomLoadedContext";
import ProductName from "../../../ProductName/ProductName";

const ProductBankData = (props: any) => {
  const location = useLocation();
  const param = useParams();
  const itemsPerPage = 3;

  const {
    user_id,
    username,
    postIds,
    title,
    isLinks,
    handleShop,
    brand_color,
    isHandleNavigate,
    isLoading,
    data,
    component,type
  } = props;
  const [isLocalLoading, setLocalLoading] = useState(true);
  const navigate = useNavigate();
  const domLoaded = useDomLoaded();
  useEffect(() => {
    setTimeout(() => {
      setLocalLoading(isLoading);
    }, 1000);
  }, [isLoading]);
  // const { isLoading: CustomProductLoading, data: CustomProduct }: any =
  //   useLoadCustomProduct(user_id, username, title);
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef<any>(null);
  const isButtonClicked = useRef(false);
  function handleRedirect(
    postId: string,
    media_url: string,
    title: any,
    name: any
  ) {
    track("Post Click", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: {
        post_id: postId,
        media_url: media_url,
        title: title,
        name: name,
        ...(param?.id ? { id: param?.id } : {}), // Conditionally add `id` if it exists
      },
      user_id: user_id,
      title: "Stay Up To Date",
    });
    updateRefClick(postId, user_id);
  }

  const handleScroll = useCallback(
    debounce(() => {
      if (!isButtonClicked.current) {
        if (containerRef.current) {
          const scrollLeft = containerRef.current.scrollLeft;
          const itemWidth = containerRef.current.scrollWidth / data.length;
          const newCurrentIndex = Math.round(scrollLeft / itemWidth);
          setCurrentIndex(newCurrentIndex);
        }
      }
    }, 100),
    [data]
  );
  useEffect(() => {
    if (containerRef.current) {
      const itemWidth = containerRef.current.scrollWidth / data.length;
      const scrollPosition = itemWidth * currentIndex;
      containerRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [currentIndex, data]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
      return () => {
        if (containerRef.current) {
          containerRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [data, handleScroll]);

  const handlePrevPage = () => {
    isButtonClicked.current = true;
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };

  const handleNextPage = () => {
    isButtonClicked.current = true;
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, data.length - itemsPerPage)
    );
    setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };

  const handleNavigate = (component: any) => {
    if (isNavigable(component)) {
      navigate(`/${username}/${component.unique_id}`, {
        state: { data: component },
      });
    }
  };

  return (
    <div>
      {isLoading || isLocalLoading || !domLoaded ? (
        <DataSkeleton />
      ) : (
        <>
          {data && data.length ? (
            <div>
              <div className="mainDiv">
                <p
                  className="component-name"
                  style={param?.id ? { cursor: "auto" } : {}}
                  onClick={(e) => handleNavigate(component)}
                >
                  {title}
                </p>
                {!param?.id ? (
                  <CustomPagination
                    totalItems={data?.length}
                    currentIndex={currentIndex}
                    handlePrevPage={handlePrevPage}
                    handleNextPage={handleNextPage}
                  />
                ) : null}
              </div>
              <div
                className={!param?.id ? "outer-container-reels" : ""}
                ref={containerRef}
              >
                <div
                  className={
                    !param?.id
                      ? "links-grid-container-reels"
                      : isMobile
                      ? "item-grid creator-card-grid-wrp"
                      : "item-grid"
                  }
                >
                  {data &&
                    data.map((link: any, index: number) => (
                      <div key={index} className="link-url">
                        <>
                          <a
                            href={link?.table_url}
                            target="_blank"
                            onClick={(e) => {
                              e.preventDefault();
                              handleRedirect(
                                link?.table_id,
                                link?.table_image,
                                title,
                                link?.shop_title || link?.table_name
                              );
                              if (link?.table_url && link?.table_url !== "") {
                                window.open(link?.table_url, "_blank");
                              }
                            }}
                          >
                            <div
                              className={"creator-feed-image-wrapper-links"}
                              style={{
                                marginBottom: "2%",
                              }}
                            >
                              <Card.Img
                                variant="top"
                                src={link?.table_image}
                                className={"creator-feed-card-links-image"}
                                loading="eager"
                                style={{
                                  backgroundColor: brand_color && brand_color,
                                }}
                              />
                            </div>
                           

                            <ProductName type={type} post={link}/>
                            {isLinks && (
                              <Button className={"template-shop-btn-links"}>
                                Shop
                              </Button>
                            )}
                          </a>
                        </>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default ProductBankData;
