import React, { useEffect, useState } from "react";
import LineTemplate from "./Components/LineTemplate";
import GridTemplate from "./Components/GridTemplate";
import "./LinksComponentDashboard.css";
import { FiEdit2, FiX } from "react-icons/fi";
import { useLoadUserLinks } from "./Utils/useLoadUserLinks";
import { deleteUserLinks } from "../../../../../Utils/SupabaseServices/UserLinksDatabase";
import MyLinksSkeleton from "../../../../../components/StayUpToDateComponent/MyLinksSkeleton";

const LinksComponentFrontend = (props: any) => {
  const { component, username, user_id, brand_color } = props;
  const { name, details } = component;
  const { data, isLoading, error } = useLoadUserLinks(user_id, name);
  const [templates, setTemplates] = useState<any>([
    {
      id: 0,
      link_name: "",
      link_url: "",
      image_url: null,
      index: 0,
    },
  ]);

  // Start with one empty template

  const handleRemoveTemplate = (id: number) => {
    const updatedTemplates = templates.filter(
      (template: any) => template.id !== id
    );
    if (updatedTemplates.length === 0) {
      setTemplates([1]);
    } else {
      setTemplates(updatedTemplates);
    }

    deleteUserLinks(user_id, name, id);
  };

  return isLoading ? ( // Remove curly brace, fix syntax
    <div className="creator-card-grid-wrp">
      <MyLinksSkeleton /> // Replace MyLinksSkeleton with simple loading state
    </div>
  ) : (
    <>
      {data && data.length ? (
        <>
          <div className="mainDiv">
            <p className="component-name">{name}</p>{" "}
            {/* Change title to name */}
          </div>
          {/* links-container-frontend */}
          <div
            className={`creator-card-grid-wrp ${
              details === "grid" ? "grid-layout" : "line-layout"
            }`}
            style={
              details?.includes("line")
                ? { gridTemplateColumns: "repeat(1, 1fr)", gridGap: "12px" }
                : {}
            }
          >
            {data &&
              data.length &&
              data.map((card: any, index: any) =>
                details === "grid" ? (
                  <GridTemplate
                    key={index}
                    card={card}
                    onRemove={handleRemoveTemplate}
                    username={username}
                    user_id={user_id}
                    componentName={name}
                    isDashboard={false}
                    brand_color={brand_color}
                  />
                ) : (
                  <LineTemplate
                    key={index}
                    card={card}
                    onRemove={handleRemoveTemplate}
                    username={username}
                    user_id={user_id}
                    componentName={name}
                    isDashboard={false}
                    brand_color={brand_color}
                  />
                )
              )}
            {/* {details === "grid" ? (
        <button
          className="add-button-grid"
          onClick={handleAddTemplate}
          aria-label="Add new template"
        >
          +
        </button>
      ) : ( */}

            {/* )} */}
          </div>
        </>
      ) : null}
    </>
  );
};

export default LinksComponentFrontend;
