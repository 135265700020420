import { Button, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { supabase } from "../../../config/supabaseClient";
import TextArea from "antd/es/input/TextArea";
import "../Feed/feedTab.css";
import { UpdateCombinedText } from "../../../Utils/SupabaseServices/ContentDatabase";

const ManageCaptionModal = (props: any) => {
  const { selectedPost, user_id, username, captionModal } = props;
  const [isEdit, setIsEdit] = useState(false);
  const [newCaption, setNewCaption] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isCaptionChanged, setIsCaptionChanged] = useState(false);



  useEffect(() => {
    const fetchLinks = async () => {
      if (selectedPost && selectedPost.post_id) {
        try {
          const { data, error } = await supabase
            .from(`${username}`)
            .select("*")
            .eq("post_id", selectedPost.post_id);

          if (error) {
          } else if (data && data.length > 0) {
            // Assuming data is an array and we're interested in the first item
            const fetchedData = data[0];
            setNewCaption(fetchedData.caption);
          }
        } catch (err) {}
      }
    };

    fetchLinks(); // Call fetchLinks inside useEffect
  }, [selectedPost, captionModal]);
  async function handleCaptionOk() {
    setConfirmLoading(true);
    const updatedPost = { ...selectedPost, caption: newCaption };

    const { data } = await supabase
      .from("content_new")
      .select("*")
      .eq("post_id", selectedPost.post_id)
      .eq("user_id", user_id);
    let combined = "";
    if (data && data.length) {
      combined =
        (newCaption ? newCaption : "") +
  
        " " +
        (data[0].ocr ? data[0].ocr : "") +
        " " +
        (data[0].content_category ? data[0].content_category : "") +
        " " +
        (data[0].tagg ? data[0].tagg : "") +
        " " +
        (data[0].tagged_link
          ? "instagram.com/" + "" + data[0].tagged_link
          : "");
    }
    await Promise.all([
      supabase
        .from("content_new")
        .update({ caption: newCaption})
        .eq("post_id", selectedPost.post_id)
        .eq("user_id", user_id),

      supabase
        .from(username)
        .update({
          caption: newCaption,
        
        })
        .eq("post_id", selectedPost.post_id)
        .eq("user_id", user_id),
    ]);
    UpdateCombinedText(
      username,
      user_id,
      selectedPost.post_id
    )
    props.setSelectedPost(updatedPost);
    props.setCaptionModal(false);
    props.refetchData();

    setConfirmLoading(false);
    setIsCaptionChanged(false);
    setNewCaption("");
    setIsEdit(false);
  }

  function handleCaptionCancel() {
    props.setCaptionModal(false);
    setNewCaption(selectedPost?.caption);
    setIsEdit(false);
    setIsCaptionChanged(false);
  }

  return (
    <>
      <Modal
        open={captionModal}
        onOk={handleCaptionOk}
        onCancel={handleCaptionCancel}
        confirmLoading={confirmLoading}
        footer={[
          // <Button key="back" onClick={handleCaptionCancel}>
          //   Cancel
          // </Button>,
          <Button
            key="submit"
            type="primary"
            disabled={!isCaptionChanged} // Disable if no changes
            onClick={handleCaptionOk}
            id="save-caption"
          >
            Save
          </Button>,
        ]}
      >
        {selectedPost && (
          <>
            <h5 className="text-center mb-4">Manage Caption</h5>
            <label className="bold-label">Caption:</label>
           

            <div className="mt-2">
              <div className="display-flex">
               
                <TextArea
                  value={newCaption}
                  onChange={(e: any) => {
                    setNewCaption(e.target.value);
                    setIsCaptionChanged(true);
                  }}
                  id="enter-caption"
                  placeholder="enter caption here"
                  autoSize={{
                    minRows: 3,
                    maxRows: 5,
                  }}
                  // className="text-area"
                />
              </div>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default ManageCaptionModal;
