import React from "react";
import Card from "react-bootstrap/Card";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const FeedPosts = () => {
  function SkeletonStatBox() {
    return (
      <div className="media">
        <Card>
          <span className="skeleton-feed-wrapper">
            <Skeleton
              className="card-skeleton"
            />
          </span>
        </Card>
      </div>
    );
  }
  return (
    <div className="skeleton-content">
      <div className="skeleton-feed">{Array<null>(6)
        .fill(null)
        .map((_, index) => (
          <SkeletonStatBox key={index} />
        ))}</div>
    </div>
  );
};

export default FeedPosts;
