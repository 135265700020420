import UpToDateLinks from "../UpToDateComponents/UpToDateLinks/UpToDateLinks";
import YesterdayLinks from "../UpToDateComponents/YesterdayLinks/YesterdayLinks";
import TiktokData from "../UpToDateComponents/TikTokData/TiktokData";
import ReelsData from "../UpToDateComponents/ReelsData/ReelsData";
import InstagramStoryData from "../UpToDateComponents/InstagramStoryData/InstagramStoryData";
import PodcastData from "../UpToDateComponents/PodcastData/PodcastData";
import YoutubeData from "../UpToDateComponents/YoutubeData/YoutubeData";
import CustomContentData from "../UpToDateComponents/CustomContentData/CustomContentData";
import HighlightDataSecton from "../UpToDateComponents/HighlightDataSection/HighlightDataSecton";
import BannerData from "../UpToDateComponents/BannerData/BannerData";
import ProductBankData from "../UpToDateComponents/ProductBankData/ProductBankData";
import QueryLinkData from "../UpToDateComponents/QueryLinkData/QueryLinkData";
import QueryContentData from "../UpToDateComponents/QueryContentData/QueryContentData";
import TrendingProductsData from "../UpToDateComponents/TrendingProductsData/TrendingProductsData";
import NewsletterData from "../UpToDateComponents/NewsletterData/NewsletterData";
import FormData from "../UpToDateComponents/FormData/FormData";
import NewsletterPopupData from "../UpToDateComponents/NewsletterData/NewsletterPopupData";
import { StaticVariables } from "../../../Utils/StaticVariables";
import { useLoadRenderComponent } from "./useLoadRenderComponent";
import {
  daysSinceMultipleDays,
  updateCardImage,
  updateIsEmptyStatus,
} from "../../../Utils/StayUpToDate/RenderComponent";
import { useLoadCustomProduct } from "../../../Utils/customHooks/UpToDateContent/useLoadCustomProduct";
import { useLoadYesterdayLinks } from "../../../Utils/customHooks/UpToDateContent/useLoadYesterdayLinks";
import { useLoadTrendingProducts } from "../../../Utils/customHooks/UpToDateContent/useLoadTrendingProducts";
import { useLoadQueryFeed } from "../../../Screens/DashboardView/LandingPageCarousels/QueryBasedCarousel/useLoadQueryFeed";
import { useLoadCustomContent } from "../../../Utils/customHooks/UpToDateContent/useLoadCustomContent";
import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import CustomLinkData from "../UpToDateComponents/CustomLinkData/CustomLinkData";
import { useLoadYesterdayContent } from "../../../Utils/customHooks/UpToDateContent/useLoadYesterdayContent";
import YesterdayContent from "../UpToDateComponents/YesterdayContent/YesterdayContent";
import FeaturedData from "../UpToDateComponents/FeaturedData/FeaturedData";
import LinksComponentFrontend from "../../../Screens/DashboardView/StayUpToDate/LandingPageComponents/LinksComponent/LinksComponentFrontend";

const dayMappings: { [key: string]: number[] } = StaticVariables().dayMappings;

export const RenderComponent = (props: any) => {
  const {
    componentId,
    user_id,
    username,
    brand_color,
    handleShop,
    handleRedirect,
    isHandleNavigate,
    components,
    // handleEmptyCarousel,
  } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [componentId]);
  const { isLoading, data } = useLoadRenderComponent(user_id, componentId);
  const {
    display_name: displayName = "",
    name = "",
    type = "",
    post_ids: postIds = [],
  } = data || {};
  const { isLoading: CustomProductLoading, data: CustomProduct }: any =
    useLoadCustomProduct(user_id, username, name);

  const numberOfDays =
    displayName === "Yesterday"
      ? [1]
      : displayName === "This Week" || displayName === "This Week Content"
      ? [7]
      : displayName === "This Month"
      ? [30]
      : dayMappings[displayName]
      ? daysSinceMultipleDays(dayMappings[displayName])
      : null;

  const { isLoading: yesterdayLinksLoading, data: yesterdayLinksData } =
    useLoadYesterdayLinks(
      username,
      user_id,
      data?.display_name === "Yesterday"
        ? [1]
        : data?.display_name === "This Week"
        ? [7]
        : data?.display_name === "This Month"
        ? [30]
        : dayMappings[data?.display_name]
        ? daysSinceMultipleDays(dayMappings[data?.display_name])
        : null,
      data?.post_ids,
      data?.removed_postids,
      data?.name,
      false
    );

  const { isLoading: yesterdayContentLoading, data: yesterdayContentData } =
    useLoadYesterdayContent(
      username,
      user_id,

      data?.display_name === "This Week Content" ? [7] : null,
      data?.post_ids,
      data?.removed_postids,
      data?.name
    );

  const { isLoading: TrendingProductsLoading, data: TrendingProducts }: any =
    useLoadTrendingProducts(
      user_id,
      username,
      name,
      data?.details?.split(",")?.[0],
      data?.removed_postids,
      data?.details?.split(",")?.[1],
      data?.details?.split(",")?.[2],
      data?.details?.split(",")?.[3]
    );
  const isShopLinks = true;
  const showSocials =data?.type === "query-content" ? StaticVariables().CONTENT_PLATFORMS : StaticVariables().PLATFORMS;
  const contentOrder = "Default";

  const {
    isLoading: queryFeedLoading,
    data: queryFeed,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  }: any = useLoadQueryFeed(
    username,
    user_id,
    data?.query,

    showSocials,
    contentOrder,
    data?.type === "query-links" ? true : false,
    data?.removed_postids,
    data?.name,data?.content_order
  );
  const { isLoading: CustomLinkDataLoading, data: CustomLinkDataArray }: any =
    useLoadCustomContent(
      username,
      user_id,
      postIds && postIds!="" ? postIds?.split(",") : [],
      name
    );

  const param = useParams();
  const location = useLocation();

  function handleEmptyCarousel(name: any, id: any) {
    updateIsEmptyStatus(name, user_id, id);
  }
  function handleFirstImage(name: any, id: any, firstElement: any) {
    const imageToSend =
      firstElement?.table_image ||
      (firstElement?.media_url?.includes("video")
        ? firstElement?.thumbnail
        : firstElement?.media_url);

    if (imageToSend) {
      updateCardImage(name, user_id, id, imageToSend);
    }
  }

  const renderContent = () => {
    if (dayMappings[displayName]) {
      if (!yesterdayLinksLoading) {
        if (!yesterdayLinksData || yesterdayLinksData.length === 0) {
          handleEmptyCarousel(name, data.id);
        } else {
          handleFirstImage(name, data?.id, yesterdayLinksData[0]);
        }
      }
      return (
        <YesterdayLinks
          key={data.id}
          user_id={user_id}
          username={username}
          numberOfDays={numberOfDays}
          title={name}
          isLinks={true}
          handleShop={handleShop}
          postIds={data?.post_ids}
          brand_color={brand_color}
          removedPostIds={data?.removed_postids}
          isHandleNavigate={isHandleNavigate}
          isLoading={yesterdayLinksLoading}
          data={yesterdayLinksData}
          component={data}
          type={data?.type}
        />
      );
    }
    switch (displayName) {
      case "Yesterday":
        if (!yesterdayLinksLoading) {
          if (!yesterdayLinksData || yesterdayLinksData.length === 0) {
            handleEmptyCarousel(name, data.id);
          } else {
            // handleTrueCarousel
            handleFirstImage(name, data?.id, yesterdayLinksData[0]);
          }
        }
        return (
          <YesterdayLinks
            key={data.id}
            user_id={user_id}
            username={username}
            numberOfDays={numberOfDays}
            title={name}
            isLinks={true}
            handleShop={handleShop}
            postIds={data?.post_ids}
            brand_color={brand_color}
            removedPostIds={data?.removed_postids}
            isHandleNavigate={isHandleNavigate}
            isLoading={yesterdayLinksLoading}
            data={yesterdayLinksData}
            component={data}
            type={data?.type}
          />
        );
      case "This Week":
        if (!yesterdayLinksLoading) {
          if (!yesterdayLinksData || yesterdayLinksData.length === 0) {
            handleEmptyCarousel(name, data.id);
          } else {
            handleFirstImage(name, data?.id, yesterdayLinksData[0]);
          }
        }
        return (
          <YesterdayLinks
            key={data.id}
            user_id={user_id}
            username={username}
            numberOfDays={numberOfDays}
            title={name}
            isLinks={true}
            handleShop={handleShop}
            postIds={data?.post_ids}
            brand_color={brand_color}
            removedPostIds={data?.removed_postids}
            isHandleNavigate={isHandleNavigate}
            isLoading={yesterdayLinksLoading}
            data={yesterdayLinksData}
            component={data}
            type={data?.type}
          />
        );
      case "This Week Content":
        if (!yesterdayContentLoading) {
          if (!yesterdayContentData || yesterdayContentData.length === 0) {
            handleEmptyCarousel(name, data.id);
          } else {
            handleFirstImage(name, data?.id, yesterdayContentData[0]);
          }
        }
        return (
          <YesterdayContent
            key={data.id}
            user_id={user_id}
            username={username}
            numberOfDays={numberOfDays}
            title={name}
            isLinks={false}
            handleShop={handleShop}
            postIds={data?.post_ids}
            brand_color={brand_color}
            removedPostIds={data?.removed_postids}
            isHandleNavigate={isHandleNavigate}
            isLoading={yesterdayContentLoading}
            data={yesterdayContentData}
            component={data}
            type={data?.type}
          />
        );
      case "This Month":
        if (!yesterdayLinksLoading) {
          if (!yesterdayLinksData || yesterdayLinksData.length === 0) {
            handleEmptyCarousel(name, data.id);
          } else {
            handleFirstImage(name, data?.id, yesterdayLinksData[0]);
          }
        }
        return (
          <YesterdayLinks
            key={data.id}
            user_id={user_id}
            username={username}
            numberOfDays={numberOfDays}
            title={name}
            isLinks={true}
            handleShop={handleShop}
            postIds={data?.post_ids}
            brand_color={brand_color}
            removedPostIds={data?.removed_postids}
            isHandleNavigate={isHandleNavigate}
            isLoading={yesterdayLinksLoading}
            data={yesterdayLinksData}
            component={data}
            type={data?.type}
          />
        );

      case "My Links":
        return (
          <UpToDateLinks
            key={data.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={name}
            details={data?.details}
            handleShop={handleShop}
            brand_color={brand_color}
            isHandleNavigate={isHandleNavigate}
            component={data}
          />
        );

      
      case "Inline Newsletter":
        return (
          <NewsletterData
            key={data.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={name}
            postIds={postIds}
            isLinks={true}
            component={data}
            handleRedirect={handleRedirect}
            brand_color={brand_color}
            flowdeskId={data.details.split(",")[0]}
            isHandleNavigate={isHandleNavigate}
          />
        );
      case "Popup Newsletter":
        return (
          <NewsletterPopupData
            key={data.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={name}
            postIds={postIds}
            isLinks={true}
            component={data}
            handleRedirect={handleRedirect}
            brand_color={brand_color}
            flowdeskId={data.details.split(",")[0]}
            isHandleNavigate={isHandleNavigate}
          />
        );

      case "Form":
        return (
          <FormData
            key={data.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={name}
            postIds={postIds}
            isLinks={true}
            component={data}
            handleRedirect={handleRedirect}
            brand_color={brand_color}
            filloutId={data.details}
            isHandleNavigate={isHandleNavigate}
          />
        );

      case "reels":
        return (
          <ReelsData
            key={data.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={name}
            handleRedirect={handleRedirect}
            brand_color={brand_color}
            isHandleNavigate={isHandleNavigate}
            component={data}
          />
        );
      case "tiktok":
        return (
          <TiktokData
            key={data.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={name}
            handleRedirect={handleRedirect}
            brand_color={brand_color}
            isHandleNavigate={isHandleNavigate}
            component={data}
          />
        );
      case "instagram story":
        return (
          <InstagramStoryData
            key={data.id}
            user_id={user_id}
            username={username}
            numberOfDays={15}
            title={name}
            handleShop={handleShop}
            brand_color={brand_color}
            isHandleNavigate={isHandleNavigate}
            component={data}
          />
        );
      case "podcast":
        return (
          <PodcastData
            key={data.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={name}
            handleRedirect={handleRedirect}
            brand_color={brand_color}
            isHandleNavigate={isHandleNavigate}
            component={data}
          />
        );
      case "youtube":
        return (
          <YoutubeData
            key={data.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={name}
            handleRedirect={handleRedirect}
            brand_color={brand_color}
            isHandleNavigate={isHandleNavigate}
            component={data}
          />
        );
      case "featured":
        return (
          <FeaturedData
            key={data.id}
            user_id={user_id}
            username={username}
            numberOfDays={7}
            title={name}
            handleRedirect={handleRedirect}
            brand_color={brand_color}
            isHandleNavigate={isHandleNavigate}
            component={data}
            handleShop={handleShop}
            isLinks={true}
          />
        );
      case "videos":
      default:
        if (type === "content") {
          if (!CustomLinkDataLoading) {
            if (!CustomLinkDataArray || CustomLinkDataArray.length === 0) {
              handleEmptyCarousel(name, data.id);
            } else {
              handleFirstImage(name, data?.id, CustomLinkDataArray[0]);
            }
          }
          return (
            <CustomContentData
              key={data.id}
              user_id={user_id}
              username={username}
              numberOfDays={7}
              title={name}
              postIds={postIds}
              handleRedirect={handleRedirect}
              brand_color={brand_color}
              isHandleNavigate={isHandleNavigate}
              isLoading={CustomLinkDataLoading}
              data={CustomLinkDataArray}
              component={data}
              type={data?.type}
            />
          );
        }
        else if(type === "links-section"){
          return(
            <LinksComponentFrontend
              key={data.id}
              user_id={user_id}
              username={username}
              numberOfDays={7}
              title={name}
              component={data}
              brand_color={brand_color}

            />
          )
        }
        
        
        
        else if (type === "links") {
          if (!CustomLinkDataLoading) {
            if (!CustomLinkDataArray || CustomLinkDataArray.length === 0) {
              handleEmptyCarousel(name, data.id);
            } else {
              handleFirstImage(name, data?.id, CustomLinkDataArray[0]);
            }
          }
          return (
            <CustomLinkData
              key={data.id}
              user_id={user_id}
              username={username}
              numberOfDays={7}
              title={name}
              postIds={postIds}
              isLinks={true}
              handleShop={handleShop}
              brand_color={brand_color}
              isHandleNavigate={isHandleNavigate}
              isLoading={CustomLinkDataLoading}
              data={CustomLinkDataArray}
              component={data}
              type={data?.type}
            />
          );
        } else if (type === "query-content") {
          if (!queryFeedLoading) {
            if (!queryFeed || queryFeed.length === 0) {
              handleEmptyCarousel(name, data.id);
            } else {
              handleFirstImage(name, data?.id, queryFeed[0]);
            }
          }
          return (
            <QueryContentData
              key={data.id}
              user_id={user_id}
              username={username}
              numberOfDays={7}
              title={name}
              postIds={postIds}
              handleRedirect={handleRedirect}
              brand_color={brand_color}
              query={data?.query}
              removedPostIds={data?.removed_postids}
              isHandleNavigate={isHandleNavigate}
              isLoading={queryFeedLoading}
              data={queryFeed}
              component={data}
              type={data?.type}
            />
          );
        } else if (type === "query-links") {
          if (!queryFeedLoading) {
            if (!queryFeed || queryFeed.length === 0) {
              handleEmptyCarousel(name, data.id);
            } else {
              handleFirstImage(name, data?.id, queryFeed[0]);
            }
          }
          return (
            <QueryLinkData
              key={data.id}
              user_id={user_id}
              username={username}
              numberOfDays={7}
              title={name}
              postIds={postIds}
              isLinks={true}
              handleShop={handleShop}
              brand_color={brand_color}
              query={data?.query}
              removedPostIds={data?.removed_postids}
              isHandleNavigate={isHandleNavigate}
              isLoading={queryFeedLoading}
              data={queryFeed}
              component={data}
              type={data?.type}
            />
          );
        } else if (type === "product-bank") {
          if (!CustomProductLoading) {
            if (!CustomProduct || CustomProduct.length === 0) {
              handleEmptyCarousel(name, data.id);
            } else {
              handleFirstImage(name, data?.id, CustomProduct[0]);
            }
          }
          return (
            <ProductBankData
              key={data.id}
              user_id={user_id}
              username={username}
              numberOfDays={7}
              title={name}
              postIds={postIds}
              isLinks={true}
              handleShop={handleShop}
              brand_color={brand_color}
              isHandleNavigate={isHandleNavigate}
              isLoading={CustomProductLoading}
              data={CustomProduct}
              component={data}
              type={data?.type}
            />
          );
        } else if (type === "banner") {
          return (
            <BannerData
              key={data.id}
              user_id={user_id}
              username={username}
              numberOfDays={7}
              title={name}
              postIds={postIds}
              isLinks={true}
              component={data}
              handleRedirect={handleRedirect}
              brand_color={brand_color}
              isHandleNavigate={isHandleNavigate}
            />
          );
        } else if (type === "highlight") {
          return (
            <HighlightDataSecton
              key={data.id}
              user_id={user_id}
              username={username}
              numberOfDays={7}
              title={name}
              postIds={postIds}
              data={data}
              handleRedirect={handleRedirect}
              handleShop={handleShop}
              brand_color={brand_color}
              component={data}
              isHandleNavigate={isHandleNavigate}
            />
          );
        } else if (type === "trending products") {
          if (!TrendingProductsLoading) {
            if (!TrendingProducts || TrendingProducts.length === 0) {
              handleEmptyCarousel(name, data.id);
            } else {
              handleFirstImage(name, data?.id, TrendingProducts[0]);
            }
          }

          return (
            <TrendingProductsData
              key={data.id}
              user_id={user_id}
              username={username}
              title={name}
              postIds={postIds}
              isLinks={true}
              handleShop={handleShop}
              brand_color={brand_color}
              details={data?.details}
              removedPostIds={data?.removed_postids}
              isHandleNavigate={isHandleNavigate}
              isLoading={TrendingProductsLoading}
              data={TrendingProducts}
              component={data}
              type={data?.type}
            />
          );
        }
        return null;
    }
  };
  return <>{renderContent()}</>;
};
