import React, { Fragment, useEffect, useState } from "react";
import { Carousel, Input, Pagination, Tag } from "antd";
import { StaticVariables } from "../../../../Utils/StaticVariables";
import SearchInput from "../../../../components/SearchInput/SearchInput";
// import "./FeedCheckboxComponent.css";
import "../../../../styles/StayUptoDateCSS/FeedCheckboxComponent.css";
import Skeleton from "react-loading-skeleton";
import { supabase } from "../../../../config/supabaseClient";
import { HiCheck, HiPencil } from "react-icons/hi";
import { useLoadQueryFeed } from "./useLoadQueryFeed";
import VideoComponent from "../../../../components/VideoComponent/VideoComponent";
import { UpdateCombinedText } from "../../../../Utils/SupabaseServices/ContentDatabase";
import CarouselOrderControl from "../../../../components/CarouselControls/CarouselOrderControl";
import { useLoadQueryBasedFeedCount } from "./useLoadQueryBasedFeedCount";
import { useLoadQueryBasedFeed } from "./useLoadQueryBasedFeed";
import CarouselShapeControl from "../../../../components/CarouselControls/CarouselShapeControl";
import CarouselSoundControl from "../../../../components/CarouselControls/CarouselSoundControl";
import Title from "antd/es/typography/Title";
const POSTS_PER_PAGE = 10;
const contentOrder = "Default";

const QueryFeedCheckboxComponent = (props: any) => {
  const {
    username,
    user_id,
    isShopLinks,
    componentName,
    post_ids,
    defaultQuery,
    setDefaultQuery,
    type,
    order,
    setOrder,
    shape,
    setShape,
    isSoundOn,
    setIsSoundOn,
    edit,
    setEdit,
    editIndex,
    setEditIndex,
  } = props;
  const showSocials =
    type === "content"
      ? StaticVariables().CONTENT_PLATFORMS
      : StaticVariables().PLATFORMS;
  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postIds, setPostIds] = useState<any>();
  useEffect(() => {
    setPostIds(postIds);
    setQuery(defaultQuery);
  }, [postIds, defaultQuery]);
  const { data: pages }: any = useLoadQueryBasedFeedCount(
    username,
    user_id,
    query,
    POSTS_PER_PAGE,
    showSocials,
    isShopLinks,
    order
  );
  const {
    isLoading,
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  }: any = useLoadQueryBasedFeed(
    currentPage,
    username,
    user_id,
    query,
    POSTS_PER_PAGE,
    pages?.totalPages || 0,
    pages?.feedCount,
    pages?.postIds,
    showSocials,
    contentOrder,
    isShopLinks,
    order
  );

  function handleFeed(postIds: any) {
    setPostIds(postIds || pages?.postIds);
    props.handlePostIds(postIds || pages?.postIds);
  }
  const [tags, setTags] = useState<string[]>([query]);

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
    const elem: any = document.getElementById("search-feed");
    elem.value = "";
    setQuery("");
    refetch();
  };

  return (
    <>
      <div className="margin-table">
        <AddedContentTable
          post_ids={pages?.postIds}
          username={username}
          user_id={user_id}
          setPostIds={setPostIds}
          componentName={componentName}
          handleFeed={handleFeed}
          query={query}
          refetch={refetch}
          isShopLinks={isShopLinks}
          type={type}
          pages={pages}
          order={order}
          shape={shape}
          isSoundOn={isSoundOn}
          edit={edit}
          setEdit={setEdit}
          editIndex={editIndex}
          setEditIndex={setEditIndex}
        />
        <Title level={3}>Carosuel Control</Title>
        <div className="addedContent">
          <CarouselOrderControl order={order} setOrder={setOrder} />
          <CarouselShapeControl shape={shape} setShape={setShape} />
          <CarouselSoundControl
            isSoundOn={isSoundOn}
            setIsSoundOn={setIsSoundOn}
          />
        </div>

        <SearchInput
          placeholder={
            isShopLinks
              ? "Search all shoppable content and products"
              : "Search content"
          }
          id="search-feed"
          value={query}
          onChange={(e: any) => {
            e.preventDefault();
            const elem: any = document.getElementById("search-feed");
            const searchVal = e.target.value;
            setQuery(e.target.value);
            setDefaultQuery(e.target.value);
            setCurrentPage(1);
          }}
          handleKeyUp={(e: any) => {
            e.preventDefault();
            if (e.keyCode === 13) {
              const elem: any = document.getElementById("search-feed");
              const searchVal = e.target.value;
              if (searchVal.length === 0) {
                elem.blur();
                setQuery("");
                setDefaultQuery("");
                props.handleQuery("");

                return;
              }
              elem.blur();
              setQuery(searchVal);
              setDefaultQuery(searchVal);
              props.handleQuery(searchVal);
              setCurrentPage(1);
            }
          }}
          isActiveFilter={Boolean(query)}
          onSearchClick={() => {
            const elem: any = document.getElementById("search-feed");
            const searchVal = elem.value;
            if (searchVal.length === 0) {
              return;
            }
            elem.blur();
            setQuery(searchVal);
            setDefaultQuery(searchVal);

            props.handleQuery(searchVal);
            setCurrentPage(1);
          }}
          onClearClick={() => {
            const elem: any = document.getElementById("search-feed");
            elem.value = "";
            setQuery("");
            setDefaultQuery("");

            props.handleQuery("");

            refetch();
            setCurrentPage(1);
          }}
        />
        {query && (
          <Tag
            // key={tag}
            closable
            className="tag-style"
            id="querry-tags"
            onClose={() => handleClose(query)}
          >
            <span onDoubleClick={(e) => {}}>{query}</span>
          </Tag>
        )}
      </div>

      <ContentTable
        username={username}
        user_id={user_id}
        data={data}
        handleFeed={handleFeed}
        postIds={pages?.postIds}
        query={query}
        isLoading={isLoading}
        handleQuery={props.handleQuery}
        isShopLinks={isShopLinks}
        type={type}
      />

      {/* Render the Pagination component */}
      {pages?.feedCount > POSTS_PER_PAGE && (
        <Pagination
          className="pagination"
          current={currentPage}
          total={pages?.feedCount}
          size="small"
          showSizeChanger={false}
          showQuickJumper
          pageSize={POSTS_PER_PAGE}
          onChange={(page: number) => setCurrentPage(page)}
        />
      )}
    </>
  );
};

const AddedContentTable = (props: any) => {
  const {
    username,
    user_id,
    post_ids,
    componentName,
    query,
    isShopLinks,
    type,
    order,
    shape,
    isSoundOn,
    // type,
    edit,
    setEdit,
    editIndex,
    setEditIndex,
  } = props;

  const [postIds, setPostIds] = useState(post_ids);
  useEffect(() => {
    setPostIds(post_ids);
  }, [post_ids]);
  // const isShopLinks = false
  const showSocials =
    type === "content"
      ? StaticVariables().CONTENT_PLATFORMS
      : StaticVariables().PLATFORMS;
  const contentOrder = "Default";
  const {
    isLoading,
    data: queryData,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  }: any = useLoadQueryFeed(
    username,
    user_id,
    query,

    showSocials,
    contentOrder,
    isShopLinks,
    "",
    componentName,
    order
  );

  useEffect(() => {
    props.handleFeed(postIds);
  }, []);

  const [value, setValue] = useState<string>();

  const handleDone = async (post_id: string) => {
    if (type === "content" || type === "query-content") {
      const { data: user, error } = await supabase
        .from(`${username}`)
        .update({ content_title: value })
        .eq("post_id", post_id)
        .eq("user_id", user_id);
      const { data: newProduct, error: newProductErroor } = await supabase
        .from("product_bank")
        .update({ content_title: value })
        .eq("product_id", post_id)
        .eq("user_id", user_id);
    } else {
      const { data: user, error } = await supabase
        .from(`${username}`)
        .update({ shop_title: value, caption: value, product_name: value })
        .eq("post_id", post_id)
        .eq("user_id", user_id);
      const { data: newProduct, error: newProductErroor } = await supabase
        .from("product_bank")
        .update({ shop_title: value })
        .eq("product_id", post_id)
        .eq("user_id", user_id);
    }
    const { data: newContent, error: newErroor } = await supabase
      .from("content_new")
      .update({ product_name: value })
      .eq("post_id", post_id)
      .eq("user_id", user_id);
    UpdateCombinedText(username, user_id, post_id);
    setEdit(false);
    setEditIndex("");
    refetch();
  };

  const SkeletonStatBox = () => {
    return (
      <div className="skeletonDiv">
        <Skeleton className="skeletonStyle" />
      </div>
    );
  };
  return (
    <>
      <div className="addedContent">
        <h5 className="addedContentTitle">
          {componentName ? componentName : "Carousel Title"}
        </h5>
        <div className="addedSvg">
          {queryData && queryData.length ? (
            queryData.map((post: any, index: any) => (
              <React.Fragment key={index}>
                <div
                  key={index}
                  className="feed-tab-post-container feed-wrapper"
                >
                  <div
                    className="feed-tab-media-wrapper"
                    style={
                      post.platform === "newsletter" ||
                      post?.platform === "linkedin" ||
                      post?.platform === "twitter"
                        ? {
                            textAlign: "center",
                            height: "100%",
                            borderRadius: "1rem",
                          }
                        : {}
                    }
                  >
                    {post?.platform === "newsletter" ? (
                      <div
                        className={`${shape === "sq" ? "square-wrapper" : ""} no-media-url`}
                      >
                        <div
                          className={`${
                            shape === "sq"
                              ? "newsletter-media-carousel square-image"
                              : "newsletter-media-carousel h-250"
                          }`}
                        >
                        <h4 className="newsletter-label">Newsletter</h4>
                          <h4 className="mt-7 date">
                            {new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              day: "numeric",
                              month: "long",
                            }).format(new Date(post.post_date))}
                          </h4>
                        </div>
                      </div>
                    ) : post?.platform === "linkedin" && !post?.media_url ? (
                      <div
                        className={`${shape === "sq" ? "square-wrapper" : ""} no-media-url`}
                      >
                        <div
                          className={`${
                            shape === "sq"
                              ? "newsletter-media-carousel square-image"
                              : "newsletter-media-carousel h-250"
                          }`}
                        >
                          <h4 className="newsletter-label">LinkedIn Post</h4>
                          <h4 className="mt-7 date">
                            {new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              day: "numeric",
                              month: "long",
                            }).format(new Date(post.post_date))}
                          </h4>
                        </div>
                      </div>
                    ) : post?.platform === "twitter" && !post?.media_url ? (
                      <div
                        className={`${shape === "sq" ? "square-wrapper" : ""} no-media-url`}
                      >
                        <div
                          className={`${
                            shape === "sq"
                              ? "newsletter-media-carousel square-image"
                              : "newsletter-media-carousel h-250"
                          }`}
                        >
                          <h4 className="newsletter-label">Twitter Post</h4>
                          <h4 className="mt-7 date">
                            {new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              day: "numeric",
                              month: "long",
                            }).format(new Date(post.post_date))}
                          </h4>
                        </div>
                      </div>
                    ) : (
                      <div className={`${shape === "sq" ? "square-grid" : ""}`}>
                        {post?.media_url ? (
                          <div
                            className={`${
                              shape === "sq" ? "square-wrapper" : ""
                            }`}
                          >
                            {post?.media_url.split(",").length > 1 &&
                            post?.media_url
                              .split(",")
                              .every((url: any) => url.trim() !== "") ? (
                              <Carousel
                                dotPosition="bottom"
                                className="feed-tab-media-carousel"
                              >
                                {post?.media_url
                                  .split(",")
                                  .map((media_url: any, index: any) => (
                                    <React.Fragment key={index}>
                                      {media_url.includes("video") ? (
                                        <VideoComponent
                                          media_url={media_url}
                                          thumbnail={
                                            post.thumbnail &&
                                            post.thumbnail.split(",").length &&
                                            post.thumbnail.split(",")[index]
                                              ? post.thumbnail.split(",")[index]
                                              : "/assets/dummy_video.png"
                                          }
                                          content_shape={shape}
                                          isSoundOn={isSoundOn}
                                        />
                                      ) : (
                                        <img
                                          className={`${
                                            shape === "sq"
                                              ? "feed-tab-media-check square-image mb-2"
                                              : "feed-tab-media-check mb-2"
                                          }`}
                                          src={media_url}
                                          alt="post"
                                        />
                                      )}
                                    </React.Fragment>
                                  ))}
                              </Carousel>
                            ) : (
                              <>
                                {post.media_url.includes("video") ? (
                                  <div
                                    className={`${
                                      shape === "sq"
                                        ? "square-wrapper"
                                        : "h-255"
                                    }`}
                                  >
                                    <VideoComponent
                                      media_url={post.media_url}
                                      thumbnail={post.thumbnail}
                                      content_shape={shape}
                                      isSoundOn={isSoundOn}
                                    />
                                  </div>
                                ) : (
                                  <img
                                    className={`${
                                      shape === "sq"
                                        ? "feed-tab-media-check square-image"
                                        : "feed-tab-media-check"
                                    }`}
                                    src={
                                      post.media_url
                                        ? post.media_url
                                        : "/assets/dummy_video.png"
                                    }
                                    alt="post"
                                  />
                                )}
                              </>
                            )}
                          </div>
                        ) : (
                          <div
                            className={`${
                              shape === "sq" ? "square-wrapper" : ""
                            }`}
                          >
                            <img
                              className={`${
                                shape === "sq"
                                  ? "feed-tab-media-check square-image"
                                  : "feed-tab-media-check"
                              }`}
                              src={
                                post?.thumbnail
                                  ? post?.thumbnail
                                  : "/assets/dummy_video.png"
                              }
                              alt="post"
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {edit === true && editIndex === index ? (
                    <>
                      <Input
                        value={value}
                        onChange={(e: any) => setValue(e.target.value)}
                      />{" "}
                      <HiCheck
                        onClick={() => {
                          handleDone(post.post_id);
                        }}
                      />
                    </>
                  ) : (
                    <p
                      style={{
                        textAlign: post.product_name !== "" ? "left" : "center",
                      }}
                    >
                      {type === "content" || type === "query-content"
                        ? post?.content_title
                          ? post?.content_title
                          : post?.product_name
                        : post?.shop_title
                        ? post?.shop_title
                        : post?.product_name}
                      <HiPencil
                        onClick={() => {
                          setValue(
                            type === "content" || type === "query-content"
                              ? post?.content_title
                                ? post?.content_title
                                : post?.product_name
                              : post?.shop_title
                              ? post?.shop_title
                              : post?.product_name
                          );
                          setEdit(true);
                          setEditIndex(index);
                        }}
                      />
                    </p>
                  )}
                </div>
              </React.Fragment>
            ))
          ) : (
            <>
              {/* <p>No Added Data</p> */}
              <>
                <div
                  // key={index}
                  // className="feed-tab-post-container"
                  className="skeletonLoader"
                >
                  {[...Array(4)].map((_, index) => (
                    <SkeletonStatBox />
                  ))}
                </div>
              </>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const ContentTable = (props: any) => {
  const { data, postIds, isLoading, query, isShopLinks, type } = props;
  // const [checkedValues, setCheckedValues] = useState<{
  //   [key: string]: boolean;
  // }>({});
  const [checkedValues, setCheckedValues] = useState<any>(postIds || []);
  useEffect(() => {
    setCheckedValues(postIds);
  }, [postIds]);
  useEffect(() => {
    // Call handleFeed on initial render and whenever checkedValues changes
    if (query != "" && postIds && postIds.length >= 0) {
      props.handleQuery(query);
      props.handleFeed(postIds.reverse());
    }
  }, [query, postIds]);

  return (
    <>
      {!isLoading ? (
        <>
          {data?.pages ? (
            <div className="feed-tab-posts-container">
              {data.pages.map((group: any, pageIndex: number) => (
                <Fragment key={pageIndex}>
                  {group.map((image: any, index: number) => (
                    <div
                      key={index}
                      className="feed-tab-post-container feed-post-wrapper"
                    >
                      <div
                        className="feed-tab-media-wrapper"
                        style={
                          image.platform === "newsletter" ||
                          image.platform === "linkedin" ||
                          image.platform === "twitter"
                            ? {
                                textAlign: "center",
                                height: "100%",
                                borderRadius: "1rem",
                              }
                            : {}
                        }
                      >
                        {image.platform === "newsletter" ? (
                          <div className="newsletter-media-carousel h-250 no-media-url" 
                          style={{backgroundColor: "black", color: "white"}}
                          >
                            <h4 className="newsletter-label"> Newsletter</h4>
                            <h4 className="mt-7 date">
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                day: "numeric",
                                month: "long",
                              }).format(new Date(image.post_date))}
                            </h4>
                          </div>
                        ) : image.platform === "linkedin" &&
                          !image?.media_url ? (
                          <div className="newsletter-media-carousel h-250 no-media-url" 
                          // style={{backgroundColor: "black", color: "white"}}
                          >
                            <h4 className="newsletter-label">LinkedIn Post</h4>
                            <h4 className="mt-7 date">
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                day: "numeric",
                                month: "long",
                              }).format(new Date(image.post_date))}
                            </h4>
                          </div>
                        ) : image.platform === "twitter" &&
                          !image?.media_url ? (
                          <div className="newsletter-media-carousel h-250 no-media-url" 
                          // style={{backgroundColor: "black", color: "white"}}
                          >
                            <h4 className="newsletter-label">Twitter Post</h4>
                            <h4 className="mt-7 date">
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                day: "numeric",
                                month: "long",
                              }).format(new Date(image.post_date))}
                            </h4>
                          </div>
                        ) : (
                          <>
                            {image.media_url ? (
                              <>
                                {image.media_url.split(",").length > 1 &&
                                image.media_url
                                  .split(",")
                                  .every((url: any) => url.trim() !== "") ? (
                                  <Carousel
                                    dotPosition="bottom"
                                    className="feed-tab-media-carousel"
                                  >
                                    {image.media_url
                                      .split(",")
                                      .map((media_url: any, index: any) => (
                                        <React.Fragment key={index}>
                                          {media_url.includes("video") ? (
                                            <div className="h-255">
                                              <VideoComponent
                                                media_url={media_url}
                                                thumbnail={
                                                  image.thumbnail &&
                                                  image.thumbnail.split(",")
                                                    .length &&
                                                  image.thumbnail.split(",")[
                                                    index
                                                  ]
                                                    ? image.thumbnail.split(
                                                        ","
                                                      )[index]
                                                    : "/assets/dummy_video.png"
                                                }
                                              />
                                            </div>
                                          ) : (
                                            <img
                                              className="feed-tab-media-check mb-2"
                                              src={media_url}
                                              alt="post"
                                            />
                                          )}
                                        </React.Fragment>
                                      ))}
                                  </Carousel>
                                ) : (
                                  <>
                                    {image.media_url.includes("video") ? (
                                      <div className="h-255">
                                        <VideoComponent
                                          media_url={image.media_url}
                                          thumbnail={image.thumbnail}
                                        />
                                      </div>
                                    ) : (
                                      <img
                                        className="feed-tab-media-check"
                                        src={
                                          image.media_url
                                            ? image.media_url
                                            : "/assets/dummy_video.png"
                                        }
                                        alt="post"
                                      />
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <img
                                className="feed-tab-media-check"
                                src={
                                  image.thumbnail
                                    ? image.thumbnail
                                    : "/assets/dummy_video.png"
                                }
                                alt="post"
                              />
                            )}
                          </>
                        )}
                      </div>
                      <p
                        style={{
                          textAlign:
                            image.product_name !== "" ? "left" : "center",
                        }}
                      >
                        {type === "content" || type === "query-content"
                          ? image?.content_title
                            ? image?.content_title
                            : image?.product_name
                          : image?.shop_title
                          ? image?.shop_title
                          : image?.product_name}{" "}
                      </p>
                    </div>
                  ))}
                </Fragment>
              ))}
            </div>
          ) : (
            <>
              {isShopLinks ? (
                <p>No shoppable content present for '{query}'</p>
              ) : (
                <p>No content present for '{query}'</p>
              )}
            </>
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default QueryFeedCheckboxComponent;
