
import "../../../../styles/StayUptoDateCSS/SubComponents.css";
import { useLocation } from "react-router-dom";
import { useDomLoaded } from "../../../../hooks/DomLoadedContext";
import track, { TRACKING_TYPES } from "../../../../Utils/SupabaseServices/Track";
import { useLoadCustomContent } from "../../../../Utils/customHooks/UpToDateContent/useLoadCustomContent";
import DataSkeleton from "../../../../components/StayUpToDateComponent/UpToDateComponents/DataSkeleton";
import ImagePostTag from "../../../../components/GeneralPostTag/ImagePostTag";
import { getPostTitle } from "../../../../Utils/HelperFunction/HelperFunction";
import VideoPostTag from "../../../../components/GeneralPostTag/VideoPostTag";
import ImagePostCarouselTag from "../../../../components/GeneralPostTag/ImagePostCarouselTag";
import React from "react";
import DashboardCarouselStructure from "../../../../components/CarouselStructure/DashboardCarouselStructure";

const CustomContentDashboard = (props: any) => {
  const { user_id, username, numberOfDays, isLinks, brand_color, component } =
    props;

  const {
    key,
    name: title,
    post_ids,
    cta,
    query,
    removed_postids: removedPostIds,
    content_order,
    content_shape,
    content_sound,
    type
  } = component;
  const location = useLocation();
  const domLoaded = useDomLoaded();
  function handleShop(postId: string, media_url: string) {
    track("Redirect to shop link", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: { post_id: postId, media_url: media_url },
      user_id: user_id,
      title: "Stay Up To Date",
    });
  }
  const {
    isLoading: CustomContentDataLoading,
    data: CustomContentData,
    forceRefetch,
  }: any = useLoadCustomContent(username, user_id, post_ids?.split(","), title);


  return (
    <>
      <div>
        {CustomContentDataLoading || !domLoaded ? (
          <div className="outer-container-subcomponents">
            <DataSkeleton dashboard={true} />{" "}
          </div>
        ) : (
          <div className="outer-container-subcomponents">
            {CustomContentData && CustomContentData.length > 0 ? (
              <div className={`links-grid-container-subcomponents ${content_shape === "sq" ? 'square-grid' : ''}`}>
                {CustomContentData &&
                  CustomContentData.map((link: any, index: any) => {
                    const productTitle = getPostTitle(link, type);
                    return (
                      <React.Fragment key={index}>
                        <DashboardCarouselStructure
                          link={link}
                          index={index}
                          handleShop={handleShop}
                          title={title}
                          brand_color={brand_color}
                          isLinks={isLinks}
                          productName={type ? productTitle : ""}
                          contentShape={content_shape == "sq" ? true : false}
                          contentSound={content_sound}
                          type={type}
                        />
                      </React.Fragment>
                    )
                  })}
              </div>
            ) : (
              <div className="outer-container-subcomponents">
                <p>No content at this time</p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default CustomContentDashboard;
