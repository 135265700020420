import React from "react";
import SearchInput from "../SearchInput/SearchInput";

const CarouselSearchbar = (props: any) => {
  const {
    isShopLinks,
    query,
    setQuery,
    setDefaultQuery,
    setCurrentPage,
    setActiveFilter,
    refetch,
    // postIds,
    // setPostIds,
  } = props;

  return (
    <>
      <SearchInput
        placeholder={
          isShopLinks
            ? "Search all shoppable content and products"
            : "Search content"
        }
        id="search-feed"
        value={query}
        onChange={(e: any) => {
          e.preventDefault();
          const elem: any = document.getElementById("search-feed");
          const searchVal = e.target.value;
          setQuery(e.target.value);
          setDefaultQuery(e.target.value);
          setCurrentPage(1);
        }}
        handleKeyUp={(e: any) => {
          e.preventDefault();
          if (e.keyCode === 13) {
            const elem: any = document.getElementById("search-feed");
            const searchVal = e.target.value;
            if (searchVal.length === 0) {
              elem.blur();
              setQuery("");
              setDefaultQuery("");

              return;
            }
            elem.blur();
            setQuery(searchVal);
            setCurrentPage(1);
            setDefaultQuery(searchVal);
          }
        }}
        isActiveFilter={Boolean(query)}
        onSearchClick={() => {
          const elem: any = document.getElementById("search-feed");
          const searchVal = elem.value;
          if (searchVal.length === 0) {
            return;
          }
          elem.blur();
          setQuery(searchVal);
          setDefaultQuery(searchVal);
          setCurrentPage(1);
        }}
        onClearClick={() => {
          const elem: any = document.getElementById("search-feed");
          elem.value = "";
          setQuery("");
          setDefaultQuery("");
          setActiveFilter("both");

          refetch();
          setCurrentPage(1);
          props?.setPostIds(props?.postIds);
        }}
      />
    </>
  );
};

export default CarouselSearchbar;
