import React from "react";
import { updateSelectedPlatforms } from "../../../../Utils/SupabaseServices/OnboardingFlow";
import { supabase } from "../../../../config/supabaseClient";

const ConnectPlatformStep = (props: any) => {
  const {
    handleNext,
    buttonStyle,
    handleBack,
    selectedPlatforms,
    handlePlatformToggle,
    username,
    setUsername,
    setSelectedPlatforms,
    currentStep,
    instagramUsername,
    setInstagramUsername,
  } = props;
  const [expandedPlatform, setExpandedPlatform] = React.useState<string>("");
  // const [instagramUsername, setInstagramUsername] = React.useState<string>(username);
  const [youtubeUsername, setYoutubeUsername] = React.useState<string>("");
  const [podcastShowName, setPodcastShowName] = React.useState<string>("");
  const [podcastLink, setPodcastLink] = React.useState<string>("");
  const [twitterUsername, setTwitterUsername] = React.useState<string>("");
  const [tiktokUsername, setTiktokUsername] = React.useState<string>("");
  const [linkedinProfileUrl, setLinkedinProfileUrl] =
    React.useState<string>("");
  const [newsletterEmail, setNewsletterEmail] = React.useState<string>("");
  const [newPlatforms,setNewPlatforms] = React.useState<any>([])
  const toggleExpand = (platformId: string) => {
    console.log("toggleExpand", platformId);

    setExpandedPlatform((prev) => (prev === platformId ? "" : platformId));
  };

  const platforms = [
    {
      id: "instagram",
      name: "Instagram",
      icon: (
        <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
          <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zM12 0C8.741 0 8.333.014 7.053.072 2.695.272.273 2.69.073 7.052.014 8.333 0 8.741 0 12c0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98C8.333 23.986 8.741 24 12 24c3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98C15.668.014 15.259 0 12 0z" />
          <path d="M12 5.838a6.162 6.162 0 1 0 0 12.324 6.162 6.162 0 0 0 0-12.324zM12 16a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
          <circle cx="18.406" cy="5.594" r="1.44" />
        </svg>
      ),
    },
    {
      id: "youtube",
      name: "YouTube",
      icon: (
        <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
          <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.622v-8l8 3.993-8 4.007z" />
        </svg>
      ),
    },
    {
      id: "twitter",
      name: "Twitter",
      icon: (
        <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
          <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
        </svg>
      ),
    },
    {
      id: "tiktok",
      name: "TikTok",
      icon: (
        <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
          <path d="M12.53.02C13.84 0 15.14.01 16.44 0c.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1 1.59-2.47 2.32-4.15 2.32-1.68 0-3.15-.72-4.15-2.32-.81-1.15-1.28-2.54-1.35-3.94-.02-1.7.01-3.35-.02-5.03-.21-1.48-1.03-2.63-2.24-3.12-.63-.29-1.98-.80-2.34-1.20.86-1.01 2.13-1.59 3.44-1.59 1.57 0 2.93.77 3.7 2.01z" />
        </svg>
      ),
    },

    {
      id: "linkedin",
      name: "LinkedIn",
      icon: (
        <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
          <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
        </svg>
      ),
    },
    {
      id: "newsletter",
      name: "Newsletter",
      icon: (
        <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
          <path d="M19 3.5H5c-1.1 0-2 .9-2 2v13c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-13c0-1.1-.9-2-2-2zm-10 12H6v-10h3v10zm4 0h-3v-10h3v10zm5 0h-3v-10h3v10z" />
        </svg>
      ),
    },
    {
      id: "podcast",
      name: "Podcast",
      icon: (
        <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
          <path d="M12 0C8.686 0 6 2.686 6 6v6c0 3.314 2.686 6 6 6s6-2.686 6-6V6c0-3.314-2.686-6-6-6zm4 12c0 2.21-1.79 4-4 4s-4-1.79-4-4V6c0-2.21 1.79-4 4-4s4 1.79 4 4v6zm4 3.5c0-.276-.224-.5-.5-.5s-.5.224-.5.5c0 3.584-2.916 6.5-6.5 6.5S6 19.084 6 15.5c0-.276-.224-.5-.5-.5s-.5.224-.5.5c0 4.136 3.364 7.5 7.5 7.5s7.5-3.364 7.5-7.5z" />
        </svg>
      ),
    },
  ];

  const handlePlatformValues = async (platformId: string, isConnecting: boolean) => {
    console.log("handlePlatformValues",platformId,isConnecting)
    if (isConnecting) {
      let username = "";
      switch (platformId) {
        case "instagram":
          username = instagramUsername;
          break;
        case "youtube":
          username = youtubeUsername;
          break;
        case "twitter":
          username = twitterUsername;
          break;
        case "tiktok":
          username = tiktokUsername;
          break;
        case "linkedin":
          username = linkedinProfileUrl;
          break;
        case "newsletter":
          username = newsletterEmail;
          break;
        case "podcast":
          username = `${podcastShowName},${podcastLink}`;
          break;
      }

      const newPlatform = {
        platform: platformId,
        username: username,
      };

      // Update both states in sequence
      setNewPlatforms(newPlatform);
      setSelectedPlatforms((prev: any) => {

        const updatedPlatforms = [...prev, newPlatform];
        // Call updateSelectedPlatforms with the latest data
        updateSelectedPlatforms(updatedPlatforms, username, newPlatform);
        return updatedPlatforms;
      });
    } else {
      setSelectedPlatforms((prev: any) => {
        const updatedPlatforms = prev.filter((item: any) => item.platform !== platformId);
        // Call updateSelectedPlatforms with the latest data
        updateSelectedPlatforms(updatedPlatforms, username, null);
        return updatedPlatforms;
      });
    }
    const {data} = await supabase.from("user").update({onboarding_step:currentStep}).eq("username",username)
    
  };
const handlePageNext = () => {
  handleNext();
}
console.log("selectedPlatforms check",selectedPlatforms,instagramUsername )
  return (
    <div style={{ textAlign: "center" }}>
      <h2 style={{ fontSize: 20, marginBottom: 16, color: "#333" }}>
        Choose Platforms
      </h2>
      <p style={{ color: "#666", marginBottom: 16 }}>
        Select the platforms you want to connect
      </p>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 12,
          marginBottom: 16,
        }}
      >
        {platforms.map((platform) => {
          const Icon = platform.icon;
          const isSelected = selectedPlatforms.some(
            (item: any) => item.platform === platform.id
          );
          const isExpanded = expandedPlatform === platform.id;

          return (
            <div key={platform.id}>
              <button
                type="button"
                onClick={() => toggleExpand(platform.id)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "8px 16px",
                  border: "1px solid #ccc",
                  borderRadius: 4,
                  backgroundColor: "#fff",
                  color: "#333",
                  cursor: "pointer",
                  width: "100%",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {Icon}
                  <span style={{ marginLeft: 12 }}>{platform.name}</span>
                  {isSelected && (
                    <>
                      <svg
                        viewBox="0 0 24 24"
                        width="20"
                        height="20"
                        fill="green"
                        style={{ marginLeft: 8 }}
                      >
                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
                      </svg>
                      {/* {platform.id === "instagram" && ( */}
                        <span
                          style={{
                            marginLeft: 8,
                            fontSize: "0.9em",
                            color: "#666",
                          }}
                        >
                          {
                            selectedPlatforms.find(
                              (item: any) => item.platform === platform?.id
                            )?.username
                          }
                        
                        </span>
                      {/* )} */}
                    </>
                  )}
                </div>
                <svg
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                  style={{
                    transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.3s ease",
                  }}
                >
                  <path d="M7 10l5 5 5-5z" />
                </svg>
              </button>

              {isExpanded && (
                <div
                  style={{
                    padding: "16px",
                    border: "1px solid #ccc",
                    borderTop: "none",
                    borderRadius: "0 0 4px 4px",
                    backgroundColor: "#fff",
                    marginTop: -4,
                  }}
                >
                  {platform.id === "instagram" ? (
                    <div style={{ marginBottom: 16 }}>
                      <label
                        htmlFor="instagram-username"
                        style={{
                          display: "block",
                          textAlign: "left",
                          marginBottom: 8,
                          color: "#666",
                        }}
                      >
                        Instagram Username
                      </label>
                      <input
                        id="instagram-username"
                        type="text"
                        value={instagramUsername}
                        onChange={(e) => setInstagramUsername(e.target.value)}
                        style={{
                          width: "100%",
                          padding: "8px 12px",
                          border: "1px solid #ccc",
                          borderRadius: 4,
                          fontSize: "16px",
                        }}
                        placeholder={instagramUsername}
                      />
                    </div>
                  ) : platform.id === "youtube" ? (
                    <div style={{ marginBottom: 16 }}>
                      <label
                        htmlFor="youtube-channel-id"
                        style={{
                          display: "block",
                          textAlign: "left",
                          marginBottom: 8,
                          color: "#666",
                        }}
                      >
                        YouTube Channel ID
                      </label>
                      <input
                        id="youtube-channel-id"
                        type="text"
                        value={youtubeUsername}
                        onChange={(e) => setYoutubeUsername(e.target.value)}
                        style={{
                          width: "100%",
                          padding: "8px 12px",
                          border: "1px solid #ccc",
                          borderRadius: 4,
                          fontSize: "16px",
                        }}
                        placeholder="Enter your YouTube channel id"
                      />
                    </div>
                  ) : platform.id === "podcast" ? (
                    <div style={{ marginBottom: 16 }}>
                      <div style={{ marginBottom: 16 }}>
                        <label
                          htmlFor="podcast-show-name"
                          style={{
                            display: "block",
                            textAlign: "left",
                            marginBottom: 8,
                            color: "#666",
                          }}
                        >
                          Podcast Show Name
                        </label>
                        <input
                          id="podcast-show-name"
                          type="text"
                          value={podcastShowName}
                          onChange={(e) => setPodcastShowName(e.target.value)}
                          style={{
                            width: "100%",
                            padding: "8px 12px",
                            border: "1px solid #ccc",
                            borderRadius: 4,
                            fontSize: "16px",
                          }}
                          placeholder="Enter your podcast show name"
                        />
                      </div>
                      <div style={{ marginBottom: 16 }}>
                        <label 
                          htmlFor="podcast-link" 
                          style={{ 
                            display: 'block', 
                            textAlign: 'left', 
                            marginBottom: 8,
                            color: '#666'
                          }}
                        >
                          Podcast Link
                        </label>
                        <input
                          id="podcast-link"
                          type="text"
                          value={podcastLink}
                          onChange={(e) => setPodcastLink(e.target.value)}
                          style={{
                            width: '100%',
                            padding: '8px 12px',
                            border: '1px solid #ccc',
                            borderRadius: 4,
                            fontSize: '16px'
                          }}
                          placeholder="Enter your podcast link"
                        />
                      </div>
                    </div>
                  ) : platform.id === "twitter" ? (
                    <div style={{ marginBottom: 16 }}>
                      <label
                        htmlFor="twitter-username"
                        style={{
                          display: "block",
                          textAlign: "left",
                          marginBottom: 8,
                          color: "#666",
                        }}
                      >
                        Twitter Username
                      </label>
                      <input
                        id="twitter-username"
                        type="text"
                        value={twitterUsername}
                        onChange={(e) => setTwitterUsername(e.target.value)}
                        style={{
                          width: "100%",
                          padding: "8px 12px",
                          border: "1px solid #ccc",
                          borderRadius: 4,
                          fontSize: "16px",
                        }}
                        placeholder="@yourusername"
                      />
                    </div>
                  ) : platform.id === "tiktok" ? (
                    <div style={{ marginBottom: 16 }}>
                      <label
                        htmlFor="tiktok-username"
                        style={{
                          display: "block",
                          textAlign: "left",
                          marginBottom: 8,
                          color: "#666",
                        }}
                      >
                        TikTok Username
                      </label>
                      <input
                        id="tiktok-username"
                        type="text"
                        value={tiktokUsername}
                        onChange={(e) => setTiktokUsername(e.target.value)}
                        style={{
                          width: "100%",
                          padding: "8px 12px",
                          border: "1px solid #ccc",
                          borderRadius: 4,
                          fontSize: "16px",
                        }}
                        placeholder="@yourusername"
                      />
                    </div>
                  ) : platform.id === "linkedin" ? (
                    <div style={{ marginBottom: 16 }}>
                      <label
                        htmlFor="linkedin-profile"
                        style={{
                          display: "block",
                          textAlign: "left",
                          marginBottom: 8,
                          color: "#666",
                        }}
                      >
                        LinkedIn Profile URL
                      </label>
                      <input
                        id="linkedin-profile"
                        type="text"
                        value={linkedinProfileUrl}
                        onChange={(e) => setLinkedinProfileUrl(e.target.value)}
                        style={{
                          width: "100%",
                          padding: "8px 12px",
                          border: "1px solid #ccc",
                          borderRadius: 4,
                          fontSize: "16px",
                        }}
                        placeholder="https://www.linkedin.com/in/yourprofile"
                      />
                    </div>
                  ) : platform.id === "newsletter" ? (
                    <div style={{ marginBottom: 16 }}>
                      <label
                        htmlFor="newsletter-email"
                        style={{
                          display: "block",
                          textAlign: "left",
                          marginBottom: 8,
                          color: "#666",
                        }}
                      >
                        Newsletter Email
                      </label>
                      <input
                        id="newsletter-email"
                        type="email"
                        value={newsletterEmail}
                        onChange={(e) => setNewsletterEmail(e.target.value)}
                        style={{
                          width: "100%",
                          padding: "8px 12px",
                          border: "1px solid #ccc",
                          borderRadius: 4,
                          fontSize: "16px",
                        }}
                        placeholder="your@email.com"
                      />
                    </div>
                  ) : (
                    <p>Additional content for {platform.name}</p>
                  )}
                  <button
                    onClick={() => {
                      const isConnecting = !selectedPlatforms.some(
                        (item: any) => item.platform === platform.id
                      );
                      handlePlatformValues(platform.id, isConnecting);
                      // handlePlatformToggle(platform.id);
                    }}
                    style={{
                      backgroundColor: isSelected ? "#fff" : "#000",
                      color: isSelected ? "#000" : "#fff",
                      border: "1px solid #000",
                      padding: "8px 16px",
                      borderRadius: 4,
                      cursor: "pointer",
                      marginTop: 8,
                    }}
                  >
                    {isSelected ? "Disconnect" : "Connect"}
                  </button>
                </div>
              )}
            </div>
          );
        })}
      </div>

      <button
        type="button"
        onClick={() => {
          setSelectedPlatforms(selectedPlatforms);
          handlePageNext();
        }}
        style={{
          backgroundColor: "#000",
          color: "#fff",
          border: "none",
          padding: "8px 16px",
          borderRadius: 4,
          cursor: selectedPlatforms.length > 0 ? "pointer" : "not-allowed",
          width: "100%",
          opacity: selectedPlatforms.length > 0 ? 1 : 0.5,
        }}
        disabled={selectedPlatforms.length === 0}
      >
        Next
      </button>
    </div>
  );
};

export default ConnectPlatformStep;
