import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomPagination from "../../CustomPagination";
import { useLoadTypeContentData } from "../../../../Utils/customHooks/UpToDateContent/useLoadTypeContent";
import DataSkeleton from "../DataSkeleton";
import { debounce } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isNavigable } from "../../../../Utils/StayUpToDate/RenderComponent";
import track, {
  TRACKING_TYPES,
} from "../../../../Utils/SupabaseServices/Track";
import { isMobile } from "react-device-detect";
import ImagePostCarouselTag from "../../../GeneralPostTag/ImagePostCarouselTag";
import VideoPostTag from "../../../GeneralPostTag/VideoPostTag";
import ImagePostTag from "../../../GeneralPostTag/ImagePostTag";
import { useDomLoaded } from "../../../../hooks/DomLoadedContext";

const FeaturedData = (props: any) => {
  let platform = "featured";
  let type = "FEATURE";
  const itemsPerPage = 3;

  const param = useParams();

  const {
    user_id,
    username,
    title,
    handleRedirect,
    brand_color,
    isHandleNavigate,
    component,
    handleShop,
    isLinks,
  } = props;
  const { isLoading: PodcastDataLoading, data }: any = useLoadTypeContentData(
    username,
    user_id,
    platform,
    type
  );

  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef<any>(null);
  const isButtonClicked = useRef(false);
  const navigate = useNavigate();
  const location = useLocation();
  const domLoaded = useDomLoaded();
  const handleScroll = useCallback(
    debounce(() => {
      if (!isButtonClicked.current) {
        if (containerRef.current) {
          const scrollLeft = containerRef.current.scrollLeft;
          const itemWidth = containerRef.current.scrollWidth / data.length;
          const newCurrentIndex = Math.round(scrollLeft / itemWidth);
          setCurrentIndex(newCurrentIndex);
        }
      }
    }, 100),
    [data]
  );
  useEffect(() => {
    if (containerRef.current) {
      const itemWidth = containerRef.current.scrollWidth / data.length;
      const scrollPosition = itemWidth * currentIndex;
      containerRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [currentIndex, data]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
      return () => {
        if (containerRef.current) {
          containerRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [data, handleScroll]);

  const handlePrevPage = () => {
    isButtonClicked.current = true;
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };

  const handleNextPage = () => {
    isButtonClicked.current = true;
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, data.length - itemsPerPage)
    );
    setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };

  const handleNavigate = (component: any) => {
    if (isNavigable(component)) {
      navigate(`/${username}/${component.unique_id}`, {
        state: { data: component },
      });
    }
  };

  return (
    <div className="reels-wrapper">
      {PodcastDataLoading || !domLoaded ? (
        <DataSkeleton />
      ) : (
        <>
          {data && data.length ? (
            <div>
              <div className="mainDiv">
                <p
                  className="component-name"
                  style={param?.id ? { cursor: "auto" } : {}}
                  onClick={(e) => handleNavigate(component)}
                >
                  {title}{" "}
                </p>
                {!param?.id && (
                  <CustomPagination
                    totalItems={data?.length}
                    currentIndex={currentIndex}
                    handlePrevPage={handlePrevPage}
                    handleNextPage={handleNextPage}
                  />
                )}
              </div>
              <div
                className={!param?.id ? "outer-container-reels" : ""}
                ref={containerRef}
              >
                <div
                  className={
                    !param?.id
                      ? "links-grid-container-reels"
                      : isMobile
                      ? "item-grid creator-card-grid-wrp"
                      : "item-grid"
                  }
                >
                  {data &&
                    data.map((link: any, index: number) => (
                      <div
                        key={index}
                        className={
                          link.platform != "youtube"
                            ? "link-url"
                            : !link.product_image
                            ? "link-url youtube-link-position"
                            : "link-url"
                        }
                      >
                        <>
                          {link.media_url ? (
                            link.media_url.split(",").length > 1 &&
                            link.media_url
                              .split(",")
                              .every((url: any) => url.trim() !== "") ? (
                              <>
                                <ImagePostCarouselTag
                                  post={link}
                                  handleRedirect={handleShop}
                                  title={title}
                                  brand_color={brand_color}
                                  isLinks={link.shop_link ? true : false}
                                  isDashboard={false}
                                  isYoutube={
                                    link.platform == "youtube" &&
                                    !link.product_image
                                      ? true
                                      : false
                                  }
                                />
                              </>
                            ) : (
                              <>
                                {link.product_image &&
                                link.product_image !== "" ? (
                                  <>
                                    <ImagePostTag
                                      data={link}
                                      handleRedirect={handleShop}
                                      title={title}
                                      brand_color={brand_color}
                                      isLinks={link.shop_link ? true : false}
                                      isDashboard={false}
                                      isYoutube={
                                        link.platform == "youtube" &&
                                        !link.product_image
                                          ? true
                                          : false
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    {link.media_url.includes("video") ? (
                                      <>
                                        <VideoPostTag
                                          post={link}
                                          handleRedirect={handleShop}
                                          title={title}
                                          brand_color={brand_color}
                                          isLinks={
                                            link.shop_link ? true : false
                                          }
                                          isDashboard={false}
                                          thumbnail={link.thumbnail}
                                          isYoutube={
                                            link.platform == "youtube" &&
                                            !link.product_image
                                              ? true
                                              : false
                                          }
                                          type={type}

                                        />
                                      </>
                                    ) : (
                                      <>
                                        <ImagePostTag
                                          data={link}
                                          handleRedirect={handleShop}
                                          title={title}
                                          brand_color={brand_color}
                                          isLinks={
                                            link.shop_link ? true : false
                                          }
                                          isDashboard={false}
                                          isYoutube={
                                            link.platform == "youtube" &&
                                            !link.product_image
                                              ? true
                                              : false
                                          }
                                        />
                                      </>
                                    )}
                                  </>
                                )}
                                
                              </>
                            )
                          ) : (
                            <>
                              <ImagePostTag
                                data={link}
                                handleRedirect={handleShop}
                                title={title}
                                brand_color={brand_color}
                                isLinks={link.shop_link ? true : false}
                                isDashboard={false}
                                isYoutube={
                                  link.platform == "youtube" &&
                                  !link.product_image
                                    ? true
                                    : false
                                }
                              />
                            </>
                          )}
                        </>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default FeaturedData;
