import { Button, Input, List, Modal } from "antd";
import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import '../../../../styles/StayUptoDateCSS/StayUpToDateControls.css'
import { addComponent } from "../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import QueryFeedCheckboxComponent from "./QueryFeedCheckboxComponent";

const AddQueryLinksComponents = (props: any) => {
  const {
    username,
    user_id,
    isQueryLinksComponentOpen,
    isLinksEditModalOpen,
    type,
  } = props;
  const [name, setName] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [postIds, setPostIds] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [query, setQuery] = useState("");
  const [defaultQuery, setDefaultQuery] = useState("");
  const [order, setOrder] = useState("DESC");
  const [shape, setShape] = useState("rect");
  const [isSoundOn, setIsSoundOn] = useState(false);
  // const [defaultQuery,setDefaultQuery]=useState("")
  const [edit,setEdit]=useState(false)
  const [editIndex,setEditIndex]=useState("")
  const handleInputChange = (value: string, setter: Function) => {
    setter(value);
    setIsButtonDisabled(!value);
  };
  const handleOk = async () => {
    setConfirmLoading(true);

    const res = await addComponent(
      name,
      "",
      username,
      user_id,
      "false",
      "Check it out!",
      query,
      name,
      "query-links",
      "",
      order,
      shape,
      isSoundOn
    );
    if (res === "true") {
      props.refetch();

      props.setIsShoplinksModalOpen(false);
      props.setIsQueryLinksComponentOpen(false);

      setConfirmLoading(false);
      handleClose()
    } else {
      setErrorMsg("Category with this name already exists");
      setConfirmLoading(false);
    }
    setEdit(false)
    setEditIndex("")

  };

  const handleCancel = () => {
    props.setIsShoplinksModalOpen(false);
    props.setIsQueryLinksComponentOpen(false);
    handleClose();
    setName("");
    setPostIds("");
    setErrorMsg("");
    setQuery("")
    setDefaultQuery("")
    setEdit(false)
    setEditIndex("")

  };
  const handlePrevious = () => {
    props.setIsQueryLinksComponentOpen(false);
    props.setIsShoplinksModalOpen(true);
    handleClose();
  };
  function handleClose() {
    setName("");
    setPostIds("");
    setErrorMsg("");
    setQuery("");
    setDefaultQuery("");
    setOrder("DESC");
    setShape("rect");
    setIsSoundOn(false)
  
    setQuery("")
    setDefaultQuery("")
    setEdit(false)
    setEditIndex("")

  };
  function handlePostIds(post_ids: any) {
    setPostIds(post_ids);
  }
  function handleQuery(term: any) {
    setQuery(term);
  }
  return (
    <>
      <Modal
        title={
          <div className="modal-title">
            <button onClick={handlePrevious} className="newbutton">
              <FaArrowLeft />
            </button>
          </div>
        }
        open={isQueryLinksComponentOpen || isLinksEditModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Add"
        confirmLoading={confirmLoading}
        okButtonProps={{ disabled: !name }}
        data-testid="phrase-based"
      >
        <h5 className="title mb-15">
          Create a Word or Phrase Based Shoppable Content Carousel{" "}
        </h5>
        <label className="bold-label">Carousel Title</label>
        <Input
          placeholder="Enter Title"
          id="phrase-based-title"
          value={name}
          onChange={(e: any) => {
            handleInputChange(e.target.value, setName);
            setErrorMsg("");
          }}
          className="mb-15"
        />
        {errorMsg && <p className="errorMsg">{errorMsg}</p>}

        <QueryFeedCheckboxComponent
          isShopLinks={true}
          username={username}
          user_id={user_id}
          handlePostIds={handlePostIds}
          componentName={name}
          post_ids={postIds}
          handleQuery={handleQuery}
          defaultQuery={defaultQuery}
          type="links"
          setDefaultQuery={setDefaultQuery}
          shape={shape}
          setShape={setShape}
          order={order}
          setOrder={setOrder}
          isSoundOn={isSoundOn}
          setIsSoundOn={setIsSoundOn}
          edit={edit}
          setEdit={setEdit}
          editIndex={editIndex}
          setEditIndex={setEditIndex}
        />
      </Modal>
    </>
  );
};

export default AddQueryLinksComponents;
