import React, { useEffect, useState } from "react";
import LineTemplate from "./Components/LineTemplate";
import GridTemplate from "./Components/GridTemplate";
import "./LinksComponentDashboard.css";
import { Button, Popover, Spin } from "antd";
import { useLoadUserLinks } from "./Utils/useLoadUserLinks";
import { deleteUserLinks } from "../../../../../Utils/SupabaseServices/UserLinksDatabase";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { UpdateUserLinksIndex } from "../../../../../Utils/SupabaseServices/UserLinksDatabase";
import { IoAddCircle } from "react-icons/io5";
import { MyLinksSkeleton } from "../../../../../components/skeleton/DashboardView/myLinksSkeleton";

import DraggableTemplateGrid from "./Components/DraggableTemplateGrid";
import DraggableTemplateLine from "./Components/DraggableTemplateLine";
const LinksComponentDashboard = (props: any) => {
  const { component, username, user_id, brand_color } = props;
  const { name, details } = component;
  const { data, error, refetch } = useLoadUserLinks(user_id, name);
  const [editIndex, setEditIndex] = useState<number | undefined>();
  const [templates, setTemplates] = useState<any[]>([]);
  const [showPopover, setShowPopover] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isNewCard, setIsNewCard] = useState(false);
  useEffect(() => {
    if (data) {
      const sortedData = [...data].sort((a, b) => a.index - b.index);
      setTemplates(sortedData);
      if (sortedData.length === 0) {
        const defaultTemplate = {
          id: 0,
          link_name: "",
          link_url: "",
          image_url: null,
          index: 0,
        };
        setTemplates([defaultTemplate]);
        // setIsNewCard(true);
      }
      // else if(isNewCard){
      //   const defaultTemplate = {
      //     id: 0,
      //     link_name: "",
      //     link_url: "",
      //     image_url: null,
      //     index: 0,
      //   };
      //   setTemplates([...sortedData, defaultTemplate]);
      // }
      setIsLoading(false);
    }
  }, [data]);

  const handleAddTemplate = () => {
    const hasEmptyTemplate = templates.some(
      (template: any) => template.id === 0
    );
    setIsNewCard(true);
    if (hasEmptyTemplate) {
      setShowPopover(true);
      setTimeout(() => setShowPopover(false), 3000);
      return;
    }
    setEditIndex(undefined);
    const newTemplate = {
      id: 0,
      link_name: "",
      link_url: "",
      image_url: null,
      index: templates.length,
    };
    setTemplates([...templates, newTemplate]);
    setTimeout(() => {
      setIsNewCard(false);
    }, 300);
  };

  const handleRemoveTemplate = async (id: number) => {
    // setIsLoading(true);
    const updatedTemplates = templates.filter(
      (template: any) => template.id !== id
    );
    setTemplates(updatedTemplates);
    setEditIndex(undefined);
    setIsNewCard(false);
    await deleteUserLinks(user_id, name, id);
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 300);
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const newTemplates = Array.from(templates);
    const [reorderedItem] = newTemplates.splice(result.source.index, 1);
    newTemplates.splice(result.destination.index, 0, reorderedItem);

    UpdateUserLinksIndex(newTemplates, user_id, name);
    setTemplates(newTemplates);
  };

  const isEmptyTemplate = templates.some(
    (template: any) =>
      template.id === 0 && !template.link_name && !template.link_url
  );

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  }, [details]);
  const handleReorder = (newOrder: any) => {
    const reorderedTemplates = newOrder.map((template: any, index: number) => ({
      ...template,
      index: index,
    }));
    UpdateUserLinksIndex(reorderedTemplates, user_id, name);
    setTemplates(reorderedTemplates);
  };
  return (
    <>
      {isLoading ? (
        <div className="loader-container">
          <MyLinksSkeleton
            templateType={details === "grid" ? "grid" : "line"}
            count={3}
          />
        </div>
      ) : (
        // details === "grid" ? (
        //   <DragDropContext onDragEnd={onDragEnd}>
        //     <Droppable
        //       droppableId="template-list"
        //       direction="horizontal"
        //     >
        //       {(provided) => (
        //         <div
        //           className="creator-card-grid-wrp-subcomponents"
        //           {...provided.droppableProps}
        //           ref={provided.innerRef}
        //           style={{
        //             // display: 'grid',
        //             // gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        //             // gap: '1rem',
        //             // width: '100%',
        //             // minHeight: '200px',
        //           }}
        //         >
        //           {templates.map((card: any, index: number) => (
        //             <Draggable
        //               key={card?.id}
        //               draggableId={card?.id?.toString()}
        //               index={index}
        //             >
        //               {(provided, snapshot) => (
        //                 <div
        //                   ref={provided.innerRef}
        //                   {...provided.draggableProps}
        //                   {...provided.dragHandleProps}
        //                   style={{
        //                     ...provided.draggableProps.style,
        //                     // width: '200px',
        //                     transform: snapshot.isDragging ? provided.draggableProps.style?.transform : 'none',
        //                     zIndex: snapshot.isDragging ? 9999 : 'auto',
        //                   }}
        //                 >
        //                   <GridTemplate
        //                     card={card}
        //                     onRemove={handleRemoveTemplate}
        //                     username={username}
        //                     user_id={user_id}
        //                     componentName={name}
        //                     isDashboard={true}
        //                     refetch={refetch}
        //                     editIndex={editIndex}
        //                     setEditIndex={setEditIndex}
        //                     brand_color={brand_color}
        //                     templates={templates}
        //                     showDelete={!isEmptyTemplate}
        //                   />
        //                 </div>
        //               )}
        //             </Draggable>
        //           ))}
        //           {provided.placeholder}
        //         </div>
        //       )}
        //     </Droppable>
        //   </DragDropContext>
        // ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId="template-list"
            direction={details === "grid" ? "horizontal" : "vertical"}
          >
            {(provided) => (
              <>
                <div
                  className={
                    details === "grid"
                      ? "creator-card-grid-wrp-subcomponents"
                      : ""
                  }
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    // display: "grid",
                    // gridTemplateColumns: details === "grid" 
                    //   ? "repeat(auto-fill, minmax(200px, 1fr))"
                    //   : "1fr",
                    // gap: "1rem",
                    // width: "100%",
                    // maxWidth: "100%",
                    // overflowX: "hidden",
                  }}
                >
                  {templates.map((card: any, index: any) => (
                    <Draggable
                      key={card?.id}
                      draggableId={card?.id?.toString()}
                      index={index}
                      // isDragDisabled
                    >
                      {(provided, snapshot) => {
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...(details === "line"
                                ? {
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }
                                : {}),
                              ...provided.draggableProps.style,
                            }}
                            // className={details === "grid" ? `${gridStyle}` : ""}
                          >
                            {details === "grid" ? (
                              <>
                                <GridTemplate
                                  card={card}
                                  onRemove={handleRemoveTemplate}
                                  username={username}
                                  user_id={user_id}
                                  componentName={name}
                                  isDashboard={true}
                                  refetch={refetch}
                                  editIndex={editIndex}
                                  setEditIndex={setEditIndex}
                                  brand_color={brand_color}
                                  templates={templates}
                                  showDelete={!isEmptyTemplate}
                                />
                              </>
                            ) : (
                              <div
                                className={`links-container ${
                                  details === "grid"
                                    ? "grid-layout"
                                    : "line-layout"
                                }`}
                              >
                                <LineTemplate
                                  card={card}
                                  onRemove={handleRemoveTemplate}
                                  username={username}
                                  user_id={user_id}
                                  componentName={name}
                                  isDashboard={true}
                                  refetch={refetch}
                                  editIndex={editIndex}
                                  setEditIndex={setEditIndex}
                                  brand_color={brand_color}
                                  templates={templates}
                                  showDelete={!isEmptyTemplate}
                                />
                              </div>
                            )}
                          </div>
                        );
                      }}
                    </Draggable>
                  ))}

                  {provided.placeholder}
                </div>
                <div className="add-button-line-container">
                  <Popover
                    open={showPopover}
                    content="Please complete the current template before adding a new one"
                    placement="top"
                  >
                    <span>
                      <IoAddCircle
                        className="button-icon my-2"
                        size={45}
                        onClick={handleAddTemplate}
                      />
                    </span>
                  </Popover>
                </div>
              </>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
};

export default LinksComponentDashboard;

export const DeleteConfirmation = (props: any) => {
  const { setShowDeleteConfirm, setIsEditing, setEditIndex, handleDelete } =
    props;
  return (
    <div className="grid-template-delete-confirm">
      <div className="grid-template-delete-confirm-content">
        <p>Are you sure you want to delete this item?</p>
        <div className="grid-template-delete-confirm-actions">
          <Button
            onClick={() => {
              setShowDeleteConfirm(false);
              setEditIndex(undefined);
            }}
            className="grid-template-delete-confirm-cancel"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            className="grid-template-delete-confirm-delete"
          >
            Delete
          </Button>
        </div>
      </div>
    </div>
  );
};
