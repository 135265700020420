import { Button, Input, Modal, Upload, UploadProps } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { updateComponentSingle } from "../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import { supabase } from "../../../../../config/supabaseClient";
import { debounce } from "lodash";
import { handleProductFileUpload } from "../../../../../Utils/uploadImage/UploadImage";

const EditCustomBanner = (props: any) => {
  const {
    isBannerEditModalOpen,
    user_id,
    username,
    type,
    component,
    setSelectedComponent,
  } = props;
  const [name, setName] = useState(component?.name || "");
  const [link, setLink] = useState(component?.details?.split(",")[1] || "");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [cta, setCTA] = useState(component?.cta_placeholder);

  const [imageUrl, setImageUrl] = useState(
    component?.details?.split(",")[0] || ""
  );
  const [fileList, setFileList] = useState<any[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  useEffect(() => {
    setName(component?.name);
    setLink(component?.details?.split(",")[1]);
    setImageUrl(component?.details?.split(",")[0]);
    setCTA(component?.cta_placeholder);
  }, [component]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [errorInputMsg, setErrorInputMsg] = useState("");
  useEffect(() => {
    setIsButtonDisabled(!name || !cta || !link || !imageUrl || errorInputMsg?.length>0);
  }, [name, link, imageUrl,errorInputMsg]);
  const handleOk = async () => {
    setConfirmLoading(true);
    const res = await updateComponentSingle(
      name,
      component,
      [imageUrl, link].toString(),
      username,
      user_id,
      cta,
      "banner"
    );
    if (res === "true") {
      props.refetch();
      props.setIsBannerEditModalOpen(false);
      setName("");
      setLink("");
      setImageUrl("");
      setErrorMsg("");
      setCTA("");
      setSelectedComponent("");
      setConfirmLoading(false);

    } else {
      setErrorMsg("Component with this name already exists.");
      setConfirmLoading(false);

    }

  };

  const handleCancel = () => {
    props.setIsBannerEditModalOpen(false);
    setName("");
    setLink("");
    setImageUrl("");
    setErrorMsg("");
    setCTA("");
    setSelectedComponent("");
    setFileList([])
  };

  // const handleInputChange = (value: string, setter: Function) => {
  //   setter(value);
  // };

  const handleInputChange = (value: string, setter: React.Dispatch<React.SetStateAction<string>>, limit: number) => {
    if (limit && value.length <= limit) {
      setErrorInputMsg("")
      setter(value);
    } else {
      setter(value);
      limit === 15 ? setErrorInputMsg(`Button Text is limited to ${limit} characters.`) : setErrorInputMsg(`Title Text is limited to ${limit} characters.`)
      // message.warning(`Input is limited to ${limit} characters.`);
    }
  };

  const debouncedUpload = useCallback(
    debounce(async (info) => {
      const { file } = info;

      // Prevent multiple uploads
      if (isUploading) {
        return;
      }

      // Set uploading flag
      setIsUploading(true);

      try {
        await handleProductFileUpload({
          info,
          username,
          productName: "",
          setFileList,
          setProductImage: setImageUrl,
          storageName: "banner_section"
        });
      } finally {
        // Reset uploading flag after completion
        setIsUploading(false);
      }
    }, 300), // Debounce with 300ms delay
    [isUploading, username, supabase]
  );


  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    accept: "image/*",
    async onChange(info) {
      setFileList([...info.fileList]);
      debouncedUpload(info);
    },
  }

  return (
    <>
      <Modal
        open={isBannerEditModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Update"
        data-textid="edit-banner"
        confirmLoading={confirmLoading}
        okButtonProps={{ disabled: (!name || name?.length > 150 || !cta || cta?.length > 15 || !link || !imageUrl) }}
      >
        <div className="title">
          <h5>
            Editing {name}
          </h5>

          <div className="preview">
            <div className="banner-wrapper">
              <div className="previewHeading">{name}</div>
              <div>
                <Button
                  className="ant-btn css-dev-only-do-not-override-1nimcbs ant-btn-primary mw-250 banner-button-primary"
                >
                  {cta}
                </Button>
              </div>
            </div>
            <div className="previewImage">
              <img
                src={imageUrl}
              />
            </div>
          </div>

          <div className="fieldGroup">
            <label
              className="bold-label"
            >
              Banner Title
            </label>
            <Input
              placeholder="Enter Title"
              id="edit-banner-title"
              value={name}
              onChange={(e: any) => {
                handleInputChange(e.target.value, setName, 150);
                setErrorMsg("");
              }}
              className="mb-20"
            />
            {errorInputMsg && errorInputMsg.includes("Title") && <p className="errorMsg">{errorInputMsg}</p>}
            <label
              className="bold-label"
            >
              Button Text
            </label>
            <Input
              placeholder="Enter Button Text"
              id="edit-banner-button"
              value={cta}
              onChange={(e: any) => handleInputChange(e.target.value, setCTA, 15)}
              className="mb-20"
            />
            {errorInputMsg && errorInputMsg.includes("Button") && <p className="errorMsg">{errorInputMsg}</p>}
            <label
              className="bold-label"
            >
              Button Link
            </label>
            <Input
              placeholder="www.yourlink.com"
              id="edit-banner-link"
              value={link}
              onChange={(e: any) => setLink(e.target.value)}
              className="mb-20"
            />

            <div
              className="uploadDiv"
            >
              <Upload accept="image/*" {...uploadProps} multiple={false} maxCount={1} showUploadList={fileList.length === 0 ? false : {showRemoveIcon: false,}}>
                <Button
                  className="uploadButton"
                  disabled={!name}

                >
                  <div className="text-center">
                    <p
                      className="textContainerHeading"
                    >
                      Click to select image or drag & drop to this area to upload
                    </p>
                    <p
                      className="upload-text"
                    >
                      {" "}
                      JPG, JPEG, PNG, HEIC, or WEBP
                    </p>
                  </div>
                </Button>
              </Upload>
            </div>
          </div>
          {errorMsg && <p className="errorMsg">{errorMsg}</p>}
        </div>
      </Modal>
    </>
  );
};

export default EditCustomBanner;
