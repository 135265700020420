import Skeleton from "react-loading-skeleton";
import React from 'react';

function LoadingPost() {
    return (
      <div className="skeletonDiv">
        <Skeleton className="skeletonStyle" />
      </div>
    );
 
}

export default LoadingPost