import { Button, Input, Spin, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { changeSearchBarText } from "../../Utils/SupabaseServices/UserDatabase";
import { BsInfoCircle, BsSearch } from "react-icons/bs";
import { updateSearchbarToggle } from "../../Utils/SupabaseServices/ControlsDatabase";
import { useLoadSearchBarTags } from "../../Utils/customHooks/useLoadSearchBarTags";
import { useRotatingPlaceholder } from "../Search/useRotatingPlaceholder";
import SearchBarTags from "./SearchBarTags";

const SearchBarText = (props: any) => {
  const {
    isLoading,
    userData,
    controls,
    controlsLoading,
    refetchControls,
    refetch,
    user_id,
    searchBarText,
    username,
  } = props;
  const [isEdit, setIsEdit] = useState(false);
  const { isLoading: tagsLoading, data } = useLoadSearchBarTags(username);

  const [newSearchName, setNewSearchName] = useState("");

  const [errorMsg, setErrorMsg] = useState("");

  const handleUpdate = async () => {
    await changeSearchBarText(userData?.user_id, newSearchName).then(() => {
      props.refetch();
      setIsEdit(false);
    });
  };
  const handleCancel = () => {
    setIsEdit(false);
  };
  const toggleSearchbar = async (checked: boolean) => {
    const toggle = checked;
    updateSearchbarToggle(user_id, toggle).then(() => {
      refetchControls();
    });
  };
  const placeholder = useRotatingPlaceholder(username, searchBarText);


  return (
    <>
      <div className="dashboard-grid-items-grid">
        <div className="dashboard-grid-item" id="show-hashtags">
          <p>
            Search Bar Presentation{" "}
            <BsInfoCircle
              className="info-tooltip"
              data-tooltip-id="showSearchbar"
            />
          </p>
          <Switch
            style={{
              backgroundColor: controls?.showSearchbar
                ? "green"
                : "rgb(241, 98, 98)",
            }}
            checked={controls?.showSearchbar}
            onChange={toggleSearchbar}
          // disabled={!topHashtags}
          />
        </div>
      </div>
      <div className="account-social-logins-heading">
        <h5>Search Bar Preview </h5>
      </div>
      <div className="account-social-content-connect">

        <div className="search-container-div mt-3">
          <input
            type="text"
            id="searchInput"
            placeholder={placeholder}
            className="search-wrapper-input slidingVertical" // Add slidingVertical class
            autoComplete="off"
          />

          <button className="search-wrapper-btn">
            <BsSearch />
          </button>
        </div>

        <div className="dashboard-grid-item searchbar-configure">
          <div>
            <div className="account-social-logins-heading">
              <h6>Placeholder Text </h6>
            </div>
            {isLoading ? (
              <div className="spin-integration">
                <Spin />
              </div>
            ) : (
              <>
                {userData?.name ? (
                  <>
                    <div className="">
                      <React.Fragment>
                        <div className="account-social-link-connect-row pb-0">
                          <div className="account-social-link-connect-row-cont searchbar">
                            {isEdit ? (
                              <Input
                                value={newSearchName}
                                onChange={(e: any) =>
                                  setNewSearchName(e.target.value)
                                }
                              />
                            ) : (
                              <p className="m-1 m-sm-0 searchbar-placeholder-text">
                                {userData?.search_bar_text
                                  ? userData?.search_bar_text
                                  : `Search ${userData?.name}'s Page`}
                              </p>
                            )}
                            <>
                              {!isEdit ? (
                                <Button
                                  className="search-btn-style"
                                  onClick={() => {
                                    setNewSearchName(
                                      userData?.search_bar_text
                                        ? userData?.search_bar_text
                                        : `Search ${userData?.name}'s Page`
                                    );
                                    setIsEdit(true);
                                  }}
                                >
                                  Edit
                                </Button>
                              ) : (
                                <>
                                  <Button
                                    className="search-btn-style"
                                    onClick={() => {
                                      // setIsEdit(false);
                                      handleUpdate();
                                    }}
                                    disabled={!newSearchName}
                                  >
                                    Save
                                  </Button>
                                  <Button
                                    className="search-btn-style"
                                    onClick={() => {
                                      // setIsEdit(false);
                                      handleCancel();
                                    }}
                                  //  disabled={!newSearchName}
                                  >
                                    Cancel
                                  </Button>
                                </>
                              )}
                            </>
                          </div>
                        </div>

                        {/* <hr
                      className="hr-style"
                    /> */}
                      </React.Fragment>
                    </div>
                  </>
                ) : (
                  <div className="account-social-content-connect account-style"></div>
                )}
              </>
            )}{" "}
          </div>
          <hr className="w-100" />
          <SearchBarTags
            searchBarText={
              userData?.search_bar_text
                ? userData?.search_bar_text
                : `Search ${userData?.name} Content`
            }
            username={userData?.username}
            isLoading={isLoading}
            userData={userData}
            refetch={refetch}
          />
        </div>


      </div>



    </>
  );
};

export default SearchBarText;
