import React, { useState } from "react";
import { Radio, Typography } from "antd";

const { Title } = Typography;
const CarouselOrderControl = (props: any) => {
  const { order, setOrder } = props;
  const handleOrderChange = (e: any) => {
    setOrder(e.target.value);
  };

  return (
    <div className="mb-3">
      <Title level={4}>Content Order</Title>
      <Radio.Group onChange={handleOrderChange} value={order}>
        <Radio value="DESC">Newest to Oldest</Radio>
        <Radio value="ASC">Oldest to Newest</Radio>
      </Radio.Group>
    </div>
  );
};

export default CarouselOrderControl;
