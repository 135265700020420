import {
  Avatar,
  Button,
  Card,
  Divider,
  message,
  Modal,
  Spin,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import "./CreatorProfile.css";
import { UserOutlined } from "@ant-design/icons";

import React, { useCallback, useEffect, useRef, useState } from "react";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import LiveMenu from "../Header/LiveMenu";
import { BsInstagram, BsPinterest, BsSearch, BsYoutube } from "react-icons/bs";
import { IoLogoTiktok, IoMailOutline } from "react-icons/io5";
import {
  MdContactMail,
  MdOutlineContactMail,
  MdOutlinePodcasts,
} from "react-icons/md";
// import React, { useCallback, useEffect, useRef, useState } from "react";
import track, { TRACKING_TYPES } from "../../Utils/SupabaseServices/Track";
import {
  FaAmazon,
  FaFacebookSquare,
  FaSpotify,
  FaApple,
  FaSnapchatGhost,
  FaLinkedin,
} from "react-icons/fa";
import { RiShoppingBagFill } from "react-icons/ri";
import { HiLink } from "react-icons/hi";
import { IoMdHome } from "react-icons/io";
import { supabase } from "../../config/supabaseClient";
import EditBio, {
  EditType,
} from "../../Screens/DashboardView/StayUpToDate/StayUpToDateComponents/EditTemplates/EditBio";
import { updateProfileNameDescription } from "../../Utils/SupabaseServices/UserDatabase";
import ActiveSocialLinks from "../SocialLinks/ActiveSocialLinks";
import { handleProductFileUpload } from "../../Utils/uploadImage/UploadImage";
import { debounce } from "lodash";
import { GrFormEdit } from "react-icons/gr";
// import { ColorModal } from "../ColorModal/ColorModal";
// import { useLoadControls } from "../../Utils/customHooks/useLoadControls";
// import SocialLinksComponent from "../../Screens/DashboardView/ManageLinks/TabComponents/SocialLinksComponent"

const CreatorLandingProfile = (props: any) => {
  const {
    userDetails,
    showIcons,
    liveView,
    username,
    showSocialLinks,
    socialLinks,
    description,
    desiredOrder,
    isUserDashboard
   } = props;
  const { name, picture, user_id, brand_color, brand_text_color } = userDetails;

  const [fileList, setFileList] = useState<UploadFile[]>([
    {
      uid: "-1",
      name: `${props.username}`,
      status: "done",
      url: `${picture}` || "/assets/userhead.png",
    },
  ]);
  const [imageUrl, setImageUrl] = useState(picture || "/assets/userhead.png");
  const [isModalVisible, setModalVisible] = useState(false);
  const [editType, setEditType] = useState<EditType>("name");
  const [isImageLoaded, setIsImageLoaded] = useState(false); // Track when the image has loaded

  const [value, setValue] = useState({ name: name, description: description });
  const [isUploading, setIsUploading] = useState(false);

  const convertToClickable = (text: string) => {
    const urlPattern = /(https?:\/\/\S+)/g;

    const phonePattern = /\b\d{10}\b/g;

    const processedPhoneNumbers = new Set<string>();

    const textWithUrls = text?.replace(
      urlPattern,
      (url, index) =>
        `<a key=${index} href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`
    );

    const textWithClickablePhoneNumbers = textWithUrls?.replace(
      phonePattern,
      (phoneNumber, index) => {
        if (!processedPhoneNumbers.has(phoneNumber)) {
          processedPhoneNumbers.add(phoneNumber);
          return `<a key=${index} href="tel:${phoneNumber}">${phoneNumber}</a>`;
        } else {
          return phoneNumber;
        }
      }
    );

    return React.createElement("div", {
      dangerouslySetInnerHTML: { __html: textWithClickablePhoneNumbers },
    });
  };

  const beforeUpload = (file: any) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      message.error(
        <div style={{ color: "black" }}>You can only upload image files!</div>
      );
    }
    return isImage || Upload.LIST_IGNORE;
  };

  const handleOpenModal = () => {
    // setEditType(type);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleSubmit = async (newValue: {
    name: string;
    description: string;
  }) => {
    setValue(newValue);

    await updateProfileNameDescription(username, newValue);

    props.refetch();
    props?.refetchUser();
  };

  const debouncedUpload = useCallback(
    debounce(async (info) => {
      const { file } = info;

      // Prevent multiple uploads
      if (isUploading) {
        return;
      }

      setIsUploading(true);
      setIsImageLoaded(false); // Reset image loaded state

      try {
        const newUrl = await handleProductFileUpload({
          info,
          username,
          productName: "",
          setFileList,
          setProductImage: setImageUrl,
          storageName: "profile-picture",
        });
        await supabase
          .from("user")
          .update({
            picture: newUrl?.publicUrl || imageUrl,
            storage_url: newUrl?.publicUrl || imageUrl,
          })
          .eq("username", username);
        //   setImageUrl(newUrl?.publicUrl || imageUrl); // Set the new image URL

        props.refetch();
        props?.refetchUser();
      } finally {
        setIsUploading(false);
      }
    }, 50), // Debounce with 300ms delay
    [isUploading, username, supabase]
  );

  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    accept: "image/*",
    async onChange(info) {
      setFileList([...info.fileList]);
      debouncedUpload(info);
    },
  };

  useEffect(() => {
    setValue(prevValue => ({
      ...prevValue,
      name: name,
      description: description
    }));
  }, [name, description]);

  return (
    <div className="creator-profile-wrapper">
      <div className="grid-display">
        <div></div>
        {!isUploading ? (
          <>
            {imageUrl ? (
              <div className="profile-image">
                <img
                  src={imageUrl ? imageUrl : "/assets/userhead.png"}
                  className="creator-profile-img"
                  alt="name"
                  id="creator-profile-img"
                />
                {isUserDashboard && (
                  <Upload
                    {...uploadProps}
                    showUploadList={false} // Hide default file list UI
                    // onChange={handleChange}
                    fileList={fileList ? fileList : []}
                    beforeUpload={beforeUpload}
                  >
                    <span className="edit-profile">
                      <GrFormEdit />
                    </span>
                  </Upload>
                )}
              </div>
            ) : (
              <Avatar size={100} icon={<UserOutlined />} />
            )}
          </>
        ) : (
          <div className="profile-image">
            <div className="creator-profile-img-loading-div">
              <Spin />
            </div>
          </div>
          //   <Avatar size={100} icon={<UserOutlined />} />
        )}
      </div>

      <fieldset className="userdashboard-profile">
        <legend>
          {" "}
          {name}{" "}
          <span className="edit-name" onClick={handleOpenModal}>
            <GrFormEdit />
          </span>
        </legend>
        <p
          className="creator-dashboard-profile-description"
          style={{ whiteSpace: "pre-line" }}
        >
          {convertToClickable(description)}
        </p>
      </fieldset>
      <div
        className="creator-profile-social-links social-link"
        style={{ border: "none" }}
        onClick={() => {}}
      >
        <ActiveSocialLinks
          defaultTextColor={brand_text_color}
          defaultColor={brand_color}
          isUserDashboard={isUserDashboard}
        />
      </div>
      <EditBio
        visible={isModalVisible}
        onClose={handleCloseModal}
        onSubmit={handleSubmit}
        initialValue={value}
      />
    </div>
  );
};

export default CreatorLandingProfile;
