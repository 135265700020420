import React from "react";
import ImagePostCarouselTag from "../GeneralPostTag/ImagePostCarouselTag";
import ImagePostTag from "../GeneralPostTag/ImagePostTag";
import VideoPostTag from "../GeneralPostTag/VideoPostTag";

const DashboardCarouselStructure = (props: any) => {
  const {
    link,
    index,
    handleShop,
    title,
    brand_color,
    isLinks,
    productName,
    contentShape,
    contentSound,type
  } = props;
  return (
    <React.Fragment key={index + title}>
      {link.media_url ? (
        link.media_url.split(",").length > 1 &&
        link.media_url.split(",").every((url: any) => url.trim() !== "") ? (
          <>
            <ImagePostCarouselTag
              post={link}
              handleRedirect={handleShop}
              title={title}
              brand_color={brand_color}
              isLinks={isLinks}
              isDashboard={true}
              isHasMediaUrl={link.type === "FEED" ? true : false}
              contentShape={contentShape}
              productName={productName}
              isSoundOn={contentSound}
              type={type}
            />
          </>
        ) : (
          <>
            {link.product_image && link.product_image !== "" ? (
              <>
                <ImagePostTag
                  data={link}
                  handleRedirect={handleShop}
                  title={title}
                  brand_color={brand_color}
                  isLinks={isLinks}
                  isDashboard={true}
                  isHasMediaUrl={link.type === "FEED" ? true : false}
                  contentShape={contentShape}
                  productName={productName}
                  type={type}
                />
              </>
            ) : (
              <>
                {link.media_url.includes("video") ? (
                  <>
                    <VideoPostTag
                      post={link}
                      handleRedirect={handleShop}
                      title={title}
                      brand_color={brand_color}
                      isLinks={isLinks}
                      isDashboard={true}
                      thumbnail={link.thumbnail}
                      isHasMediaUrl={link.type === "FEED" ? true : false}
                      contentShape={contentShape}
                      productName={productName}
                      isSoundOn={contentSound}
                      type={type}
                    />
                  </>
                ) : (
                  <>
                    <ImagePostTag
                      data={link}
                      handleRedirect={handleShop}
                      title={title}
                      brand_color={brand_color}
                      isLinks={isLinks}
                      isDashboard={true}
                      isHasMediaUrl={link.type === "FEED" ? true : false}
                      contentShape={contentShape}
                      productName={productName}
                      type={type}
                    />
                  </>
                )}
              </>
            )}
          </>
        )
      ) : (
        <>
          <ImagePostTag
            data={link}
            handleRedirect={handleShop}
            title={title}
            brand_color={brand_color}
            isLinks={isLinks}
            isDashboard={true}
            isHasMediaUrl={link.type === "FEED" ? true : false}
            contentShape={contentShape}
            productName={productName}
            type={type}
          />
        </>
      )}
    </React.Fragment>
  );
};

export default DashboardCarouselStructure;
