import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal, Form, Checkbox, message, Input } from "antd";
import { supabase } from "../../config/supabaseClient";
import { debounce } from "lodash";
import { generateRandom12DigitNumber } from "../../Utils/SupabaseServices/ContentDatabase";

interface ContactMeModalProps {
  creatorId: string;
  onClose: () => void;
  isContactModalVisible: boolean;
  username: string;
}

const fieldOptions = ["Name", "Email", "Phone", "Instagram", "Message"];

const ContactMeModal: React.FC<ContactMeModalProps> = ({
  creatorId,
  onClose,
  isContactModalVisible,
  username,
}) => {
  const [form] = Form.useForm();
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchContactPageData = async () => {
      if (!isContactModalVisible) return;

      try {
        const { data, error } = await supabase
          .from("contact_page")
          .select("*")
          .eq("user_id", creatorId)
          .single();

        if (error && error.code !== "PGRST116") {
          throw error;
        }

        if (data) {
          // Prefill the form fields
          const {
            field_config,
            creator_mail,
            image_url,
            title_contact_text,
            button_contact_text,
          } = data;

          const isEmptyConfig =
            !field_config?.fields ||
            Object.keys(field_config.fields).length === 0;
          const isAllEmpty =
            isEmptyConfig &&
            !creator_mail &&
            !image_url &&
            !title_contact_text &&
            !button_contact_text;

         
          const selectedFields = Object.keys(field_config?.fields || {}).filter(
            (key) => field_config.fields[key] === true
          );

          form.setFieldsValue({
            fields: selectedFields,
            creatorMail: creator_mail || "",
            titleText: title_contact_text || "",
            buttonText: button_contact_text || "",
            imageUrl: image_url || "",
          });

          setImageUrl(image_url || null);
        }
      } catch (error) {
        console.error("Error fetching contact page data:", error);
        message.error("Failed to load contact page data");
      }
    };

    fetchContactPageData();
  }, [isContactModalVisible, creatorId, form]);

  const handleSaveConfiguration = async () => {
    try {
      const values = form.getFieldsValue();

      // Add check for empty fields
      const isEmpty =
        !values.fields?.length &&
        !values.creatorMail &&
        !values.titleText &&
        !values.buttonText &&
        !imageUrl;

      if (isEmpty) {
        const { data } = await supabase
          .from("user")
          .update({ show_contact_page: false })
          .eq("user_id", creatorId);
      }else{
        const { data } = await supabase
        .from("user")
        .update({ show_contact_page: true })
        .eq("user_id", creatorId);
      }

      const fieldConfig = {
        fields: values.fields?.reduce(
          (acc: Record<string, boolean>, field: any) => ({
            ...acc,
            [field]: true,
          }),
          {}
        ),
      };

      const { data: existingData, error: fetchError } = await supabase
        .from("contact_page")
        .select("id")
        .eq("user_id", creatorId)
        .single();

      if (fetchError && fetchError.code !== "PGRST116") {
        throw fetchError;
      }

      if (existingData) {
        // Update the existing record
        const { error: updateError } = await supabase
          .from("contact_page")
          .update({
            field_config: fieldConfig,
            image_url: imageUrl,
            creator_mail: values.creatorMail,
            title_contact_text: values.titleText,
            button_contact_text: values.buttonText,
          })
          .eq("id", existingData.id);

        if (updateError) {
          throw updateError;
        }

      
   
        message.success("Contact page configuration updated successfully");
      } else {
        let randomNumber = generateRandom12DigitNumber();
        const { error } = await supabase.from("contact_page").insert({
          id: randomNumber,
          user_id: creatorId,
          field_config: fieldConfig,
          image_url: imageUrl,
          creator_mail: values.creatorMail,
          title_contact_text: values.titleText,
          button_contact_text: values.buttonText,
        });

        if (error) {
          throw error;
        }
        const { data } = await supabase
          .from("user")
          .update({ show_contact_page: true })
          .eq("user_id", creatorId);
        message.success("Contact page configured successfully");
      }
      const { error: contactError } = await supabase
        .from("user")
        .update({ is_contact_me: true })
        .eq("user_id", creatorId);

      form.resetFields();
      onClose();
    } catch (error) {
      console.error(error);
      message.error("Failed to save configuration");
    }
  };

  const onCancel = () => {
    form.resetFields();
    setImageUrl(null);
    onClose();
  };

  const handleReset = () => {
    form.resetFields();
    setImageUrl(null);
    form.setFieldsValue({
      fields: [],
      creatorMail: "",
      titleText: "",
      buttonText: "",
    });
  };

  return (
    <Modal
      title="Configure Contact Page"
      open={isContactModalVisible}
      onCancel={onCancel}
      footer={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            key="reset"
            onClick={handleReset}
            style={{
              backgroundColor: "black",
              color: "white",
            }}
          >
            Reset
          </Button>
          <div>
            <Button key="cancel" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              onClick={handleSaveConfiguration}
              style={{ marginLeft: 8 }}
            >
              Save
            </Button>
          </div>
        </div>
      }
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="fields"
          label="Select Fields"
          rules={[
            {
              validator: (_, value) => {
                // Ensure 'Email' and 'Message' are selected
                const requiredFields = ["Email", "Message"];
                const missingFields = requiredFields.filter(
                  (field) => !value?.includes(field)
                );
                if (missingFields.length > 0) {
                  return Promise.reject(
                    `Please select the following required fields: ${missingFields.join(
                      ", "
                    )}`
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Checkbox.Group options={fieldOptions} />
        </Form.Item>
        <Form.Item
          name="creatorMail"
          label="Enter your email"
          rules={[
            { required: true, message: "Email is required" },
            { type: "email", message: "Please enter a valid email" },
          ]}
        >
          <Input placeholder="Enter your email" type="email" />
        </Form.Item>
        <Form.Item
          name="titleText"
          label="Title Text"
          // rules={[
          //     { required: true, message: 'Title text is required' },
          // ]}
        >
          <Input placeholder="Enter the title text for your contact page" />
        </Form.Item>
        <Form.Item
          name="buttonText"
          label="Button Text"
          // rules={[
          //     { required: true, message: 'Button text is required' },
          // ]}
        >
          <Input placeholder="Enter the button text for your contact page" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ContactMeModal;
