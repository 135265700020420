import React from "react";

const ProductName = (props: any) => {
  const { type, post, title } = props;
  const { product_name, content_title, shop_title, table_name } = post;
  return (
    <>
      {type === "content" || type === "query-content" ? (
        content_title ? (
          <p className="product-name">{content_title}</p>

        ) : (
          // <p className="product-name">{product_name}</p>
          null
        )
      ) : type === "MAIL" ? (
        content_title ? (
          <p className="product-name">{content_title}</p>
        ) : (
          <p className="product-name">{product_name}</p>
        )
      ) : type === "product-bank" ? (
        shop_title ? (
          <p className="product-name">{shop_title}</p>
        ) : (
          <p className="product-name">{table_name}</p>
        )
      ) : post?.shop_title ? (
        <p className="product-name">{shop_title}</p>
      ) : post?.content_title ? (
        <p className="product-name">{content_title}</p>
      ) : post?.product_name ? (
        <p className="product-name">{product_name}</p>
      ) : null}{" "}
    </>
  );
};

export default ProductName;
