import { useQuery } from "react-query";
import { supabase } from "../../../../../../config/supabaseClient";

interface Link {
  id: string;
  link_name: string;
  image_url: string;
  link_url: string;
  parent_id: string | null;
  type: string;
  updated_at: string | null;
}

interface Collection {
  id: string;
  collection_name: string;
  links: Link[];
}

export function useLoadUserLinks(user_id: any, collection_name: any) {
  const fetchCollectionsWithLinks = async () => {
    // Fetch all collections
    const { data: collections, error: collectionsError } = await supabase
      .from("user_links")
      .select("*")
      .eq("collection_name", collection_name)
      .eq("user_id", user_id)
      .order("index");

    if (collectionsError) {
      throw new Error(collectionsError.message);
    }

    // For each collection, fetch its associated links

    return collections;
  };

  return useQuery(
    ["userLinks", user_id, collection_name],
    fetchCollectionsWithLinks,
    {
      refetchOnWindowFocus: false,
    }
  );
}
