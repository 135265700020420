import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import Header from "../../../components/Header/header";
import RouteList from "./RouteList";
import ImageSidebar from "../../../components/Header/ImageSidebar";
import { isMobile } from "react-device-detect";
import Account from "../Account/account";
import { fetchUser } from "../../../Utils/SupabaseServices/UserDatabase";
import AnalyticsTabs from "../Analytics/analyticsTab";
import { TabTitle } from "../../../Utils/GeneralFunctions";
import ControlsTab from "../Controls/controlsTab";
import DashboardSkeleton from "../../../components/skeleton/DashboardView/dashboardSkeleton";
import CopyToClipboard from "../../../components/CopyToClipboard/CopyToClipboard";
import track, { TRACKING_TYPES } from "../../../Utils/SupabaseServices/Track";
import { useAuth } from "../../../hooks/useAuth";
import { useLoadUser } from "../../../Utils/customHooks/useLoadUser";
import ManageLinks from "../ManageLinks/ManageLinks";
import StayUpToDateControls from "../StayUpToDate/StayUpToDateControls";
import ProductBank from "../ProductBank/ProductBank";
import FeedNew from "../Feed new/FeedNew";
import SocialIntegration from "../SocialIntegration/SocialIntegration";
import { useLoadCompletePage } from "../../../Utils/customHooks/useLoadCompletePage";

const Dashboard = () => {
  const { username } = useParams();
  const { user } = useAuth();
  const user_id = user?.id || "";
  const location = useLocation();
  TabTitle(`${username} • Bubble`);
  const [option, setOption] = useState<any>("Analytics");

  useEffect(() => {
    const handleStorageChange = () => {
      const emailData = localStorage.getItem("email_verify");
      const phoneData = localStorage.getItem("phone_verify");
      if (location.pathname.includes("productbank")) {
        setOption("Product Bank");
      } else {
        // Check if either email_verify or phone_verify is "false"
        if (emailData === "false" || phoneData === "false") {
          // setOption("Account");
        } else if (!emailData && !phoneData) {
          // Check if both email_verify and phone_verify are not present or have truthy values
          // setOption("Analytics");
        }
      }
    };

    // Add event listener for storage changes
    window.addEventListener("storage", handleStorageChange);

    // Initial setup (run once)
    handleStorageChange();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [window]);

  const [isEmail, setIsEmail] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isName, setIsName] = useState(false);
  const [isPhone, setIsPhone] = useState(false);
  const [isPhoneEmpty, setIsPhoneEmpty] = useState(false);
  const [tab, setTab] = useState<any>();
  const [isEmailVerified, setIsEmailVerified] = useState<any>();

  useEffect(() => {
    fetchEmail();
  }, [isEmail, isName]);
  // useEffect(() => {
  //   // updateDashboardCount(username);
  // }, []);
  useEffect(() => {
    track(location.pathname, TRACKING_TYPES.PAGE, {
      pathname: location.pathname,
      title: "Dashboard",
      user_id: user_id,
    });
  }, []);

  // const { isLoading: userLoading, data: userData }: any = useLoadUser(username);

  async function fetchEmail() {

    const userData = await fetchUser(username);
    if (userData) {
      if (userData.email !== "" && userData.name !== "") {
        setIsName(true);
        setIsEmail(true);
        setIsPhone(true);
        setLoading(false);
        setIsEmailVerified(true);
      } else {
        if (userData.email !== "") {
          setIsEmail(true);
        } else if (userData.name !== "") {
          setIsName(true);
        } else {
          setIsEmail(false);
          setIsName(false);
        }
        setOption("Account");
        setTab(0);
        setLoading(false);
      }
    }
  }

  const handleClick = (option: any) => {
    setOption(option);
  };

  const { isLoading: userLoading, data: userData, refetch }: any = useLoadUser(username);
  const {
    isLoading,
    data:creatorData,
    error,
    refetch: refetchUserDetail,
  }: any = useLoadCompletePage(username, "follower");
  return (
    <div className="dashboard">
      <Header
        extraComponent={
          <div
            className="extra-component"
          >
            <CopyToClipboard
              textToCopy={`https://www.searchbubble.com/${username}`}
            />
            <ImageSidebar
              username={username as string}
              isDashboard={true}
              pictureUrl={userData?.picture}
            />
          </div>
        }
      />

      {!isMobile ? (
        <div className="content-container">
          <div className="row">
            <h1 className="dashboard-mobile-show">Dashboard</h1>
            {loading ? (
              <DashboardSkeleton />
            ) : (
              <>

                <div id="12" className="left-panel box">
                  <RouteList
                    handleClick={handleClick}
                    isEmail={isEmail}
                    isName={isName}
                    isPhone={isPhone}
                    isPhoneEmpty={isPhoneEmpty}
                    tab={tab}
                    isEmailVerified={isEmailVerified}
                    email_verify={userData?.email_verify}
                    option={option}
                  />
                </div>
                <div className="right-panel box">
                  {option ? (
                    option === "Account" ? (
                      <Account
                        username={username}
                        setIsEmail={setIsEmail}
                        setIsName={setIsName}
                        setIsPhone={setIsPhone}
                        setIsPhoneEmpty={setIsPhoneEmpty}
                        setIsEmailVerified={setIsEmailVerified}
                        subscription_type={userData?.subscription_type}
                      />
                    ) :
                      option === "Platform Integrations" ? (
                        <SocialIntegration
                          username={username}
                          user_id={userData}
                        />
                      )
                        : option === "Analytics" ? (
                          <AnalyticsTabs
                            username={username}
                            user_id={user_id}
                            subscription_type={userData?.subscription_type}
                            brand_color={userData?.brand_color}
                          />
                        ) : option.includes("Controls") ? (
                          <ControlsTab
                            refetch={refetch}
                            subscription_type={userData?.subscription_type}
                          />
                        ) : option.includes("Content") ? (
                          <FeedNew
                            subscription_type={userData?.subscription_type}
                          />
                        ) : option.includes("Links") ? (
                          <ManageLinks username={username} />
                        ) : option.includes("Landing Page") ? (
                          <StayUpToDateControls
                            username={username}
                            showSocials={userData?.showSocials}
                            myLinks={userData?.myLinks}
                            subscription_type={userData?.subscription_type}
                            user_id={user_id}
                            brand_color={userData?.brand_color}
                            isFlowdeskOn={userData?.is_flowdesk_on}
                            flowdeskId={userData?.flowdeskId}
                            isFlowdeskPopupOn={userData?.is_flowdeskpopup_on}
                            flowdeskPopupId={userData?.flowdeskPopupId}
                            isFilloutOn={userData?.is_fillout_on}
                            filloutId={userData?.filloutId}
                            isLandingPage={true}
                            refetchUser={refetch}
                            refetchUserDetail={refetchUserDetail}
                            creatorData={creatorData}
                            isLoading={isLoading}
                          />
                        )
                          : option.includes("Landing") ? (
                            <StayUpToDateControls
                              username={username}
                              showSocials={userData?.showSocials}
                              myLinks={userData?.myLinks}
                              subscription_type={userData?.subscription_type}
                              user_id={user_id}
                              brand_color={userData?.brand_color}
                              isFlowdeskOn={userData?.is_flowdesk_on}
                              flowdeskId={userData?.flowdeskId}
                              isFlowdeskPopupOn={userData?.is_flowdeskpopup_on}
                              flowdeskPopupId={userData?.flowdeskPopupId}
                              isFilloutOn={userData?.is_fillout_on}
                              filloutId={userData?.filloutId}
                              isLandingPage={false}
                              refetchUser={refetch}
                              refetchUserDetail={refetchUserDetail}
                              creatorData={creatorData}
                              isLoading={isLoading}
                            />
                          )
                            : option.includes("Product Bank") ? (
                              <ProductBank
                                username={username}
                                showSocials={userData?.showSocials}
                                myLinks={userData?.myLinks}
                                subscription_type={userData?.subscription_type}
                                user_id={user_id}
                                brand_color={userData?.brand_color}
                              />
                            ) : (
                              ""
                            )
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <>
          <div className="content-container">
            <div className="row">
              <h1 className="dashboard-mobile-show">Dashboard</h1>
              {loading ? (
                <DashboardSkeleton />
              ) : (
                <>
                  <div id="12" className="left-panel box">
                    <RouteList
                      handleClick={handleClick}
                      isEmail={isEmail}
                      isName={isName}
                      isPhone={isPhone}
                      isPhoneEmpty={isPhoneEmpty}
                      tab={tab}
                      isEmailVerified={isEmailVerified}
                      email_verify={userData?.email_verify}
                      option={option}
                    />
                  </div>

                  <div className="right-panel box">
                    {option ? (
                      option === "Account" ? (
                        <Account
                          username={username}
                          setIsEmail={setIsEmail}
                          setIsName={setIsName}
                          setIsPhone={setIsPhone}
                          setIsPhoneEmpty={setIsPhoneEmpty}
                          setIsEmailVerified={setIsEmailVerified}
                          subscription_type={userData?.subscription_type}
                        />
                      ) : option === "Platform Integrations" ? (
                        <SocialIntegration
                          username={username}
                          user_id={userData}
                          userLoading={userLoading}
                        />
                      )
                        : option === "Analytics" ? (
                          <AnalyticsTabs
                            username={username}
                            user_id={user_id}
                            subscription_type={userData?.subscription_type}
                            brand_color={userData?.brand_color}
                          />
                        ) : option.includes("Controls") ? (
                          <ControlsTab
                            refetch={refetch}
                            subscription_type={userData?.subscription_type}
                          />
                        ) : option.includes("Content") ? (
                          <FeedNew
                            subscription_type={userData?.subscription_type}
                          />
                        ) : option.includes("Links") ? (
                          <ManageLinks username={username} />
                        ) : option.includes("Landing Page") ? (
                          <StayUpToDateControls
                            username={username}
                            showSocials={userData?.showSocials}
                            myLinks={userData?.myLinks}
                            subscription_type={userData?.subscription_type}
                            user_id={user_id}
                            brand_color={userData?.brand_color}
                            isFlowdeskOn={userData?.is_flowdesk_on}
                            flowdeskId={userData?.flowdeskId}
                            isFlowdeskPopupOn={userData?.is_flowdeskpopup_on}
                            flowdeskPopupId={userData?.flowdeskPopupId}
                            isFilloutOn={userData?.is_fillout_on}
                            filloutId={userData?.filloutId}
                            isLandingPage={true}
                            refetchUser={refetch}
                            refetchUserDetail={refetchUserDetail}
                            creatorData={creatorData}
                            isLoading={isLoading}
                          />
                        ) : option.includes("Landing") ? (
                          <StayUpToDateControls
                            username={username}
                            showSocials={userData?.showSocials}
                            myLinks={userData?.myLinks}
                            subscription_type={userData?.subscription_type}
                            user_id={user_id}
                            brand_color={userData?.brand_color}
                            isFlowdeskOn={userData?.is_flowdesk_on}
                            flowdeskId={userData?.flowdeskId}
                            isFilloutOn={userData?.is_fillout_on}
                            filloutId={userData?.filloutId}
                            isFlowdeskPopupOn={userData?.is_flowdeskpopup_on}
                            flowdeskPopupId={userData?.flowdeskPopupId}
                            isLandingPage={false}
                            refetchUser={refetch}
                            refetchUserDetail={refetchUserDetail}
                            creatorData={creatorData}
                            isLoading={isLoading}
                          />
                        )

                          : option.includes("Product Bank") ? (
                            <ProductBank
                              username={username}
                              showSocials={userData?.showSocials}
                              myLinks={userData?.myLinks}
                              subscription_type={userData?.subscription_type}
                              user_id={user_id}
                              brand_color={userData?.brand_color}
                            />
                          ) : (
                            ""
                          )
                    ) : (
                      ""
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
