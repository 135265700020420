import { useState } from "react";
import "./PostCarousel.css";

export default function Caption(props: any) {
  const { caption } = props;
  const [showAll, setShowAll] = useState(false);
  const maxCharLength = 150;

  // Function to convert URLs and phone numbers to clickable links
  const convertToClickable = (text: string) => {
    // More specific URL pattern to avoid double matches
    const urlPattern = /(?:https?:\/\/)?[\w-]+(?:\.[\w-]+)+(?:[\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/g;
    const phonePattern = /\b\d{10}\b/g;
    const processedPhoneNumbers = new Set<string>();
    const processedUrls = new Set<string>();

    // First find all matches
    const parts = [];
    let lastIndex = 0;
    
    // Process URLs
    text.replace(urlPattern, (match, offset) => {
      if (!processedUrls.has(match)) {
        if (offset > lastIndex) {
          parts.push(text.slice(lastIndex, offset));
        }
        parts.push(match);
        processedUrls.add(match);
        lastIndex = offset + match.length;
      }
      return match;
    });
    
    // Add remaining text
    if (lastIndex < text.length) {
      parts.push(text.slice(lastIndex));
    }

    return parts.map((part, index) => {
      if (processedUrls.has(part)) {
        return (
          <a 
            key={index} 
            href={part.startsWith('http') ? part : `https://${part}`}
            target="_blank" 
            rel="noopener noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            {part}
          </a>
        );
      } else if (part.match(phonePattern) && !processedPhoneNumbers.has(part)) {
        processedPhoneNumbers.add(part);
        return (
          <a 
            key={index} 
            href={`tel:${part}`}
            onClick={(e) => e.stopPropagation()}
          >
            {part}
          </a>
        );
      }
      return part;
    });
  };

  return (
    <>
      {caption && caption.length > 0 && (
        <div
          className="posts-caption"
          id="post-caption"
          style={{ background: caption.trim().length ? "#fcfcfc" : "#fff" }}
        >
          {showAll || caption.length <= maxCharLength ? (
            convertToClickable(caption)
          ) : (
            <>
              {convertToClickable(caption.substring(0, maxCharLength))}
              <span
                className="posts-caption-more"
                onClick={() => {
                  setShowAll(true);
                }}
              >
                {!props.isNewsletter ? (
                  <>... see more</>
                ) : (
                  <button
                    className="posts-caption-more-btn"
                    onClick={() => props.setIsModalOpen(true)}
                  >
                    ... see more
                  </button>
                )}
              </span>
            </>
          )}
        </div>
      )}
    </>
  );
}
