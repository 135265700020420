import { supabase } from "../../config/supabaseClient";

export async function storeUserSearches(
  query: any,
  count: any,
  user_id: any,
  usage: any
) {
  const { data }: any = await supabase
    .from("searches")
    .select("*")
    .eq("query", query)
    .eq("user_id", user_id);

  if (data.length === 0) {
    await supabase
      .from("searches")
      .insert([
        {
          query,
          occurrences: count,
          user_id,
          clicked: 0,
          type: "hashtag",
          usage,
        },
      ])
      .single();
  }
}

export async function getPopularSearchesDateRange(
  username: any,

  max: any,
  startDate: any,
  endDate: any,
  user_id: any
) {
  const { data }: any = await supabase.rpc("get_popular_searches_date_rangee", {
    route_name: `/${username}`,
    start_date: startDate,
    end_date: endDate,
    max_res: max,
    userid: user_id,
  });
  if (data.length) {
    return data.map(
      (item: { count: number; query: string; name: string }, idx: number) => {
        if (
          item.query !== "" &&
          !item.query.includes("post_id") &&
          item.query !== "Top Hashtags" &&
          item.query !== "My Links"
        ) {
          return { ...item, id: idx + 1 };
        }
      }
    );
  }

  return [];
}

export async function getPopularSearches(
  user_id: any,
  username: any,
  max: any
) {
  let { data }: any = await supabase
    .from("analytics")
    .select("*")
    .eq("pathname", `/${username}`)
    .eq("type", "search")
    .eq("user_id", user_id)

    .range(0, max - 1);

  if (data && data.length > 0) {
    let hashtags: any = [];

    data.map((hash: any) => {
      const alreadyHashtagObj = hashtags.find(
        (obj: any) => obj.query === hash.details
      );

      if (alreadyHashtagObj) {
        const index = hashtags.findIndex(
          (obj: any) => obj.query === alreadyHashtagObj.query
        );
        hashtags.splice(index, 1);
        hashtags.push({ id: hash.id, query: hash.details });
      } else {
        hashtags.push({ id: hash.id, query: hash.details });
      }
    });

    return hashtags;
  } else {
    return false;
  }
}

export async function updateSearches(
  user_id: any,
  query: any,
  hashtagType: any
) {
  if (user_id) {
    let { data }: any = await supabase
      .from("searches")
      .select("*")
      .eq("user_id", user_id)
      .eq("query", `${query}`)
      .eq("type", hashtagType);

    if (data && data.length === 0) {
      await supabase
        .from("searches")
        .insert([
          {
            query,
            user_id: user_id,
            clicked: 1,
            occurrences: 1,
            type: hashtagType,
          },
        ])
        .single();
    } else {
      await supabase.rpc("increase_occurence", {
        userid: `${user_id}`,
        search: `${query}`,
        active: `${hashtagType}`,
      });
    }
  }
}

export async function getSearchbarSearchesCount(
  username: any,
  startDate: any,
  endDate: any,
  user_id: any
) {
  let { count } = await supabase
    .from("analytics")
    .select("*", { count: "exact", head: true })
    .eq("pathname", `/${username}`)
    .eq("name", "searchbar search")
    .eq("user_id", user_id)
    .lt("created_at", endDate)
    .gt("created_at", startDate);
  if (typeof count === "number") {
    return count;
  } else {
    return 0;
  }
}

export async function getHashtagSearchesCount(
  username: any,
  startDate: any,
  endDate: any,
  user_id: any
) {
  let { count } = await supabase
    .from("analytics")
    .select("*", { count: "exact", head: true })
    .eq("pathname", `/${username}`)
    .eq("name", "Top Hashtags search")
    .eq("user_id", user_id)
    .lt("created_at", endDate)
    .gt("created_at", startDate);
  if (typeof count === "number") {
    return count;
  } else {
    return 0;
  }
}

export async function getPopularSearchesCount(
  username: any,
  startDate: any,
  endDate: any,
  user_id: any
) {
  let { count }: any = await supabase
    .from("analytics")
    .select("*", { count: "exact", head: true })
    .eq("pathname", `/${username}`)
    .eq("name", "popular search")
    .eq("user_id", user_id)
    .lt("created_at", endDate)
    .gt("created_at", startDate);

  if (typeof count === "number") {
    return count;
  } else {
    return 0;
  }
}
export async function getInstagramHighlightsSearchesCount(
  username: any,
  startDate: any,
  endDate: any,
  user_id: any
) {
  let { count }: any = await supabase
    .from("analytics")
    .select("*", { count: "exact", head: true })
    .eq("pathname", `/${username}`)
    .eq("name", "Instagram Highlights search")
    .eq("user_id", user_id)
    .lt("created_at", endDate)
    .gt("created_at", startDate);

  if (typeof count === "number") {
    return count;
  } else {
    return 0;
  }
}
export async function getInstagramLinksSearchesCount(
  username: any,
  startDate: any,
  endDate: any,
  user_id: any
) {
  let { count }: any = await supabase
    .from("analytics")
    .select("*", { count: "exact", head: true })
    .eq("pathname", `/${username}`)
    .ilike("name", "%my links link click%")

    .neq("name", "my links link click")
    .neq("name", "My Links link click")
    .eq("user_id", user_id)
    .lt("created_at", endDate)
    .gt("created_at", startDate);
  if (typeof count === "number") {
    return count;
  } else {
    return 0;
  }
}

export async function getCustomCategoriesSearchesCount(
  username: any,
  customCategories: any,
  startDate: any,
  endDate: any,
  user_id: any
) {
  const copiedCategories = Array.isArray(customCategories)
    ? customCategories.map((category) => ({ ...category }))
    : [];

  if (copiedCategories) {
    for (let category of copiedCategories) {
      let { count }: any = await supabase
        .from("analytics")
        .select("*", { count: "exact", head: true })
        .eq("pathname", `/${username}`)
        .eq("name", `${category.name} search`)
        .eq("user_id", user_id)
        .lt("created_at", endDate)
        .gt("created_at", startDate);
      category["count"] = count;
    }
  }

  return copiedCategories;
}

export async function getCustomSearchesDateRange(
  username: any,

  max: any,
  customCategories: any[],
  startDate: any,
  endDate: any,
  user_id: any
) {
  const copiedCategories = customCategories
    .filter(
      (category) =>
        category.queries && category.queries !== "" && category.queries !== null
    )
    .map((category) => ({ ...category }));
  if (copiedCategories) {
    for (let category of copiedCategories) {
      let { data }: any = await supabase.rpc(
        "get_custom_searches_date_rangee",
        {
          route_name: `/${username}`,
          category: `${category.name} search`,
          start_date: startDate,
          end_date: endDate,
          max_res: max,
          userid: user_id,
        }
      );
      const filteredData = data
        .filter(
          (item: any) => item.query !== "" && item.query !== category.name
        )
        .filter((obj: any) =>
          new Set(category.queries.toLowerCase().split(",")).has(
            obj.query.toLowerCase()
          )
        );

      category["analytics"] = [...filteredData];
      if (data.length === 0) {
        // const categoryQueries = category.queries.split(",");
        const queriesWithCountZero = category.queries
          .split(",")
          .map((query: any) => ({ query, count: 0 }));
        category["analytics"] = queriesWithCountZero;
      }

      // If needed, log the updated category
    }
  }

  return copiedCategories;
}

export async function getInstagramHighlightsSearchesDateRange(
  username: any,

  max: any,
  instagramHighlights: any[],
  startDate: any,
  endDate: any,
  user_id: any
) {
  let categoryObj: any = {};
  if (instagramHighlights) {
    const { data }: any = await supabase.rpc(
      "get_instagram_highlights_searches_date_rangee",
      {
        route_name: `/${username}`,
        category: `Instagram Highlights search`,
        start_date: startDate,
        end_date: endDate,
        max_res: max,
        userid: user_id,
      }
    );
    categoryObj = [...data];
    categoryObj = data.filter((item: any) => item.query !== "");
    categoryObj = categoryObj.filter(
      (item: any) => item.query !== "Instagram Highlights"
    );
  }

  return categoryObj;
}
export async function getInstagramLinksSearchesDateRange(
  username: any,

  max: any,
  instagramLinks: any[],
  startDate: any,
  endDate: any,
  user_id: any
) {
  const noSearchesTerm: any = [];
  let categoryObj: any = {};
  if (instagramLinks) {
    const { data }: any = await supabase.rpc(
      "get_instagram_links_searches_date_rangee",
      {
        route_name: `/${username}`,
        category: `%my links link click%`,
        start_date: startDate,
        end_date: endDate,
        max_res: max,
        userid: user_id,
      }
    );
    categoryObj = [...data];
    const pattern = /([^-\s]+)\s*-\s*(.*)/;
    categoryObj = categoryObj.map((item: any) => {
      item.query = item.query ? item.query.split("-")[0].trim() : null;
      return { ...item };
    });
    categoryObj = categoryObj.filter(
      (item: any) => item.query.toLowerCase() !== "my links link click"
    );
  }

  return categoryObj;
}
