import React, { useEffect, useState } from "react";
import { Button, Popconfirm, Spin } from "antd";
import {
  BsArchiveFill,
  BsPlusCircleFill,
  BsFillBookmarkStarFill,
  BsFillBookmarkDashFill,
  BsFillTrashFill,
  BsFillChatDotsFill,
} from "react-icons/bs";
import useUpdatePostStatus from "../../Feed/useUpdatePostStatus";
import { TiPin } from "react-icons/ti";
import { FaLock } from "react-icons/fa";
import {
  changeAdSequence,
  deletePostFunction,
  getAdsFeedLength,
  reduceAdSequence,
  removePostasAd,
  removePostasFeature,
} from "../../../../Utils/SupabaseServices/ContentDatabase";
import { MdOutlineDragIndicator, MdOutlineFileDownload } from "react-icons/md";
import useDeletePosts from "../../Feed/useDeletePosts";
import { fetchComments } from "../../../../Utils/SupabaseServices/CommentsDatabase";
import { downloadContent } from "../../../../Utils/SupabaseServices/StorageBuckets";
import { IoIosLink } from "react-icons/io";
import ManageLinksModal from "../ManageLinksModal";
import ManageCaptionModal from "../ManageCaptionModal";
import "../../Feed/feedTab.css";

const FeedActionButtons = (props: any) => {
  const {
    activeKey,
    post,
    postId,
    pageIndex,
    index,
    postIndex,
    platform,
    type,
    url,
    filter,
    username,
    subscription_type,
    user_id,
    setLimitError,
    refetchData,
    setLoadingComments,
    setSelectedPostId,
    setIsModalOpen,
    setCommentsData,
    setNoCommentsMsg,
    localData,
    adsOrder,
    setLocalData,
  } = props;

  const showModal = async (
    post_id: string,
    pageIndex: number,
    postIndex: number
  ) => {
    setLoadingComments(true);
    setSelectedPostId(post_id);

    setIsModalOpen(true);
    const comments = await fetchComments(post_id, user_id);
    if (comments) {
      setCommentsData(comments);
      setNoCommentsMsg("");
      setLoadingComments(false);
    } else {
      setCommentsData([]);
      setNoCommentsMsg("There are no Comments for this post");
      setLoadingComments(false);
    }
  };
  const { mutate: updatePostStatus, isSuccess } = useUpdatePostStatus();
  const [linksModal, setLinksModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState<any>();
  const [captionModal, setCaptionModal] = useState(false);
  const { mutate: deletePostPermanently } = useDeletePosts();
  const [loadingAdButton, setLoadingAdButton] = useState(false);
  const [loadingFeatureButton, setLoadingFeatureButton] = useState(false);
  const [loadingRemoveAdButton, setLoadingRemoveAdButton] = useState(false);
  const [loadingRemoveFeatureButton, setLoadingRemoveFeatureButton] = useState(false);
  const [loadingArchiveButton, setLoadingArchiveButton] = useState(false);
  const [loadingRemoveArchiveButton, setLoadingRemoveArchiveButton] = useState(false);

  async function handleManageLinks(post: any) {
    setSelectedPost(post);
    setLinksModal(true);
  }
  async function downloadPost(
    post_id: any,
    platform: any,
    type: any,
    url: any
  ) {
    await downloadContent(post_id, type, url);
  }

  async function handleManageCaption(post: any) {
    setSelectedPost(post);
    setCaptionModal(true);
  }
  async function archivePost(
    post_id: string,
    pageIndex: number,
    postIndex: number
  ) {
    setLoadingArchiveButton(true);
    try {
      await new Promise(resolve => setTimeout(resolve, 100));

      await updatePostStatus({
        newPostStatus: "archived",
        postId: post_id,
        activeKey,
        pageIndex,
        postIndex,
        filter,
        username,
        user_id,
      });
      await refetchData();
    } catch (error) {
      console.error("Error archiving post:", error);
    } finally {
      await new Promise(resolve => setTimeout(resolve, 1000));
      setLoadingArchiveButton(false);
    }
  }
  async function activePost(
    post_id: string,
    pageIndex: number,
    postIndex: number
  ) {
    setLoadingRemoveArchiveButton(true);
    try {
      await new Promise(resolve => setTimeout(resolve, 100));

      await updatePostStatus({
        newPostStatus: "active",
        postId: post_id,
        activeKey,
        pageIndex,
        postIndex,
        filter,
        username,
        user_id,
      });
      await refetchData();
    } catch (error) {
      console.error("Error activating post:", error);
    } finally {
      await new Promise(resolve => setTimeout(resolve, 1000));
      setLoadingRemoveArchiveButton(false);
    }
  }
  async function adPost(post_id: string, pageIndex: number, index: number) {
    setLoadingAdButton(true);
    try {
      const adCount = await getAdsFeedLength(user_id, username);
      if (adCount + 1 <= 2) {
        await Promise.all([
          updatePostStatus({
            newPostStatus: "active,ad",
            postId: post_id,
            activeKey,
            pageIndex,
            postIndex,
            filter,
            username,
            user_id,
            localData,
          }),
          changeAdSequence(post_id, adCount, username, user_id)
        ]);
        setLimitError(false);
      } else {
        setLimitError(true);
      }
      await refetchData();
    } catch (error) {
      console.error("Error marking as ad:", error);
    } finally {
      setTimeout(() => {
        setLoadingAdButton(false);
      }, 1000);
    }
  }
  async function featurePost(
    post_id: string,
    pageIndex: number,
    index: number,
    postIndex: string
  ) {
    setLoadingFeatureButton(true);
    try {
      await new Promise(resolve => setTimeout(resolve, 1000));

      if (postIndex == "active,ad") {
        await Promise.all([removeFromAds(post_id)])
      }

      // First update the post status
      await Promise.all([
        updatePostStatus({
          newPostStatus: "active,featured",
          postId: post_id,
          activeKey,
          pageIndex,
          postIndex: index,
          filter,
          username,
          user_id,
        })
      ]);

      await refetchData()
      
    } catch (error) {
      console.error("Error featuring post:", error);
    } finally {
      setTimeout(() => {
        setLoadingFeatureButton(false);
      }, 1000);
    }
  }

  async function removeFromAds(post_id: any) {
    setLoadingRemoveAdButton(true);
    try {
      await Promise.all([
        removePostasAd(post_id, username, user_id),
        updatePostStatus({
          newPostStatus: "active",
          postId: post_id,
          activeKey,
          pageIndex,
          postIndex,
          filter,
          username,
          user_id,
        })
      ]);

      const adCount = await getAdsFeedLength(user_id, username);
      await reduceAdSequence(user_id, username);

      if (adCount + 1 <= 2) {
        setLimitError(false);
      }

      const newData = localData && localData?.filter((data: any) => data.post_id != post_id);
      setLocalData(newData);

      await refetchData();
    } catch (error) {
      console.error("Error removing ad:", error);
    } finally {
      setTimeout(() => {
        setLoadingRemoveAdButton(false);
      }, 500);
    }
  }
  async function removeFromFeatured(post_id: any) {
    setLoadingRemoveFeatureButton(true);
    try {
      // First perform the removal operations
      await Promise.all([
        removePostasFeature(post_id, username, user_id),
        updatePostStatus({
          newPostStatus: "active",
          postId: post_id,
          activeKey,
          pageIndex,
          postIndex,
          filter,
          username,
          user_id,
        })
      ]);

      // Then refetch the data
      await refetchData();

      // Add a longer delay before removing loading state
      await new Promise(resolve => setTimeout(resolve, 1000));
    } catch (error) {
      console.error("Error removing feature:", error);
    } finally {
      setLoadingRemoveFeatureButton(false);
    }
  }

  async function deletePost(post_id: string, pageIndex: number, index: number) {
    //  await deletePostPermanently({
    //     postId: post_id,
    //     activeKey,
    //     pageIndex,
    //     postIndex: index,
    //     filter,
    //     user_id
    //   })
    //   refetchData()

    deletePostFunction(post_id, username, user_id).then(() => {
      refetchData();
    });
  }
  useEffect(() => {
    if (isSuccess) {
      refetchData();
    }
  }, [isSuccess]);


  return (
    <>
      {activeKey === "active" ? (
        <Button
          type="default"
          icon={loadingArchiveButton ? <Spin size="small" /> : <BsArchiveFill className="iconSize" />}
          onClick={() => {
            archivePost(postId, pageIndex, index);
          }}
          disabled={loadingArchiveButton}
        >
          {loadingArchiveButton ? "Archiving..." : "Archive"}
        </Button>
      ) : activeKey === "archived" ? (
        <Button
          type="default"
          icon={loadingRemoveArchiveButton ? <Spin size="small" /> : <BsPlusCircleFill className="iconSize" />}
          onClick={() => {
            activePost(postId, pageIndex, index);
          }}
          disabled={loadingRemoveArchiveButton}
        >
          {loadingRemoveArchiveButton ? "Activating..." : "Active"}
        </Button>
      ) : (
        ""
      )}

      {activeKey === "active" ? (
        <>
          <Button
            icon={
              loadingAdButton ? (
                <Spin size="small" />
              ) : subscription_type === "basic" ? (
                <FaLock className="iconSize" />
              ) : (
                <BsFillBookmarkStarFill className="iconSize" />
              )
            }
            onClick={() => {
              adPost(postId, pageIndex, index);
            }}
            disabled={
              loadingAdButton ||
              postIndex.includes("ad") ||
              subscription_type === "basic"
            }
          >
            {loadingAdButton
              ? "Marking..."
              : postIndex.includes("ad")
                ? "Added as Ad"
                : "Mark as Ad"}
          </Button>

          <Button
            icon={loadingFeatureButton ? <Spin size="small" /> : <TiPin className="ti-pin" />}
            className="manage-button-font"
            onClick={() => {
              featurePost(postId, pageIndex, index, postIndex);
            }}
            disabled={loadingFeatureButton || postIndex.includes("featured")}
          >
            {loadingFeatureButton
              ? "Marking..."
              : postIndex.includes("featured")
                ? "Added as Featured"
                : "Mark as Featured"}
          </Button>
        </>
      ) : activeKey === "active,ad" ? (
        <Button
          icon={loadingRemoveAdButton ? <Spin size="small" /> : <BsFillBookmarkDashFill className="iconSize" />}
          onClick={() => {
            removeFromAds(postId);
          }}
          disabled={loadingRemoveAdButton}
        >
          {loadingRemoveAdButton ? "Processing..." : "Remove as Ad"}
        </Button>
      ) : activeKey === "active,featured" ? (
        <Button
          icon={loadingRemoveFeatureButton ? <Spin size="small" /> : <TiPin className="ti-pin" />}
          onClick={() => {
            removeFromFeatured(postId);
          }}
          disabled={loadingRemoveFeatureButton}
        >
          {loadingRemoveFeatureButton ? "Processing..." : "Remove as Featured"}
        </Button>
      ) : (
        ""
      )}
      {platform === "newsletter" ? (
        <>
          <Button onClick={() => showModal(postId, pageIndex, index)}>
            Add Thumbnail
          </Button>
        </>
      ) : (
        ""
      )}
      {platform !== "podcast" &&
        platform !== "newsletter" &&
        platform !== "youtube" && (
          <Button
            icon={<MdOutlineFileDownload />}
            onClick={() => downloadPost(postId, platform, type, url)}
          >
            Download
          </Button>
        )}
      {activeKey === "active" ||
        activeKey === "archived" ||
        activeKey === "active,featured" ? (
        <Button
          icon={<IoIosLink className="iconSize" />}
          onClick={() => handleManageLinks(post)}
          style={{ gap: "1px" }}
        >
          Manage Links
        </Button>
      ) : (
        ""
      )}
      {activeKey === "active" ||
        activeKey === "archived" ||
        activeKey === "active,featured" ? (
        <Button
          // icon={
          //   <IoIosLink className="iconSize" />
          // }
          onClick={() => handleManageCaption(post)}
          className="manage-button-font"
        >
          Manage Caption
        </Button>
      ) : (
        ""
      )}
      {/* <Button
              onClick={() => showModal(postId, pageIndex, index)}
              type="primary"
              icon={
                <BsFillChatDotsFill
                  style={{ fontSize: "12px", margin: "0 4px 0px 0" }}
                />
              }
            >
              <span className="comments-btn-title">Show Relevant Comments</span>
            </Button> */}
      {activeKey !== "active,ad" && activeKey !== "active,featured" ? (
        <Popconfirm
          title="Are you sure you want to permanently delete this post?"
          onConfirm={() => deletePost(postId, pageIndex, index)}
          okText="Yes"
          cancelText="No"
          overlayStyle={{ maxWidth: "240px" }}
        >
          <Button
            type="primary"
            danger
            icon={<BsFillTrashFill className="iconSize" />}
            style={{ gap: "1px" }}
          >
            Delete Post
          </Button>
        </Popconfirm>
      ) : (
        ""
      )}
      <ManageLinksModal
        username={username}
        user_id={user_id}
        selectedPost={selectedPost}
        setSelectedPost={setSelectedPost}
        refetchData={refetchData}
        linksModal={linksModal}
        setLinksModal={setLinksModal}
      />
      <ManageCaptionModal
        username={username}
        user_id={user_id}
        selectedPost={selectedPost}
        setSelectedPost={setSelectedPost}
        refetchData={refetchData}
        captionModal={captionModal}
        setCaptionModal={setCaptionModal}
      />
    </>
  );
};

export default FeedActionButtons;
