import { useQuery } from "react-query";
import { supabase } from "../../../../config/supabaseClient";

/**
 * Load User info
 */
export function useLoadUserInfo(username: any) {
  async function getUser() {
    let activeSocials: any = [];
    let { data }: any = await supabase
      .from("user")
      .select("*")
      .eq("username", username);

    if (data.length === 0) {
      return false;
    } else {
      const user_id = data[0].user_id;
      const name = data[0].name;
      var picture;
      if (data[0].storage_url) {
        picture = data[0].storage_url;
      } else {
        picture = data[0].picture;
      }

      const email = data[0].email;
      const phoneNo = data[0].phoneNo;
      const instaLink = data[0].instagram_link;
      const youtubeLink = data[0].youtube_link;
      const amazonLink = data[0].amazon_link;
      const podcastLink = data[0].podcast_link;
      const pinterestLink = data[0].pinterest_link;
      const twitterLink = data[0].twitter_link;
      const newsletterLink = data[0].newsletter_email;
      const isOnboardingFinished = data[0].is_onboarding_finished;
      const isNecSearch = data[0].is_nec_search;
      const subscription_type = data[0].subscription_type;
      const first_connected_platform = data[0].first_connected_platform;
      const is_instagram_on = data[0].is_instagram_on;
      const is_youtube_on = data[0].is_youtube_on;
      const is_newsletter_on = data[0].is_newsletter_on;
      const is_tiktok_on = data[0].is_tiktok_on;
      const is_twitter_on = data[0].is_twitter_on;

      const is_podcast_on = data[0].is_podcast_on;
      const is_pinterest_on = data[0].is_pinterest_on;
      const is_linkedin_on = data[0].is_linkedin_on;

      const myLinks = data[0].instagram_links;
      const pictureUrl = data[0].storage_url || data[0].picture;
      const email_verify = data[0].email_verify;
      const description = data[0].description;
      const search_bar_text = data[0].search_bar_text;
      const brand_color = data[0].brand_color;
      const brand_text_color = data[0].brand_text_color;
      const is_flowdesk_on=data[0].is_flowdesk_on;
      const flowdeskId=data[0].flowdesk_user;
      const is_flowdeskpopup_on=data[0].is_flowdeskpopup_on;
      const flowdeskPopupId=data[0].flowdeskpopup_user;
      const is_fillout_on=data[0].is_fillout_on;
      const filloutId=data[0].fillout_user

      if (!email_verify) {
        localStorage.setItem("email_verify", "false");
      }

      if (instaLink) {
        activeSocials.push({ term: "instagram", link: instaLink });
      }
      if (youtubeLink) {
        activeSocials.push({ term: "youtube", link: youtubeLink });
      }
      if (amazonLink) {
        activeSocials.push({ term: "amazon", link: amazonLink });
      }
      if (podcastLink) {
        activeSocials.push({ term: "podcast", link: podcastLink });
      }
      if (pinterestLink) {
        activeSocials.push({ term: "pinterest", link: pinterestLink });
      }
      if (twitterLink) {
        activeSocials.push({ term: "twitter", link: twitterLink });
      }
      if (newsletterLink) {
        activeSocials.push({ term: "newsletter" });
      }
      const showSocials = [
        { platform: "instagram", isOn: is_instagram_on },
        { platform: "youtube", isOn: is_youtube_on },
        { platform: "podcast", isOn: is_podcast_on },
        { platform: "newsletter", isOn: is_newsletter_on },
        { platform: "tiktok", isOn: is_tiktok_on },
        { platform: "pinterest", isOn: is_pinterest_on },
        { platform: "linkedin", isOn: is_linkedin_on },

        { platform: "LTK",isOn:true },
        { platform: "Amazon",isOn:true },
        { platform: "ShopMy" ,isOn:true},
        { platform: "MISC", isOn: true },
        { platform: "MISC-content", isOn: true },


        // Add other platforms here similarly
      ]
        .filter((social) => social.isOn)
        .map((social) => social.platform);
      return {
        name,
        picture,
        email,
        phoneNo,
        instaLink,
        youtubeLink,
        amazonLink,
        podcastLink,
        pinterestLink,
        twitterLink,
        activeSocials,
        user_id,
        isOnboardingFinished,
        isNecSearch,
        subscription_type,
        first_connected_platform,
        showSocials,
        myLinks,
        pictureUrl,
        email_verify,
        description,
        search_bar_text,
        brand_color,
        brand_text_color,
        is_flowdesk_on,
        flowdeskId,
        is_flowdeskpopup_on,
        flowdeskPopupId,
        is_fillout_on,
        filloutId
      };
    }
  }

  return useQuery(["getUserInfo", username], getUser, {
    refetchOnWindowFocus: true,
  });
}
