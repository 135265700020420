import { Button, Carousel, Input, List, Modal } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import "../../../../styles/StayUptoDateCSS/StayUpToDateControls.css";
import { updateComponent } from "../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import Skeleton from "react-loading-skeleton";
import { supabase } from "../../../../config/supabaseClient";
import { HiCheck, HiPencil } from "react-icons/hi";
import { StaticVariables } from "../../../../Utils/StaticVariables";
import { useLoadQueryFeed } from "./useLoadQueryFeed";
import VideoComponent from "../../../../components/VideoComponent/VideoComponent";
import RemovedContentTable from "../../../../components/EditModalComponents.tsx/RemovedContentTable";
import { getPostTitle } from "../../../../Utils/HelperFunction/HelperFunction";
import { UpdateCombinedText } from "../../../../Utils/SupabaseServices/ContentDatabase";
import CarouselOrderControl from "../../../../components/CarouselControls/CarouselOrderControl";
import CarouselShapeControl from "../../../../components/CarouselControls/CarouselShapeControl";
import CarouselSoundControl from "../../../../components/CarouselControls/CarouselSoundControl";
import Title from "antd/es/typography/Title";
import ProductName from "../../../../components/ProductName/ProductName";
import LoadingPost from "../../../../components/skeleton/Views/LoadingPost";

const EditQueryBasedCarousel = (props: any) => {
  const {
    username,
    user_id,
    isQueryLinksEditModalOpen,
    setIsQueryLinksEditModalOpen,
    type,
    component,
    refetchDashboard,
    initial,
    setInitial,
  } = props;

  const [name, setName] = useState(component.name || ""); // const [details, setDetails] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // State to manage button disable state
  const [postIds, setPostIds] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [defaultQuery, setDefaultQuery] = useState("");
  const [removePostIds, setRemovePostIds] = useState<any>("");
  const [isChange, setIsChange] = useState(false);
  const [order, setOrder] = useState();
  const [shape, setShape] = useState();
  const [isSoundOn, setIsSoundOn] = useState();
  const [isActionLoading, setIsActionLoading] = useState(false);

  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  useEffect(() => {
    setPostIds(component?.post_ids);
    setName(component?.name);
    setDefaultQuery(component?.query);
    setRemovePostIds(component?.removed_postids);
    setOrder(component?.content_order);
    setShape(component?.content_shape);
    setIsSoundOn(component?.content_sound);
  }, [component]);
  const handleInputChange = (value: string, setter: Function) => {
    setter(value); // Set the input value
    setIsButtonDisabled(!value); // Disable button if either name or details is empty
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    const res = await updateComponent(
      name,
      component,
      username,
      user_id,
      defaultQuery,
      component?.type,
      "",
      "",

      removePostIds,
      order,
      shape,
      isSoundOn
    );

    if (res === "true") {
      // props.refetch();
      refetchDashboard();
      props.setIsQueryLinksEditModalOpen(false);
      props.setSelectedComponent("");
      setConfirmLoading(false);

      setName("");
      setPostIds("");
      setErrorMsg("");
      setDefaultQuery("");
      setRemovePostIds("");
      setIsChange(false);
      setEdit(false);
      setEditIndex("");
    } else {
      setErrorMsg("Category with this name already exists");
      setConfirmLoading(false);
      setEdit(false);
      setEditIndex("");
    }
  };

  const handleCancel = () => {
    props.setIsQueryLinksEditModalOpen(false);
    props.setSelectedComponent("");
    refetchDashboard();
    setName("");
    setPostIds("");
    setErrorMsg("");
    setDefaultQuery("");
    setRemovePostIds("");
    setEdit(false);
    setEditIndex("");
  };

 async function handleRemovedPostIds(post_ids: any, type: any) {
    if (type === "minus") {
      setRemovePostIds((prev: any) => {
        const idsArray = prev?.split(",").filter(Boolean);
        idsArray.push(post_ids);

        return idsArray.join(",");
      });
    } else if (type === "add") {
      setRemovePostIds((prev: any) => {
        const idsArray = prev.split(",").filter(Boolean);
        const filteredArray = idsArray.filter((elem: any) => elem !== post_ids);
        return filteredArray.join(",");
      });
    }
  }

  return (
    <>
      <Modal
        open={isQueryLinksEditModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Update"
        confirmLoading={confirmLoading}
        okButtonProps={{ disabled: !name }}
        data-testid="edit-query-carousel"
      >
        <div className="mt-1">
          <h5 className="editTitle">Editing {name}</h5>
          <label className="bold-label">Carousel Title</label>
          <Input
            placeholder="Enter Carousel Title"
            value={name}
            onChange={(e: any) => {
              handleInputChange(e.target.value, setName);
              setErrorMsg("");
            }}
            id="edit-query-carousel-title"
            className="mb-15"
          />
          {errorMsg && <p className="errorMsg">{errorMsg}</p>}
          {component?.query && component?.query !== "" && (
            <p>Content shown based on "{component?.query}"</p>
          )}
          <AddedContentTable
            post_ids={component?.post_ids?.split(",")}
            username={username}
            user_id={user_id}
            setPostIds={setPostIds}
            handleRemovedPostIds={handleRemovedPostIds}
            componentName={name}
            refetchDashboard={refetchDashboard}
            removePostIds={removePostIds}
            setRemovePostIds={setRemovePostIds}
            type={component?.type}
            displayName={component?.display_name}
            query={component?.query}
            setIsChange={setIsChange}
            isChange={isChange}
            component={component}
            order={order}
            shape={shape}
            isSoundOn={isSoundOn}
            edit={edit}
            setEdit={setEdit}
            editIndex={editIndex}
            setEditIndex={setEditIndex}
            isActionLoading={isActionLoading}
            setIsActionLoading={setIsActionLoading}
          />
          <Title level={3}>Carosuel Control</Title>
          <div className="addedContent">
            <CarouselOrderControl order={order} setOrder={setOrder} />
            <CarouselShapeControl shape={shape} setShape={setShape} />
            <CarouselSoundControl
              isSoundOn={isSoundOn}
              setIsSoundOn={setIsSoundOn}
            />
          </div>
          <RemovedContentTable
            post_ids={component?.post_ids?.split(",")}
            username={username}
            user_id={user_id}
            setPostIds={setPostIds}
            handleRemovedPostIds={handleRemovedPostIds}
            componentName={name}
            refetchDashboard={refetchDashboard}
            removePostIds={removePostIds}
            setRemovePostIds={setRemovePostIds}
            type={component?.type}
            displayName={component?.display_name}
            query={component?.query}
            edit={edit}
            setEdit={setEdit}
            editIndex={editIndex}
            setEditIndex={setEditIndex}
            isActionLoading={isActionLoading}
            setIsActionLoading={setIsActionLoading}
          />
        </div>
      </Modal>
    </>
  );
};
const AddedContentTable = (props: any) => {
  const {
    username,
    user_id,
    post_ids,
    componentName,
    refetchDashboard,
    setRemovePostIds,
    type,
    displayName,
    removePostIds,
    query,
    setIsChange,
    isChange,
    component,
    order, shape, isSoundOn,
    edit, editIndex, setEdit, setEditIndex, isActionLoading, setIsActionLoading
  } = props;

  const [postIds, setPostIds] = useState(post_ids);
  const [deletePostIds, setDeletePostIds] = useState(removePostIds);

  useEffect(() => {
    setDeletePostIds(removePostIds);
  }, [removePostIds]);
  const isShopLinks = type === "query-content" ? false : true;
  const showSocials = type === "query-content" ? StaticVariables().CONTENT_PLATFORMS : StaticVariables().PLATFORMS;
  const contentOrder = "Default";
  const {
    isLoading,
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  }: any = useLoadQueryFeed(
    username,
    user_id,
    query,

    showSocials,
    contentOrder,
    isShopLinks,
    deletePostIds,
    componentName,
    order
  );
  const onMinus = async (post_id: string) => {
    setIsActionLoading(true);
    try {
      // Update the deletePostIds state
      setDeletePostIds((prev: any) => {
        const idsArray = prev.split(",").filter(Boolean);
        idsArray.push(post_id);
        return idsArray.join(",");
      });

      // Wait for both operations to complete
      await Promise.all([
        props.handleRemovedPostIds(post_id, "minus"),
        refetch()
      ]);

      await new Promise(resolve => setTimeout(resolve, 1000));
    } finally {
      setIsActionLoading(false);
    }
  };
  const [value, setValue] = useState<string>();
  // const [edit, setEdit] = useState(false);
  // const [editIndex, setEditIndex] = useState("");

  const handleDone = async (post_id: string) => {
    const { data: user, error } = await supabase
      .from(`${username}`)
      .select("*")
      .eq("post_id", post_id)
      .eq("user_id", user_id);
    if (type === "query-content" || type === "content") {
      const { data, error } = await supabase
        .from(`${username}`)
        .update({
          content_title: value,
        })
        .eq("post_id", post_id)
        .eq("user_id", user_id);

      const { data: bankContent, error: bankErroor } = await supabase
        .from("product_bank")
        .update({
          content_title: value,
        })
        .eq("product_id", post_id)
        .eq("user_id", user_id);

      refetch();
    } else {
      const { data } = await supabase
        .from(`${username}`)
        .update({
          shop_title: value,
          caption: value,
          product_name: value,
        })
        .eq("post_id", post_id)
        .eq("user_id", user_id);

      const { data: bankContent, error: bankErroor } = await supabase
        .from("product_bank")
        .update({
          shop_title: value,
        })
        .eq("product_id", post_id)
        .eq("user_id", user_id);
    }

    setIsChange(true);
    const { data: newContent, error: newErroor } = await supabase
      .from("content_new")
      .update({
        product_name: value,
      })
      .eq("post_id", post_id)
      .eq("user_id", user_id);
    UpdateCombinedText(username, user_id, post_id);
    setEdit(false);
    setEditIndex("");
    refetch();
    refetchDashboard();
  };
 

  const filteredData = useMemo(() => {
    return (data || [])
      .filter((element: any) => element !== undefined)
      .filter((obj: any) => {
        const deleteIdsArray = deletePostIds?.split(",") || [];
        return !deleteIdsArray.includes(obj.post_id);
      });
  }, [data, deletePostIds]);
  const hasFilteredData = filteredData.length > 0;
  return (
    <>
      <div className="addedContent">
        <h5 className="addedContentTitle">{componentName}</h5>
        {!isLoading && !isActionLoading ? (
          <div className="addedSvg">
            {hasFilteredData ? (
              filteredData.map((post: any, index: any) => (
                <React.Fragment key={post.post_id}>
                  <div
                    key={post.post_id}
                    className="feed-tab-post-container added-post"
                  >
                    <div className="feed-tab-media-wrapper">
                      <Button
                        onClick={() => onMinus(post.post_id)}
                        className="carousel-content-remove-btn"
                      >
                        Remove
                      </Button>
                      {post?.platform === "newsletter" ? (
                        <div className={`${shape === "sq" ? 'square-wrapper' : ''} no-media-url`} 
                        // style={{backgroundColor: "black", color: "white"}}
                        >
                          <div className={`${shape === "sq" ? 'newsletter-media-carousel square-image' : 'newsletter-media-carousel h-250'}`}>
                          <h4 className="newsletter-label">Newsletter</h4>
                            <h4 className="mt-7 date">
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                day: "numeric",
                                month: "long",
                              }).format(new Date(post.post_date))}
                            </h4>
                          </div>
                        </div>
                      ) : post?.platform === "linkedin" && !post?.media_url ? (
                        <div className={`${shape === "sq" ? 'square-wrapper' : ''} no-media-url`} 
                        // style={{backgroundColor: "black", color: "white"}}
                        >
                          <div className={`${shape === "sq" ? 'newsletter-media-carousel square-image' : 'newsletter-media-carousel h-250'}`}>
                          <h4 className="newsletter-label">LinkedIn Post</h4>
                            <h4 className="mt-7 date">
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                day: "numeric",
                                month: "long",
                              }).format(new Date(post.post_date))}
                            </h4>
                          </div>
                        </div>
                      ) : post?.platform === "twitter" && !post?.media_url ? (
                        <div className={`${shape === "sq" ? 'square-wrapper' : ''} no-media-url`} 
                        // style={{backgroundColor: "black", color: "white"}}
                        >
                          <div className={`${shape === "sq" ? 'newsletter-media-carousel square-image' : 'newsletter-media-carousel h-250'}`}>
                          <h4 className="newsletter-label">Twitter Post</h4>
                            <h4 className="mt-7 date">
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                day: "numeric",
                                month: "long",
                              }).format(new Date(post.post_date))}
                            </h4>
                          </div>
                        </div>
                      ) : (
                        <div className={`${post?.type === "FEED" || shape === "sq" ? 'square-grid' : ''}`}>
                          {post?.media_url ? (
                            <div className={`${post?.type === "FEED" || shape === "sq" ? 'square-wrapper' : ''}`}>
                              {post?.media_url.split(",").length > 1 &&
                                post?.media_url
                                  .split(",")
                                  .every((url: any) => url.trim() !== "") ? (
                                <Carousel
                                  dotPosition="bottom"
                                  className="feed-tab-media-carousel"
                                >
                                  {post?.media_url
                                    .split(",")
                                    .map((media_url: any, index: any) => (
                                      <React.Fragment key={index}>
                                        {media_url.includes("video") ? (
                                          <VideoComponent
                                            media_url={media_url}
                                            thumbnail={
                                              post.thumbnail &&
                                                post.thumbnail.split(",")
                                                  .length &&
                                                post.thumbnail.split(",")[
                                                index
                                                ]
                                                ? post.thumbnail.split(
                                                  ","
                                                )[index]
                                                : "/assets/dummy_video.png"
                                            }
                                            content_shape={shape}
                                            isSoundOn={isSoundOn}
                                          />
                                        ) : (
                                          <img
                                            className={`${post?.type === "FEED" || shape === "sq" ? 'feed-tab-media-check square-image mb-2' : 'feed-tab-media-check mb-2'}`}
                                            src={media_url}
                                            alt="post"
                                          />
                                        )}
                                      </React.Fragment>
                                    ))}
                                </Carousel>
                              ) : (
                                <>
                                  {post.media_url.includes("video") ? (
                                    <div className={`${post?.type === "FEED" || shape === "sq" ? 'square-wrapper' : 'h-255'}`}>
                                      <VideoComponent
                                        media_url={post.media_url}
                                        thumbnail={post.thumbnail}
                                        content_shape={shape}
                                        isSoundOn={isSoundOn}
                                      />
                                    </div>
                                  ) : (

                                    <img
                                      className={`${post?.type === "FEED" || shape === "sq" ? 'feed-tab-media-check square-image' : 'feed-tab-media-check'}`}
                                      src={
                                        post.media_url
                                          ? post.media_url
                                          : "/assets/dummy_video.png"
                                      }
                                      alt="post"
                                    />

                                  )}
                                </>
                              )}
                            </div>
                          ) : (
                            <div className={`${post?.type === "FEED" || shape === "sq" ? 'square-wrapper' : ''}`}>
                              <img
                                className={`${post?.type === "FEED" || shape === "sq" ? 'feed-tab-media-check square-image' : 'feed-tab-media-check'}`}
                                src={
                                  post?.thumbnail
                                    ? post?.thumbnail
                                    : "/assets/dummy_video.png"
                                }
                                alt="post"
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    {edit === true && editIndex === index ? (
                      <>
                        <Input
                          value={value}
                          onChange={(e: any) => setValue(e.target.value)}
                        />{" "}
                        <HiCheck
                          onClick={() => {
                            handleDone(post.post_id);
                          }}
                        />
                      </>
                    ) : (
                      <p
                        style={{
                          textAlign:
                            post?.content_title !== "" ||
                              post?.shop_title !== "" ||
                              post?.product_name !== ""
                              ? "left"
                              : "center",
                        }}
                      >

                        <ProductName type={type} post={post} />

                        <HiPencil
                          onClick={() => {
                            const name = getPostTitle(post, type);
                            setValue(name);
                            setEdit(true);
                            setEditIndex(index);
                          }}
                        />
                      </p>
                    )}
                  </div>
                </React.Fragment>
              ))
            ) : (
              <React.Fragment>
                <>
                  <div className="feed-tab-post">
                    {[...Array(4)].map((_, index) => (
                      <LoadingPost key={index} />
                    ))}
                  </div>
                </>
              </React.Fragment>
            )}
          </div>
        ) : (
          <div className="feed-tab-post">
            {[...Array(4)].map((_, index) => (
              <LoadingPost
                key={index}
              // style={{ width: '100%', height: '255px' }}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default EditQueryBasedCarousel;
