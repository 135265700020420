import React, { useState } from "react";
import { supabase } from "../../../../config/supabaseClient";
import axios from "axios";
import { signupUserFolder } from "../../../../Utils/SupabaseServices/OnboardingFlow";

const ConnectInstagramStep = (props: any) => {
  const {
    username,
    setUsername,
    handleNext,
    buttonStyle,
    currentStep,
    instagramUsername,
    setInstagramUsername,
  } = props;
  //   const [instagramUsername, setInstagramUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const handleConnectUsername = async () => {
    console.log("username", username);
    const { data } = await supabase
      .from("user")
      .select("*")
      .eq("username", username);

    // const profileSetupUrl = `http://localhost:3001/profilesetup`;

    const profileSetupUrl = `https://dev.thesearchbubble.com/profilesetup-onboarding`;

    axios.post(
      profileSetupUrl,
      { id: data?.[0].user_id, name: username , from: "content",instaUsername:instagramUsername },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
     await supabase
    .from("user")
    .update({ is_username_signup: true, onboarding_step: currentStep,instagram_username:instagramUsername })
    .eq("username",username);
    handleNext();
  };
  return (
    <div style={{ textAlign: "center" }}>
      <h2 style={{ fontSize: 20, marginBottom: 16, color: "#333" }}>
        Connect Your Instagram
      </h2>
      <p style={{ color: "#666", marginBottom: 16 }}>
        Enter your Instagram username to get started
      </p>

      <input
        type="text"
        placeholder="username"
        value={instagramUsername}
        onChange={(e) => {
          setInstagramUsername(e.target.value);
          setUsernameError("");
        }}
        style={{
          width: "100%",
          padding: "8px 12px",
          border: "1px solid #ccc",
          borderRadius: 4,
          marginBottom: 16,
          backgroundColor: "#fff",
          color: "#333",
        }}
      />
      {usernameError && <p style={{ color: "red" }}>{usernameError}</p>}
      {errorMsg && <p style={{ color: "red" }}>{errorMsg}</p>}

      <button
        type="button"
        onClick={handleConnectUsername}
        style={{
          ...buttonStyle,
          opacity: !instagramUsername ? 0.5 : 1,
          cursor: !instagramUsername ? "not-allowed" : "pointer",
        }}
        disabled={!instagramUsername}
      >
        Next
      </button>
    </div>
  );
};

export default ConnectInstagramStep;
