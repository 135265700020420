import { Input, List, Modal } from "antd";
import React, { useState } from "react";
import { FaArrowLeft, FaBars } from "react-icons/fa";
import '../../../../../styles/StayUptoDateCSS/StayUpToDateControls.css'
import { addComponent } from "../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import { BsGrid3X3GapFill } from "react-icons/bs";

const AddLinksComponents = (props: any) => {
  const {
    username,
    user_id,
    isAddLinksModalOpen,
    setIsAddLinksModalOpen,
    setIsModalOpen,
    refetch,
    StayUpToDateComponents,
  } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [view, setView] = useState("grid");
  const [title, setTitle] = useState("");
  const handleOk = async () => {
    setConfirmLoading(true);
    const res = await addComponent(
      title,
      view,
      username,
      user_id,
      "false",
      "Check it out!",
      "",
      title,
      "links-section"
    );
    if (res === "true") {
      refetch();
      setIsAddLinksModalOpen(false);
      setIsModalOpen(false);
      setConfirmLoading(false);
      setView(view);
      setTitle("")
    }
    else{
      setErrorMsg("Component with this title already exists")
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    setIsAddLinksModalOpen(false);
    setIsModalOpen(false);
    setView("grid");
    setTitle("")
    setErrorMsg("")

  };
  const handlePrevious = () => {
    setIsModalOpen(true);
    setIsAddLinksModalOpen(false);
    setView("grid");
    setTitle("")
    setErrorMsg("")

  };

  return (
    <>
      <Modal
        title={
          <div>
            <button onClick={handlePrevious} className="newbutton">
              <FaArrowLeft />
            </button>
          </div>
        }
        open={isAddLinksModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        okText="Add"
        data-testid="mylink-add"
        okButtonProps={{disabled:!title}}
      >
        <>
          <div>
            <h5 className="title mb-15">Add Links</h5>
           

            <label className="bold-label">Title</label>
            <Input
              placeholder="Enter Title"
              value={title}
              onChange={(e: any) => {
                setTitle(e.target.value);
                setErrorMsg("");
              }}
              id="edit-title-custom-carousel"
              className="mb-20"
            />
            {errorMsg && <p className="error-msg">{errorMsg}</p>}
            <h5 className="bold-label text-center">Links Presentation</h5>
            <div className="link-presentation">
              <span
                className={view == "grid" ? "active" : ""}
                onClick={() => setView("grid")}
              >
                {" "}
                <BsGrid3X3GapFill className="links-view-icon" />{" "}
              </span>
              {/* <HiBars4 /> */}
              <span
                className={view == "line" ? "active" : ""}
                onClick={() => setView("line")}
              >
                {" "}
                <FaBars className="links-view-icon" />{" "}
              </span>
            </div>
          </div>
          {/* <hr /> */}
        </>
      </Modal>
    </>
  );
};

export default AddLinksComponents;
