import {
    Alert,
    Button,
    Input,
    Modal,
    Radio,
    RadioChangeEvent,
    Space,
    Spin,
  } from "antd";
  import React, { useState } from "react";
  
  import { useAuth } from "../../../hooks/useAuth";
  import { useParams } from "react-router-dom";
  import { storeLinkedInChannel } from "../../../Utils/SupabaseServices/ContentDatabase";
  
  const LinkedInModal = (props: any) => {
    const { user } = useAuth();
    const { username } = useParams();
    const { showLinkedInModal, user_id, data,refetchData } = props;
    const [linkedinUser, setLinkedInUser] = useState(data?.linkedin_user);
    const [errorLinkedIn, setErrorLinkedIn] = useState("");
    const [loadingLinkedInConnect, setLoadingLinkedInConnect] = useState(false);
    const [integrateLinkedInMsg, setIntegrateLinkedInMsg] = useState("");
  
    const [showIntegrationModal, setShowIntegrationModal] = useState(false);
    // const onChange = async (e: RadioChangeEvent) => {
    //   setValue(e.target.value);
    // };
  
    const handleOk = async () => {
      setShowIntegrationModal(false);
    };
  
    const handleCancel = () => {
      setLinkedInUser(data?.linkedin_user)
      setShowIntegrationModal(false);
    };
    return (
      <>
        <Modal
          title="Connect to LinkedIn"
          open={showLinkedInModal}
          onOk={async () => {
            if (linkedinUser && linkedinUser?.length > 0) {
              setLoadingLinkedInConnect(true);
  
              const res = await storeLinkedInChannel(user?.id as string, linkedinUser, username);
              if (!res) {
                setIntegrateLinkedInMsg("");
                setErrorLinkedIn("error while connecting to this LinkedIn user");
              } else {
                setIntegrateLinkedInMsg(
                  "Your LinkedIn data will be integrated in your feed within 24 hours!"
                );
                // props.setShowLinkedInModal(false);
                props.onClose();
                refetchData()
                setShowIntegrationModal(true)
              }
  
              setLoadingLinkedInConnect(false);
            } else {
              setErrorLinkedIn("Enter valid name")
            }
          }}
          onCancel={() => {
            setErrorLinkedIn("");
            setLinkedInUser(data?.linkedin_user)
            // props.setShowLinkedInModal(false);
            props.onClose();
  
          }}
          okText="Connect"
          okButtonProps={{ disabled: !linkedinUser, loading: loadingLinkedInConnect }}
          cancelButtonProps={{ disabled: loadingLinkedInConnect }}
        >
          <p>
            By connecting your LinkedIn username, all your LinkedIn data will be
            integrated and shown as posts in the feed of your main page. In the
            content tab you will be able to hide or delete the posts you don't
            want to show.{" "}
          </p>
          {loadingLinkedInConnect ? (
            <div
              className="spin-integration"
            >
              <Spin />
            </div>
          ) : (
            <>
              {errorLinkedIn && (
                <Alert
                  type="error"
                  message={`Error: ${errorLinkedIn}`}
                  showIcon
                  className="alert-margin"
                />
              )}
              {data?.linkedin_user ? (
                <>
                  <p>
                    <b>Already connected LinkedIn username: {data?.linkedin_user}</b>
                  </p>
                  <p>New name will connection will replace this one.</p>
                </>
              ) : (
                ""
              )}
              <p>To connect just enter your LinkedIn profile url :</p>
  
              <Input
                placeholder="profile url"
                value={linkedinUser}
                onChange={(e) => {
                  const value = e.target.value
                  setLinkedInUser(e.target.value)
                  if (!value) {
                    setErrorLinkedIn("Enter valid name");
                  } else {
                    setErrorLinkedIn("");
                  }
                }}
              />
            </>
          )}
          {/* <Button type="primary" onClick={showModal}>
            Open Modal
          </Button> */}
        </Modal>
        <Modal
          title={linkedinUser ? `${linkedinUser}'s LinkedIns` : ""}
          open={showIntegrationModal}
          onOk={handleOk}
          okText="Ok"
          onCancel={handleCancel}
        >
          <>
            <p>{integrateLinkedInMsg} </p>
          </>
        </Modal>
      </>
    );
  };
  
  export default LinkedInModal;
  