import dayjs from "dayjs";
import { supabase } from "../../config/supabaseClient";

export const daysSinceMultipleDays = (targetDaysOfWeek: number[]) => {
  const currentDayOfWeek = dayjs().day();
  const daysSinceTargetDays = targetDaysOfWeek.map(
    (targetDayOfWeek) => (currentDayOfWeek - targetDayOfWeek + 7) % 7
  );

  return daysSinceTargetDays;
};

export const isNavigable = (component: any) => {
  return (
    component.display_name !== "My Links" &&
    !component.display_name.includes("Form") &&
    component.type !== "banner" &&
    component.type !== "highlight" && 
    component.type !== "links-section" &&

    component.display_name !== "reels" &&
    component.display_name !== "podcast" &&
    !component.display_name.includes("Newsletter") &&
    component.display_name !== "instagram story" &&
    component.display_name !== "tiktok"
  );
};

export async function updateIsEmptyStatus(name: any, user_id: any, id: any) {
  const { data, error } = await supabase
    .from("stayuptodate_components")
    .update({ isEmpty: true })
    .eq("name", name)
    .eq("id", id)
    .eq("user_id", user_id);
}
export async function updateCardImage(
  name: any,
  user_id: any,
  id: any,
  image: any
) {
  const { data, error } = await supabase
    .from("stayuptodate_components")
    .update({ card_image: image, isEmpty: false })
    .eq("name", name)
    .eq("id", id)
    .eq("user_id", user_id);
}

export function getMediaSrc(
  type: any,
  data: any,
  isCarousel: any,
  media_url: any
) {

  if (data?.media_url || data?.table_image) {
    if (isCarousel) {
      return media_url;
    } else {
      if (type !== "content" && type !== "query-content") {
        if (type === "product-bank") {
          return data?.media_url
            ? data?.media_url
            : data?.product_image
            ? data?.product_image
            : data?.table_image;
        } else {
          return data?.media_url.includes("video")
            ? data?.product_image
              ? data?.product_image
              : data?.thumbnail
            : data?.product_image
            ? data?.product_image
            : data?.media_url;
        }
      } else {
        return data?.media_url
          ? data?.media_url
          : data?.media_url.includes("video")
          ? data?.thumbnail
          : data?.product_image;
      }
    }
  } else if(data?.thumbnail) {
    return data?.thumbnail;
  } else {
    return "";
  }
}




export const testLink = (link: any) => {
  const url = link;
  if (url && url !== "") {
    const validUrl = url.startsWith("http") ? url : `http://${url}`;
    window.open(validUrl, "_blank");
  }
};