import React, { useEffect, useState } from "react";
import '../../../../../styles/StayUptoDateCSS/SubComponents.css'
import { useLocation } from "react-router-dom";

import track, {
  TRACKING_TYPES,
} from "../../../../../Utils/SupabaseServices/Track";
import { useLoadCustomContent } from "../../../../../Utils/customHooks/UpToDateContent/useLoadCustomContent";
import ImagePostCarouselTag from "../../../../../components/GeneralPostTag/ImagePostCarouselTag";
import VideoPostTag from "../../../../../components/GeneralPostTag/VideoPostTag";
import ImagePostTag from "../../../../../components/GeneralPostTag/ImagePostTag";
import DataSkeleton from "../../../../../components/StayUpToDateComponent/UpToDateComponents/DataSkeleton";
import { useDomLoaded } from "../../../../../hooks/DomLoadedContext";
const CustomLinksWrapper = (props: any) => {
  console.log("firstname")
  const { user_id, username, numberOfDays, isLinks, brand_color, component } =
    props;
  const {
    key,
    name: title,
    post_ids: postIds,
    cta,
    type,
    query,
    removed_postids: removedPostIds,
    content_order,
    content_shape,
    content_sound,
  } = component;
  const location = useLocation();
  console.log(content_sound)
  const domLoaded = useDomLoaded();
  const {
    isLoading: CustomLinkDataLoading,
    data: CustomLinkData,
    forceRefetch,
  }: any = useLoadCustomContent(username, user_id, postIds?.split(","), title);
  function handleShop(postId: string, media_url: string) {
    track("Redirect to shop link", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: { post_id: postId, media_url: media_url },
      user_id: user_id,
      title: "Stay Up To Date",
    });
  }
  useEffect(() => {
    forceRefetch();

    // Optionally, you can return a cleanup function if necessary
    return () => {
      // Cleanup code here (if needed)
    };
  }, []);

  return (
    <>
      <div>
        {CustomLinkDataLoading || !domLoaded ? (
          <div className="outer-container-subcomponents">
            <DataSkeleton dashboard={true} />{" "}
          </div>
        ) : (
          <div className="outer-container-subcomponents">
            {CustomLinkData && CustomLinkData.length ? (
              <div className="links-grid-container-subcomponents">
                {CustomLinkData &&
                  CustomLinkData.map((link: any, index: any) => (
                    <React.Fragment key={index}>
                      {link.media_url ? (
                        link.media_url.split(",").length > 1 &&
                          link.media_url
                            .split(",")
                            .every((url: any) => url.trim() !== "") ? (
                          <>
                            <ImagePostCarouselTag
                              post={link}
                              handleRedirect={handleShop}
                              title={title}
                              brand_color={brand_color}
                              isLinks={true}
                              isDashboard={true}
                              isHasMediaUrl={
                                link.type === "FEED" ? true : false
                              }
                              contentShape={content_shape == "sq" ? true : false}
                              isSoundOn={content_sound}
                            />
                          </>
                        ) : (
                          <>
                            {link.product_image && link.product_image !== "" ? (
                              <>
                                <ImagePostTag
                                  data={link}
                                  handleRedirect={handleShop}
                                  title={title}
                                  brand_color={brand_color}
                                  isLinks={true}
                                  isDashboard={true}
                                  isHasMediaUrl={
                                    link.type === "FEED" ? true : false
                                  }
                                  contentShape={content_shape == "sq" ? true : false}
                                  isSoundOn={content_sound}
                                />
                              </>
                            ) : (
                              <>
                                {link.media_url.includes("video") ? (
                                  <>
                                    <VideoPostTag
                                      post={link}
                                      handleRedirect={handleShop}
                                      title={title}
                                      brand_color={brand_color}
                                      isLinks={true}
                                      isDashboard={true}
                                      thumbnail={link.thumbnail}
                                      isHasMediaUrl={
                                        link.type === "FEED" ? true : false
                                      }
                                      type={type}
                                      contentShape={content_shape == "sq" ? true : false}
                                      isSoundOn={content_sound}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <ImagePostTag
                                      data={link}
                                      handleRedirect={handleShop}
                                      title={title}
                                      brand_color={brand_color}
                                      isLinks={true}
                                      isDashboard={true}
                                      isHasMediaUrl={
                                        link.type === "FEED" ? true : false
                                      }
                                      contentShape={content_shape == "sq" ? true : false}
                                      isSoundOn={content_sound}
                                    />
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )
                      ) : (
                        <>
                          <ImagePostTag
                            data={link}
                            handleRedirect={handleShop}
                            title={title}
                            brand_color={brand_color}
                            isLinks={true}
                            isDashboard={true}
                            isHasMediaUrl={
                              link.type === "FEED" ? true : false
                            }
                            contentShape={content_shape == "sq" ? true : false}
                            isSoundOn={content_sound}
                          />
                        </>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            ) : (
              <div className="outer-container-subcomponents">
                <p>No content at this time</p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default CustomLinksWrapper;
