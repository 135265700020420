import React, { useState } from "react";
import { supabase } from "../../../../config/supabaseClient";

const ConnectLinkinbioStep = (props: any) => {
  const {
    handleNext,
    buttonStyle,
    handleBack,
    username,
    setUsername,
    setLinkinbioUsername,
    linkinbioUsername,
    currentStep
  } = props;
  const [error, setError] = useState("");



  const handleLinkinbioChange = async () => {
    const cleanUsername = linkinbioUsername.startsWith("@")
    ? linkinbioUsername.slice(1)
    : linkinbioUsername;
    setLinkinbioUsername(cleanUsername);
   
    const { data } = await supabase
      .from("user")
      .update({ linkinbio: cleanUsername, onboarding_step: currentStep })
      .eq("username", username);
    handleNext();
  };
  return (
    <div style={{ textAlign: "center", position: "relative" }}>
      {handleBack && (
        <button
          type="button"
          onClick={handleBack}
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            background: "none",
            border: "none",
            fontSize: "24px",
            cursor: "pointer",
            color: "#666",
          }}
        >
          ←
        </button>
      )}
      <button
        type="button"
        onClick={handleNext}
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          background: "none",
          border: "none",
          fontSize: "16px",
          cursor: "pointer",
          color: "#666",
        }}
      >
        Skip
      </button>

      <h2 style={{ fontSize: 20, marginBottom: 16, color: "#333" }}>
        Connect Your Linkinbio
      </h2>
      <p style={{ color: "#666", marginBottom: 16 }}>
        Enter your Linkinbio (if you're using any)
      </p>

      <input
        type="text"
        placeholder="linkinbio"
        value={linkinbioUsername}
        onChange={(e) => {
          setError("");
          setLinkinbioUsername(e.target.value);
        }}
        style={{
          width: "100%",
          padding: "8px 12px",
          border: `1px solid ${error ? "#ff4444" : "#ccc"}`,
          borderRadius: 4,
          marginBottom: 8,
          backgroundColor: "#fff",
          color: "#333",
        }}
      />

      {error && (
        <p style={{ color: "#ff4444", fontSize: 14, marginBottom: 16 }}>
          {error}
        </p>
      )}

      <div style={{ display: "flex", gap: 12, justifyContent: "center" }}>
        {/* {handleBack && (
          <button
            type="button"
            onClick={handleBack}
            style={{ ...buttonStyle, backgroundColor: '#666' }}
          >
            Back
          </button>
        )} */}
        <button type="button" onClick={handleLinkinbioChange} style={buttonStyle}>
          Connect Linkinbio
        </button>
        {/* <button
          type="button"
          onClick={handleNext}
          style={{ ...buttonStyle, backgroundColor: '#666' }}
        >
          Skip
        </button> */}
      </div>
    </div>
  );
};

export default ConnectLinkinbioStep;
