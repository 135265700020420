import { Button, Segmented, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useLoadStayUpToDateComponents } from "../../../../../Utils/customHooks/UpToDateContent/useLoadStayUpToDateComponents";
import "../Charts.css";
import ChartClicksTable from "./ChartClicksTable";
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import StatBox from "../../../../../components/StatBox/StatBox";
import { StaticVariables } from "../../../../../Utils/StaticVariables";

const ContentCharts = (props: any) => {
  const {
    username,
    user_id,
    postClicksAnalytics,
    loadingPostClicksAnalytics,
    postClicksViewsAnalytics,
    loadingPostClicksViewsAnalytics,
    carouselClicksViewsAnalytics,
    loadingCarouselClicksViewsAnalytics,
    carouselClicksAnalytics,
    loadingCarouselClicksAnalytics,
    daysNumber,
    brand_color,
    carouselViews,
    loadingCarouselViews,
  } = props;
  const {
    isLoading: StayUpToDateComponentsLoading,
    data: StayUpToDateComponents,
  }: any = useLoadStayUpToDateComponents(username, user_id);
  const [selectedComponent, setSelectedComponent] = useState<any>("");
  const [defaultCurrentPage, setDefaultCurrentPage] = useState(1);
  useEffect(() => {
    if (StayUpToDateComponents?.data) {
      const filteredComponents = StayUpToDateComponents.data.filter(
        (elem: any) => {
          return elem.display_name != "My Links" && elem.status === "active";
        }
      );
      setSelectedComponent(filteredComponents?.[0]?.name);
    }
  }, [StayUpToDateComponents?.data]);

  const renderCustomTooltip = (props: any) => {
    const { active, payload } = props;
    if (active && payload && payload.length) {
      const data = payload[0].payload;

      return (
        <div className="custom-tooltip">
          <p className="m-2 p-3px">{data.formatted_date}</p>
          <p className="user-clr p-3px">Clicks: {data.clicks}</p>
        </div>
      );
    }

    return null;
  };
  const CustomLegend = () => (
    <div className="clicks">
      <div className="user-clr">
        <ul>
          <li> Clicks</li>
        </ul>
      </div>
    </div>
  );
  return (
    <>
      <div className="segmented-container">
        {StayUpToDateComponents && StayUpToDateComponents?.data
          ? StayUpToDateComponents?.data
              .filter((elem: any) => elem.display_name !== "My Links")
              .filter((elem: any) => elem.status === "active")
              .map((elem: any, index: any) => (
                <React.Fragment key={index}>
                  <Button
                    onClick={(e: any) => {
                      e.preventDefault();
                      setSelectedComponent(elem.name);
                      setDefaultCurrentPage(1);
                    }}
                    style={{
                      background:
                        selectedComponent === elem.name ? "#3b3b3b" : "white",
                      color:
                        selectedComponent === elem.name ? "white" : "black",
                    }}
                  >
                    {elem.name}
                  </Button>
                </React.Fragment>
              ))
          : ""}
      </div>

      <div className="fullDiv">
        {StayUpToDateComponents &&
        StayUpToDateComponents.showingComponents &&
        StayUpToDateComponents.showingComponents.length ? (
          <>
            {
              <>
                {loadingCarouselClicksAnalytics ||
                loadingCarouselClicksViewsAnalytics ? (
                  <div className="spin">
                    <Spin />
                  </div>
                ) : (
                  <>
                    {selectedComponent ? (
                      <>
                        {carouselClicksAnalytics[selectedComponent] &&
                        carouselClicksAnalytics[selectedComponent].length ? (
                          <>
                            {daysNumber !== 1 && (
                              <>
                                {" "}
                                {carouselClicksViewsAnalytics[
                                  selectedComponent
                                ] &&
                                  carouselClicksViewsAnalytics[
                                    selectedComponent
                                  ].length && (
                                    <ResponsiveContainer
                                      width="100%"
                                      height={550}
                                    >
                                      <LineChart
                                        data={
                                          carouselClicksViewsAnalytics[
                                            selectedComponent
                                          ]
                                        }
                                        margin={{
                                          top: 15,
                                          right:
                                            window.innerWidth <= 768 ? 0 : 30,
                                          left:
                                            window.innerWidth <= 768 ? 0 : 20,
                                          bottom: 5,
                                        }}
                                      >
                                        <XAxis
                                          dataKey="formatted_date"
                                          stroke="grey"
                                          strokeWidth={StaticVariables().X_AXIS_STROKE_WIDTH}
                                        />
                                        <YAxis
                                          stroke="grey"
                                          width={60}
                                          tick={{ dx: 0 }}
                                          strokeWidth={StaticVariables().Y_AXIS_STROKE_WIDTH}
                                        />
                                        <Tooltip
                                          content={renderCustomTooltip}
                                        />
                                        <Legend content={CustomLegend} />
                                        <Line
                                          type="monotone"
                                          dataKey="clicks"
                                          stroke="#8685EF"
                                          activeDot={{ r: 8 }}
                                          strokeWidth={StaticVariables().LINE_STROKE_WIDTH}
                                        />
                                      </LineChart>
                                    </ResponsiveContainer>
                                  )}
                              </>
                            )}

                            <div>
                              <ChartClicksTable
                                loadingPostClicksAnalytics={
                                  loadingCarouselClicksAnalytics
                                }
                                chartClicks={
                                  carouselClicksAnalytics[selectedComponent]
                                }
                                defaultCurrentPage={defaultCurrentPage}
                                setDefaultCurrentPage={setDefaultCurrentPage}
                                brand_color={brand_color}
                                carouselViews={carouselViews}
                                loadingCarouselViews={loadingCarouselViews}
                                selectedComponent={selectedComponent}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="total-count">
                              <div className="analytics-titles">
                                {!loadingCarouselViews &&
                                carouselViews[selectedComponent] &&
                                carouselViews[selectedComponent]?.[0]?.clicks >
                                  0 ? (
                                  <StatBox
                                    isLoading={loadingCarouselViews}
                                    name="Page Views"
                                    number={
                                      carouselViews[selectedComponent]?.[0]
                                        ?.clicks
                                    }
                                    classname="stat-page"
                                    brand_color={brand_color}
                                  />
                                ) : (
                                  <h5 style={{ fontWeight: "500" }}>
                                    No data to show for "{selectedComponent}".
                                  </h5>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <div className="spin">
                        <Spin />
                      </div>
                    )}
                  </>
                )}
              </>
            }
          </>
        ) : (
          <p>No data to show!</p>
        )}
      </div>
    </>
  );
};

export default ContentCharts;
