import React, { useEffect, useState } from "react";
import Search from "../../Search/search";
import { useFeedPostsPagination } from "../../../Utils/customHooks/useFeedPostsPagination";
import FeedPosts from "../../skeleton/Views/skeletonComponents/feedPosts";
import ClipLoader from "react-spinners/ClipLoader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetAlternateQuery } from "../../../Utils/customHooks/useGetAlternateQuery";
import CreatorFeed from "../../CreatorFeed/creatorFeed";
import { useLoadFeedContent } from "../../../Utils/customHooks/useLoadFeedContent";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLoadParams } from "../../../Utils/customHooks/useLoadParams";
import { useLoadNecData } from "../../../Utils/customHooks/useLoadNecData";
import { useFeedPostsPaginationEmbedding } from "../../../Utils/customHooks/useFeedPostsPaginationEmbedding";
import { useLoadFeedContentEmbedding } from "../../../Utils/customHooks/useLoadFeedContentEmbedding";
import { useLoadAdData } from "../../../Utils/customHooks/useLoadAdData";
import { useLoadFirstEmbedding } from "../../../Utils/customHooks/useLoadFirstEmbedding";
import StayUpToDate from "../../StayUpToDateComponent/StayUpToDate";
const POSTS_PER_PAGE = 5;

const Feed = ({
  indexPages,
  isNecSearch,
  username,

  page,
  name,
  controls,
  activeFilters,
  highlights,
  customCategories,
  userId,
  hashtagFilterArray,
  avgWords,
  showSocials,
  contentType,
  contentPresent,
  is_nec_present,
  search_bar_text,
  search_bar_tags,
  contentOrder,
  isSearchPage,
  brand_color,
  brand_text_color,
  isFilloutOn,
  setShowFeedData,
  showFeedData,
  showSearchbar,
  showSearch,
  setShowSearchLoading
}: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const param = useParams();
  const [query, setQuery] = useState("");
  const [defaultCategory, setDefaultCategory] = useState("");

  const [category, setCategory] = useState("");
  const [filter, setFilter] = useState(activeFilters);
  const [activeHighlights, setActiveHighlights] = useState(highlights);

  const [trending, setTrending] = useState(contentType);
  const [queryFilter, setQueryFilter] = useState<any>();
  const [loadingFeed, setLoadingFeed] = useState<any>(true);
  const [extraId, setExtraId] = useState<any>();
  const [adsIndex, setAdsIndex] = useState<any>(0);
  const {
    showAds,
    showComments,
    showCaption,
    openAiSearch,
    maxHashtags,
    maxPopularSearches,

    checkedType,
  } = controls;
  const { data: pages }: any = useFeedPostsPagination(
    username,
    [userId],
    query,
    category,
    POSTS_PER_PAGE,
    filter,
    activeHighlights,
    openAiSearch,
    extraId,
    trending,
    showSocials,
    checkedType,
    defaultCategory,
    contentOrder

  );
  const { data: firstEmbedding }: any = useLoadFirstEmbedding(
    username,
    [userId],
    query,
    category,
    POSTS_PER_PAGE,
    filter,
    activeHighlights,
    openAiSearch,
    extraId,
    trending,
    showSocials,
    pages?.feedCount,
    
  );
  // console.log("first", firstEmbedding);
  const { data: pagesEmbedding }: any = useFeedPostsPaginationEmbedding(
    username,
    [userId],
    query,
    category,
    POSTS_PER_PAGE,
    filter,
    activeHighlights,
    openAiSearch,
    extraId,
    trending,
    showSocials,
    firstEmbedding?.threshold
  );
  const {
    isLoading,
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  }: any = useLoadFeedContent(
    username,
    [userId],
    query,
    category,
    showAds,
    // adsIndex,
    filter,
    activeHighlights,
    POSTS_PER_PAGE,
    pages?.totalPages || 0,
    pages?.feedCount,
    pages?.postIds,
    openAiSearch,
    extraId,
    trending,
    avgWords,
    showSocials,
    checkedType,
    defaultCategory,
    contentOrder
  );
  const { isLoading: necLoading, data: necData }: any = useLoadNecData(
    username,
    [userId],
    query,
    category,
    is_nec_present
  );
  const { isLoading: adLoading, data: adData }: any = useLoadAdData(
    username,
    [userId],
    query,
    category,
    adsIndex,
    showAds
  );
  const {
    isLoading: embeddingLoading,
    data: embeddingData,
    hasNextPage: hasEmbeddingNextPage,
    fetchNextPage: fetchEmbeddingNextPage,
    isFetchingNextPage: isFetchingEmbeddingNextPage,
    refetch: refetchEmbeddingData,
  }: any = useLoadFeedContentEmbedding(
    username,
    [userId],
    query,
    category,
    showAds,
    // adsIndex,
    filter,
    activeHighlights,
    POSTS_PER_PAGE,
    pagesEmbedding?.totalPages || 0,
    pagesEmbedding?.feedCount,
    pagesEmbedding?.postIds,
    openAiSearch,
    extraId,
    trending,
    avgWords,
    showSocials,
    firstEmbedding?.threshold,
    contentOrder
  );

  let necDataIncluded = false;

  // console.log("fts", pages, data);
  // console.log("embed", pagesEmbedding, embeddingData);
  // console.log("necc", necData);

  const mergedData =
    pages && pages?.totalPages !== 0 && data?.pages
      ? processPages(
        data.pages,
        pages?.feedCount,
        adData,
        necData,
        embeddingData?.pages,
        true
      )
      : pagesEmbedding !== 0 && pagesEmbedding?.totalPages != 0
        ? processEmbeddingPages(
          embeddingData?.pages,
          pagesEmbedding?.feedCount,
          adData,
          necData
        )
        : necData?.length > 0
          ? processNecData(adData, necData)
          : "";

  function processPages(
    pagesData: any[],
    dataCount: any,
    adData: any[],
    necData: any[],
    embeddingData?: any[],
    ftsData?: any
  ) {
    let currentAdOrder = 0;
    const maxAdOrder = adData?.length;
    return pagesData
      ? pagesData
        .flatMap((page: any) => page)
        .filter((post: any) => post?.post_id)
        .reduce((result: any[], post: any, index: number) => {
          if (result.length + 1 < dataCount) {
            result.push(post);
          } else {
            result.push(post);

            if (!necLoading) {
              if (necData && necData.length >= 0 && !necDataIncluded) {
                const filteredNecData = necData.filter(
                  (necPost) =>
                    !result.some(
                      (resPost) => resPost?.post_id === necPost?.post_id
                    )
                );
                filteredNecData.forEach((necPost) => {
                  result.push(necPost);

                  if (
                    result &&
                    result.length > 0 &&
                    result.length % 10 === 0
                  ) {
                    if (adData && adData.length) {
                      if (currentAdOrder >= maxAdOrder) {
                        currentAdOrder = 0;
                        const currentAd = adData.find(
                          (ad) => ad.ads_order === currentAdOrder
                        );
                        if (currentAd) {
                          result.push(currentAd);
                          currentAdOrder++;
                        }
                      } else {
                        const currentAd = adData.find(
                          (ad) => ad.ads_order === currentAdOrder
                        );
                        if (currentAd) {
                          result.push(currentAd);
                          currentAdOrder++;
                        }
                      }
                    }
                  }
                });
                necDataIncluded = true;
              } else {
                if (embeddingData && embeddingData.length) {
                  embeddingData
                    .flatMap((page: any) => page)
                    .filter((post: any) => post?.post_id)
                    .reduce((embedResult: any[], post: any) => {
                      const isAllowedDuplicate = post.index === "active,ad";
                      const isDuplicate = result.some(
                        (uniquePost) => uniquePost.post_id === post?.post_id
                      );
                      if (!isDuplicate || isAllowedDuplicate) {
                        result.push(post);
                      }

                      if (result.length % 10 === 0) {
                        if (adData && adData.length) {
                          if (currentAdOrder >= maxAdOrder) {
                            currentAdOrder = 0;
                          }

                          const currentAd = adData.find(
                            (ad) => ad.ads_order === currentAdOrder
                          );
                          if (currentAd) {
                            result.push(currentAd);
                            currentAdOrder++;
                          }
                        }
                      }

                      return result;
                    }, []); // Pass an empty array as the initial value for embedResult
                }
              }
            }
            if (necDataIncluded && embeddingData?.length) {
              embeddingData
                .flatMap((page: any) => page)
                .filter((post: any) => post?.post_id)
                .reduce((embedResult: any[], post: any) => {
                  const isAllowedDuplicate = post.index === "active,ad";
                  const isDuplicate = result.some(
                    (uniquePost) => uniquePost.post_id === post?.post_id
                  );
                  if (!isDuplicate || isAllowedDuplicate) {
                    result.push(post);
                  }
                  if (
                    result &&
                    result.length > 0 &&
                    result.length % 10 === 0
                  ) {
                    if (adData && adData.length) {
                      if (currentAdOrder >= maxAdOrder) {
                        currentAdOrder = 0;
                        const currentAd = adData.find(
                          (ad) => ad.ads_order === currentAdOrder
                        );
                        if (currentAd) {
                          result.push(currentAd);
                          currentAdOrder++;
                        }
                      } else {
                        const currentAd = adData.find(
                          (ad) => ad.ads_order === currentAdOrder
                        );
                        if (currentAd) {
                          result.push(currentAd);
                          currentAdOrder++;
                        }
                      }
                    }
                  }
                });
            }
          }
          // }

          if (result && result.length > 0 && result.length % 10 === 0) {
            if (adData && adData.length) {
              if (currentAdOrder >= maxAdOrder) {
                currentAdOrder = 0;
                const currentAd = adData.find(
                  (ad) => ad.ads_order === currentAdOrder
                );
                if (currentAd) {
                  result.push(currentAd);
                  currentAdOrder++;
                }
              } else {
                const currentAd = adData.find(
                  (ad) => ad.ads_order === currentAdOrder
                );
                if (currentAd) {
                  result.push(currentAd);
                  currentAdOrder++;
                }
              }
            }
          }

          return result;
        }, [])
      : "";
  }
  function processEmbeddingPages(
    pagesData: any[],
    dataCount: any,
    adData: any[],
    necData: any[],
    embeddingData?: any[],
    ftsData?: any
  ) {
    let currentAdOrder = 0;
    const maxAdOrder = adData?.length;
    return pagesData
      ? pagesData
        .flatMap((page: any) => page)
        .filter((post: any) => post?.post_id)
        .reduce((result: any[], post: any) => {
          const isAllowedDuplicate = post?.index === "active,ad";
          const isDuplicate = result.some(
            (uniquePost) => uniquePost?.post_id === post?.post_id
          );
          if (!isDuplicate || isAllowedDuplicate) {
            if (is_nec_present) {
              // if () {
              if (
                necData !== undefined &&
                necData &&
                necData.length >= 0 &&
                !necDataIncluded
              ) {
                const filteredNecData = necData.filter(
                  (necPost) =>
                    !result.some(
                      (resPost) => resPost?.post_id === necPost?.post_id
                    )
                );
                // result.push(...filteredNecData);
                filteredNecData.forEach((necPost) => {
                  result.push(necPost);

                  // Add an ad after every 10 posts
                  if (
                    result &&
                    result.length > 0 &&
                    result.length % 10 === 0
                  ) {
                    if (adData && adData.length) {
                      // result.push(...adData);
                      if (currentAdOrder >= maxAdOrder) {
                        currentAdOrder = 0;
                        const currentAd = adData.find(
                          (ad) => ad.ads_order === currentAdOrder
                        );
                        if (currentAd) {
                          result.push(currentAd);
                          currentAdOrder++;
                        }
                      } else {
                        const currentAd = adData.find(
                          (ad) => ad.ads_order === currentAdOrder
                        );
                        if (currentAd) {
                          result.push(currentAd);
                          currentAdOrder++;
                        }
                      }
                    }
                  }
                });
                necDataIncluded = true;
              }

              if (necDataIncluded) {
                if (
                  !result.some(
                    (resPost) => resPost?.post_id === post?.post_id
                  )
                ) {
                  result.push(post);
                }
              }
            } else {
              if (
                !result.some((resPost) => resPost?.post_id === post?.post_id)
              ) {
                result.push(post);
              }
            }
          }

          if (result && result.length > 0 && result.length % 10 === 0) {
            if (adData && adData.length) {
              if (currentAdOrder >= maxAdOrder) {
                currentAdOrder = 0;
                const currentAd = adData.find(
                  (ad) => ad.ads_order === currentAdOrder
                );
                if (currentAd) {
                  result.push(currentAd);
                  currentAdOrder++;
                }
              } else {
                const currentAd = adData.find(
                  (ad) => ad.ads_order === currentAdOrder
                );
                if (currentAd) {
                  result.push(currentAd);
                  currentAdOrder++;
                }
              }
            }
          }

          return result;
        }, [])
      : "";
  }
  function processNecData(adData: any[], necData: any[]) {
    let currentAdOrder = 0; // Track the current ad order
    const maxAdOrder = adData?.length;
    return necData
      ? necData
        .flatMap((page: any) => page)
        .filter((post: any) => post?.post_id)
        .reduce((result: any[], post: any, index: number) => {
          const isAllowedDuplicate = post.index === "active,ad";
          const isDuplicate = result.some(
            (uniquePost) => uniquePost.post_id === post?.post_id
          );

          if (!isDuplicate || isAllowedDuplicate) {
            if (is_nec_present) {
              if (necData && necData.length && !necDataIncluded) {
                const filteredNecData = necData.filter(
                  (necPost) =>
                    !result.some(
                      (resPost) => resPost?.post_id === necPost?.post_id
                    )
                );
                // result.push(...filteredNecData);
                filteredNecData.forEach((necPost) => {
                  result.push(necPost);

                  // Add an ad after every 10 posts
                  if (
                    result &&
                    result.length > 0 &&
                    result.length % 10 === 0
                  ) {
                    if (adData && adData.length) {
                      // result.push(...adData);
                      if (currentAdOrder >= maxAdOrder) {
                        currentAdOrder = 0;
                        const currentAd = adData.find(
                          (ad) => ad.ads_order === currentAdOrder
                        );
                        if (currentAd) {
                          result.push(currentAd);
                          currentAdOrder++;
                        }
                      } else {
                        const currentAd = adData.find(
                          (ad) => ad.ads_order === currentAdOrder
                        );
                        if (currentAd) {
                          result.push(currentAd);
                          currentAdOrder++;
                        }
                      }
                    }
                  }
                });
                necDataIncluded = true;
              }
            }
          }

          return result;
        }, [])
      : "";
  }
  // console.log("merged", mergedData);

  const {
    isLoading: queryLoading,
    data: queryData,
    error,
  }: any = useLoadParams(query, category, location);
  useEffect(() => {
    if (!queryLoading && queryData) {
      // Assuming queryData contains properties for 'query' and 'category'
      const { term, from } = queryData;
      // Update state variables using your state setters (setQuery and setCategory)
      setQuery(term);
      setCategory(from);
    } else {
      setQuery("");
      setCategory("");
    }
  }, [queryLoading, queryData]);

  const getQuery = (queryTerm: any, comingFrom: any) => {
    setLoadingFeed(true);
    setTimeout(() => {
      setLoadingFeed(false);
    }, 100);

    setQueryFilter(contentType);
    setQuery(queryTerm);
    setCategory(comingFrom);
    // setSearchParam(comingFrom);
    setShowFeedData(true);
    // refetchFilterFeedCount();
    let paramObj: any = {};
    if (
      queryTerm === "" &&
      comingFrom !== "" &&
      comingFrom !== undefined &&
      comingFrom !== contentType
    ) {
      paramObj.term = encodeURIComponent(queryTerm);
      paramObj.from = comingFrom;
      param?.id
        ? navigate(`/${username}?${new URLSearchParams(paramObj).toString()}`)
        : navigate(`?${new URLSearchParams(paramObj).toString()}`);
    } else if (
      queryTerm === "" ||
      queryTerm === undefined ||
      comingFrom === undefined
    ) {
      if (location.pathname.includes("/creator")) {
        navigate(`/creator/${username}`);
      } else if (location.pathname.includes("/follower")) {
        navigate(`/follower/${username}`);
      } else {
        navigate(`/${username}`);
      }
    } else {
      paramObj.term = encodeURIComponent(queryTerm);
      paramObj.from = comingFrom;
      param?.id
        ? navigate(`/${username}?${new URLSearchParams(paramObj).toString()}`)
        : navigate(`?${new URLSearchParams(paramObj).toString()}`);
    }

    setFilter(activeFilters);
    // setLoadingFeed(false);
  };

  function handleFilter(choice: any) {
    setQueryFilter(undefined);
    if (choice === contentType) {
      setFilter(activeFilters.slice(1));
    } else {
      setFilter([choice]);
    }
  }
  function handleTrending(choice: any) {
    setTrending(choice);
  }

  const { data: altQuery } = useGetAlternateQuery(
    userId,
    isLoading,
    data,
    query || ""
  );

  function getExtraFeed(arr: any) {
    setExtraId(arr);
  }
  useEffect(() => {
    let timeout: any;

    if (!isLoading) {
      // If isLoading becomes false, set loadingFeed to false after 1 second
      timeout = setTimeout(() => {
        setLoadingFeed(false);
      }, 1000);
    }

    // Clear the timeout if the component unmounts or if isLoading becomes true again
    return () => {
      clearTimeout(timeout);
    };
  }, [isLoading]);

  return (
    <>
      {/* {param?.id ? ():()} */}
      <div id="app-wrapper">
        {isSearchPage && (
          <Search
            indexPages={indexPages}
            firstName={name}
            getQuery={getQuery}
            showTopHashtags={controls.showTopHashtags}
            showPopularSearches={controls.showPopularSearches}
            hashtagFilterArray={hashtagFilterArray}
            getExtraFeed={getExtraFeed}
            isNecSearch={isNecSearch}
            username={username}
            user_id={userId}
            result={altQuery}
            query={!queryLoading ? queryData?.term : ""}
            searchParam={
              !queryLoading && queryData !== undefined ? queryData?.from : " "
            }
            setQueryFilter={setQueryFilter}
            setLoadingFeed={setLoadingFeed}
            setTrending={setTrending}
            contentFilters={activeFilters}
            showFilters={controls?.showFilters}
            handleFilter={handleFilter}
            handleTrending={handleTrending}
            clickedFilter={queryFilter}
            showBrowserCategories={controls?.showBrowserCategories}
            customCategories={customCategories}
            showCustomCategories={controls?.showCustomCategories}
            showTrendingCategories={controls?.showTrendingCategories}
            showHighlights={controls?.showHighlights}
            activeHighlights={activeHighlights}
            setCategory={setCategory}
            contentType={contentType}
            searchBarText={search_bar_text}
            searchBarTags={search_bar_tags}
            
            setDefaultCategory={setDefaultCategory}
            showSearchbar={showSearchbar}
          />
        )}
        {showFeedData ? (
          <>
            {" "}
            {contentPresent ? (
              <>
                {category ? (
                  <>
                    {category !== "searchbar" ? (
                      loadingFeed || isLoading || pages === undefined ? (
                        <FeedPosts />
                      ) : mergedData.length > 0 ? (
                        <>
                          <InfiniteScroll
                            dataLength={mergedData.length}
                            next={async () => {
                              if (category === "searchbar") {
                                if (
                                  mergedData.length < pages?.feedCount &&
                                  mergedData.length > 0 &&
                                  pages?.totalPages > 0
                                ) {
                                  fetchNextPage();
                                } else {
                                  await fetchNextPage();
                                  fetchEmbeddingNextPage();
                                }
                              } else {
                                fetchNextPage();
                              }
                            }}
                            scrollThreshold={0.1}
                            hasMore={
                              category !== "searchbar"
                                ? Boolean(hasNextPage)
                                : Boolean(hasNextPage) ||
                                Boolean(hasEmbeddingNextPage)
                            }
                            loader={
                              (Boolean(hasNextPage) ||
                                Boolean(hasEmbeddingNextPage)) && (
                                <ClipLoader
                                  color="#5274f9"
                                  loading={true}
                                  className="feed-loader"
                                  size={30}
                                />
                              )
                            }
                            // endMessage={
                            //   <p className="end-message">
                            //     <b>You've seen it all :)</b>
                            //   </p>
                            // }
                            endMessage={
                              <p style={{
                                padding: "0.75rem 1.5rem",
                                backgroundColor: "white",
                                color: "black",
                                border: "1px solid #e5e7eb",
                                borderRadius: "0.5rem",
                                fontWeight: 500,
                                fontSize: "0.875rem",
                                alignItems: "center",
                                transition: "background-color 0.2s",
                                margin: "25px auto",
                                lineHeight: 1.2,
                                display: "block",
                                textAlign: "center",
                                cursor: "pointer",
                                width: "fit-content"
                              }}
                              // onMouseOver={(e) => e.currentTarget.style.backgroundColor = "#f9fafb"} 
                              // onMouseOut={(e) => e.currentTarget.style.backgroundColor = "white"}
                              >
                                You've seen it all :)
                              </p>
                            }

                          >
                            <CreatorFeed
                              data={mergedData}
                              showCaption={showCaption}
                              showComments={showComments}
                              isCreatorFeed={true}
                              username={username}
                              query={query}
                              shoppableContent={controls.shoppableContent}
                            />
                          </InfiniteScroll>
                        </>
                      ) : (
                        <CreatorFeed
                          data={[]}
                          showCaption={showCaption}
                          showComments={showComments}
                          isCreatorFeed={true}
                          username={username}
                          query={query}
                          shoppableContent={controls.shoppableContent}
                        />
                      )
                    ) : pages?.totalPages > 0 ? (
                      loadingFeed || isLoading ? (
                        <FeedPosts />
                      ) : mergedData.length > 0 ? (
                        <>
                          <InfiniteScroll
                            dataLength={mergedData.length}
                            next={() => {
                              if (category === "searchbar") {
                                // if(pages.feedCount)

                                if (
                                  mergedData.length < pages.feedCount &&
                                  mergedData.length > 0 &&
                                  pages?.totalPages > 0
                                ) {
                                  fetchNextPage();
                                } else {
                                  fetchEmbeddingNextPage();
                                  fetchNextPage();
                                }
                              } else {
                                fetchNextPage();
                              }
                            }}
                            scrollThreshold={0.1}
                            hasMore={
                              category !== "searchbar"
                                ? Boolean(hasNextPage)
                                : Boolean(hasNextPage) ||
                                Boolean(hasEmbeddingNextPage)
                            }
                            loader={
                              (Boolean(hasNextPage) ||
                                Boolean(hasEmbeddingNextPage)) && (
                                <ClipLoader
                                  color="#5274f9"
                                  loading={true}
                                  className="feed-loader"
                                  size={30}
                                />
                              )
                            }
                            // endMessage={
                            //   <p className="end-message">
                            //     <b>You've seen it all :)</b>
                            //   </p>
                            // }
                            endMessage={
                              <p style={{
                                padding: "0.75rem 1.5rem",
                                backgroundColor: "white",
                                color: "black",
                                border: "1px solid #e5e7eb",
                                borderRadius: "0.5rem",
                                fontWeight: 500,
                                fontSize: "0.875rem",
                                alignItems: "center",
                                transition: "background-color 0.2s",
                                margin: "25px auto",
                                lineHeight: 1.2,
                                display: "block",
                                textAlign: "center",
                                cursor: "pointer",
                                width: "fit-content"
                              }}
                              // onMouseOver={(e) => e.currentTarget.style.backgroundColor = "#f9fafb"} 
                              // onMouseOut={(e) => e.currentTarget.style.backgroundColor = "white"}
                              >
                                You've seen it all :)
                              </p>
                            }

                          >
                            <CreatorFeed
                              data={mergedData}
                              showCaption={showCaption}
                              showComments={showComments}
                              isCreatorFeed={true}
                              username={username}
                              query={query}
                              shoppableContent={controls.shoppableContent}
                            />
                          </InfiniteScroll>
                        </>
                      ) : (
                        <CreatorFeed
                          data={[]}
                          showCaption={showCaption}
                          showComments={showComments}
                          isCreatorFeed={true}
                          username={username}
                          query={query}
                          shoppableContent={controls.shoppableContent}
                        />
                      )
                    ) : pagesEmbedding && pagesEmbedding?.totalPages > 0 ? (
                      loadingFeed || embeddingLoading ? (
                        <FeedPosts />
                      ) : mergedData.length > 0 ? (
                        <>
                          <InfiniteScroll
                            dataLength={mergedData.length}
                            next={() => {
                              if (category === "searchbar") {
                                // if(pages.feedCount)

                                if (
                                  mergedData.length < pages.feedCount &&
                                  mergedData.length > 0 &&
                                  pages?.totalPages > 0
                                ) {
                                  fetchNextPage();
                                }
                                // if (
                                //   mergedData.length <
                                //   pages?.feedCount + pagesEmbedding?.feedCount
                                // )
                                else {
                                  // await fetchNextPage();
                                  fetchEmbeddingNextPage();
                                  // fetchNextPage()
                                }
                              } else {
                                fetchNextPage();
                                // fetchEmbeddingNextPage()
                              }
                            }}
                            scrollThreshold={0.1}
                            hasMore={
                              category !== "searchbar"
                                ? Boolean(hasNextPage)
                                : Boolean(hasNextPage) ||
                                Boolean(hasEmbeddingNextPage)
                            }
                            loader={
                              (Boolean(hasNextPage) ||
                                Boolean(hasEmbeddingNextPage)) && (
                                <ClipLoader
                                  color="#5274f9"
                                  loading={true}
                                  className="feed-loader"
                                  size={30}
                                />
                              )
                            }
                            // endMessage={
                            //   <p className="end-message">
                            //     <b>You've seen it all :)</b>
                            //   </p>
                            // }
                            endMessage={
                              <p style={{
                                padding: "0.75rem 1.5rem",
                                backgroundColor: "white",
                                color: "black",
                                border: "1px solid #e5e7eb",
                                borderRadius: "0.5rem",
                                fontWeight: 500,
                                fontSize: "0.875rem",
                                alignItems: "center",
                                transition: "background-color 0.2s",
                                margin: "25px auto",
                                lineHeight: 1.2,
                                display: "block",
                                textAlign: "center",
                                cursor: "pointer",
                                width: "fit-content"
                              }}
                              // onMouseOver={(e) => e.currentTarget.style.backgroundColor = "#f9fafb"} 
                              // onMouseOut={(e) => e.currentTarget.style.backgroundColor = "white"}
                              >
                                You've seen it all :)
                              </p>
                            }

                          >
                            <CreatorFeed
                              data={mergedData}
                              showCaption={showCaption}
                              showComments={showComments}
                              isCreatorFeed={true}
                              username={username}
                              query={query}
                              shoppableContent={controls.shoppableContent}
                            />
                          </InfiniteScroll>
                        </>
                      ) : pagesEmbedding.totalPages > 0 ? (
                        <FeedPosts />
                      ) : (
                        <CreatorFeed
                          data={[]}
                          showCaption={showCaption}
                          showComments={showComments}
                          isCreatorFeed={true}
                          username={username}
                          query={query}
                          shoppableContent={controls.shoppableContent}
                        />
                      )
                    ) : pagesEmbedding === undefined ? (
                      <FeedPosts />
                    ) : pagesEmbedding.feedCount === 0 &&
                      necLoading &&
                      necData === undefined ? (
                      <FeedPosts />
                    ) : necData?.length > 0 ? (
                      <InfiniteScroll
                        dataLength={mergedData.length}
                        next={() => {
                          if (category === "searchbar") {
                            // if(pages.feedCount)

                            if (
                              mergedData.length < pages.feedCount &&
                              mergedData.length > 0 &&
                              pages?.totalPages > 0
                            ) {
                              fetchNextPage();
                            }
                            // if (
                            //   mergedData.length <
                            //   pages?.feedCount + pagesEmbedding?.feedCount
                            // )
                            else {
                              // await fetchNextPage();
                              fetchEmbeddingNextPage();
                              // fetchNextPage()
                            }
                          } else {
                            fetchNextPage();

                            // fetchEmbeddingNextPage()
                          }
                        }}
                        scrollThreshold={0.1}
                        hasMore={
                          category !== "searchbar"
                            ? Boolean(hasNextPage)
                            : Boolean(hasNextPage) ||
                            Boolean(hasEmbeddingNextPage)
                        }
                        loader={
                          (Boolean(hasNextPage) ||
                            Boolean(hasEmbeddingNextPage)) && (
                            <ClipLoader
                              color="#5274f9"
                              loading={true}
                              className="feed-loader"
                              size={30}
                            />
                          )
                        }
                        // endMessage={
                        //   <p className="end-message">
                        //     <b>You've seen it all :)</b>
                        //   </p>
                        // }
                        endMessage={
                          <p style={{
                            padding: "0.75rem 1.5rem",
                            backgroundColor: "white",
                            color: "black",
                            border: "1px solid #e5e7eb",
                            borderRadius: "0.5rem",
                            fontWeight: 500,
                            fontSize: "0.875rem",
                            alignItems: "center",
                            transition: "background-color 0.2s",
                            margin: "25px auto",
                            lineHeight: 1.2,
                            display: "block",
                            textAlign: "center",
                            cursor: "pointer",
                            width: "fit-content"
                          }}
                          // onMouseOver={(e) => e.currentTarget.style.backgroundColor = "#f9fafb"} 
                          // onMouseOut={(e) => e.currentTarget.style.backgroundColor = "white"}
                          >
                            You've seen it all :)
                          </p>
                        }

                      >
                        <CreatorFeed
                          data={mergedData}
                          showCaption={showCaption}
                          showComments={showComments}
                          isCreatorFeed={true}
                          username={username}
                          query={query}
                          shoppableContent={controls.shoppableContent}
                        />
                      </InfiniteScroll>
                    ) : (
                      <CreatorFeed
                        data={[]}
                        showCaption={showCaption}
                        showComments={showComments}
                        isCreatorFeed={true}
                        username={username}
                        query={query}
                        shoppableContent={controls.shoppableContent}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {contentType === "Landing Page" ? (
                      <StayUpToDate
                        user_id={userId}
                        username={username}
                        brand_color={brand_color}
                        isFilloutOn={isFilloutOn}
                        showSearchbar={showSearchbar}
                        searchBarText={search_bar_text}
                        getQuery={getQuery}
                        setLoadingFeed={setLoadingFeed}
                        setCategory={setCategory}
                        showSearch={showSearch}
                        setShowSearchLoading={setShowSearchLoading}

                      />
                    ) : (
                      <>
                        {loadingFeed || isLoading || pages === undefined ? (
                          <FeedPosts />
                        ) : mergedData.length > 0 ? (
                          <>
                            <InfiniteScroll
                              dataLength={mergedData.length}
                              next={async () => {
                                if (category === "searchbar") {
                                  if (
                                    mergedData.length < pages?.feedCount &&
                                    mergedData.length > 0 &&
                                    pages?.totalPages > 0
                                  ) {
                                    fetchNextPage();
                                  } else {
                                    await fetchNextPage();
                                    fetchEmbeddingNextPage();
                                  }
                                } else {
                                  fetchNextPage();
                                }
                              }}
                              scrollThreshold={0.1}
                              hasMore={
                                category !== "searchbar"
                                  ? Boolean(hasNextPage)
                                  : Boolean(hasNextPage) ||
                                  Boolean(hasEmbeddingNextPage)
                              }
                              loader={
                                (Boolean(hasNextPage) ||
                                  Boolean(hasEmbeddingNextPage)) && (
                                  <ClipLoader
                                    color="#5274f9"
                                    loading={true}
                                    className="feed-loader"
                                    size={30}
                                  />
                                )
                              }
                              // endMessage={
                              //   <p className="end-message">
                              //     <b>You've seen it all :)</b>
                              //   </p>
                              // }
                              endMessage={
                                <p style={{
                                  padding: "0.75rem 1.5rem",
                                  backgroundColor: "white",
                                  color: "black",
                                  border: "1px solid #e5e7eb",
                                  borderRadius: "0.5rem",
                                  fontWeight: 500,
                                  fontSize: "0.875rem",
                                  alignItems: "center",
                                  transition: "background-color 0.2s",
                                  margin: "25px auto",
                                  lineHeight: 1.2,
                                  display: "block",
                                  textAlign: "center",
                                  cursor: "pointer",
                                  width: "fit-content"
                                }}
                                // onMouseOver={(e) => e.currentTarget.style.backgroundColor = "#f9fafb"} 
                                // onMouseOut={(e) => e.currentTarget.style.backgroundColor = "white"}
                                >
                                  You've seen it all :)
                                </p>
                              }
                            >
                              <CreatorFeed
                                data={mergedData}
                                showCaption={showCaption}
                                showComments={showComments}
                                isCreatorFeed={true}
                                username={username}
                                query={query}
                                shoppableContent={controls.shoppableContent}
                              />
                            </InfiniteScroll>
                          </>
                        ) : (
                          <CreatorFeed
                            data={[]}
                            showCaption={showCaption}
                            showComments={showComments}
                            isCreatorFeed={true}
                            username={username}
                            query={query}
                            shoppableContent={controls.shoppableContent}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <div className="creator-feed-div-not-found">
                <p>Coming Soon :)</p>
                <p className="font-1">
                  Your page will be ready in the next 24 hours. We'll email you
                  as soon as it is ready!
                </p>
              </div>
            )}
          </>
        ) : null}
        {/* </>} */}
      </div>
    </>
  );
};

export default Feed;
