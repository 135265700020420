import { Button } from "antd";
import React from "react";
import { Card } from "react-bootstrap";
import { BsHourglassSplit } from "react-icons/bs";
import "../StayUpToDateComponent/StayUpToDate.css";
import { getMediaSrc } from "../../Utils/StayUpToDate/RenderComponent";
import ProductName from "../ProductName/ProductName";

const IndividualImagePostTag = React.memo((props: any) => {
  const {
    data,
    brand_color,
    title,
    redirectVariable,
    isLinks,
    isDashboard,
    type,
    isYoutube,
    isHasMediaUrl,
    productName,
    isCarousel,
    media_url,
    contentShape
  } = props;

  const getWrapperClassName = () => {
    const baseClass = "";
    const classes = [baseClass];

    if (contentShape) classes.push("square-wrapper");
    else if (isYoutube) classes.push("youtube-feed-wrapper");
    else if (isHasMediaUrl) classes.push("media-feed-wrapper");
    // else if (!data?.media_url) classes.push("not-available");

    return classes.join(" ");
  };

  const getImageClassName = () => {
    const baseClass = "card-image-individual";
    const classes = [baseClass];

    if (contentShape) classes.push("square-image");
    else if (isYoutube) classes.push("youtube-feed-height");
    else if (isHasMediaUrl) classes.push("media-feed-height");

    return classes.join(" ");
  };
  const redirectUrl = !isLinks
    ? data?.permalink || data?.shop_link || data?.table_url
    : data?.shop_link || data?.table_url || data?.permalink;
  return (
    <Card>
      <a
        href={redirectUrl}
        target="_blank"
        onClick={(e) => {
          e.preventDefault();
          props.handleRedirect(
            data?.post_id || data?.table_id,

            getMediaSrc(type, data, isCarousel, media_url),
            title,
            type === "content" || type === "query-content"
              ? data?.content_title || data?.product_name
              : data?.shop_title || data?.product_name || data?.table_name
          );
          if (
            (data?.shop_link && data?.shop_link !== "") ||
            (data?.permalink && data?.permalink !== "") ||
            (data?.table_url && data?.table_url !== "")
          ) {
            window.open(
              redirectUrl,
              "_blank"
            );
          }
        }}
      >
        {!data?.media_url && !data?.table_image ? (
          data?.platform === "newsletter" || data?.platform === "linkedin" || data?.platform === "twitter" ? (
            <>
              <div
                className={`creator-feed-image-wrapper-links ${getWrapperClassName()}`}
                style={{
                  textAlign: "center",
                  height: "100%",
                  borderRadius: "1rem",
                  color: "#000",
                }}
              >
                <div className={`newsletter-media-carousel ${getImageClassName()} no-media-url`} 
                // style={{backgroundColor: "black", color: "white", borderRadius: "1rem", border: "1px solid #e6e7eb"}}
                >
                <h4 className="newsletter-label">
                    {" "}
                    {data?.platform === "newsletter"
                      ? "Newsletter"
                      : data?.platform === "linkedin"
                      ? "LinkedIn Post"
                      : "Twitter Post"}{" "}
                  </h4>
                  <h4 className="mt-7 date">
                    {new Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                      day: "numeric",
                      month: "long",
                    }).format(new Date(data?.post_date))}
                  </h4>
                </div>
                <>

                 <ProductName type={type} post={data}/>
                </>
              </div>
            </>
          ) : data?.platform === "instagram" ? (
            <div
            className={getWrapperClassName()}
          >
            <Card.Img
              src={data?.thumbnail}
              className={
                getImageClassName()
              }
              loading="eager"
              style={{ backgroundColor: brand_color && brand_color }}
            />

            <ProductName type={type} post={data}/>           

            {isLinks && (
              <Button className={"template-shop-btn-links-individual"}>
                Shop
              </Button>
            )}
          </div>
          ) : (
            <div className={"creator-feed-card-links not-available"}>
              <BsHourglassSplit className="hourglassSplit" />
              <p>
                Work in progress: This content will be available in a few
                minutes.
              </p>
            </div>
          )
        ) : (
          <div
            className={getWrapperClassName()}
          >
            <Card.Img
              src={getMediaSrc(type, data, isCarousel, media_url)}
              className={
                getImageClassName()
              }
              loading="eager"
              style={{ backgroundColor: brand_color && brand_color }}
            />

            <ProductName type={type} post={data}/>           

            {isLinks && (
              <Button className={"template-shop-btn-links-individual"}>
                Shop
              </Button>
            )}
          </div>
        )}
      </a>
    </Card>
  );
});

export default IndividualImagePostTag;
