import React, { useEffect, useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import "./StayUpToDateComponentsWrapper.css";
import { MdOutlineDragIndicator } from "react-icons/md";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Button, Modal } from "antd";
import {
  deleteComponent,
  updateIndexValue,
} from "../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import SubComponents from "./SubComponents";
import EditCustomBanner from "./EditTemplates/EditCustomBanner";
import EditHighlightLinks from "./EditTemplates/EditHighlightLinks";
import StayUpToDateSkeleton from "./StayUpToDateSkeleton";
import EditYesterdayLinks from "./EditTemplates/EditYesterdayLinks";
import EditProductBank from "./EditTemplates/EditProductBank";
import EditQueryContent from "../../LandingPageCarousels/QueryBasedCarousel/EditQueryBasedCarousel";
import StatusSelector from "../../../../components/StatusSelector/StatusSelector";
import EditYesterdayContent from "./EditTemplates/EditYesterdayContent";
import CopyComponent from "../../../../components/CopyComponent/CopyComponent";
import EditQueryBasedCarousel from "../../LandingPageCarousels/QueryBasedCarousel/EditQueryBasedCarousel";
import EditCustomContent from "../../LandingPageCarousels/CustomCarousel/EditCustomContent";
import EditCustomLinks from "../../LandingPageCarousels/CustomCarousel/EditCustomLinks";

const ArchivedComponentsWrapper = (props: any) => {
  const {
    username,
    user_id,
    StayUpToDateComponents,
    StayUpToDateComponentsLoading,
    refetch,
    brand_color,
    refatchStayUpToDate,
  } = props;
  const [localData, setLocalData] = useState(StayUpToDateComponents);

  const [selectedComponent, setSelectedComponent] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [initial, setInitial] = useState(false);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isContentEditModalOpen, setIsContentEditModalOpen] = useState(false);
  const [isLinksEditModalOpen, setIsLinksEditModalOpen] = useState(false);
  const [isYesterdayLinksEditModalOpen, setIsYesterdayLinksEditModalOpen] =
    useState(false);
  const [isYesterdayContentEditModalOpen, setIsYesterdayContentEditModalOpen] =
    useState(false);
  const [isQueryLinksEditModalOpen, setIsQueryLinksEditModalOpen] =
    useState(false);

  const [isBannerEditModalOpen, setIsBannerEditModalOpen] = useState(false);
  const [isProductBankEditModalOpen, setIsProductBankEditModalOpen] =
    useState(false);
  const [initialContent, setInitialContent] = useState(false);
  const [initialLinks, setInitialLinks] = useState(false);
  const [isHighlightEditModalOpen, setIsHighlightEditModalOpen] =
    useState(false);

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  useEffect(() => {
    setLocalData(StayUpToDateComponents);
  }, [StayUpToDateComponents, status]);

  useEffect(() => {
    refetch();
  }, [status, StayUpToDateComponents]);

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }
    const newData = [...localData];

    const [removed] = newData.splice(result.source.index, 1);

    newData.splice(result.destination.index, 0, removed);
    setLocalData([...newData]);
    updateIndexValue(user_id, newData);
  };
  async function handleDelete(name: any) {
    setSelectedComponent(name);
    setOpenDeleteModal(true);
  }
  async function handleEdit(name: any) {
    if (name.type === "content") {
      setIsContentEditModalOpen(true);
    } else if (name.type === "links") {
      setIsLinksEditModalOpen(true);
    } else if (name.type === "query-links" || name.type === "query-content") {
      setIsQueryLinksEditModalOpen(true);
    } else if (name.display_name === "This Week Content") {
      setIsYesterdayContentEditModalOpen(true);
    } else if (
      name.display_name.split(",").includes("Yesterday") ||
      name.display_name.split(",").includes("This Week") ||
      name.display_name.split(",").includes("Monday") ||
      name.display_name.split(",").includes("Tuesday") ||
      name.display_name.split(",").includes("Wednesday") ||
      name.display_name.split(",").includes("Thursday") ||
      name.display_name.split(",").includes("Friday") ||
      name.display_name.split(",").includes("Saturday") ||
      name.display_name.split(",").includes("Sunday") ||
      name.display_name.split(",").includes("This Weekend")
    ) {
      setIsYesterdayLinksEditModalOpen(true);
    } else if (name.type === "product-bank") {
      setIsProductBankEditModalOpen(true);
    } else if (name.type === "banner") {
      setIsBannerEditModalOpen(true);
    } else if (name.type === "highlight") {
      setIsHighlightEditModalOpen(true);
    }

    setSelectedComponent(name);
  }
  const handleOk = async () => {
    setConfirmLoading(true);
    const newData = [...localData];
    const filteredData = newData.filter(
      (item) => item.name !== selectedComponent
    );
    const dataToDelete = newData.filter(
      (item) => item.name === selectedComponent
    );
    await deleteComponent(
      selectedComponent,
      user_id,
      filteredData,
      dataToDelete,
      refetch,
      username
    ).then(() => {
      setLocalData(filteredData);
      setConfirmLoading(false);
      setOpenDeleteModal(false);
    });
  };

  const handleCancel = () => {
    setOpenDeleteModal(false);
  };

  return (
    <div className="stay-up-to-date-wrapper">
      {StayUpToDateComponentsLoading ? (
        <div className="loader-container">
          <div className="component-box">
            <StayUpToDateSkeleton />
          </div>
        </div>
      ) : (
        <>
          {localData && localData.length > 0 ? (
            <>
              <DragDropContext
                onDragStart={() => {
                  document.documentElement.style.scrollBehavior = "unset";
                  document.body.style.scrollBehavior = "unset";
                }}
                onDragEnd={(result: any) => {
                  onDragEnd(result);
                }}
              >
                <Droppable droppableId="element">
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {localData &&
                        localData.map((component: any, index: any) => (
                          // <div key={index}>
                          <Draggable
                            key={component.index?.toString()}
                            draggableId={component.index?.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                {" "}
                                <div
                                  key={component.id}
                                  className="component-box"
                                >
                                  <div className="component-item">
                                    <div className="component-text">
                                      <MdOutlineDragIndicator className="outline-drag" />
                                      <span className="component-name">
                                        {component.display_name === "Form" &&
                                        component.name === ""
                                          ? null
                                          : component.name}
                                      </span>
                                    </div>
                                    <div className="button-container">
                                      <CopyComponent
                                        user_id={user_id}
                                        username={username}
                                        component={component}
                                      />
                                      <StatusSelector
                                        defaultValue={component.status}
                                        component={component}
                                        user_id={user_id}
                                        refetch={refetch}
                                        refatchStayUpToDate={
                                          refatchStayUpToDate
                                        }
                                        setStatus={setStatus}
                                      />
                                      {component.type === "links" ||
                                      component.type === "query-links" ||
                                      component.type === "query-content" ||
                                      component.type === "content" ||
                                      component.type === "banner" ||
                                      component.type === "highlight" ||
                                      component.type === "product-bank" ||
                                      component.display_name
                                        .split(",")
                                        .includes("Monday") ||
                                      component.display_name
                                        .split(",")
                                        .includes("Tuesday") ||
                                      component.display_name
                                        .split(",")
                                        .includes("Wednesday") ||
                                      component.display_name
                                        .split(",")
                                        .includes("Thursday") ||
                                      component.display_name
                                        .split(",")
                                        .includes("Friday") ||
                                      component.display_name
                                        .split(",")
                                        .includes("Saturday") ||
                                      component.display_name
                                        .split(",")
                                        .includes("Sunday") ||
                                      component.display_name
                                        .split(",")
                                        .includes("This Weekend") ||
                                      component.display_name
                                        .split(",")
                                        .includes("Yesterday") ||
                                      component.display_name
                                        .split(",")
                                        .includes("This Week") ||
                                      component.display_name
                                        .split(",")
                                        .includes("This Week Content") ? (
                                        <Button
                                          className="edit-btn"
                                          onClick={() => {
                                            handleEdit(component);
                                          }}
                                        >
                                          Edit
                                        </Button>
                                      ) : (
                                        ""
                                      )}
                                      <Button
                                        className="remove-btn"
                                        onClick={() =>
                                          handleDelete(component.name)
                                        }
                                      >
                                        Remove
                                      </Button>
                                    </div>
                                  </div>
                                  <SubComponents
                                    user_id={user_id}
                                    username={username}
                                    component={component}
                                    selectedComponent={selectedComponent}
                                    setSelectedComponent={setSelectedComponent}
                                    isEdit={isEdit}
                                    setIsEdit={setIsEdit}
                                    isEditModalOpen={isEditModalOpen}
                                    setIsEditModalOpen={setIsEditModalOpen}
                                    refetch={refetch}
                                    brand_color={brand_color}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </>
          ) : (
            <p>No archived components</p>
          )}
          <Modal
            open={openDeleteModal}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Yes"
            confirmLoading={confirmLoading}
          >
            <p className="alertText">
              Are you sure you don't want to show{" "}
              <span className="alertTextTitle">{selectedComponent}</span>{" "}
              section ?{" "}
            </p>
          </Modal>
          <EditCustomContent
            username={username}
            user_id={user_id}
            isContentEditModalOpen={isContentEditModalOpen}
            setIsContentEditModalOpen={setIsContentEditModalOpen}
            type="content"
            component={selectedComponent}
            setSelectedComponent={setSelectedComponent}
            refetch={refetch}
            initial={initialContent}
            setInitial={setInitialContent}
          />
          <EditCustomLinks
            username={username}
            user_id={user_id}
            isLinksEditModalOpen={isLinksEditModalOpen}
            setIsLinksEditModalOpen={setIsLinksEditModalOpen}
            type="links"
            component={selectedComponent}
            setSelectedComponent={setSelectedComponent}
            refetch={refetch}
            initial={initialLinks}
            setInitial={setInitialLinks}
          />

          <EditProductBank
            username={username}
            user_id={user_id}
            isProductBankEditModalOpen={isProductBankEditModalOpen}
            setIsProductBankEditModalOpen={setIsProductBankEditModalOpen}
            type="product-bank"
            component={selectedComponent}
            setSelectedComponent={setSelectedComponent}
            refetch={refetch}
            initial={initial}
            setInitial={setInitial}
          />
          <EditYesterdayLinks
            username={username}
            user_id={user_id}
            isYesterdayLinksEditModalOpen={isYesterdayLinksEditModalOpen}
            setIsYesterdayLinksEditModalOpen={setIsYesterdayLinksEditModalOpen}
            type="links"
            component={selectedComponent}
            setSelectedComponent={setSelectedComponent}
            refetch={refetch}
          />
          <EditYesterdayContent
            username={username}
            user_id={user_id}
            isYesterdayLinksEditModalOpen={isYesterdayContentEditModalOpen}
            setIsYesterdayLinksEditModalOpen={
              setIsYesterdayContentEditModalOpen
            }
            type="links"
            component={selectedComponent}
            setSelectedComponent={setSelectedComponent}
            refetch={refetch}
          />
          <EditQueryBasedCarousel
            username={username}
            user_id={user_id}
            isQueryLinksEditModalOpen={isQueryLinksEditModalOpen}
            setIsQueryLinksEditModalOpen={setIsQueryLinksEditModalOpen}
            type="query"
            component={selectedComponent}
            setSelectedComponent={setSelectedComponent}
            refetchDashboard={refetch}
            initial={initial}
            setInitial={setInitial}
            edit={edit}
            setEdit={setEdit}
            editIndex={editIndex}
            setEditIndex={setEditIndex}
          />

          <EditCustomBanner
            username={username}
            user_id={user_id}
            isBannerEditModalOpen={isBannerEditModalOpen}
            setIsBannerEditModalOpen={setIsBannerEditModalOpen}
            type="banner"
            component={selectedComponent}
            setSelectedComponent={setSelectedComponent}
            refetch={refetch}
          />
          <EditHighlightLinks
            username={username}
            user_id={user_id}
            isHighlightEditModalOpen={isHighlightEditModalOpen}
            setIsHighlightEditModalOpen={setIsHighlightEditModalOpen}
            type="highlight"
            component={selectedComponent}
            setSelectedComponent={setSelectedComponent}
            refetch={refetch}
          />
        </>
      )}
    </div>
  );
};

export default ArchivedComponentsWrapper;
