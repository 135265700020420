import React, { useState } from "react";
import '../../../../styles/StayUptoDateCSS/SubComponents.css'
import track, {
  TRACKING_TYPES,
} from "../../../../Utils/SupabaseServices/Track";
import { useLocation } from "react-router-dom";
import { useLoadQueryFeed } from "./useLoadQueryFeed";
import { StaticVariables } from "../../../../Utils/StaticVariables";
import { getPostTitle } from "../../../../Utils/HelperFunction/HelperFunction";
import DataSkeleton from "../../../../components/StayUpToDateComponent/UpToDateComponents/DataSkeleton";
import { useDomLoaded } from "../../../../hooks/DomLoadedContext";
import DashboardCarouselStructure from "../../../../components/CarouselStructure/DashboardCarouselStructure";
export const QueryContentDashboard = (props: any) => {

  const { user_id, username, numberOfDays, isLinks, brand_color, component } =
    props;
  const {
    key,
    name: title,
    postIds,
    cta,
    type,
    query,
    removed_postids: removedPostIds,
    content_order,
    content_shape,
    content_sound,
  } = component;
  const location = useLocation();
  const domLoaded = useDomLoaded();
  const isShopLinks = false;
  const showSocials = StaticVariables().CONTENT_PLATFORMS;
  const contentOrder = "Default";
  function handleShop(postId: string, media_url: string) {
    track("Redirect to shop link", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: { post_id: postId, media_url: media_url },
      user_id: user_id,
      title: "Stay Up To Date",
    });
    // updateRefClick(postId, user_id);
  }
  const {
    isLoading,
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  }: any = useLoadQueryFeed(
    username,
    user_id,
    query,

    showSocials,
    contentOrder,
    isShopLinks,
    removedPostIds,
    title,
    content_order
  );
  return (
    <>
      <div>
        {isLoading || !domLoaded ? (
          <div className="outer-container-subcomponents">
            <DataSkeleton dashboard={true} />{" "}
          </div>
        ) : (
          <div className="outer-container-subcomponents">
            {data && data.length ? (
              <div className={`links-grid-container-subcomponents ${content_shape === "sq" ? 'square-grid' : ''}`}>
                {data &&
                  data.map((link: any, index: any) => {
                    const productTitle = getPostTitle(link, type);
                    return (
                      <React.Fragment key={index}>
                        <DashboardCarouselStructure
                          link={link}
                          index={index}
                          handleShop={handleShop}
                          title={title}
                          brand_color={brand_color}
                          isLinks={isLinks}
                          productName={type ? productTitle : ""}
                          contentShape={content_shape == "sq" ? true : false}
                          contentSound={content_sound}
                          type={type}
                        />
                      </React.Fragment>
                    );
                  })}
              </div>
            ) : (
              <div className="outer-container-subcomponents">
                <p>No content at this time</p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

