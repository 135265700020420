import React, { useCallback, useEffect, useState } from "react";
import { useLoadCustomContent } from "../../../../Utils/customHooks/UpToDateContent/useLoadCustomContent";
import { Button, Carousel, Input, Pagination, Space } from "antd";
import VideoComponent from "../../../../components/VideoComponent/VideoComponent";
import Skeleton from "react-loading-skeleton";
import SearchInput from "../../../../components/SearchInput/SearchInput";
import { useLoadSearchFeedCount } from "../../../../Utils/customHooks/UpToDateContent/useLoadSearchFeedCount";
import { useLoadSearchFeed } from "../../../../Utils/customHooks/UpToDateContent/useLoadSearchFeed";
import { StaticVariables } from "../../../../Utils/StaticVariables";
import _ from "lodash";
import { HiCheck, HiPencil } from "react-icons/hi";
import { supabase } from "../../../../config/supabaseClient";
import { getPostTitle } from "../../../../Utils/HelperFunction/HelperFunction";
import { UpdateCombinedText } from "../../../../Utils/SupabaseServices/ContentDatabase";
import CarouselFilterControl from "../../../../components/CarouselControls/CarouselFilterControl";
import CarouselSearchbar from "../../../../components/CarouselControls/CarouselSearchbar";
import CarouselShapeControl from "../../../../components/CarouselControls/CarouselShapeControl";
import CarouselSoundControl from "../../../../components/CarouselControls/CarouselSoundControl";
import Title from "antd/es/typography/Title";

const POSTS_PER_PAGE = 10;
const contentOrder = "Default";

const EditCustomFeedComponent = (props: any) => {
  const {
    username,
    user_id,
    component,
    defaultQuery,
    initial,
    isModalOpen,
    handleFeed,
    type,
    activeFilter,
    setActiveFilter,
    setDefaultQuery,
    isSoundOn,
    order,
    setOrder,
    shape,
    setShape,
    setIsSoundOn,
    edit, setEdit, editIndex, setEditIndex
  } = props;

  const isShopLinks =
    component.type !== "query-content" && component.type !== "content";
  const [localPostIds, setLocalPostIds] = useState(
    component.post_ids.split(",")
  );
  const [isActionLoading, setIsActionLoading] = useState(false);
  const showSocials =
    type === "content"
      ? [
          "instagram",
          "youtube",
          "tiktok",
          "podcast",
          "pinterest",
          "newsletter",
          "twitter",
          "linkedin",

          // "instaComment"
          "MISC-content",
        ]
      : activeFilter === "both"
      ? StaticVariables().PLATFORMS
      : activeFilter === "content"
      ? [
          "instagram",
          "youtube",
          "tiktok",
          "podcast",
          "pinterest",
          "newsletter",
          "twitter",
          "linkedin",
          "MISC-content",
        ]
      : activeFilter === "products"
      ? ["LTK", "ShopMy", "Amazon", "MISC"]
      : activeFilter === "products"
      ? ["LTK", "ShopMy", "Amazon", "MISC"]
      : StaticVariables().PLATFORMS;
  useEffect(() => {
    setLocalPostIds(component.post_ids.split(","));
    setCurrentPage(1);
  }, [initial]);

  const {
    isLoading: AddedDataLoading,
    data: AddedData,
    refetch: refetchAddedContent,
  }: // forceRefetch,
  any = useLoadCustomContent(
    username,
    user_id,
    localPostIds,
    // component.post_ids,
    component.name
  );
  const [query, setQuery] = useState(component?.query || "");
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    setQuery(defaultQuery);
  }, [defaultQuery]);
  const { data: pages, refetch: refetchCount }: any = useLoadSearchFeedCount(
    username,
    user_id,
    query,
    POSTS_PER_PAGE,
    showSocials,
    isShopLinks,
    activeFilter
  );

  const {
    isLoading,
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  }: any = useLoadSearchFeed(
    currentPage,
    username,
    user_id,
    query,
    POSTS_PER_PAGE,
    pages?.totalPages || 0,
    pages?.feedCount,
    pages?.postIds,
    showSocials,
    contentOrder,
    isShopLinks,
    activeFilter
  );
  const FILTER_OPTIONS = [
    { key: "both", label: "Both" },
    { key: "content", label: "Content" },
    { key: "products", label: "Products" },
  ];

  const buttonStyle = (isActive: any) => ({
    backgroundColor: isActive ? "black" : "white",
    color: isActive ? "white" : "black",
  });
  return (
    <>
      {" "}
      <div className="margin-added-content">
        <div className="addedContent">
          <h5 className="addedContentTitle">{component.name}</h5>
          <PostStructure
            username={username}
            user_id={user_id}
            component={component}
            PostIdData={AddedData}
            localPostIds={localPostIds}
            setLocalPostIds={setLocalPostIds}
            handleFeed={handleFeed}
            type={component?.type}
            refetchAddedContent={refetchAddedContent}
            contentType="added"
            shape={shape}
            isSoundOn={isSoundOn}
            edit={edit}
            setEdit={setEdit}
            editIndex={editIndex}
            setEditIndex={setEditIndex}
            isActionLoading={isActionLoading}
            setIsActionLoading={setIsActionLoading}
          />
        </div>
        <Title level={3}>Carosuel Control</Title>
        <div className="addedContent">
          <CarouselShapeControl shape={shape} setShape={setShape} />
          <CarouselSoundControl
            isSoundOn={isSoundOn}
            setIsSoundOn={setIsSoundOn}
          />
        </div>
        <CarouselFilterControl
          type={type}
          setActiveFilter={setActiveFilter}
          activeFilter={activeFilter}
          setCurrentPage={setCurrentPage}
        />
        {/* {isSearchbar !== false && ( */}
        {/* <SearchInput
          placeholder={
            isShopLinks
              ? "Search all shoppable content and products"
              : "Search content"
          }
          id="search-feed"
          value={query}
          onChange={(e: any) => {
            e.preventDefault();
            const elem: any = document.getElementById("search-feed");
            const searchVal = e.target.value;
            setQuery(e.target.value);
            setDefaultQuery(e.target.value);
            setCurrentPage(1);
          }}
          handleKeyUp={(e: any) => {
            e.preventDefault();
            if (e.keyCode === 13) {
              const elem: any = document.getElementById("search-feed");
              const searchVal = e.target.value;
              if (searchVal.length === 0) {
                elem.blur();
                setQuery("");
                setDefaultQuery("");
                return;
              }
              elem.blur();
              setQuery(searchVal);
              setDefaultQuery(searchVal);
              setCurrentPage(1);
            }
          }}
          isActiveFilter={Boolean(query)}
          onSearchClick={() => {
            const elem: any = document.getElementById("search-feed");
            const searchVal = elem.value;
            if (searchVal.length === 0) {
              return;
            }
            elem.blur();
            setQuery(searchVal);
            setDefaultQuery(searchVal);
            setCurrentPage(1);
          }}
          onClearClick={() => {
            const elem: any = document.getElementById("search-feed");
            elem.value = "";
            setQuery("");
            setActiveFilter("both");
            setDefaultQuery("");
            // refetch();
            setCurrentPage(1);
            // setPostIds(postIds);
          }}
        /> */}
        <CarouselSearchbar
          isShopLinks={isShopLinks}
          query={query}
          setQuery={setQuery}
          setDefaultQuery={setDefaultQuery}
          setCurrentPage={setCurrentPage}
          setActiveFilter={setActiveFilter}
          refetch={refetch}
          // postIds={postIds}
          // setPostIds={setPostIds}
        />
      </div>
      <PostStructure
        username={username}
        user_id={user_id}
        component={component}
        PostIdData={data?.pages?.[0]}
        postLoading={isLoading}
        localPostIds={localPostIds}
        setLocalPostIds={setLocalPostIds}
        handleFeed={handleFeed}
        type={component?.type}
        contentType="all"
        edit={edit}
        setEdit={setEdit}
        editIndex={editIndex}
        setEditIndex={setEditIndex}
        isActionLoading={isActionLoading}
        setIsActionLoading={setIsActionLoading}
      />
      {pages?.feedCount > POSTS_PER_PAGE && (
        <Pagination
          className="pagination"
          current={currentPage}
          total={pages?.feedCount}
          size="small"
          showSizeChanger={false}
          showQuickJumper
          pageSize={POSTS_PER_PAGE}
          onChange={(page: number) => setCurrentPage(page)}
        />
      )}
    </>
  );
};

// const AddedContentTable=(props:any)=>{
//     return (   )
// }

const PostStructure = (props: any) => {
  const {
    username,
    user_id,
    component,
    PostIdData,
    isLoading,
    localPostIds,
    setLocalPostIds,
    type,
    refetchAddedContent,
    contentType,
    shape, isSoundOn, edit, setEdit, editIndex, setEditIndex, isActionLoading, setIsActionLoading
  } = props;
  const [value, setValue] = useState<string>();





  const SkeletonStatBox = () => {
    return (
      <div className="skeletonDiv">
        <Skeleton className="skeletonStyle" />
      </div>
    );
  };
  const debouncedSetCheckedValues = _.debounce((updatedArray) => {
    // setCheckedValues(updatedArray);
    props.handleFeed(updatedArray);
    setLocalPostIds(updatedArray);
  }, 100);

  const onAdd = useCallback(
    async (post_id: string) => {
      setIsActionLoading(true);
      const updatedCheckedValues = [post_id, ...localPostIds];
      try {
        await Promise.all([
          debouncedSetCheckedValues(updatedCheckedValues),
        ]);
        await new Promise(resolve => setTimeout(resolve, 1000));
      } finally {
        setIsActionLoading(false);
      }
      // if (Array.isArray(checkedValues)) {
      //   const updatedCheckedValues = [post_id, ...checkedValues];
      //   debouncedSetCheckedValues(updatedCheckedValues);
      // }
    },
    [props]
    // [checkedValues, props]
  );
  // const onMinus = (post_id: string) => {

  //   let updatedPostArray = checkedValues.filter(
  //     (postId: any) => postId !== post_id
  //   );

  //   setCheckedValues(updatedPostArray);
  //   props.handleFeed(updatedPostArray);
  //   // setCheckedValues(updatedPostArray);
  // };

  const onMinus = useCallback(
    async (post_id: string) => {
      setIsActionLoading(true);
      let updatedPostArray = localPostIds.filter(
        (postId: any) => postId !== post_id
      );
      try {
        await Promise.all([
          debouncedSetCheckedValues(updatedPostArray),
        ]);
        await new Promise(resolve => setTimeout(resolve, 1000));
      } finally {
        setIsActionLoading(false);
      }
      // Filter out the post_id to remove it from the checkedValues array
      // let updatedPostArray = checkedValues.filter(
      //   (postId: string) => postId !== post_id
      // );
      // debouncedSetCheckedValues(updatedPostArray);
      // Update state and pass to parent component's handleFeed
      // setCheckedValues(updatedPostArray);
      // props.handleFeed(updatedPostArray);
    },
    [props]

    // [checkedValues, props]
  );
  const handleDone = async (post_id: string) => {
    const postData = PostIdData.find((post: any) => post.post_id === post_id);
    postData.product_name = value;
    if (type === "content" || type === "query-content") {
      const { data: user, error } = await supabase
        .from(`${username}`)
        .update({ content_title: value })
        .eq("post_id", post_id)
        .eq("user_id", user_id);
      const { data: newContent, error: newErroor } = await supabase
        .from("product_bank")
        .update({ content_title: value })
        .eq("product_id", post_id)
        .eq("user_id", user_id);
      postData.content_title = value;
    } else {
      const { data: user, error } = await supabase
        .from(`${username}`)
        .update({ shop_title: value, caption: value, product_name: value })
        .eq("post_id", post_id)
        .eq("user_id", user_id);
      const { data: newContent, error: newErroor } = await supabase
        .from("product_bank")
        .update({ shop_title: value })
        .eq("product_id", post_id)
        .eq("user_id", user_id);
      postData.shop_title = value;
    }
    // setIsChange(true);

    const { data: newContent, error: newErroor } = await supabase
      .from("content_new")
      .update({ product_name: value })
      .eq("post_id", post_id)
      .eq("user_id", user_id);
    UpdateCombinedText(username, user_id, post_id);
    // refetch();
    // PostIdDataRefetch();
    // refetchDashboard();
    refetchAddedContent();
    setEdit(false);
    setEditIndex("");
  };
  return (
    <>
      {isLoading || isActionLoading ? (
        <div className="data-skeleton">
          {[...Array(3)].map((_, index) => (
            <div className={"m-0px"}>
              <Skeleton
                className="skeleton"
                style={{ marginRight: "20px", width: "140px", height: "220px" }}
              />
            </div>
          ))}
        </div>
      ) : (
        <>
          {PostIdData?.filter((element: any) => element !== undefined) ? (
            <div className="addedSvg">
              {PostIdData.filter((element: any) => element !== undefined)
                .length ? (
                PostIdData.filter((element: any) => element !== undefined).map(
                  (post: any, index: any) => (
                    <React.Fragment key={index}>
                      <div
                        key={index}
                        className="feed-tab-post-container added-post"
                      >
                        <div
                          className="feed-tab-media-wrapper"
                          style={
                            post?.platform === "newsletter" ||
                            post?.platform === "linkedin" ||
                            post?.platform === "twitter"
                              ? {
                                  textAlign: "center",
                                  height: "100%",
                                  borderRadius: "1rem",
                                }
                              : {}
                          }
                        >
                          {Array.isArray(localPostIds) &&
                          localPostIds?.includes(post.post_id) ? (
                            <Button
                              onClick={() => onMinus(post.post_id)}
                              className="carousel-content-remove-btn"
                            >
                              Remove
                            </Button>
                          ) : (
                            <Button
                              onClick={() => onAdd(post.post_id)}
                              className="carousel-content-add-btn"
                            >
                              Add
                            </Button>
                          )}

                          {post?.platform === "newsletter" ? (
                            <div
                              className={`${
                                post?.type === "FEED" || shape === "sq"
                                  ? "square-wrapper"
                                  : ""
                              } no-media-url` }
                              // style={{backgroundColor: "black", color: "white", borderRadius: "1rem", border: "1px solid #e6e7eb"}}
                            >
                              <div
                                className={`${
                                  post?.type === "FEED" || shape === "sq"
                                    ? "newsletter-media-carousel square-image"
                                    : "newsletter-media-carousel h-250"
                                }`}
                              >
                                <h4 className="newsletter-label">
                                  {" "}
                                  Newsletter
                                </h4>
                                <h4 className="mt-7 date">
                                  {new Intl.DateTimeFormat("en-US", {
                                    year: "numeric",
                                    day: "numeric",
                                    month: "long",
                                  }).format(new Date(post.post_date))}
                                </h4>
                              </div>
                            </div>
                          ) : post?.platform === "linkedin" &&
                            !post?.media_url ? (
                            <div
                              className={`${
                                post?.type === "FEED" || shape === "sq"
                                  ? "square-wrapper"
                                  : ""
                              } no-media-url`}
                              // style={{backgroundColor: "black", color: "white"}}
                            >
                              <div
                                className={`${
                                  post?.type === "FEED" || shape === "sq"
                                    ? "newsletter-media-carousel square-image"
                                    : "newsletter-media-carousel h-250"
                                }`}
                              >
                                <h4 className="newsletter-label">
                                  LinkedIn Post
                                </h4>
                                <h4 className="mt-7 date">
                                  {new Intl.DateTimeFormat("en-US", {
                                    year: "numeric",
                                    day: "numeric",
                                    month: "long",
                                  }).format(new Date(post.post_date))}
                                </h4>
                              </div>
                            </div>
                          ) : post?.platform === "twitter" &&
                            !post?.media_url ? (
                            <div
                              className={`${
                                post?.type === "FEED" || shape === "sq"
                                  ? "square-wrapper"
                                  : ""
                              } no-media-url`}
                              // style={{backgroundColor: "black", color: "white"}}
                            >
                              <div
                                className={`${
                                  post?.type === "FEED" || shape === "sq"
                                    ? "newsletter-media-carousel square-image"
                                    : "newsletter-media-carousel h-250"
                                }`}
                              >
                                <h4 className="newsletter-label">
                                  Twitter Post
                                </h4>
                                <h4 className="mt-7 date">
                                  {new Intl.DateTimeFormat("en-US", {
                                    year: "numeric",
                                    day: "numeric",
                                    month: "long",
                                  }).format(new Date(post.post_date))}
                                </h4>
                              </div>
                            </div>
                          ) : (
                            <div
                              className={`${
                                post?.type === "FEED" || shape === "sq"
                                  ? "square-grid"
                                  : ""
                              }`}
                            >
                              {post?.media_url ? (
                                <div
                                  className={`${
                                    post?.type === "FEED" || shape === "sq"
                                      ? "square-wrapper"
                                      : ""
                                  }`}
                                >
                                  {post?.media_url.split(",").length > 1 &&
                                  post?.media_url
                                    .split(",")
                                    .every((url: any) => url.trim() !== "") ? (
                                    <Carousel
                                      dotPosition="bottom"
                                      className="feed-tab-media-carousel"
                                    >
                                      {post?.media_url
                                        .split(",")
                                        .map((media_url: any, index: any) => (
                                          <React.Fragment key={index}>
                                            {media_url.includes("video") ? (
                                              <VideoComponent
                                                media_url={media_url}
                                                thumbnail={
                                                  post.thumbnail &&
                                                  post.thumbnail.split(",")
                                                    .length &&
                                                  post.thumbnail.split(",")[
                                                    index
                                                  ]
                                                    ? post.thumbnail.split(",")[
                                                        index
                                                      ]
                                                    : "/assets/dummy_video.png"
                                                }
                                                content_shape={shape}
                                                isSoundOn={isSoundOn}
                                              />
                                            ) : (
                                              <img
                                                className={`${
                                                  post?.type === "FEED" ||
                                                  shape === "sq"
                                                    ? "feed-tab-media-check square-image mb-2"
                                                    : "feed-tab-media-check mb-2"
                                                }`}
                                                src={media_url}
                                                alt="post"
                                              />
                                            )}
                                          </React.Fragment>
                                        ))}
                                    </Carousel>
                                  ) : (
                                    <>
                                      {post.media_url.includes("video") ? (
                                        <div
                                          className={`${
                                            post?.type === "FEED" ||
                                            shape === "sq"
                                              ? "square-wrapper"
                                              : "h-255"
                                          }`}
                                        >
                                          <VideoComponent
                                            media_url={post.media_url}
                                            thumbnail={post.thumbnail}
                                            content_shape={shape}
                                            isSoundOn={isSoundOn}
                                          />
                                        </div>
                                      ) : (
                                        <img
                                          className={`${
                                            post?.type === "FEED" ||
                                            shape === "sq"
                                              ? "feed-tab-media-check square-image"
                                              : "feed-tab-media-check"
                                          }`}
                                          src={
                                            post.media_url
                                              ? post.media_url
                                              : "/assets/dummy_video.png"
                                          }
                                          alt="post"
                                        />
                                      )}
                                    </>
                                  )}
                                </div>
                              ) : (
                                <div
                                  className={`${
                                    post?.type === "FEED" || shape === "sq"
                                      ? "square-wrapper"
                                      : ""
                                  }`}
                                >
                                  <img
                                    className={`${
                                      post?.type === "FEED" || shape === "sq"
                                        ? "feed-tab-media-check square-image"
                                        : "feed-tab-media-check"
                                    }`}
                                    src={
                                      post?.thumbnail
                                        ? post?.thumbnail
                                        : "/assets/dummy_video.png"
                                    }
                                    alt="post"
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        {contentType !== "all" ? (
                          <>
                            {" "}
                            {edit === true && editIndex === index ? (
                              <>
                                <Input
                                  value={value}
                                  onChange={(e: any) =>
                                    setValue(e.target.value)
                                  }
                                  className="w-80"
                                />{" "}
                                <HiCheck
                                  onClick={() => {
                                    handleDone(post.post_id);
                                  }}
                                />
                              </>
                            ) : (
                              <p
                                style={{
                                  textAlign:
                                    post?.shop_title !== "" ||
                                    post?.product_name !== "" ||
                                    post?.content_title !== ""
                                      ? "left"
                                      : "center",
                                }}
                              >
                                {getPostTitle(post, type)}
                                <HiPencil
                                  onClick={() => {
                                    const nameValue = getPostTitle(post, type);
                                    setValue(nameValue);
                                    setEdit(true);
                                    setEditIndex(index);
                                  }}
                                />
                              </p>
                            )}
                          </>
                        ) : (
                          <p
                            style={{
                              textAlign:
                                post?.shop_title !== "" ||
                                post?.product_name !== "" ||
                                post?.content_title !== ""
                                  ? "left"
                                  : "center",
                            }}
                          >
                            {getPostTitle(post, type)}
                          </p>
                        )}
                      </div>
                    </React.Fragment>
                  )
                )
              ) : (
                <React.Fragment>
                  {/* <p>No Added Data</p> */}
                  <>
                    <div
                      // key={index}
                      // className="feed-tab-post-container"
                      className="feed-tab-post"
                    >
                      {[...Array(4)].map((_, index) => (
                        <SkeletonStatBox key={index} />
                      ))}
                    </div>
                  </>
                </React.Fragment>
              )}
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default EditCustomFeedComponent;
