import { useInfiniteQuery, useQuery } from "react-query";
import { StaticVariables } from "../../../../Utils/StaticVariables";
import { supabase } from "../../../../config/supabaseClient";

/**
 * Load content
 */

export function useLoadQueryBasedFeedCount(
  username: any,
  user_id: any[],
  queryTerm: any,
  postsPerPage: number,

  showSocials: any[],
  isShopLinks: any,
  order:any
  // activeFilter?:any
) {
  postsPerPage = 10;
  queryTerm = queryTerm && queryTerm.toLowerCase().replace(/\s+/g, " ").trim();

  async function getCount() {
    let feedCount;
    let postIds: any = [];
    const elementsCount = 5000;
    if (isShopLinks) {
      if (queryTerm) {
        const connectingWords = StaticVariables().connectingWords;

        let words = queryTerm.split(" ");

        let singleWords: any = [];
        if (
          words.length === 1 &&
          !StaticVariables().SEARCH_EXCEPTIONS.includes(queryTerm)
        ) {
          const suffixRegex = /(s)$/i;
          if (suffixRegex.test(words[0])) {
            words = [`${words[0].replace(suffixRegex, "")}`];
          }
        }
        if (words.includes("shop") || words.includes("buy")) {
          words = words.filter(
            (word: any) => word !== "shop" && word !== "buy"
          );
        }

        let filteredWords = words.filter(
          (word: any) => !connectingWords.includes(word.toLowerCase())
        );

        const result = filteredWords.join(" ");
        const pgQuery = result.toLowerCase().replace(/\s+/g, " & ");

        singleWords = filteredWords.map((word: any) => `%%${word}%%`);
        const { data: countResult, error: countError }: any =
          await supabase.rpc("semantic_ssearchcountftspostiddshopmodall", {
            query_string: pgQuery,
            similarity_threshold: StaticVariables().THRESHOLD,
            match_count: elementsCount,
            userid: user_id,
            username: username,
            socials: showSocials,
            single: [`%%${queryTerm}%%`],
          });
        // const { data, error } = await supabase.rpc("get_productbankcountti", {
        //   query_string: result || "",
        //   userid: user_id,
        //   query_tagg: result,
        //   index_type:'active'
        // });
        let resCount = countResult.length;
        // let resCount = countResult.length + data?.[0]?.count;
        postIds = countResult.map((item: any) => item.post_id);

        feedCount = resCount;
      } else {
        let filteredArray = StaticVariables().TYPES;
        const { data: content, error: contentError } = await supabase.rpc(
          "get_generalfffeedcountshop",
          {
            userid: user_id,

            filter: filteredArray,
            query_str: queryTerm ? queryTerm : "",
            user_name: username,
            socials: showSocials,
          }
        );
        feedCount = content[0].count;
      }
    } else {
      if (queryTerm) {
        const connectingWords = StaticVariables().connectingWords;

        let words = queryTerm.split(" ");

        let singleWords: any = [];
        if (
          words.length === 1 &&
          !StaticVariables().SEARCH_EXCEPTIONS.includes(queryTerm)
        ) {
          const suffixRegex = /(s)$/i;
          if (suffixRegex.test(words[0])) {
            words = [`${words[0].replace(suffixRegex, "")}`];
          }
        }
        if (words.includes("shop") || words.includes("buy")) {
          words = words.filter(
            (word: any) => word !== "shop" && word !== "buy"
          );
        }

        let filteredWords = words.filter(
          (word: any) => !connectingWords.includes(word.toLowerCase())
        );

        singleWords = filteredWords.map((word: any) => `%%${word}%%`);

        const result = filteredWords.join(" ");
        const pgQuery = result.toLowerCase().replace(/\s+/g, " & ");
        const { data: countResult, error: countError }: any =
          await supabase.rpc("semantic_ssearchcountftspostiddmodall", {
            query_string: pgQuery,

            similarity_threshold: StaticVariables().THRESHOLD,
            match_count: elementsCount,
            userid: user_id,
            username: username,
            socials: showSocials,
            single: [`%%${queryTerm}%%`],
          });


        let resCount = countResult.length;

        // let resCount = countResult.length + data?.[0]?.count;

        postIds = countResult.map((item: any) => item.post_id);

        feedCount = resCount;

      } else {
        let filteredArray = StaticVariables().TYPES;
        const { data: content, error: contentError } = await supabase.rpc(
          "get_generalfffeedcountcontent",
          {
            userid: user_id,

            filter: filteredArray,
            query_str: queryTerm ? queryTerm : "",
            user_name: username,
            socials: showSocials,
          }
        );

        feedCount = content[0].count;
      }
    }

    return {
      totalPages: Math.ceil(feedCount / postsPerPage),
      feedCount: feedCount,
      postIds: postIds || null,
    };
  }

  return useQuery(["getQueryBasedFeedCount", queryTerm, isShopLinks,order], getCount, {
    // refetchOnWindowFocus: true,
  });
}
