import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import "../AccountsIntegration/AccountsIntegration.css";
import { useLoadSocialLinks } from "../../Utils/customHooks/useLoadSocialLinks";
import {
  FaAmazon,
  FaApple,
  FaDiscord,
  FaFacebookSquare,
  FaLinkedin,
  FaSnapchatGhost,
  FaSpotify,
} from "react-icons/fa";
import { RiShoppingBagFill } from "react-icons/ri";
import { IoLogoTiktok, IoMailOutline } from "react-icons/io5";
import { BsInstagram, BsPinterest, BsSearch, BsYoutube } from "react-icons/bs";
import { MdContactMail, MdOutlinePodcasts } from "react-icons/md";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorderSocialLinks } from "../../Utils/SupabaseServices/UserDatabase";
import { HiLink } from "react-icons/hi";
import { IoIosAddCircle } from "react-icons/io";
import { GrFormEdit } from "react-icons/gr";
import AvailableSocialLinksModal from "./AvailableSocialLinksModal";
import ContactMeModal from "../ContactMe/ContactMeModal";
import { useParams } from "react-router-dom";
import DraggableActiveSocialLinks from "./DraggableActiveSocialLinks";
import { ColorModal } from "../ColorModal/ColorModal";
import { useLoadControls } from "../../Utils/customHooks/useLoadControls";

const ActiveSocialLinks = (props: any) => {
  const {
    isUserDashboard,
    defaultColor,
    defaultTextColor,
    defaultEdit,
    setDefaultEdit
  } = props;
  const { user } = useAuth();
  const user_id = user?.id || "";
  const { username } = useParams();
  const [linksLoading, setLinksLoading] = useState(false);

  const [iconList, setIconList] = useState<any>([]);
  const [showAvailableIcons, setShowAvailableIcons] = useState(false);
  const [integrationModal, setIntegrationModal] = useState("add-icons");
  const [showLinkIndex, setShowLinkIndex] = useState("");
  const [isContactModalVisible, setIsContactModalVisible] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [textColor, setTextColor] = useState(defaultTextColor);
  const [localDefaultColor, setLocalDefaultColor] = useState("");
  const [localDefaultTextColor, setLocalDefaultTextColor] = useState("");
  const [textColorLoading, setTextColorLoading] = useState<any>(false);

  const {
    isLoading,

    data: socialLinks,
    refetch,
  } = useLoadSocialLinks(user_id);

  useEffect(() => {
    setLinksLoading(true);
    const filteredIcons = icons
      .filter((icon) => icon.show)
      .map((icon, index) => ({
        ...icon,
        index: socialLinks?.order.indexOf(icon.key.toLowerCase()),
      }))
      .sort((a, b) => a.index - b.index);
    setIconList(filteredIcons);
    setLinksLoading(false);
  }, [socialLinks, socialLinks?.order]);

  useEffect(() => {
    setLocalDefaultColor(defaultColor);
    setLocalDefaultTextColor(defaultTextColor);
  }, [defaultColor, defaultTextColor]);

  const handleColor = (bgColor: string, textColor: string) => {
    setTextColorLoading(true);
    setTextColor(textColor);
    setLocalDefaultTextColor(textColor);
    setLocalDefaultColor(bgColor);
    setTimeout(() => {
      setTextColorLoading(false);
    }, 500);
  };
  const page = "follower";

  const {
    isLoading: controlsLoading,
    data: controls,
    refetch: refetchControls,
  }: any = useLoadControls(user_id || "", page);

  useEffect(() => {
    refetch();
  }, [textColorLoading]);
  const icons = [
    {
      key: "Instagram",
      icon: <BsInstagram />,
      show: socialLinks?.active?.hasOwnProperty("instagram") ? true : false,
      name: "instagram",
      link: socialLinks?.active?.["instagram"],
    },
    {
      key: "TikTok",
      icon: <IoLogoTiktok />,
      show: socialLinks?.active?.hasOwnProperty("tiktok") ? true : false,
      name: "tiktok",
      link: socialLinks?.active?.["tiktok"],
    },
    {
      key: "YouTube",
      icon: <BsYoutube />,
      show: socialLinks?.active?.hasOwnProperty("youtube") ? true : false,
      name: "youtube",
      link: socialLinks?.active?.["youtube"],
    },
    {
      key: "Podcast",
      icon: <MdOutlinePodcasts />,
      show: socialLinks?.active?.hasOwnProperty("podcast") ? true : false,
      name: "podcast",
      link: socialLinks?.active?.["podcast"],
    },
    {
      key: "Pinterest",
      icon: <BsPinterest />,
      show: socialLinks?.active?.hasOwnProperty("pinterest") ? true : false,
      name: "pinterest",
      link: socialLinks?.active?.["pinterest"],
    },
    {
      key: "Newsletter",
      icon: <IoMailOutline />,
      show: socialLinks?.active?.hasOwnProperty("newsletter") ? true : false,
      name: "newsletter",
      link: socialLinks?.active?.["newsletter"],
    },
    {
      key: "Facebook",
      icon: <FaFacebookSquare />,
      show: socialLinks?.active?.hasOwnProperty("facebook") ? true : false,
      name: "facebook",
      link: socialLinks?.active?.["facebook"],
    },
    {
      key: "Amazon",
      icon: <FaAmazon />,
      show: socialLinks?.active?.hasOwnProperty("amazon") ? true : false,
      name: "amazon",
      link: socialLinks?.active?.["amazon"],
    },
    {
      key: "Twitter",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill={socialLinks?.data?.brand_text_color}
          // fill="currentColor"
          className="bi bi-twitter-x"
          viewBox="0 0 16 16"
        >
          <path
            d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"
            fill={socialLinks?.data?.brand_text_color}
          />
        </svg>
      ),

      show: socialLinks?.active?.hasOwnProperty("twitter") ? true : false,
      name: "twitter",
      link: socialLinks?.active?.["twitter"],
    },

    {
      key: "Spotify",
      icon: <FaSpotify />,
      show: socialLinks?.active?.hasOwnProperty("spotify") ? true : false,
      name: "spotify",
      link: socialLinks?.active?.["spotify"],
    },
    {
      key: "Shop",
      icon: <RiShoppingBagFill />,
      show: socialLinks?.active?.hasOwnProperty("shop") ? true : false,
      name: "shop",
      link: socialLinks?.active?.["shop"],
    },
    {
      key: "ApplePodcast",
      icon: <FaApple />,
      show: socialLinks?.active?.hasOwnProperty("applepodcast") ? true : false,
      name: "applepodcast",
      link: socialLinks?.active?.["applepodcast"],
    },

    {
      key: "LTK",
      icon: (
        <svg
          className=""
          width="24px"
          height="24px"
          viewBox="-500 -500 4500 4500"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1582 3519 c-147 -16 -382 -73 -382 -93 0 -6 103 -114 228 -239 l228 -228 134 -3 c258 -7 478 -80 678 -223 47 -34 74 -48 77 -40 3 6 39 138 80 292 69 260 73 281 57 294 -33 27 -259 130 -347 159 -239 79 -501 107 -753 81z  M849 3269 c-331 -199 -594 -518 -732 -889 -37 -101 -84 -277 -75 -285 3 -3 133 29 289 70 156 42 300 80 320 85 27 8 39 19 53 47 37 79 128 205 203 284 105 110 214 190 355 258 l117 57 -212 212 c-117 117 -217 212 -223 212 -6 0 -49 -23 -95 -51z  M2906 3092 c-3 -9 -42 -153 -86 -320 l-80 -303 55 -90 c132 -220 192 -481 166 -726 -6 -64 -9 -118 -7 -121 4 -4 431 96 551 129 l35 10 0 78 c0 179 -36 406 -91 573 -42 130 -156 355 -236 468 -66 91 -249 292 -284 311 -13 7 -19 5 -23 -9z  M1396 2203 l-376 -338 0 -226 0 -226 166 -149 c92 -82 170 -149 174 -149 4 0 98 82 210 182 l203 181 180 -161 c98 -89 192 -172 207 -184 l28 -23 170 153 171 152 0 225 0 225 -330 295 c-181 162 -351 314 -378 338 l-49 42 -376 -337z  M465 1950 c-55 -16 -180 -49 -278 -75 l-177 -48 0 -105 c0 -375 145 -770 397 -1082 65 -80 195 -210 210 -210 8 0 183 600 183 627 0 5 -22 47 -49 92 -62 102 -106 207 -139 330 -22 84 -25 117 -26 266 -1 94 2 185 6 203 9 40 8 40 -127 2z  M3173 1328 l-322 -80 -40 -73 c-76 -138 -215 -290 -353 -388 -68 -49 -233 -137 -255 -137 -7 0 -13 -3 -13 -8 0 -11 410 -432 421 -432 5 0 41 19 80 43 346 207 634 568 757 947 28 86 54 190 50 200 -2 4 -148 -28 -325 -72z  M997 842 c-15 -27 -158 -564 -154 -575 9 -24 241 -136 366 -177 171 -56 310 -81 493 -87 194 -7 340 8 516 53 74 19 138 37 144 40 6 4 -95 112 -223 240 l-234 234 -115 0 c-267 0 -507 76 -717 226 -40 29 -74 50 -76 46z"
            fill={socialLinks?.data?.brand_text_color}
          ></path>
        </svg>
      ),
      show: socialLinks?.active?.hasOwnProperty("ltk") ? true : false,
      name: "ltk",
      link: socialLinks?.active?.["ltk"],
    },
    {
      key: "Shopmy",
      icon: (
        <svg className="" width="24px" height="24px" viewBox="0 0 1471 401">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M375.109 304.5C384.109 304.67 393.109 304.83 402.109 305V313H294.109V305C303.489 305.02 312.879 304.54 321.609 304.5V199.5C321.609 180.48 322.959 159.76 315.609 148C309.969 138.97 301.949 136.14 289.609 133.5C286.449 132.82 282.459 133.91 280.109 134.5C266.329 137.95 258.489 142.12 250.109 150.5L242.109 158.5V304.5C250.829 304.5 260.239 304.98 269.609 305V313H161.109V305C170.489 305.02 179.879 304.54 188.609 304.5V45L167.609 24C164.989 21.38 161.299 20.06 161.109 15L161.609 14.5C167.249 14.38 173.199 12.76 178.109 11.5C187.349 9.13 196.919 8.72 205.609 6.5C217.769 3.39 230.789 4.14 242.109 0V147.5H242.609C253.059 129.91 271.039 114.39 292.109 107.5C323.649 97.18 359.679 112.09 369.109 133.5C376.069 149.29 375.109 172.78 375.109 196V304.5Z"
            fill={socialLinks?.data?.brand_text_color}
          ></path>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M977.609 102.5C977.439 118.5 977.279 134.5 977.109 150.5H977.609V150C981.179 146.46 983.599 141.03 986.609 137C993.479 127.8 1004.2 119.11 1014.11 113C1019.24 109.83 1025.95 107.81 1032.11 106C1037.88 104.3 1047.94 103.52 1054.61 105C1057.78 105.33 1060.94 105.67 1064.11 106C1079.96 110.74 1091.54 118.96 1099.11 132C1102.43 137.71 1102.77 144.8 1105.11 151.5C1108.41 148.25 1110.35 143.26 1113.11 139.5C1118.75 131.82 1129.03 121.64 1137.11 116.5C1144.45 111.84 1152.66 107.98 1162.11 105.5C1179.55 100.93 1197.99 107.24 1208.11 113.5C1218.12 119.69 1225.79 126.72 1230.11 138.5C1235.75 153.89 1234.11 176.99 1234.11 197.5V304.5C1242.94 304.67 1251.78 304.83 1260.61 305V313H1153.61V305C1162.44 304.83 1171.28 304.67 1180.11 304.5V198C1180.11 178.38 1181.45 157.74 1173.61 146C1164.24 131.98 1141.31 131.81 1126.61 141C1117.96 146.41 1112.74 154.65 1105.61 161.5V304.5C1114.61 304.67 1123.61 304.83 1132.61 305V313H1025.61V305C1034.44 304.83 1043.28 304.67 1052.11 304.5V198C1052.11 178.49 1053.44 157.72 1045.61 146C1036.68 132.64 1014.25 131.44 999.609 140.5C990.899 145.89 985.279 153.66 978.109 160.5V304.5C986.939 304.67 995.779 304.83 1004.61 305V313H897.109V305C906.279 304.83 915.439 304.67 924.609 304.5V150C916.699 144.91 910.099 136.72 903.109 130.5C900.389 128.08 897.229 127.66 897.109 122.5C904.969 122.12 913.749 118.75 920.609 116.5C931.159 113.04 941.549 112.22 952.109 109C960.009 106.59 968.869 103.26 977.609 102.5Z"
            fill={socialLinks?.data?.brand_text_color}
          ></path>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M141.609 162H139.109C136.079 157.5 128.569 152.37 124.109 149C122.939 147.67 121.779 146.33 120.609 145C107.759 135.3 94.4593 119.86 78.1093 114.5C55.4593 107.07 33.5393 129.89 40.6093 153C46.6093 172.61 69.0293 178.72 87.1093 186.5C116.149 199 143.909 210.65 152.609 243.5C155.229 253.41 153.379 267.45 150.609 275C141.939 298.59 125.079 308.07 99.6093 315C93.3993 316.69 75.3693 319.42 66.6093 317.5C55.1593 314.99 43.7393 315.47 33.1093 312.5C27.9493 311.06 22.3993 309.95 17.1093 308C13.6693 306.73 10.2893 304.62 6.10926 304C4.27926 285.34 2.43926 266.66 0.609256 248H3.10926C4.84926 251.38 8.04926 253.43 10.6093 256C14.3593 259.76 17.8593 263.75 21.6093 267.5C34.4893 280.38 48.6893 299.88 67.1093 306.5C85.2693 313.03 103.799 302.15 110.109 291.5C112.039 288.24 112.709 283.57 113.609 279.5C115.049 272.95 112.009 266.15 110.109 262.5C103.379 249.57 92.8293 243.96 79.1093 238C46.8193 223.99 11.9293 213.71 1.60926 177.5C-1.83074 165.41 0.749256 149.94 4.60926 141C14.9593 117.04 40.3693 104.5 74.6093 104.5C82.2493 104.5 89.7893 104.59 96.1093 106C98.9393 106.17 101.779 106.33 104.609 106.5C111.539 108.1 119.529 108.76 126.109 110.5C129.439 111.38 133.579 110.78 136.109 112.5C138.119 115.59 136.669 120.76 137.609 125C139.999 135.79 141.249 150.44 141.609 162Z"
            fill={socialLinks?.data?.brand_text_color}
          ></path>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1240.61 110H1350.61V117.5C1341.67 117.62 1331.3 117.98 1321.61 118V118.5C1323.6 120.86 1323.8 124.41 1325.11 127.5C1328.32 135.06 1330.8 143.05 1334.11 151C1344.52 176.02 1353.71 202.47 1364.11 227.5C1367.75 236.27 1370.64 245.12 1374.11 253.5C1375.29 256.35 1375.34 259.32 1377.11 261.5C1377.68 256.57 1380.87 251.65 1382.61 247.5C1387.32 236.27 1391.08 224.85 1395.61 214C1408.85 182.3 1418.3 148.86 1432.61 118H1402.11C1401.19 117.38 1399.26 117.46 1397.61 117.5V110H1470.61V117.5C1461.83 117.62 1451.64 117.98 1442.11 118C1441.67 121.76 1439.4 124.98 1438.11 128C1435.38 134.39 1433.2 140.94 1430.61 147C1422.03 167.1 1415.01 187.75 1406.61 207.5C1389.87 246.9 1376.21 287.42 1359.61 326.5C1354.78 337.86 1350.68 349.47 1346.11 360.5C1343.88 365.87 1343.03 371.27 1340.11 376C1333.73 386.34 1314.79 403.56 1297.11 400H1290.61C1285.18 398.73 1279.93 397.53 1275.61 395.5C1274.78 395.33 1273.94 395.17 1273.11 395V389C1272.11 372 1271.11 355 1270.11 338C1271.38 338.04 1271.97 338.06 1272.61 338.5C1277.46 340.23 1280.69 346.53 1284.61 349.5C1294.75 357.18 1303.45 368.74 1317.11 372.5C1335.28 377.5 1339.85 350.46 1344.11 340C1345.07 337.65 1350.21 328.63 1349.61 326C1348.55 321.34 1344.54 314.53 1342.61 310C1337.49 297.98 1333.1 285.73 1328.11 274C1312.5 237.31 1299.06 199.86 1283.61 163.5C1278.81 152.21 1274.74 140.91 1270.11 130C1269.29 128.06 1266.72 118.65 1265.61 118H1243.61C1242.9 117.52 1241.97 117.51 1240.61 117.5V110Z"
            fill={socialLinks?.data?.brand_text_color}
          ></path>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M605.109 154.5C584.269 120.21 556.149 103.83 500.109 104.5C498.405 105.57 495.814 105.586 493.229 105.602C491.416 105.614 489.606 105.625 488.109 106C486.207 106.477 484.261 106.914 482.309 107.353C477.252 108.491 472.147 109.639 467.609 111.5C442.239 121.9 422.969 140.67 412.609 166C410.313 171.619 408.939 177.848 407.551 184.143C407.084 186.261 406.616 188.387 406.109 190.5C403.209 202.62 404.379 223.02 407.109 234C407.801 236.787 408.364 239.405 408.904 241.92C410.154 247.733 411.285 252.992 413.609 258.5C423.059 280.89 441.849 299.36 464.109 309C466.804 310.164 469.339 310.796 472.008 311.46C473.787 311.903 475.626 312.36 477.609 313C491.629 317.53 516.819 320.18 534.109 316C535.245 315.725 536.378 315.454 537.506 315.185C545.404 313.297 553.101 311.458 560.109 308.5C585.119 297.95 604.369 274.83 613.109 248C614.566 243.522 615.263 238.824 615.975 234.024C616.441 230.882 616.913 227.697 617.609 224.5V216.5C619.479 207.83 617.579 191.34 615.609 183.5C613.079 173.4 610.049 162.63 605.109 154.5ZM502.684 114.717C504.295 114.493 505.907 114.269 507.109 113.5C517.059 113.42 524.519 114.73 530.609 118.5C537.469 122.75 541.829 129.09 546.109 136C569.269 173.38 566.939 272.93 536.109 299C530.629 303.63 523.919 306.12 515.609 308C501.669 311.16 487.939 300.1 483.109 293.5C476.459 284.42 470.849 274.82 467.109 262.5C465.647 257.687 465.231 252.964 464.801 248.079C464.446 244.043 464.081 239.898 463.109 235.5C461.639 228.82 460.779 215.45 462.109 209C462.194 205.244 462.276 201.489 462.359 197.733C462.441 193.989 462.524 190.244 462.609 186.5C463.185 183.853 463.436 181.069 463.689 178.261C463.985 174.978 464.284 171.664 465.109 168.5C469.139 153.07 473.549 140.87 481.609 130C482.102 129.335 482.577 128.617 483.058 127.891C484.253 126.084 485.482 124.227 487.109 123C490.719 120.28 494.739 117.28 499.109 115.5C500.17 115.066 501.427 114.891 502.684 114.717Z"
            fill={socialLinks?.data?.brand_text_color}
          ></path>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M700.859 122C700.942 115.5 701.024 109 701.109 102.5C690.694 105.085 680.277 107.667 669.859 110.25C659.442 112.833 649.024 115.415 638.609 118C637.454 118.357 636.246 118.76 635.003 119.176C630.596 120.649 625.734 122.274 621.109 122.5L620.609 123C620.811 126.219 622.351 127.265 624.078 128.439C624.749 128.895 625.449 129.37 626.109 130C626.427 130.302 626.745 130.604 627.062 130.906C632.389 135.973 637.692 141.017 643.609 145.5C644.272 146 644.743 146.591 645.209 147.174C645.947 148.098 646.669 149.004 648.109 149.5V393C638.959 393.01 628.979 393.16 620.609 393.5V401H740.109V393.5C735.664 393.452 731.001 393.369 726.242 393.283C718.11 393.138 709.696 392.987 701.609 393V284L713.609 297.5C714.175 297.927 714.738 298.353 715.299 298.778C724.398 305.663 733.092 312.242 745.609 316C761.709 320.83 782.269 315.54 792.609 309.5C816.209 295.72 831.949 277.7 841.109 249.5C842.091 246.483 842.463 243.69 842.849 240.787C843.156 238.483 843.472 236.11 844.109 233.5C846.049 225.53 846.109 216 846.109 206V197C845.207 192.819 844.689 188.631 844.179 184.511C843.406 178.265 842.652 172.176 840.609 166.5C833.259 146.05 821.949 127.96 805.609 116.5C799.499 112.21 791.829 108.98 784.109 106.5C775.089 103.61 759.159 103.36 749.609 106C728.749 111.76 711.609 125.33 701.109 141.5H700.609C700.694 135 700.777 128.5 700.859 122ZM733.175 128.323C735.5 127.962 737.862 127.595 739.609 126.5C749.579 126.42 757.479 127.69 763.609 131.5C799.799 153.99 798.399 253.02 773.109 287C765.789 296.84 745.999 302.96 730.109 296.5C720.619 292.64 713.229 286.26 706.609 279.5C705.845 278.719 705.201 277.81 704.558 276.902C703.679 275.66 702.799 274.418 701.609 273.5V151C702.024 150.665 702.442 150.333 702.859 150C703.277 149.668 703.694 149.335 704.109 149C709.979 141.11 718.549 133.55 728.109 129.5C729.569 128.882 731.361 128.604 733.175 128.323Z"
            fill={socialLinks?.data?.brand_text_color}
          ></path>
        </svg>
      ),
      show: socialLinks?.active?.hasOwnProperty("shopmy") ? true : false,
      name: "shopmy",
      link: socialLinks?.active?.["shopmy"],
    },
    {
      key: "Snapchat",
      icon: <FaSnapchatGhost />,
      show: socialLinks?.active?.hasOwnProperty("snapchat") ? true : false,
      name: "snapchat",
      link: socialLinks?.active?.["snapchat"],
    },
    {
      key: "LinkedIn",
      icon: <FaLinkedin />,
      show: socialLinks?.active?.hasOwnProperty("linkedin") ? true : false,
      name: "linkedin",
      link: socialLinks?.active?.["linkedin"],
    },
    {
      key: "Website",
      icon: <HiLink />,
      show: socialLinks?.active?.hasOwnProperty("website") ? true : false,
      name: "website",
      link: socialLinks?.active?.["website"],
    },
    {
      key: "Discord",
      icon: <FaDiscord />,
      show: socialLinks?.active?.hasOwnProperty("discord") ? true : false,
      name: "discord",
      link: socialLinks?.active?.["discord"],
    },
  ];

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: any) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    setIsDragging(false);

    const reorderedIcons = reorder(iconList, source.index, destination.index);
    const keys = reorderedIcons.map((icon: any) => icon.key.toLowerCase());

    setIconList(reorderedIcons);
    reorderSocialLinks(
      user_id,
      socialLinks?.data?.social_links_order,
      keys,
      keys.length
    );
    setIsDragging(false);
  };

  const handleOk = async () => {
    try {
      setShowAvailableIcons(false);
    } catch (error) {
      console.error("Validation Failed:", error);
    }
  };

  const handleCancel = () => {
    setIsContactModalVisible(false);
  };
  return (
    <>
      <div style={{ width: "100%" }} className="account-social-content-connect">
        {(isLoading && linksLoading) || textColorLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100px",
            }}
          >
            <Spin />
          </div>
        ) : (
          <>
            {isUserDashboard && (
              <>
                {" "}
                <button className="search-button">
                  <BsSearch />
                </button>
                <button
                  className="search-button"
                  onClick={() => setIsContactModalVisible(true)}
                >
                  <MdContactMail />
                </button>
              </>
            )}
            {/* <div className="SocialLinksIcon">
              <DraggableActiveSocialLinks
                user_id={user_id}
                isUserDashboard={isUserDashboard}
                textColor={textColor}
                iconList={iconList}
              setIconList={setIconList}
              setShowAvailableIcons={setShowAvailableIcons}
              setIntegrationModal={setIntegrationModal}
              setShowLinkIndex={setShowLinkIndex}
            />
            </div> */}
            <DragDropContext
              onDragEnd={onDragEnd}
              onDragStart={() => setIsDragging(true)}
            >
              <Droppable droppableId="icons" direction="horizontal">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="SocialLinksIcon"
                  >
                    {iconList.map((icon: any, index: any) => (
                      <Draggable
                        key={icon.name}
                        draggableId={icon.key}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={
                              isUserDashboard ? "edit-link-wrapper" : ""
                            }
                            style={
                              isUserDashboard && !isDragging
                                ? {
                                  ...provided.draggableProps.style,
                                  opacity: snapshot.isDragging ? 0.5 : 1,
                                  position: "relative",
                                  cursor: "move",
                                  width: "40px",
                                  height: "40px",
                                }
                                : {
                                  ...provided.draggableProps.style,
                                  opacity: snapshot.isDragging ? 0.5 : 1,
                                  cursor: "move",
                                  width: "40px",
                                  height: "40px",
                                }
                            }
                          >
                            <button
                              className={
                                isUserDashboard
                                  ? "active-social-links profile-links-button"
                                  : "active-social-links"
                              }
                              key={icon.key}
                              style={{
                                backgroundColor: `${socialLinks?.data?.brand_color}`,
                                color: `${textColor}`,
                              }}
                            >
                              {icon.icon}
                            </button>
                            {isUserDashboard && (
                              <button
                                className="active-social-links profile-edit-button"
                                key={icon.key}
                                onClick={() => {
                                  setShowAvailableIcons(true);
                                  setIntegrationModal("edit-icons");
                                  setShowLinkIndex(icon.name);
                                }}
                                onTouchStart={() => {
                                  if (!isDragging) {
                                    setTimeout(() => {
                                      setShowAvailableIcons(true);
                                      setIntegrationModal("edit-icons");
                                      setShowLinkIndex(icon.name);
                                    }, 0);
                                  }
                                }}
                              >
                                <GrFormEdit
                                  className="edit-icon"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    padding: "4px",
                                  }}
                                />
                              </button>
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {isUserDashboard && (
              <IoIosAddCircle
                className="add-button"
                style={{ padding: "0" }}
                onClick={() => {
                  setShowAvailableIcons(true);
                  setIntegrationModal("add-icons");
                }}
              />
            )}
            <button className="search-button">
            <GrFormEdit
              className="edit-icon-configure"
              onClick={(prev) => setIsModalOpen(true)}
              style={{
                width: "100%",
                height: "100%",
                padding: "4px",
              }}
            />
            </button>
            <ColorModal
              fromScreen="socialLinksScreen"
              ModalTitle="Icons Color"
              isColorModalOpen={isModalOpen}
              setIsColorModalOpen={setIsModalOpen}
              handleColor={handleColor}
              username={username}
              user_id={user_id}
              defaultColor={localDefaultColor}
              defaultTextColor={localDefaultTextColor}
              refetch={refetchControls}
              initialColor={defaultColor}
              initialTextColor={defaultTextColor}
              setLocalDefaultColor={setLocalDefaultColor}
              setLocalDefaultTextColor={setLocalDefaultTextColor}
            />
          </>
        )}
      </div>
      <AvailableSocialLinksModal
        visible={showAvailableIcons}
        onClose={() => setShowAvailableIcons(false)}
        onSubmit={handleOk}
        user_id={user_id}
        integrationModal={integrationModal}
        showLinkIndex={showLinkIndex}
        setShowLinkIndex={setShowLinkIndex}
      />
      <ContactMeModal
        onClose={handleCancel}
        isContactModalVisible={isContactModalVisible}
        creatorId={user_id}
        username={username || ""}
      />
    </>
  );
};

export default ActiveSocialLinks;
