import React from "react";
import { useLoadLinks } from "../../../../../Utils/customHooks/useLoadLinks";
import MyLinksSkeletonTemplateWrapper from "../MyLinksSkeletonTemplateWrapper";
import track, {
  TRACKING_TYPES,
} from "../../../../../Utils/SupabaseServices/Track";
import { useLocation } from "react-router-dom";

const MyLinksWrapper = (props: any) => {
  const location = useLocation();
  const { user_id, username, title, brand_color, details } = props;

  let {
    isLoading,

    data,

    refetch,
  } = useLoadLinks(user_id);
  function linkAnalytics(link: any, url: any) {
    track(`my links link click`, TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      user_id: user_id,
      details: { link: link, url: url },
      title: "Stay Up To Date",
    });
  }
  return (
    <>
      {isLoading ? (
        <div className="creator-card-grid-wrp-subcomponents p-20">
          <MyLinksSkeletonTemplateWrapper />
        </div>
      ) : (
        <div
          className="creator-card-grid-wrp-subcomponents"
          style={
            details?.includes("line")
              ? { gridTemplateColumns: "repeat(1, 1fr)", gridGap: "12px" }
              : {}
          }
        >
          {data &&
            data.length > 0 &&
            data.split(",").map((link: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  <CardGridItem
                    link={link}
                    username={username}
                    brand_color={brand_color}
                    linkAnalytics={linkAnalytics}
                    isGridView={details?.includes("line") ? false : true}
                  />
                </React.Fragment>
              );
            })}
        </div>
      )}
    </>
  );
};
const CardGridItem = ({
  link,
  username,
  linkAnalytics,
  brand_color,
  isGridView,
}: any) => (
  <a
    href={
      link?.split(":-")[1]?.startsWith("http") ||
      link?.split(":-")[1]?.startsWith("mailto")
        ? link?.split(":-")[1]
        : `http://${link?.split(":-")[1]}`
    }
    target="_blank"
    onClick={() => linkAnalytics(link?.split(":-")[0], link?.split(":-")[1])}
    className={isGridView ? "card-link" : "card-link card-line"}
  >
    {link?.split(":-")[2] && isGridView ? (
      <>
        <div
          className={isGridView ? "creator-card" : "creator-card-line"}
          style={
            isGridView ? { backgroundColor: brand_color && brand_color } : {}
          }
        >
          <div className={isGridView ? "creator-card-cover" : "d-flex"}>
            <img
              className={
                isGridView ? "creator-card-img" : "creator-card-img-line"
              }
              style={
                !isGridView
                  ? { backgroundColor: brand_color && brand_color }
                  : {}
              }
              alt="example"
              src={link?.split(":-")[2]}
            />
            <a
              href={
                link?.split(":-")[1]?.startsWith("http") ||
                link?.split(":-")[1]?.startsWith("mailto")
                  ? link?.split(":-")[1]
                  : `http://${link?.split(":-")[1]}`
              }
              target="_blank"
              className="card-link"
              style={!isGridView ? { width: "50%" } : {}}
              onClick={() =>
                linkAnalytics(link?.split(":-")[0], link?.split(":-")[1])
              }
            >
              {" "}
              <div className="creator-card-meta">{link?.split(":-")[0]}</div>
            </a>
          </div>
        </div>
      </>
    ) : (
      <div
        className="creator-card"
        style={{ backgroundColor: brand_color && brand_color, height: "100%" }}
      >
        <a
          href={
            link?.split(":-")[1]?.startsWith("http") ||
            link?.split(":-")[1]?.startsWith("mailto")
              ? link?.split(":-")[1]
              : `http://${link?.split(":-")[1]}`
          }
          target="_blank"
          className="card-link h-100"
          onClick={() =>
            linkAnalytics(link?.split(":-")[0], link?.split(":-")[1])
          }
        >
          {" "}
          <div
            className="creator-card-meta"
            style={!isGridView ? { minHeight: "100px" } : {}}
          >
            {link?.split(":-")[0]}
          </div>
        </a>
      </div>
    )}
  </a>
);

export default MyLinksWrapper;
