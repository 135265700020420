import React, { useEffect, useState } from "react";
import ConnectInstagramStep from "./OnboardingSteps/ConnectInstagramStep";
import ConnectLinkinbioStep from "./OnboardingSteps/ConnectLinkinbioStep";
import ReviewConnectionsStep from "./OnboardingSteps/ReviewConnectionsStep";
import ConnectPlatformStep from "./OnboardingSteps/ConnectPlatformStep";
import ConnectSocialLinksStep from "./OnboardingSteps/ConnectSocialLinksStep";
import UserInfoStep from "./OnboardingSteps/UserInfoStep";
import UsernameSection from "./OnboardingSteps/UsernameSection";
import CompletionStep from "./OnboardingSteps/CompletionStep";
import { supabase } from "../../../config/supabaseClient";
import { IntegratePlatforms } from "../../../Utils/SupabaseServices/OnboardingFlow";

const OnboardingPage = () => {
  // let currentStep = 0;
  const [currentStep, setCurrentStep] = useState(0);
  const [linkinbioUsername, setLinkinbioUsername] = useState("");

  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [instagramUsername, setInstagramUsername] = useState("");
  const [formData, setFormData] = React.useState({
    name: "",

    email: "",
    phone: "",
    password: "",
  });
  const [selectedPlatforms, setSelectedPlatforms] = useState<
    Array<{ platform: string; username: string }>
  >([
    {
      platform: "instagram",
      username: instagramUsername,
    },
  ]);
  const handlePlatformToggle = (platformId: any) => {
    setSelectedPlatforms((prev: any) =>
      prev.includes(platformId)
        ? prev.filter((id: any) => id !== platformId)
        : [...prev, platformId]
    );
  };

  const handleNext = () => {
    // if (currentStep === 0 && !username.trim()) {
    //   // alert("Please enter your Instagram username");
    //   return;
    // }

    // if (currentStep === 1 && selectedPlatforms.length === 0) {
    //   alert("You must select at least one platform");
    //   return;
    // }

    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleComplete = async () => {
    handleNext();
    console.log("Onboarding complete:", { username, selectedPlatforms });
    const {data} = await supabase.from("user").update({onboarding_step:currentStep}).eq("username",username)
    IntegratePlatforms(selectedPlatforms,username)
    // alert("Your page will be ready soon!");
  };

  // Shared button styles
  const buttonStyle = {
    backgroundColor: "#000",
    color: "#fff",
    border: "none",
    padding: "8px 16px",
    borderRadius: 4,
    cursor: "pointer",
    width: "100%",
    marginTop: 12,
  };

  const containerStyle = {
    minHeight: "100vh",
    backgroundColor: "#f0f2f5",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 16,
  };

  const cardStyle = {
    width: "100%",
    maxWidth: 480,
    backgroundColor: "#fff",
    borderRadius: 8,
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
    padding: 24,
  };
  console.log("currentStep", currentStep, formData);
  useEffect(()=>{
    console.log("selectedPlatforms",selectedPlatforms)
    setSelectedPlatforms([
      {
        platform: "instagram",
        username: instagramUsername,
      },
    ]);
  },[instagramUsername])
  return (
    <div style={containerStyle}>
      <div style={cardStyle}>
        <div style={{ marginTop: "24px" }}>
          {currentStep === 0 && (
            //  <div
            //  style={{
            //    marginBottom: "24px",
            //    borderBottom: "1px solid #eee",
            //    paddingBottom: "24px",
            //  }}
            // >
            <UsernameSection
              username={username}
              setUsername={setUsername}
              usernameError={usernameError}
              setUsernameError={setUsernameError}
              handleNext={handleNext}
              currentStep={currentStep}
              //  handleChange={handleChange}
              // usernameError={usernameError}
            />
            // </div>
          )}

          {currentStep === 1 && (
            <>
              <UserInfoStep
                handleNext={handleNext}
                handleBack={handleBack}
                formData={formData}
                setFormData={setFormData}
                currentStep={currentStep}
                setUsername={setUsername}
                setUsernameError={setUsernameError}
                username={username}
              />
            </>
          )}

          {currentStep === 2 && (
            <ConnectInstagramStep
              username={username}
              setUsername={setUsername}
              handleNext={handleNext}
              buttonStyle={buttonStyle}
              currentStep={currentStep}
              instagramUsername={instagramUsername}
              setInstagramUsername={setInstagramUsername}
            />
          )}

          {currentStep === 3 && (
            <ConnectLinkinbioStep
              handleNext={handleNext}
              buttonStyle={buttonStyle}
              handleBack={handleBack}
              username={username}
              setUsername={setUsername}
              setLinkinbioUsername={setLinkinbioUsername}
              linkinbioUsername={linkinbioUsername}
              currentStep={currentStep}
            />
          )}
          {currentStep === 4 && (
            <ConnectPlatformStep
              username={username}
              setUsername={setUsername}
              handleNext={handleNext}
              buttonStyle={buttonStyle}
              handleBack={handleBack}
              setSelectedPlatforms={setSelectedPlatforms}
              selectedPlatforms={selectedPlatforms}
              setInstagramUsername={setInstagramUsername}
              instagramUsername={instagramUsername}
              // platforms={platforms}
            />
          )}

          {/* {currentStep === 4 && (
            <ConnectSocialLinksStep
              username={username}
              setUsername={setUsername}
              handleNext={handleNext}
              buttonStyle={buttonStyle}
              handleBack={handleBack}
              selectedPlatforms={selectedPlatforms}
              // platforms={platforms}
            />
          )} */}

          {currentStep === 5 && (
            <ReviewConnectionsStep
              username={username}
              setUsername={setUsername}
              handleNext={handleNext}
              buttonStyle={buttonStyle}
              handleBack={handleBack}
              selectedPlatforms={selectedPlatforms}
              // platforms={platforms}
              handleComplete={handleComplete}
              currentStep={currentStep}
            />
          )}

          {currentStep === 6 && (
            <CompletionStep 
            username={username}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OnboardingPage;
