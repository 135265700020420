import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../../config/supabaseClient";

// Hook for adding or removing tags
export default function useUpdateSocialLink() {
  const queryClient = useQueryClient();

  const submitTag = async ({
    link,
    platform,
    pageIndex,
    index,
    user_id,
  }: {
    link: any;
    platform: any;
    pageIndex: number;
    index: number;
    user_id: any;
  }) => {
    let errorLink;


    if (platform === "instagram") {
      const { error } = await supabase
        .from("user")
        .update({ social_instagram: link })
        .eq("user_id", `${user_id}`);
      errorLink = error;
    } else if (platform === "youtube") {
      const { error } = await supabase
        .from("user")
        .update({ social_youtube: link })
        .eq("user_id", `${user_id}`);
      errorLink = error;
    }

    else if (platform === "twitter") {
      const { error } = await supabase
        .from("user")
        .update({ social_twitter: link })
        .eq("user_id", `${user_id}`);
      errorLink = error;
    } else if (platform === "tiktok") {
      const { error } = await supabase
        .from("user")
        .update({ social_tiktok: link })
        .eq("user_id", `${user_id}`);
      errorLink = error;
    } else if (platform === "podcast") {
      const { error } = await supabase
        .from("user")
        .update({ social_podcast: link })
        .eq("user_id", `${user_id}`);
      errorLink = error;
    } else if (platform === "pinterest") {
      const { error } = await supabase
        .from("user")
        .update({ social_pinterest: link })
        .eq("user_id", `${user_id}`);
      errorLink = error;
    } else if (platform === "newsletter") {
      const { error } = await supabase
        .from("user")
        .update({ social_newsletter: link })
        .eq("user_id", `${user_id}`);
      errorLink = error;
    } else if (platform === "facebook") {
      const { error } = await supabase
        .from("user")
        .update({ social_facebook: link })
        .eq("user_id", `${user_id}`);
      errorLink = error;
    } else if (platform === "amazon") {
      const { error } = await supabase
        .from("user")
        .update({ social_amazon: link })
        .eq("user_id", `${user_id}`);
      errorLink = error;
    }
    else if (platform === "spotify") {
      const { error } = await supabase
        .from("user")
        .update({ social_spotify: link })
        .eq("user_id", `${user_id}`);
      errorLink = error;
    }
    else if (platform === "shop") {
      const { error } = await supabase
        .from("user")
        .update({ social_shop: link })
        .eq("user_id", `${user_id}`);
      errorLink = error;
    } else if (platform === "shopmy") {
      const { error } = await supabase
        .from("user")
        .update({ social_shopmy: link })
        .eq("user_id", `${user_id}`);
      errorLink = error;
    }
    else if (platform === "ltk") {
      const { error } = await supabase
        .from("user")
        .update({ social_ltk: link })
        .eq("user_id", `${user_id}`);
      errorLink = error;
    }
    else if (platform === "applepodcast") {
      const { error } = await supabase
        .from("user")
        .update({ social_applepodcast: link })
        .eq("user_id", `${user_id}`);
      errorLink = error;
    }
    else if (platform === "snapchat") {
      const { error } = await supabase
        .from("user")
        .update({ social_snapchat: link })
        .eq("user_id", `${user_id}`);
      errorLink = error;
    }
    else if (platform === "website") {
      const { error } = await supabase
        .from("user")
        .update({ social_website: link })
        .eq("user_id", `${user_id}`);
      errorLink = error;
    }
    else if (platform === "linkedin") {
      const { error } = await supabase
        .from("user")
        .update({ social_linkedin: link })
        .eq("user_id", `${user_id}`);
      errorLink = error;
    }
    else if (platform === "discord") {
      const { error } = await supabase
        .from("user")
        .update({ social_discord: link })
        .eq("user_id", `${user_id}`);
      errorLink = error;
    }
    // return true;
    if (errorLink) {
      throw new Error(errorLink.message);
    } else {
      return true;
    }
  };

  return useMutation(submitTag, {
    onMutate: async ({ user_id, link }) => {
      await queryClient.cancelQueries(["getSocialLinks"]);
      const previousData = queryClient.getQueryData([
        "getSocialLinks",
        user_id,
        link,
      ]);


      return { previousData };
    },
    onError: (_error, { }, context) => {
      queryClient.setQueryData(["getSocialLinks"], context?.previousData);
    },
    onSettled: (_data, _error, { pageIndex }) => {
      queryClient.invalidateQueries(["getSocialLinks"], {
        refetchPage: (page, index) => {
          return index === pageIndex;
        },
      });
    },
  });
}
